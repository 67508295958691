import { GetterTree } from "vuex";

import { AttachmentType } from "@/types";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";
import { AttachmentsState } from "./types";

const attachmentGetters: GetterTree<
  State & AttachmentsState & ModelsState & SidebarsState,
  unknown
> = {
  getAvailableAttachmentTypes: (state) => {
    return state.attachmentTypes.filter(
      (type: AttachmentType) => !state.selectedAttachmentTypes.includes(type)
    );
  },

  getSelectedAttachmentFile: (state) => {
    return state.selectedAttachmentFile;
  },

  getIfcHidden: (state) => {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      return model.hidden;
    }
    return [];
  },

  getSelectedAttachments: (state) => {
    return state.selectedAttachmentTypes;
  },

  getAttachmentItems: (state) => {
    return state.attachmentTypes;
  },
};
export default attachmentGetters;
