import { BoxHelper, Camera, Group, Scene, Vector2, Vector3, WebGLRenderer } from "three";
import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import {
  Roles,
  TagItemInterface,
  ViewerInspectorMode,
  ViewerInterface,
  ViewerObjectTypeCategory,
} from "@/types";

import mockTags from "../../../assets/mock-data/tags.json";
import { attachmentsActions } from "./attachments";
import { bottomMenuActions } from "./bottom-menu";
import { modelsActions } from "./models";
import { pointcloudsActions } from "./pointclouds";
import { pointsActions } from "./points";
import { settingsActions } from "./settings";
import { sidebarsActions } from "./sidebars";
import { transformActions } from "./transform";
import { State } from "./types";

const actions: ActionTree<State, unknown> = {
  /**
   * Spread actions from other modules.
   */
  ...attachmentsActions,
  ...bottomMenuActions,
  ...modelsActions,
  ...pointcloudsActions,
  ...pointsActions,
  ...settingsActions,
  ...sidebarsActions,
  ...transformActions,

  /**
   * This action is called when the viewer is initialized, i.e. when the user enters the viewer page.
   *
   * **NOTE!** Ideally, we would not require any pointclouds to be loaded before we can fit the scene to screen, but Potree does not provide a way to do this. We have to wait for the pointclouds to be loaded before we can fit the scene to screen, since `fitToScreen` depends on the bounding box of the pointclouds. I.e. there must be at least one pointcloud loaded.
   *
   * **[FEATURE REQUEST]** Get titles for all layers so we can populate the `layerEditor` category items while everything is loading. The point of this would be to make the left sidebar (a.k.a. `layerEditor`) less "jumpy" when things are loaded. Not sure how this would be done for points since they are not listed in the viewer response and are currently fetched in later steps. This would be a nice feature to have, but it is not a priority.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; rootGetters: any; }} { commit, dispatch, rootGetters }
   * @param {any} potree
   * @returns {Promise<void>}
   */
  async initializeViewer(
    { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    potree: any
  ): Promise<void> {
    // Make sure potree is not undefined, else throw error.
    if (!potree) {
      throw new Error("Missing potree. Can't continue!");
    }

    // Extract project and viewer id from route params and get viewer data from store.
    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    dispatch(
      "Viewers/addViewerTagTitles",
      {
        id: viewerId,
        type: ViewerInspectorMode.POINT_OBJECT,
      },
      { root: true }
    );

    const viewerData: ViewerInterface = rootGetters["Viewers/getViewer"]({ projectId, viewerId });

    const { background, title } = viewerData;

    commit("setViewerName", title);

    // Set Potree viewer settings.
    potree.setEDLEnabled(false);
    potree.setFOV(65);
    potree.setPointBudget(5000000);
    potree.loadSettingsFromURL();
    potree.setBackground(background ?? "solid");

    commit("setGraphicsInspector", {
      pointBudget: 5000000,
      fov: 65,
      background: "solid",
      solidColor: "#EEEEEE",
      gradientColorSky: "#F1F1F1",
      gradientColorGround: "#C9C9C9",
      wireframe: true,
      wireframeColor: "#333333",
      modelMaterialColor: "",
      cameraMode: "perspective",
      pointQuality: "low",
      edlStrength: 0.4,
      edlRadius: 1.0,
      pointSize: 1,
    });

    commit("setViewer", potree);
    commit("setScene", potree.scene.scene);
    commit("setPotreeScene", potree.scene);

    // We have to await the pointclouds to be loaded before we can fit the scene to screen, since `fitToScreen` depends on the bounding box of the pointclouds. I.e. there must be at least one pointcloud loaded. Ideally, we would not require any pointclouds to be loaded (i.e. not need an await) and we should be able to fit the contents of the viewer without pointclouds.
    await dispatch("loadPointClouds", potree);

    // We can now fit the scene to screen based on the bounding box of the pointclouds.
    potree.fitToScreen();

    // Continue with models and points.
    dispatch("loadModels", potree);
    dispatch("fetchPoints");

    // const mockData = mockTags.data as TagItemInterface[];
    // commit("Tags/setViewerTags", mockData ?? [], { root: true });
  },

  /**
   * This handles what to do when user clicks on a layer item.
   *
   * @param {{ dispatch: Dispatch; }} { dispatch }
   * @param {{ type: string; id: string;}} payload
   * @returns {void}
   */
  handleEditClick(
    { dispatch }: { dispatch: Dispatch },
    payload: { type: string; id: string }
  ): void {
    const { type, id } = payload;

    switch (type) {
      // TODO: If new model layer `click` for inspector is used, this can be removed. The `cube-scan` icon will instead be used to "enter" an IFC model.
      // case "models":
      //   if (getters["getLayerData"][type].items[id].type === "ifc") {
      //     dispatch("ifcModelDestructure", id);
      //   }
      //   break;
      case ViewerObjectTypeCategory.POINTCLOUDS:
        dispatch("editPointCloud", id);
        break;
      case ViewerObjectTypeCategory.MODELS:
        dispatch("editModel", id);
        break;
      case ViewerObjectTypeCategory.MEASUREMENTS:
      case ViewerObjectTypeCategory.POINT_OF_INTEREST:
        dispatch("editPoint", {
          category: type,
          id,
        });
        break;
      // dispatch("editPoint", id);
      // break;
    }
  },

  /**
   * Show delete button.
   *
   * @async
   * @param {{ dispatch: Dispatch; rootGetters: any; }} { dispatch, rootGetters }
   * @param {{ layertype: string; createdBy: string; }} payload
   * @returns {Promise<boolean>}
   */
  async showDeleteButton(
    { dispatch, rootGetters }: { dispatch: Dispatch; rootGetters: any },
    payload: { layertype: string; createdBy: string }
  ): Promise<boolean> {
    const { params: routeParams } = router.currentRoute;
    const { layertype: layerCategory, createdBy } = payload;
    const projectId = routeParams.projectId;

    let project = rootGetters["Projects/getProject"](projectId);

    if (!project) {
      await dispatch("Projects/fetchProject", projectId, { root: true });
      project = rootGetters["Projects/getProject"](projectId);
    }
    const role = project.role;

    let canDelete = false;

    if (
      layerCategory === ViewerObjectTypeCategory.MEASUREMENTS ||
      layerCategory === ViewerObjectTypeCategory.POINT_OF_INTEREST
    ) {
      canDelete = await dispatch("userRoleHandler", { role, createdBy });
    }

    return canDelete;
  },

  /**
   * Show inspector element.
   *
   * @async
   * @param {{ dispatch: Dispatch; rootGetters: any; }} { dispatch, rootGetters }
   * @param {string} createdBy
   * @returns {Promise<boolean>}
   */
  async showInspectorElement(
    { dispatch, rootGetters }: { dispatch: Dispatch; rootGetters: any },
    createdBy: string
  ): Promise<boolean> {
    const { params: routeParams } = router.currentRoute;

    const role = rootGetters["Projects/getProject"](routeParams.projectId).role;
    return await dispatch("userRoleHandler", { role, createdBy });
  },

  /**
   * Check if session user has access.
   *
   * @param {{ rootGetters: any; }} { rootGetters }
   * @param {{ is: boolean; role: string; }} payload
   * @returns {boolean}
   */
  hasAccess(
    { rootGetters }: { rootGetters: any },
    payload: { is: boolean; role: string }
  ): boolean {
    const {
      params: { projectId },
    } = router.currentRoute;
    const { is, role } = payload;

    const userProjectRole = rootGetters["Projects/getProject"](projectId).role;

    if (is) {
      return userProjectRole === role;
    } else {
      return userProjectRole !== role;
    }
  },

  /**
   * Determines if user... ?
   *
   * @param {{ rootGetters: any; }} { rootGetters }
   * @param {{ role: string; createdBy: string; }} payload
   * @returns {boolean}
   */
  userRoleHandler(
    { rootGetters }: { rootGetters: any },
    payload: { role: string; createdBy: string }
  ): boolean {
    const { role, createdBy } = payload;

    switch (role) {
      case Roles.ADMIN:
        return true;

      case Roles.EDITOR:
        return rootGetters["User/getUserName"] === createdBy;

      default:
        return false;
    }
  },
  // ------------------------
  // TRANSFORMCONTROLS
  // ------------------------
  constructPivot({ commit }, payload) {
    const { obj, scene } = payload;
    const objCenter = obj.geometry.boundingBox.getCenter(new Vector3());

    // debug
    const box = new BoxHelper(obj, 0xffff00);
    scene.add(box);

    const pivot = new Vector3(objCenter.x, objCenter.y, objCenter.z);

    /*   const pos = new Vector3(obj.position.x, obj.position.y, obj.position.z);
    pivot.add(pos); */
    commit("setTransformSelectionPivot", pivot);
  },

  /**
   * Inititates transformControls on the provided selection
   * @param payload  camera, renderer, selection : Group(threejs)
   */
  /* transformControls(
    { getters, state, commit, dispatch },
    payload: { camera: Camera; renderer: WebGLRenderer; selection: Group }
  ) {
    const { camera, renderer, selection } = payload;

    // validation
    if (!camera || !renderer || !selection) {
      throw new Error("Missing element. Can't enable transform-controls!");
    }
    if (selection.type !== "Group") {
      throw new Error("TransformControls is made to work on threejs-Groups only");
    }
    // TODO: fragile way of checking if ifc-model
    const isIfc = typeof selection.name === "number";
    isIfc && dispatch("ifcSubsetRaycast");

    const scene: Scene = getters["getScene"];

    const controls = new TransformControls(payload.camera, payload.renderer.domElement);
    controls.name = "TransformControls";
    commit("setViewerTransformTool", controls);

    controls.attach(selection);
    commit("showActiveSelectionEffectOnObject", selection);
    //dispatch("constructPivot", { obj: selection, scene });
    scene.add(controls);

    // remove TransformControls eventlisteners
    commit("removeInternalTransformEvents", { controls });

    // add own eventlisteners to TransformControls-class, so we can add own logic
    // the logic in this case is beeing able to disable OrbitControls
    // when dragevent occurs in transformcontrols
    // otherwise we orbit around at the same time as transforming the object with the gizmo.
    controls.domElement.addEventListener("pointerdown", (event: any) => {
      // if we right-mouse-click we should exit transform
      if (event.button === 2) {
        dispatch("exitTransform", { controls, scene });
      } else {
        commit("handleTransformControlsPointerDown", { controls, event });
      }
    });
    controls.domElement.addEventListener("pointermove", (event: any) => {
      const axis = controls.axis;
      const mode = controls.mode;
      const object = controls.object;
      let space = controls.space;

      if (mode === "scale") {
        space = "local";
      } else if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
        space = "world";
      }

      if (
        object === undefined ||
        axis === null ||
        controls.dragging === false ||
        controls._getPointer(event).button !== -1
      )
        return;

      const { x, y } = controls._getPointer(event);

      controls.getRaycaster().setFromCamera(new Vector2(x, y), controls.camera);

      const planeIntersect: any = intersectObjectWithRay(
        controls._plane,
        controls.getRaycaster(),
        true
      );

      if (!planeIntersect) return;
      if (!controls.enabled) return;

      // PotreeJS-OrbitControls should now be set to disabled on pointerdown/pointerMove/mousedrag
      // as the raycaster has confirmed we are using the transformcontrols
      commit("setOrbitControlDrag", false);
      const payload = {
        controls,
        pointer: controls.pointer,
        planeIntersect,
        mode,
        space,
        axis,
        object,
        event,
      }; */
};
export default actions;
