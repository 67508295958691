import { LineSegments, Material } from "three";
import Vue from "vue";
import { MutationTree } from "vuex";

import { IFCModelItemInterface, TagItemInterface } from "@/types";

import { State } from "../types";
import { currentSelection, ModelsState } from "./types";

const modelsMutations: MutationTree<ModelsState & State> = {
  setIFCModelItemInspector(state, ifcModelItemInspector) {
    state.ifcModelItemInspector = ifcModelItemInspector;
  },

  setIfcBuilding(state, model) {
    state.ifcBuilding = model;
  },

  resetIfcBuilding(state) {
    state.ifcBuilding = {
      title: "",
      building: [],
    };
  },

  setCurrentEditingModel(state, currentEditingModel) {
    state.currentEditingModel = currentEditingModel;
  },

  setIfcModels(state, model: IFCModelItemInterface) {
    state.ifcModels.push(model);
  },

  setIfcCurrentModelId(state, modelId: number) {
    state.ifcCurrentModelId = modelId;
  },

  addIfcHidden(state, ids: number[]) {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        hidden: ids,
      };
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcCurrentModelId
      );
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  removeIfcHidden(state, ids: number[]) {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      const keepHidden = model.hidden.filter((id) => !ids.includes(id));
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        hidden: keepHidden,
      };
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcCurrentModelId
      );
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  addIfcIsolated(state, ids: number[]) {
    const model = state.ifcModels.find((item) => {
      return item.modelId === state.ifcCurrentModelId;
    });
    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        isolated: ids,
      };
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcCurrentModelId
      );

      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  removeIfcIsolated(state, ids: number[]) {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      const keepIsolated = model.isolated.filter((id) => !ids.includes(id));
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        isolated: keepIsolated,
      };
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcCurrentModelId
      );
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  resetIfcIsolated(state) {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        isolated: [],
      };
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcCurrentModelId
      );
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  addIfcHighlight(state, id: number) {
    // get current ifcmodel from state
    const model = state.ifcModels.find((ifcModel) => ifcModel.modelId === state.ifcHoveredId);
    // update highlighted-value with id
    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        highlighted: id,
      };
      // get all models that is not the current ifcmodel
      const filteredModelItems = state.ifcModels.filter(
        (item) => item.modelId !== state.ifcHoveredId
      );
      // create the new ifcModels-state,with replaced current ifcmodel-state.
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  removeIfcHighlight(state, modelId: number) {
    const model = state.ifcModels.find((item) => item.modelId === modelId);

    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        highlighted: undefined,
      };
      const filteredModelItems = state.ifcModels.filter((item) => item.modelId !== modelId);
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  addIFCModelAutoTag(
    state,
    payload: {
      modelContainerId: string;
      tag: TagItemInterface;
    }
  ) {
    const { modelContainerId, tag } = payload;

    // Look for the model.
    const models = state.ifcModels;
    const modelIndex = models.findIndex((model) => model.modelContainerId === modelContainerId);
    if (modelIndex !== -1) {
      // We found the model. If this is the first tag, we need to initialize the array. Otherwise, we just push the new tag.
      const model = models[modelIndex];
      const modelAutoTags = model.autoTags;
      if (modelAutoTags && modelAutoTags.length > 0) {
        modelAutoTags.push(tag);
        Vue.set(model, "autoTags", modelAutoTags);
      } else {
        Vue.set(model, "autoTags", [tag]);
      }
    }
  },
  /**
   *
   * Keeps track of what model is hovered
   * @param modelId
   */
  setIfcHoveredId(state, modelId: number) {
    state.ifcHoveredId = modelId;
  },
  clearIfcHoveredId(state) {
    state.ifcHoveredId = undefined;
  },
  /**
   *
   * Used to keep track of the selection
   * Note: only works for ifc atm
   * @param param currentSelection, can be null or a object
   * see type for more info
   *
   */
  setCurrentSelection(state, payload: currentSelection) {
    state.currentSelection = payload;
  },
  setWireframeMaterial(state, payload: Material) {
    state.wireframeMaterial = payload;
  },
  /**
   * Isolate wireframe on model & provided id.
   * Id can either be storeyId or groupId.
   * Depending on input-id we iterate in different ways through model.wireMeshAssociations
   * Note that wireMeshAssociations has a special datastructure that looks like this:
   * { groupId:{[storeyId, groupId,groupId,...]}, groupId:{[storeyId, groupId,groupId,...]} }
   * @param id can either be storeyId, groupId or undefined, depends on where we click in ui
   * @param model IFCModelItemInterface
   * @returns void,
   */
  isolateWireframe(state, { id, model }: { id?: number; model: IFCModelItemInterface }) {
    const isStorey = id && model.storeys.find((storey) => storey.id === id);

    // turn off visible on all but storeyId we found in model.wireMeshAssociations
    if (isStorey) {
      const { id: storeyId } = isStorey;

      for (const groupId in model.wireMeshAssociations) {
        if (model.wireMeshAssociations[groupId][0] === storeyId) {
          model.wireMeshAssociations[groupId].forEach((e, index: number) => {
            if (index !== 0) {
              (e as LineSegments).visible = true;
            }
          });
        } else {
          model.wireMeshAssociations[groupId].forEach((e, index: number) => {
            if (index !== 0) {
              (e as LineSegments).visible = false;
            }
          });
        }
      }
      return;
    }

    const compareId = id?.toString();

    // go through every storey & if you find compareId,which is the groupId that has the edge segments we associate
    // with the mesh we have visible,we make edge segs. visible , rest visible = false
    for (const groupId in model.wireMeshAssociations) {
      if (groupId !== compareId) {
        model.wireMeshAssociations[groupId].forEach((e, index: number) => {
          if (index !== 0) {
            (e as LineSegments).visible = false;
          }
        });
      } else {
        model.wireMeshAssociations[groupId].forEach((edgeMesh, index: number) => {
          if (index !== 0) {
            (edgeMesh as LineSegments).visible = true;
          }
        });
      }
    }
  },
  hideWireframeByGroupId(state, { id, model }: { id: number; model: IFCModelItemInterface }) {
    for (const groupId in model.wireMeshAssociations) {
      if (groupId === id.toString()) {
        model.wireMeshAssociations[groupId].forEach((wireMesh, index: number) => {
          if (index !== 0) {
            (wireMesh as LineSegments).visible = false;
          }
        });
      }
    }
  },
  hideWireframeByStoreyId(state, { id, model }: { id: number; model: IFCModelItemInterface }) {
    for (const groupId in model.wireMeshAssociations) {
      if (model.wireMeshAssociations[groupId][0] === id) {
        model.wireMeshAssociations[groupId].forEach((wireMesh, index: number) => {
          if (index !== 0) {
            (wireMesh as LineSegments).visible = false;
          }
        });
      }
    }
  },
  hideWireframe(state, { model }: { model: IFCModelItemInterface }) {
    // go through every groupId & hide all
    for (const groupId in model.wireMeshAssociations) {
      model.wireMeshAssociations[groupId].forEach((edgeMesh, index: number) => {
        if (index !== 0) {
          (edgeMesh as LineSegments).visible = false;
        }
      });
    }
  },
  showWireframeByStoreyId(state, { id, model }: { id: number; model: IFCModelItemInterface }) {
    for (const groupId in model.wireMeshAssociations) {
      if (model.wireMeshAssociations[groupId][0] === id) {
        model.wireMeshAssociations[groupId].forEach((wireMesh, index: number) => {
          if (index !== 0) {
            (wireMesh as LineSegments).visible = true;
          }
        });
      }
    }
  },
  showWireframeByGroupId(state, { id, model }: { id: number; model: IFCModelItemInterface }) {
    for (const groupId in model.wireMeshAssociations) {
      if (groupId === id.toString()) {
        model.wireMeshAssociations[groupId].forEach((wireMesh, index: number) => {
          if (index !== 0) {
            (wireMesh as LineSegments).visible = true;
          }
        });
      }
    }
  },
  /**
   * Turns wireframe on entire model visible
   *
   * @param param1
   */
  showWireframe(state, { model }: { model: IFCModelItemInterface }) {
    // go through every groupId & show all
    for (const groupId in model.wireMeshAssociations) {
      model.wireMeshAssociations[groupId].forEach((edgeMesh, index: number) => {
        if (index !== 0) {
          (edgeMesh as LineSegments).visible = true;
        }
      });
    }
  },
  /**
   * Handles special case when we wan't to toggle wireframe on and the model has hidden & isolated elements
   * We need to go through all model & for each id check:
   * 1. if id is hidden, we hide wireframe
   * 2. if id is not hidden, we show wireframe
   * If we only would go through isolated elements we would miss the manually hidden ones.
   * @param model
   * @returns void
   */
  showOrHideWireframe(state, { model }: { model: IFCModelItemInterface }) {
    model.ids.forEach((id: number) => {
      // if model id is hidden
      const isModelidHidden = model.hidden.includes(id);

      const compareId = id.toString();
      for (const groupId in model.wireMeshAssociations) {
        if (groupId === compareId) {
          if (isModelidHidden) {
            model.wireMeshAssociations[groupId].forEach((e, index: number) => {
              if (index !== 0) {
                (e as LineSegments).visible = false;
              }
            });
          } else {
            model.wireMeshAssociations[groupId].forEach((edgeMesh, index: number) => {
              if (index !== 0) {
                (edgeMesh as LineSegments).visible = true;
              }
            });
          }
        }
      }
    });
  },
  /**
   * Updates groupIds that have hidden wireMeshItems
   * When we provide groupId it gets added to model.groupsWithWireMeshItemsHidden
   * When we provide groupId & inverse = true, we remove groupId from model.groupsWithWireMeshItemsHidden
   * @param state
   * @param payload
   */
  setGroupsWithWireMeshItemsHidden(
    state,
    payload: { modelId: number; groupId: number; inverse?: boolean }
  ) {
    const { modelId, groupId, inverse } = payload;

    const model = state.ifcModels.find((ifcModel) => ifcModel.modelId === modelId);

    // we want to remove the provided id
    if (inverse) {
      const newGroupsWithWireMeshItemsHidden = model?.groupsWithWireMeshItemsHidden.filter(
        (gId: number) => gId !== groupId
      );

      const updatedModelState: any = {
        ...model,
        groupsWithWireMeshItemsHidden: newGroupsWithWireMeshItemsHidden as number[],
      };
      const filteredModelItems = state.ifcModels.filter((item) => item.modelId !== modelId);
      state.ifcModels = [...filteredModelItems, updatedModelState];
    } else if (model && !model.groupsWithWireMeshItemsHidden.includes(groupId)) {
      // add groupId to model.groupsWithWireMeshItemsHidden
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        groupsWithWireMeshItemsHidden: [...model.groupsWithWireMeshItemsHidden, groupId],
      };

      const filteredModelItems = state.ifcModels.filter((item) => item.modelId !== modelId);
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },
  // Empties the array of groupIds that have hidden wireMeshItems
  clearGroupsWithWireMeshItemsHidden(state, { modelId }) {
    const model = state.ifcModels.find((ifcModel) => ifcModel.modelId === modelId);
    if (model) {
      const updatedModelState: IFCModelItemInterface = {
        ...model,
        groupsWithWireMeshItemsHidden: [],
      };

      const filteredModelItems = state.ifcModels.filter((item) => item.modelId !== modelId);
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },
  setModelLoadingState(state, payload: boolean) {
    state.modelLoading = payload;
  },
};
export default modelsMutations;
