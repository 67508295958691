import { State } from "./types";

const getDefaultState = (): State => {
  return {
    deleteDialog: false,
    fetched: [],
    isPointcloudContainer: undefined,
    loading: false,
    potreeConverterDialog: false,
    pointCloudUploadDialogItemData: undefined,
    projectId: "",
    projectViewers: [],
    pushItem: undefined,
    selectDialog: false,
    uploading: false,
    uploadNameDialog: false,
    uploadSelectDone: false,
    viewer: undefined,
    viewerId: "",
    uploadItem: {
      name: "",
      type: null,
      items: [],
    },
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
