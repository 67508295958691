import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import {
  AttachmentObjectItemType,
  AttachmentSectionLineItem,
  PointCloudData,
  SnackbarColors,
  ViewerInspectorMode,
  ViewerInterface,
  ViewerLayerDataCategoryKey,
} from "@/types";
import { degreesToRadian, getS3Object } from "@/utilities";

import { SidebarsState } from "../sidebars/types";
import { State } from "../types";

const modelsActions: ActionTree<State & SidebarsState, unknown> = {
  /**
   * Load point clouds. Currently this step must be the initial one in the chain of events for setting up the viewer as potree controls the 3D environment.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; rootGetters: any; }} { commit, dispatch, rootGetters }
   * @param {any} viewer
   * @returns {Promise<void>}
   */
  async loadPointClouds(
    { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    viewer: any
  ): Promise<void> {
    const potree = (window as any).Potree;
    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;
    const { pointClouds }: ViewerInterface = rootGetters["Viewers/getViewer"]({
      projectId,
      viewerId,
    });

    /**
     * Map point clouds to promise array.
     *
     * **NOTE!** While viewer is dependant on potree for scene, do not use `enabled` in if statement.
     *
     * @async
     * @param {PointCloudData} pointCloud
     * @returns {Promise<PointCloudData>[]}
     */
    const promises = pointClouds.map(async (pointCloud: PointCloudData) => {
      const { path } = pointCloud; // enabled

      if (path.startsWith("pointCloud")) {
        const pointCloudFilesObject = await dispatch("Storage/getContainer", pointCloud.path, {
          root: true,
        });
        return potree.loadPointCloud({ pointCloudFilesObject, fetchObject: getS3Object });
      }

      return undefined;
    });

    // Finalize all point cloud promises.
    const resolvedPromises: PointCloudData[] | undefined = await Promise.all(promises);
    // Filter any undefined from the resolved promises or return empty array of no resolved promises.
    const clouds = resolvedPromises?.filter((cloud: PointCloudData) => cloud) || [];

    // Iterate promises and assign point cloud to viewer scene for each.
    for (let i = 0; i < clouds.length; i++) {
      const pointCloudItem = pointClouds[i];
      const { id, title, transform, attachments } = pointCloudItem;
      const { scale: itemScale, rotation: itemRotation } = transform;

      dispatch(
        "Viewers/addViewerTagTitles",
        { id, type: ViewerInspectorMode.POINTCLOUD },
        { root: true }
      );

      // wait for point cloud to load before processing next
      // then add resolved point cloud to viewer scene
      const progress = {
        id,
        title,
        spinner: true,
        type: "pointCloud",
        value: 0,
      };

      commit("setLoadingProgressItem", { id, progress });
      const pointcloud = (clouds[i] as any).pointcloud;

      viewer.scene.addPointCloud(pointcloud);

      // Destructure new scene point cloud.
      const { material, scale, rotation } = pointcloud;

      // Set point cloud materials.
      material.size = 1.0;
      material.pointSizeType = 2;
      material.shape = 1;

      // Set scale and rotation for point cloud.
      scale.set(itemScale.x, itemScale.y, itemScale.z);
      rotation.set(
        degreesToRadian(itemRotation.x),
        degreesToRadian(itemRotation.y),
        degreesToRadian(itemRotation.z)
      );

      // Add point cloud to layer editor (a.k.a. left sidebar).
      const layerItemData = {
        attachments,
        id,
        title,
        object: pointcloud,
        type: ViewerLayerDataCategoryKey.POINTCLOUDS,
      };

      // Add data to layer editor only if in viewer page.
      if (router.currentRoute.name === "viewer") {
        commit("setLayerItemData", {
          category: ViewerLayerDataCategoryKey.POINTCLOUDS,
          data: layerItemData,
          id,
        });
      }

      commit("deleteLoadingProgressItem", id);

      // Everything loaded without errors.
      commit(
        "Utilities/showSnackbar",
        {
          message: "Pointcloud loaded",
          color: SnackbarColors.SUCCESS,
          timeout: 2,
        },
        { root: true }
      );
    }
  },

  /**
   * Edit pointcloud in inspector.
   *
   * @param {({ commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState })} {
        commit,
        dispatch,
        getters,
        state,
      }
   * @param {string} id
   */
  editPointCloud(
    {
      commit,
      dispatch,
      getters,
      state,
    }: { commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState },
    id: string
  ) {
    const {
      layerData: {
        [ViewerLayerDataCategoryKey.POINTCLOUDS]: {
          items: { [id]: layerDataItem },
        },
      },
      rightSidebar,
    } = state;

    const { title, attachments } = layerDataItem;

    // We need to get the current point object before set it, so we can determine if it was previously a point of interest. If it was we need data from it to properly close it.
    const {
      id: previousPointObjectId,
      object: previousObject,
      type: previousPointObjectType,
    } = getters["getCurrentPointObject"] || {};

    if (
      previousPointObjectType &&
      previousPointObjectType === ViewerLayerDataCategoryKey.POINT_OF_INTEREST
    ) {
      const {
        annotation: { domElement },
      } = previousObject;

      dispatch("togglePointOfInterestLabel", {
        domElement,
        id: previousPointObjectId,
      });
    }

    commit("setSelectedAttachments", []);
    commit("setCurrentEditingModel", null);
    commit("setCurrentPointObject", null);

    commit("setPointcloudInspector", {
      id,
      title,
      attachments,
      type: AttachmentObjectItemType.POINTCLOUD,
    });
    commit("setInspectorMode", ViewerInspectorMode.POINTCLOUD);
    commit("setCurrentInspector");

    attachments?.forEach((attachment) => {
      commit("addAttachment", {
        id,
        category: ViewerLayerDataCategoryKey.POINTCLOUDS,
        attachment,
        sectionLineItem: AttachmentSectionLineItem.SECTION,
      });
      dispatch("sortAttachmentItems");
    });

    if (!rightSidebar) {
      commit("setRightSidebar", true);
    }
  },
};

export default modelsActions;
