import { MutationTree } from "vuex";

import { SnackbarColors } from "@/types/vuetify";

import { State } from "./types";

const mutations: MutationTree<State> = {
  setCurrentPath: (state, path) => {
    state.currentPath = path;
  },

  setCurrentPathName: (state, name) => {
    state.currentPathName = name;
  },

  clearCurrentPath: (state) => {
    state.currentPath = undefined;
  },

  clearCurrentPathName: (state) => {
    state.currentPathName = undefined;
  },

  closeSnackbar: (state) => {
    state.snackbar.visible = false;
  },

  showSnackbar: (state, payload: { message: string; color: SnackbarColors; timeout: number }) => {
    const { message, color, timeout } = payload;
    const { snackbar, currentPathName } = state;

    snackbar.visible = true;

    switch (color) {
      case "success":
        snackbar.title = "Success";
        snackbar.icon = "check-circle";
        break;
      case "warning":
        snackbar.title = "Warning";
        snackbar.icon = "alert";
        break;
      case "error":
        snackbar.title = "Error";
        snackbar.icon = "alert-circle";
        break;
      default:
        snackbar.title = "Information";
        snackbar.icon = "information";
        break;
    }

    snackbar.color = color ? color : SnackbarColors.BLUE;

    snackbar.message = message;

    // Set default time out to shorter timeout inside viewer.
    const defaultTimeout = currentPathName === "viewer" ? 2000 : 5000;

    // Timeout in ms, default 5s (2s in viewer). If timeout is not set revert to default, if timeout is zero (0) leave open indefinitely, else multiply timeout (ms) x1000 (s).
    snackbar.timeout = !timeout ? defaultTimeout : timeout === -1 ? -1 : timeout * 1000;
  },
};

export default mutations;
