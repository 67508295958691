import {
  ViewerBackground,
  ViewerInspectorMode,
  ViewerLayerDataCategoryKey,
  ViewerObjectTypeDisplayTitle,
} from "@/types";

import { SidebarsState } from "./types";

const sidebarsState: SidebarsState = {
  currentInspector: undefined,
  graphicsInspector: {
    pointBudget: 5000000,
    fov: 60,
    background: ViewerBackground.SOLID,
    solidColor: "#EEEEEE",
    gradientColorSky: "#F1F1F1",
    gradientColorGround: "#C9C9C9",
    wireframe: true,
    wireframeColor: "#333333",
    modelMaterialColor: "#565656",
    modelMaterial: false,
    cameraMode: "perspective",
    pointQuality: "low",
    edlStrength: 0.4,
    edlRadius: 1.0,
    edlOpacity: 1.0,
    edlEnabled: false,
  },
  inspectorMode: null,
  layerData: {
    [ViewerLayerDataCategoryKey.POINTCLOUDS]: {
      category: ViewerLayerDataCategoryKey.POINTCLOUDS,
      color: "blue",
      items: {},
      selection: "",
      text: ViewerObjectTypeDisplayTitle.POINTCLOUDS,
      visible: true,
    },
    [ViewerLayerDataCategoryKey.MODELS]: {
      category: ViewerLayerDataCategoryKey.MODELS,
      color: "blue",
      items: {},
      selection: "",
      text: ViewerObjectTypeDisplayTitle.MODELS,
      visible: true,
    },
    [ViewerLayerDataCategoryKey.POINT_OF_INTEREST]: {
      category: ViewerLayerDataCategoryKey.POINT_OF_INTEREST,
      color: "blue",
      items: {},
      selection: "",
      text: ViewerObjectTypeDisplayTitle.POINT_OF_INTEREST,
      visible: true,
    },
    [ViewerLayerDataCategoryKey.MEASUREMENTS]: {
      category: ViewerLayerDataCategoryKey.MEASUREMENTS,
      color: "blue",
      items: {},
      selection: "",
      text: ViewerObjectTypeDisplayTitle.MEASUREMENTS,
      visible: true,
    },
  },
  leftSidebar: false,
  leftSidebarIfc: false,
  measureCommentInput: "",
  measureInspector: {
    attachments: [],
    cameraPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    createdAt: -1,
    createdBy: {
      id: "",
      name: "",
      reference: "",
    },
    id: "",
    object: {},
    points: [],
    title: "",
    type:
      ViewerInspectorMode.MEASURE ||
      ViewerInspectorMode.MODEL ||
      ViewerInspectorMode.IFC_MODEL_ITEM ||
      ViewerInspectorMode.POINT_OF_INTEREST ||
      ViewerInspectorMode.VIEWER_SETTINGS,
    viewerId: "",
  },
  modelInspector: {
    id: "",
    title: "",
    description: "",
    attachments: undefined,
    type: ViewerInspectorMode.MODEL,
  },
  pointcloudInspector: {
    id: "",
    title: "",
    attachments: undefined,
    type: ViewerInspectorMode.POINTCLOUD,
  },
  rightSidebar: false,
  ifcCurrentModelIdInLeftSideBar: null,
  ifcCurrentMaterial: undefined,
  selectedLayerItemId: undefined,
};

export default sidebarsState;
