import { ViewerInspectorMode } from "@/types";

import { ModelsState } from "./types";

const modelsState: ModelsState = {
  wireframeMaterial: undefined,
  currentEditingModel: null,
  ifcBuilding: {
    title: "",
    building: [],
  },
  ifcCurrentModelId: undefined,
  ifcManager: undefined,
  ifcModelItemInspector: {
    attachments: undefined,
    description: undefined,
    expressId: 0,
    globalId: "",
    itemTag: "",
    modelId: -1,
    name: "",
    objectType: "",
    predefinedType: "",
    propertySets: [],
    type: ViewerInspectorMode.IFC_MODEL_ITEM,
  },
  ifcModels: [],
  ifcHoveredId: undefined,
  currentSelection: null,
  modelLoading: true,
};

export default modelsState;
