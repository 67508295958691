import { attachmentsState } from "./attachments";
import { modelsState } from "./models";
import { settingsState } from "./settings";
import { sidebarsState } from "./sidebars";
import { State } from "./types";

const getDefaultState = (): State => {
  return {
    ...attachmentsState,
    ...modelsState,
    ...sidebarsState,
    ...settingsState,
    loading: false,
    modelLoading: true,
    deleteDialog: {
      state: false,
      id: "",
      attachmentData: undefined,
      type: undefined,
      title: undefined,
      message: "",
    },
    currentPointObject: null,
    scene: undefined,
    potreeScene: undefined,
    loadingProgress: {},
    viewer: undefined,
    viewerName: "",
    visibleSpheres: [],
    pointCreationStatus: {
      isCreating: false,
      pointObject: {},
    },
    selectDialog: false,
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
