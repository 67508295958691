
import Vue from "vue";

import Author from "@/components/author.vue";
import { FileBrowserDialog, FileSelectDialog } from "@/components/file-browser";
// import { Tag } from "@/components/tags";
import { ViewerItemExpansionPanel, ViewerItemUploadNameDialog } from "@/components/viewer";
import DeleteDialog from "@/components/viewer/delete-dialog.vue";
import PotreeConverterDialog from "@/components/viewer/potree-converter-dialog.vue";
import { PushItem } from "@/store/modules/Viewers/types";
import {
  AcceptedUploadTypesPlural,
  AcceptedUploadTypesSingular,
  ModelData,
  ModelTypes,
  SnackbarColors,
  VForm,
  ViewerInterface,
} from "@/types";
import { descriptionRules, titleRules } from "@/utilities";

interface CanDropInterface {
  pointClouds: boolean;
  models: boolean;
}

interface Data {
  addUserLoading: boolean;
  canDrop: CanDropInterface;
  descriptionRules: (value: string) => Array<boolean | string>;
  error: string;
  fetchedViewer?: ViewerInterface;
  isDragNDrop: boolean;
  modelFormats: ModelTypes[];
  newViewer: Pick<ViewerInterface, "title" | "description" | "models" | "pointClouds">;
  responseMsg: string;
  showMessage: boolean;
  titleRules: (value: string) => Array<boolean | string>;
  uploadType: string;
  valid: boolean;
  viewer: Partial<ViewerInterface>;
}

export default Vue.extend({
  name: "ViewerSettings",
  components: {
    Author,
    DeleteDialog,
    FileBrowserDialog,
    FileSelectDialog,
    PotreeConverterDialog,
    ViewerItemExpansionPanel,
    ViewerItemUploadNameDialog,
  },
  data: (): Data => ({
    addUserLoading: false,
    canDrop: { pointClouds: false, models: false },
    descriptionRules,
    error: "",
    fetchedViewer: undefined,
    isDragNDrop: false,
    modelFormats: [ModelTypes.IFC, ModelTypes.FBX],
    newViewer: { description: "", models: [], pointClouds: [], title: "" },
    responseMsg: "",
    showMessage: false,
    titleRules,
    uploadType: "",
    valid: true,
    viewer: {},
  }),
  methods: {
    /**
     * Opens the `DeleteDialog` to confirm the delete viewer action.
     *
     * @returns {void}
     */
    deleteDialogConfirm(): void {
      this.$store.commit("Viewers/setDeleteDialog", true);
    },

    /**
     * Cancel creation of new viewer and go back to viewer list.
     *
     * @returns {void}
     */
    cancelCreate(): void {
      this.$destroy();
      this.$store.dispatch("Utilities/reroute", "viewers");
    },

    /**
     * Handle dropped items.
     *
     * @param {{ dataTransfer: DataTransfer; }} event
     * @param {string} type
     * @returns {Promise<void>}
     */
    async handleDragNDrop(event: { dataTransfer: DataTransfer }, type: string): Promise<void> {
      this.uploadType = type;

      // close drag-n-drop
      this.canDrop = {
        pointClouds: false,
        models: false,
      };

      const {
        dataTransfer: { files },
      } = event;

      // set root path for item
      this.$store.commit("Storage/setPath", this.uploadType);

      // use separate upload dialog for drag-n-drop
      this.isDragNDrop = true;

      // use files object to populate items array
      const items = Object.values(files);

      // While this is done durring upload, we are doing it here to verify the files before asking the user to provide more data (e.g. name). This is the fastest route to stop if there is an issue with the files.
      if (type === "pointCloud") {
        // Verify point cloud data
        const pointCloudDataSuccess = await this.$store.dispatch(
          "Utilities/verifyPointCloudData",
          items
        );

        if (!pointCloudDataSuccess) {
          return;
        }
      }

      this.$store.commit("Viewers/setUploadItem", { name: "", type: this.uploadType, items });

      // request item name from user
      this.$store.commit("Viewers/setUploadNameDialogState", true);
    },

    /**
     * Open file browser for file selection
     *
     * @param {string} type
     * @returns {Promise<void>}
     */
    async handleSelectFile(type: string): Promise<void> {
      this.uploadType = type;
      // set root path for item
      this.$store.commit("Storage/setPath", this.uploadType);

      // set type if user choses to upload item in file browser
      this.$store.commit("Viewers/setUploadItem", { name: "", type: this.uploadType, items: [] });

      // open file browser
      this.$store.commit("Viewers/setUploadingState", true);
      this.$store.commit("Storage/setFileBrowserDialogState", true);
    },

    /**
     * When deleting an item from viewer type array.
     *
     * This is the final step after updating database.
     *
     * @param {AcceptedUploadTypesPlural} arrayName
     * @param {string} id
     * @returns {void}
     */
    removeViewerArrayItem(arrayName: AcceptedUploadTypesPlural, id: string): void {
      if (!this.viewer) {
        return;
      }

      // Workaround TS error: Vetur(2349)
      switch (arrayName) {
        case "pointClouds":
          this.viewer[arrayName] = this.viewer[arrayName]?.filter((item) => item.id !== id);
          break;
        case "models":
          this.viewer[arrayName] = this.viewer[arrayName]?.filter((item) => item.id !== id);
          break;
      }
    },

    async createViewer(): Promise<void> {
      try {
        await this.$store.dispatch("Viewers/createViewer", this.viewer);
        this.$router.push({ name: "viewers" });
      } catch (error: any) {
        this.$store.commit("Utilities/showSnackbar", {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        });
      }
    },

    async updateViewer(): Promise<void> {
      try {
        await this.$store.dispatch("Viewers/updateViewer", this.viewer.id);
      } catch (error: any) {
        this.$store.commit("Utilities/showSnackbar", {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        });
      }
    },

    /**
     * Validate input before updating or creating a new viewer item for project.
     *
     * @returns {Promise<void>}
     */
    async validate(): Promise<void> {
      if ((this.$refs.form as VForm).validate()) {
        const routeName = this.$route.name;

        switch (routeName) {
          case "viewerEdit":
            await this.updateViewer();
            break;
          case "viewerCreate":
            await this.createViewer();
            break;
        }
      }
    },

    /**
     * Clear form.
     *
     * @returns {void}
     */
    clearForm(): void {
      (this.$refs.form as VForm).reset();
    },

    /**
     * Set title based on action.
     *
     * @returns {string}
     */
    getActionText(): string {
      const routeName = this.$route.name;
      let title = "";

      switch (routeName) {
        case "viewerCreate":
          title = "Create";
          break;
        case "viewerEdit":
          title = "Save changes";
          break;
      }

      return title;
    },

    /**
     * When items are pushed to viewer type object array.
     *
     * @param {PushItem} pushItem
     * @returns {void}
     */
    handlePushItem(pushItem: PushItem): void {
      const { id, title, path } = pushItem;

      // Extract type from push items path.
      const type = path.split("#")[0] as AcceptedUploadTypesSingular;

      // Viewer object uses plurals for item type object array.
      const pluralType = `${type}s` as AcceptedUploadTypesPlural;
      const typeArray = this.viewer?.[pluralType] as PushItem[];

      // Finalize upload/select and make sure item isn't already in viewer type object array.
      const inArray = typeArray.find((item: PushItem) => item.id === id);
      if (inArray) {
        this.$store.commit("Utilities/showSnackbar", {
          message: `${title} is already selected`,
          color: SnackbarColors.ERROR,
        });
      } else {
        // push item to viewer type object array
        typeArray.push(pushItem);

        // this.$store.commit("Utilities/showSnackbar", {
        //   message: `${title} added`,
        //   color: "success",
        // });
      }

      // reset states and items
      // drag-n-drop upload dialog
      this.isDragNDrop = false;
      // upload dialog
      // this.$store.commit("Storage/setUploadDialogState", false);
      // uploading state
      this.$store.commit("Viewers/setUploadingState", false);
      // upload item
      this.$store.commit("Viewers/resetUploadItem");
      // push item
      this.$store.commit("Viewers/resetPushItem");
    },
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    canDeleteViewer(): boolean {
      const correctRoute = this.$route.name === "viewerEdit";
      return this.isAdmin && correctRoute;
    },

    isLoading(): boolean {
      return this.$store.getters["Viewers/getLoadingState"];
    },

    isEdit(): boolean {
      const {
        params: { projectId, viewerId },
        name: routeName,
      } = this.$route;

      if (projectId && viewerId && routeName === "viewerEdit") {
        return true;
      }

      return false;
    },

    getActionTitle() {
      const { name: routeName } = this.$route;
      let title = "";

      switch (routeName) {
        case "viewerCreate":
          title = "Create point cloud viewer";
          break;

        case "viewerEdit":
          title = "Edit point cloud viewer";
          break;
      }

      return title;
    },

    /**
     * Do initially and when upload or select is done.
     *
     * @returns {PushItem[]}
     */
    pointCloudItems(): PushItem[] {
      const pushItem: PushItem = this.$store.getters["Viewers/getPushItem"];

      // When new items are added.
      if (pushItem) {
        this.handlePushItem(pushItem);
      }

      // Always return computed point clouds.
      return this.viewer?.pointClouds || [];
    },

    /**
     * Do initially and when upload or select is done.
     *
     * @returns {ModelData[]}
     */
    modelItems(): ModelData[] {
      const pushItem: PushItem = this.$store.getters["Viewers/getPushItem"];

      // When new items are added.
      if (pushItem) {
        this.handlePushItem(pushItem);
      }

      // Always return computed models.
      return this.viewer?.models || [];
    },

    titleInput(): HTMLInputElement {
      return this.$refs.titleInput as HTMLInputElement;
    },

    // hasTags(): boolean {
    //   return this.viewerTags?.length > 0;
    // },

    // viewerTags(): TagItemInterface[] {
    //   return this.viewer.tags || [];
    // },
  },
  beforeMount() {
    // This component is only for admins.
    if (!this.isAdmin) {
      this.$router.push({ name: "viewers" });
    }
  },
  async mounted() {
    const {
      params: { projectId, viewerId },
      name: routeName,
    } = this.$route;

    this.$store.commit("Viewers/setProjectId", projectId);
    this.$store.commit("Viewers/setViewerId", viewerId);

    const payload = { projectId, viewerId };
    const viewer = this.$store.getters["Viewers/getViewer"](payload);

    switch (routeName) {
      case "viewerCreate":
        this.viewer = this.newViewer;
        this.titleInput.focus();
        break;
      case "viewerEdit":
        if (!viewer) {
          await this.$store.dispatch("Viewers/fetchViewer", payload);
        }
        this.viewer = this.$store.getters["Viewers/getViewer"](payload);
        break;
    }
  },
});
