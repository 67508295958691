import { Auth } from "aws-amplify";

const getAccessToken = async (): Promise<Record<string, any>> => {
  const sessionResponse = await Auth.currentSession();
  return sessionResponse.getAccessToken();
};

const getIdToken = async (): Promise<Record<string, any>> => {
  const sessionResponse = await Auth.currentSession();
  return sessionResponse.getIdToken();
};

const getJwt = async (): Promise<Record<string, string>> => {
  const idToken = await getIdToken();
  return idToken.getJwtToken();
};

export { getAccessToken, getIdToken, getJwt };
