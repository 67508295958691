
import Vue from "vue";

import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import { AttachmentSectionLineItem, AttachmentType } from "@/types";

import AttachmentChip from "./attachment-chip.vue";
import FileAttachmentType from "./file.vue";
import LinkAttachmentType from "./link.vue";
import NoteAttachmentType from "./note.vue";

export default Vue.extend({
  name: "ViewerInspectorAttachmentsSection",
  components: {
    AttachmentChip,
    FileAttachmentType,
    LinkAttachmentType,
    NoteAttachmentType,
  },
  methods: {
    disableAttachments() {
      return this.availableAttachments.length < 1;
    },

    /**
     * Runs `createAttachment` action after setting up proper payload.
     *
     * @param attachmentType Attachment type to create.
     * @returns void
     */
    createAttachment(attachmentType: AttachmentType) {
      this.$store.dispatch("PointCloudViewer/createAttachment", {
        attachmentType,
        sectionLineItem: AttachmentSectionLineItem.SECTION,
      });
    },

    hasAttachmentType(type: AttachmentType) {
      return this.selectedAttachments.includes(type);
    },

    sectionId(attachmentType: string): string {
      if (!this.inspector.attachments) {
        return `${attachmentType}-undefined`;
      }

      const attachmentSectionIndex = this.inspector.attachments?.findIndex(
        (attachment) => attachment.type === attachmentType
      );

      const sectionId = this.inspector.attachments[attachmentSectionIndex].id;
      return sectionId;
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    selectedAttachments(): AttachmentType[] {
      return this.$store.getters["PointCloudViewer/getSelectedAttachments"];
    },

    availableAttachments(): AttachmentType[] {
      return this.$store.getters["PointCloudViewer/getAvailableAttachmentTypes"];
    },
  },
});
