
import Vue from "vue";

import UserList from "@/components/account-management/user-list.vue";

export default Vue.extend({
  name: "UserManagement",
  components: {
    UserList,
  },
});
