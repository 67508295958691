import { State } from "./types";

const getDefaultState = (): State => {
  return {
    deleteDialogState: false,
    editDialogState: false,
    fetched: false,
    loading: false,
    selectedTag: undefined,
    tagListCount: 0,
    tagListFilterCount: 0,
    tags: [],
    tagsUndo: [],
    viewerHiddenTagItems: [],
    viewerIFCAutoTags: [],
    viewerSelectedTags: [],
    viewerTags: [],
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
