
import { Scene } from "three";
import Vue from "vue";
import { IFCManager } from "web-ifc-three/IFC/components/IFCManager";

export default Vue.extend({
  name: "PointCloudViewerContainer",

  data: () => ({
    fixedMain: "position: fixed !important; top: 0; left: 0; right: 0; bottom: 0",
    styles: [
      "/potree/build/potree/potree.css",
      "/potree/libs/jquery-ui/jquery-ui.min.css",
      "/potree/libs/openlayers3/ol.css",
      "/potree/libs/spectrum/spectrum.css",
      "/potree/libs/jstree/themes/mixed/style.css",
    ],
    scripts: [
      "/potree/libs/jquery/jquery-3.1.1.min.js",
      "/potree/libs/other/BinaryHeap.js",
      "/potree/libs/tween/tween.min.js",
      "/potree/libs/d3/d3.js",
      "/potree/libs/proj4/proj4.js",
      "/potree/libs/openlayers3/ol.js",
      "/potree/libs/i18next/i18next.js",
      "/potree/libs/plasio/js/laslaz.js",
      "/potree/libs/jquery-ui/jquery-ui.min.js",
      "/potree/libs/spectrum/spectrum.js",
      "/potree/libs/jstree/jstree.js",
      "/potree/build/potree/potree.js",
    ],
  }),
  methods: {
    clearViewer() {
      /**
       * Get state IFC manager and use dispose method.
       */
      const ifcManager: IFCManager | undefined =
        this.$store.getters["PointCloudViewer/getIFCManager"];
      ifcManager?.dispose();

      /**
       * Get state scene and use clear method.
       */
      const scene: Scene | undefined = this.$store.getters["PointCloudViewer/getScene"];
      scene?.clear();

      /**
       * Reset layer editor.
       */
      this.$store.commit("PointCloudViewer/clearLayerEditor");

      /**
       * Reusable function for removing potree styles and scripts from DOM head when leaving viewer
       *
       * @param {string[]} urls
       * @param {string} target
       */
      const removeFromHead = (urls: string[], target: string) => {
        urls.forEach((url: string) => {
          const element = document.head.querySelector(`[${target}="${url}"`);
          if (element) {
            document.head.removeChild(element);
          }
        });
      };
      removeFromHead(this.styles, "href");
      removeFromHead(this.scripts, "src");

      /**
       * Lastly, reset point cloud viewer to default state.
       *
       * **NOTE!** This must be performed last so that all other, current, state objects can be accessed and disposed/cleared.
       */
      this.$store.commit("PointCloudViewer/resetState");
      // delete (window as any).Potree;
    },
  },
  beforeMount() {
    // Make sure viewer is cleared before mounting.
    this.clearViewer();
    // Add styles and scripts to DOM head before continuing. Reusable function for appending potree styles and scripts to DOM head
    const appendToHead = (urls: string[], target: string, type: string) => {
      urls.forEach((url: string) => {
        const exists = document.head.querySelector(`[${target}="${url}"`);
        if (!exists) {
          const element = document.createElement(type === "css" ? "link" : "script");
          element.setAttribute("type", `text/${type}`);
          element.setAttribute(target, url);
          if (type === "css") {
            element.setAttribute("rel", "stylesheet");
          }
          document.head.appendChild(element);
        }
      });
    };
    appendToHead(this.styles, "href", "css");
    appendToHead(this.scripts, "src", "javascript");
  },
  mounted() {
    document.addEventListener("beforeunload", this.clearViewer);
    // Code that will run only after the view has been rendered.
    this.$nextTick(async function () {
      // Promise with 10s timeout on potree in window object check.
      const isPotreeWindow = async (timeout = 10000) => {
        const start = Date.now();
        const checkPotree = (resolve: any, reject: any) => {
          if ((window as any).Potree) {
            resolve((window as any).Potree);
          } else if (timeout && Date.now() - start >= timeout) {
            reject(new Error("Potree in window timeout"));
          } else {
            setTimeout(checkPotree.bind(checkPotree, resolve, reject), 30);
          }
        };
        return new Promise(checkPotree);
      };

      // Wait until potree is available in the window object.
      await isPotreeWindow();

      // Make sure project and viewer are both fetched.
      const { projectId, viewerId } = this.$route.params;
      const hasProject = this.$store.getters["Projects/getProject"](projectId);
      const hasViewer = this.$store.getters["Viewers/getViewer"](viewerId);
      if (!hasProject) {
        await this.$store.dispatch("Projects/fetchProject", projectId);
      }
      if (!hasViewer) {
        await this.$store.dispatch("Viewers/fetchViewer", { projectId, viewerId });
      }

      // Load point clouds, this is dependent on all of the above in next tick.
      const viewerContainer: any = this.$refs.DOMviewerContainer;
      const viewerElement = viewerContainer.$el;

      // Create potree viewer.
      const potree = (window as any).Potree;
      const viewer = new potree.Viewer(viewerElement, { useDefaultRenderLoop: false });

      // Set viewer canvas element in store so we can update background on it. **NOTE!** This must be done after `potree.Viewer` is instantiated since that is where the canvas element is created.
      const viewerCanvas = viewerElement.getElementsByTagName("canvas")[0];
      this.$store.commit("PointCloudViewer/setViewerCanvas", viewerCanvas);

      // Load pointclouds.
      await this.$store.dispatch("PointCloudViewer/initializeViewer", viewer);
    });
  },
  beforeDestroy() {
    /**
     * Clean up all resources and properly dispose of viewer.
     */
    this.clearViewer();
  },
});
