import { GetterTree } from "vuex";

import { TransformState } from "./types";

const transformGetters: GetterTree<TransformState, unknown> = {
  getRightSideBarTransform: (state) => {
    return state.rightSideBarTransform;
  },
  getTransformSelection: (state) => {
    return state.transformSelection;
  },
};

export default transformGetters;
