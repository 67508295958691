import { GetObjectCommand, GetObjectCommandInput, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl as getSignedS3Url } from "@aws-sdk/s3-request-presigner";
import { Auth } from "aws-amplify";

import { FileType } from "@/types/file";

import { getClientData } from "./local-storage";
import { removeDiacritics } from "./strings";

// user credentials used in getS3Object
let userCredentials: any = null;

/**
 * Takes a file format, e.g. "pdf", and returns the corresponding MIME type i.e. "application/pdf".
 *
 * Defaults to "application/octet-stream".
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 *
 * @example
 * getFileMimeType("pdf"); // returns "application/pdf"
 * getFileMimeType("jpg"); // returns "image/jpeg"
 *
 * @param {string} fileFormat String file format.
 * @returns {string} String MIME type.
 */
export const getFileMimeType = (fileFormat: string): string => {
  switch (fileFormat.toLowerCase()) {
    case "pdf":
      return "application/pdf";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "tiff":
      return "image/tiff";
    case "txt":
      return "text/plain";
    case "html":
      return "text/html";
    case "css":
      return "text/css";
    case "js":
      return "application/javascript";
    case "json":
      return "application/json";
    case "xml":
      return "application/xml";
    case "csv":
      return "text/csv";
    case "mp3":
      return "audio/mpeg";
    case "mp4":
      return "video/mp4";
    case "wav":
      return "audio/wav";
    case "ogg":
      return "audio/ogg";
    case "zip":
      return "application/zip";
    case "tar":
      return "application/x-tar";
    case "gz":
      return "application/gzip";
    default:
      return "application/octet-stream";
  }
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  // if 0 bytes no need to calculcate
  if (bytes === 0) {
    return "0 Bytes";
  }

  // setup
  const base = 1024;
  const decimal = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // calculate exponent
  const exponent = Math.floor(Math.log(bytes) / Math.log(base));

  return `${parseFloat((bytes / Math.pow(base, exponent)).toFixed(decimal))} ${sizes[exponent]}`;
};

export const cleanFileName = (name: string): string => {
  // strip file ending
  return name.replace(/^(.*)\..*$/, "$1");
};

export const getNameType = (name: string): string => {
  // meta type is empty use name file ending
  // regex to extract anything after last dot (.)
  return name.replace(/^.*\.(.*)$/, "$1").toLocaleLowerCase();
};

export const getGroupType = (groupType: string): string => {
  // get file type from group type (e.g. group/type)
  // regex to extract specific type after slash (/)
  return groupType.replace(/.*?\/(.*?)/, "$1").toLocaleLowerCase();
};

export const getTypeIcon = (object: Record<string, string>): FileType => {
  // assign type
  let type = object.type;
  // check if type is "category/type" or just a regular type
  if (type.includes("/")) {
    // extract file type
    type = object.type.replace(/.*?\/(.*?)/, "$1");
  }

  // default type for unkown types
  const defaultType = {
    icon: "mdi-file-outline",
    color: "grey",
  };

  const svgType = {
    icon: "mdi-image-size-select-large",
    color: "yellow darken-2",
  };
  const videoType = {
    icon: "mdi-file-video-outline",
    color: "cyan",
  };
  const binaryType = {
    icon: "mdi-select-group",
    color: "teal",
  };

  // list of known types
  const types = [
    {
      type: "txt",
      icon: "mdi-file-document-outline",
      color: "teal darken-1",
    },
    {
      type: "json",
      icon: "mdi-file-document-outline",
      color: "amber",
    },
    {
      type: "folder",
      icon: "mdi-folder",
      color: "blue",
    },
    {
      type: "binary",
      icon: binaryType.icon,
      color: binaryType.color,
    },
    {
      type: "macbinary",
      icon: binaryType.icon,
      color: binaryType.color,
    },
    {
      type: "pointCloud",
      icon: "mdi-image-filter-tilt-shift",
      color: "purple",
    },
    {
      type: "model",
      icon: "mdi-cube-scan",
      color: "teal lighten-2",
    },
    {
      type: "zip",
      icon: "mdi-folder-zip",
      color: "amber accent-2",
    },
    {
      type: "pdf",
      icon: "mdi-file-pdf-box",
      color: "red",
    },
    {
      type: "jpg",
      icon: "mdi-file-image-outline",
      color: "orange",
    },
    {
      type: "jpeg",
      icon: "mdi-file-image-outline",
      color: "orange",
    },
    {
      type: "webp",
      icon: "mdi-file-image-outline",
      color: "green",
    },
    {
      type: "png",
      icon: "mdi-file-image-outline",
      color: "purple",
    },
    {
      type: "svg",
      icon: svgType.icon,
      color: svgType.color,
    },
    {
      type: "svg+xml",
      icon: svgType.icon,
      color: svgType.color,
    },
    {
      type: "mp4",
      icon: videoType.icon,
      color: videoType.color,
    },
    {
      type: "mov",
      icon: videoType.icon,
      color: videoType.color,
    },
    {
      type: "avi",
      icon: videoType.icon,
      color: videoType.color,
    },
    {
      type: "mkv",
      icon: videoType.icon,
      color: videoType.color,
    },
    {
      type: "wmv",
      icon: videoType.icon,
      color: videoType.color,
    },
    {
      type: "webm",
      icon: videoType.icon,
      color: videoType.color,
    },
  ];

  // look for type in types list
  const listedType = types.find((item) => item.type === type);

  // if type is not listed in types use default type data
  return listedType ? listedType : defaultType;
};

// returns a (string) blob URL or optional fallback
// defaults to (string) "default"
export const getS3Object = async (
  object: string,
  fallback = "",
  progressCallback?: (percent: number) => void,
  requestRange?: string,
  download?: boolean,
  filename?: string,
  open = false
): Promise<any> => {
  // set up S3 get request
  const { region, bucket }: any = getClientData();
  // if user credentials not set, get from Auth
  if (!userCredentials) {
    userCredentials = await Auth.currentUserCredentials();
  }
  const client = new S3Client({ region, credentials: userCredentials });

  const objectFileEnding = object.split(".").pop() || "unkown";

  const getObjectInput: GetObjectCommandInput = {
    // ResponseCacheControl no-store is needed for Chrome in Windows
    Bucket: bucket,
    Key: `files/${object}`,
    ResponseContentType: objectFileEnding,
  };

  if (!open) {
    getObjectInput.ResponseCacheControl = "no-store";
  }

  // if (open && filename) {
  //   getObjectInput.ResponseContentDisposition = `inline; filename="${removeDiacritics(filename)}"`;
  // }

  getObjectInput.ResponseContentType = getFileMimeType(objectFileEnding);

  if (!open && download && filename) {
    getObjectInput.ResponseContentDisposition = `attachment; filename="${removeDiacritics(
      filename
    )}"`;
  }

  if (open && download && filename) {
    getObjectInput.ResponseContentDisposition = `inline; filename="${removeDiacritics(filename)}"`;
  }

  if (requestRange) {
    getObjectInput.Range = requestRange;
  }

  const command = new GetObjectCommand(getObjectInput);

  try {
    if (download) {
      return await getSignedS3Url(client, command, { expiresIn: 120 });
    }
    // handle object response
    const { Body, ContentLength } = await client.send(command);

    const response = new Response(Body as ReadableStream);

    if (requestRange) {
      const arrayBuffer = await response.arrayBuffer();
      return arrayBuffer;
    } else {
      const reader = response.body?.getReader();

      // Bytes received.
      let receivedLength = 0;

      // Array of received binary chunks.
      const chunks = [];

      do {
        if (reader) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          if (value) {
            chunks.push(value);
            receivedLength += value.length;
          }
          if (progressCallback && ContentLength) {
            const percent = receivedLength / ContentLength;
            progressCallback(percent);
          }
        }
      } while (ContentLength && receivedLength < ContentLength);

      const blob = new Blob(chunks);

      // if (filename) {
      //   (blob as any).name = removeDiacritics(filename);
      // }

      // assign blob to create object URL
      const source = URL.createObjectURL(blob);

      // if source is undefined return string "default"
      return source ? source : fallback;
    }
  } catch (error: unknown) {
    console.log(error);
  }
};

export const getBlobWithProgress = async (
  filePath: string,
  progressObject: Record<string, any>,
  value = 100
): Promise<string> => {
  const onProgress = (progress: number) => {
    progressObject.value = Math.round(progress * value);
  };

  const modelBlob = await getS3Object(filePath, "", onProgress);
  return modelBlob;
};
