import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import {
  ModelData,
  PointCloudData,
  TagItemInterface,
  ViewerInspectorMode,
  ViewerInterface,
  ViewerPointColorType,
  ViewerPointShape,
  ViewerPointSizeType,
} from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { supportedModelTypes } from "@/utilities/containers";
import { coreApiPost } from "@/utilities/core-api";
import { getNameType } from "@/utilities/files";

import { State } from "./types";

const apiRoot = "/project";

const actions: ActionTree<State, unknown> = {
  /**
   * Fetches all viewers for a project.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch }} { commit, dispatch }
   * @param {string} projectId
   * @returns {Promise<void>}
   */
  async fetchViewers({ commit }: { commit: Commit }, projectId: string): Promise<void> {
    commit("setLoadingState", true);

    try {
      const viewers = await coreApiPost(`${apiRoot}/get-viewers`, { projectId });

      commit("setViewers", { projectId, viewers });
      commit("setFetchedState", projectId);
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );

      // There was an error fetching projects. This can be intentional (e.g if user does not have sufficient permissions) or unintentional (e.g. network error). In either case, we should reroute to the dashboard.
      router.push({ name: "dashboard" });
    }
    commit("setLoadingState", false);
  },

  /**
   * Fetches a viewer for a project.
   *
   * @async
   * @param {{ dispatch: Dispatch; commit: Commit; rootGetters: any; }} { dispatch, commit, rootGetters }
   * @param {Record<string, string>} payload
   * @returns {Promise<void>}
   */
  async fetchViewer(
    { commit }: { commit: Commit },
    payload: Record<string, string>
  ): Promise<void> {
    const { projectId, viewerId } = payload;
    commit("setLoadingState", true);

    try {
      const viewer = await coreApiPost(`${apiRoot}/get-viewer`, { projectId, viewerId });
      commit("setViewer", { projectId, viewer });
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );

      // There was an error fetching projects. This can be intentional (e.g if user does not have sufficient permissions) or unintentional (e.g. network error). In either case, we should reroute to the dashboard.
      router.push({ name: "dashboard" });
    }
    commit("setLoadingState", false);
  },

  async addViewerTagTitles(
    { commit, dispatch, rootGetters },
    payload: {
      // modelContainerId?: string;
      // pointCloudId?: string;
      // viewerId?: string;
      id: string;
      ifcItems?: {
        expressId: number;
        globalId: string;
      }[];
      type: ViewerInspectorMode;
    }
  ) {
    const { ifcItems } = payload;

    const response = await coreApiPost(`/viewer/get-viewer-tags`, payload);

    let viewerTags: Partial<TagItemInterface>[] = response ?? [];

    if (!Array.isArray(response) || response.length < 1) {
      // `response` is not an array or is an empty array. We can stop here.
      return;
    }

    try {
      // There are viewer tags present. We need to get global tags so we can add proper titles in `viewerTags` from global tags. Fetch global tags if they haven't been fetched yet.
      const globalTagsFetched = rootGetters["Tags/getFetchState"];
      if (!globalTagsFetched) {
        await dispatch("Tags/fetchTags", undefined, { root: true });
      }

      const globalTags: TagItemInterface[] = rootGetters["Tags/getTags"];

      const { id: modelContainerId } = payload;

      // Add titles to viewer tags since the original only contains id and items.
      viewerTags = viewerTags.map((viewerTag) => {
        const { items, tableItemId } = viewerTag;

        // Add `tableItemId` to each item. This is to make sure that the final item in the tag items array contains the proper `tableItemId` for each item. Since it is used to determine where the tag is in the database entry.
        items?.forEach((item) => {
          const { id: itemId } = item;

          item.tableItemId = tableItemId;

          // Handle IFC model item tags. We need to add `expressId` and `modelContainerId` to each item so they can be properly filtered using tags.
          const findIFCItem = ifcItems?.find((ifcItem) => ifcItem.globalId === itemId);

          if (!findIFCItem) {
            return;
          }

          const { expressId } = findIFCItem;
          item.expressId = expressId;
          item.modelContainerId = modelContainerId;
        });

        delete viewerTag.tableItemId;

        return {
          ...viewerTag,
          title: globalTags.find((globalTag) => globalTag.id === viewerTag.id)?.title,
        };
      });
    } catch (error: any) {
      commit(
        "Utilities/showSnackbar",
        {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    // Set `viewerTags` in `Tags` module.
    commit("Tags/setViewerTags", viewerTags ?? [], { root: true });
  },

  /**
   * Creates a viewer for a project.
   *
   * @async
   * @param {{ commit: Commit; getters: any; }} { commit, getters }
   * @param {Partial<ViewerInterface>} viewer
   * @returns {Promise<void>}
   */
  async createViewer(
    { commit, getters }: { commit: Commit; getters: any },
    viewer: Partial<ViewerInterface>
  ): Promise<void> {
    const projectId = getters["getProjectId"];
    commit("setLoadingState", true);

    try {
      const response = await coreApiPost(`${apiRoot}/create-viewer`, {
        projectId,
        ...viewer,
      });

      const newViewer = {
        ...response,
        ...viewer,
        background: "gradient",
        fov: 60,
        pointBudget: 5000000,
        pointColorType: ViewerPointColorType.RGB,
        pointShape: ViewerPointShape.SQUARE,
        pointSize: 1,
        pointSizeType: ViewerPointSizeType.ADAPTIVE,
      };

      commit("setViewer", { projectId, viewer: newViewer });
      commit(
        "Utilities/showSnackbar",
        {
          message: `Viewer <strong>${viewer.title}</strong> created successfully`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  /**
   * Updates a viewer in a project.
   *
   * @async
   * @param {{ commit: Commit; getters: any; }} { commit, getters }
   * @param {string} viewerId
   * @returns {Promise<void>}
   */
  async updateViewer(
    { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    viewerId: string
  ): Promise<void> {
    const projectId = getters["getProjectId"];
    const viewer: ViewerInterface = getters["getViewer"]({ projectId, viewerId });

    const { title } = viewer;

    const requiredVariables = [
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!viewerId,
        message: "viewer ID",
      },
      {
        test: !!title,
        message: "Title",
      },
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    commit("setLoadingState", true);

    try {
      await coreApiPost(`${apiRoot}/update-viewer`, {
        projectId,
        ...viewer,
      });
      commit("setViewer", { projectId, viewer });
      commit(
        "Utilities/showSnackbar",
        {
          message: `Viewer <strong>${title}</strong> updated.`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  /**
   * Delete a viewer from the database.
   *
   * **NOTE** Users can only delete a viewer that has no points.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @param {string} id
   * @returns {Promise<void>}
   */
  async deleteViewer(
    { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    id: string
  ): Promise<void> {
    const projectId = getters["getProjectId"];
    commit("setLoadingState", true);

    try {
      // Send delete request to API.
      await coreApiPost(`${apiRoot}/delete-viewer`, {
        id,
        projectId,
      });

      // Remove project from store.
      commit("removeViewer", {
        projectId,
        viewerId: id,
      });

      // Display success message.
      commit(
        "Utilities/showSnackbar",
        {
          message: `Viewer ${id} was successfully deleted`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );

      // Reroute to projects page.
      dispatch("Utilities/reroute", "viewers", { root: true });
    } catch (error: any) {
      // Display error.
      commit(
        "Utilities/showSnackbar",
        {
          message: error.response.data ?? error.toString(),
          color: SnackbarColors.ERROR,
          timeout: -1,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Used when entering a viewer.
   *
   * @param {{ getters: any; }} { getters }
   * @param {string} viewerId
   */
  enterViewer({ getters }: { getters: any }, viewerId: string) {
    const projectId = getters["getProjectId"];
    router.push({
      name: "viewer",
      params: { projectId, viewerId },
    });
  },

  /**
   * Set item container name.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; }} { commit, dispatch }
   * @param {string} itemName
   * @returns {Promise<void>}
   */
  async setItemContainerName(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    itemName: string
  ): Promise<void> {
    // Verify name isn't empty string.
    if (itemName === "") {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Name can not be empty",
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
      return;
    }

    // Upload item(s).
    dispatch("uploadItem");
  },

  /**
   * When uploading items in viewer settings page.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any;  }} { commit, dispatch, getters }
   * @returns {Promise<void>}
   */
  async uploadItem({
    commit,
    dispatch,
    getters,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    getters: any;
  }): Promise<void> {
    // Destructure upload item.
    const uploadItem = getters["getUploadItem"];
    const { name, type, items } = uploadItem;

    // Create item container (folder).
    const container = await dispatch("createItemContainer");
    const { id: containerId, modelType } = container;

    // Item name dialog.
    commit("setUploadNameDialogState", false);

    if (type === "pointCloud") {
      commit("setPotreeConverterDialogState", true);
    }

    // Start uploading container item(s).
    // set files, show upload dialog and upload files
    commit("Storage/setUploadFiles", items, { root: true });
    // commit("Storage/setShowFileInput", false, { root: true });
    // commit("Storage/setUploadDialogState", true, { root: true });
    await dispatch("Storage/uploadFiles", null, { root: true });

    // Return item to push in viewer item list.
    let pushItem: Record<string, string> = { id: containerId, title: name, type };
    if (modelType) {
      pushItem = {
        ...pushItem,
        modelType,
      };
    }

    if (type !== "pointCloud") {
      dispatch("finalPushItem", pushItem);
    }
  },

  /**
   * Create container (folder) for upload item.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @returns {Promise<unknown | undefined>}
   */
  async createItemContainer({
    commit,
    dispatch,
    getters,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    getters: any;
  }): Promise<unknown | undefined> {
    commit("Storage/setLoadingState", true, { root: true });

    // Select dialog clears on close (watch in file browser). Only close once name has been set.
    commit("setSelectDialogState", false);

    // Destructure upload item.
    const uploadItem = getters["getUploadItem"];

    const { name, type, items } = uploadItem;
    const isModelUpload = uploadItem && uploadItem.type === "model";

    try {
      // If dealing with model upload.
      let modelType = undefined;
      if (isModelUpload) {
        // Default file type unknown.
        let fileType = "unknown";

        // Is file of supported type.
        const isSupported = items.find((item: File) => {
          fileType = getNameType(item.name);
          return supportedModelTypes.find((supported) => fileType === supported.type);
        });

        if (isSupported) {
          // Model type supported assign to variable.
          modelType = getNameType(isSupported.name);
        } else {
          // Model type not supported throw error.
          throw `${fileType} format is currently not supported`;
        }
      }

      let pointCloudType = undefined;
      if (type === "pointCloud") {
        const verifyPointCloudData = await dispatch("Utilities/verifyPointCloudData", items, {
          root: true,
        });

        if (!verifyPointCloudData) {
          commit(
            "Utilities/showSnackbar",
            {
              message: "Unsupported file type",
              color: SnackbarColors.ERROR,
            },
            { root: true }
          );
          return;
        }

        const isPotree = await dispatch("Utilities/isValidPotreeDataSet", items, { root: true });

        if (!isPotree) {
          // Get file extension. This is used to set the point cloud type.
          pointCloudType = items[0].name.split(".").pop();
        }

        // This is used later on in this process when we upload the file(s) for this point cloud container. We need to be able to tell the backend that it should start the potree conversion process.
        commit("setIsPointcloudContainer", true);
      }

      // Create folder for item.
      const response = await dispatch(
        "Storage/putFolder",
        {
          name,
          type,
          ...(modelType && { modelType }),
          ...(pointCloudType && { pointCloudType }),
        },
        { root: true }
      );

      // Extract types container id.
      const id = response.split("#")[1];

      // Set new path using folder id on files.
      commit("Storage/setPath", id, { root: true });

      // Needed in await response.
      let responseObject: { id: string; modelType?: string } = {
        id,
      };

      // If model type not undefined.
      if (modelType) {
        responseObject = {
          ...responseObject,
          modelType,
        };
      }

      commit("Storage/setLoadingState", false, { root: true });

      return responseObject;
    } catch (message: unknown) {
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );

      // Could not create container. Close container naming dialog.
      commit("setUploadNameDialogState", false);
      commit("Storage/setLoadingState", false, { root: true });
    }
  },

  /**
   * Set existing selected item from file browser.
   *
   * @async
   * @param {{ dispatch: Dispatch; }} { dispatch }
   * @param {{ id: string; name: stirng; type: string: modelType: string; }} item
   * @returns {Promise<void>}
   */
  async setSelectedItem(
    { dispatch }: { dispatch: Dispatch },
    item: {
      id: string;
      modelType: string;
      name: string;
      type: string;
    }
  ): Promise<void> {
    const { modelType, id, name, type } = item;
    const cleanId = id.split("#")[1];

    // Return item to push in viewer item list.
    dispatch("finalPushItem", {
      ...(modelType && { modelType }),
      id: cleanId,
      title: name,
      type,
    });
  },

  /**
   * Set push item to module state for computed values.
   *
   * @param {{ commit: Commit; }} { commit }
   * @param {{ id: string; title: string; type: string; modelType?: string }} payload
   * @returns {void}
   */
  finalPushItem(
    { commit }: { commit: Commit },
    payload: {
      id: string;
      modelType?: string;
      title: string;
      type: string;
    }
  ): void {
    const { id, title, type: containerType, modelType } = payload;

    let item: PointCloudData | ModelData = {
      enabled: true,
      id,
      path: `${containerType}#${id}`,
      title,
      transform: {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
      },
    };

    // If creating model container. Check for and add model type
    if (containerType.includes("model") && modelType) {
      item = {
        ...item,
        ...(modelType && { type: modelType }),
      };
    }

    // Append camera object to point cloud type.
    if (containerType === "pointCloud") {
      item = {
        ...item,
        camera: {
          position: { x: 0, y: 0, z: 0 },
          lookat: { x: 0, y: 0, z: 0 },
        },
      };
    }

    // Final item to push in type array.
    commit("setPushItem", item);
  },
};

export default actions;
