import Vue from "vue";
import { ActionTree, Commit, Dispatch } from "vuex";
import { IFCManager } from "web-ifc-three/IFC/components/IFCManager";

import {
  LayerEditorModelData,
  LayerEditorPointcloudData,
  LayerEditorPointData,
  SnackbarColors,
  ViewerInspectorMode,
  ViewerLayerDataCategoryKey,
  ViewerObjectTypeCategory,
  WireframeAction,
} from "@/types";

import { State, ViewerLayerDataInterface } from "../types";
import { SidebarsState } from "./types";

const sidebarsActions: ActionTree<State & SidebarsState, unknown> = {
  /**
   * Performed when moving out of an IFC model structure view (in left sidebar) back to layer editor.
   *
   * @param {{ commit: Commit; }} Actions { commit }
   * @param {boolean} [closeAll = false] If action should close both IFC model view and layer editor. Defaults to false.
   * @returns {void}
   */
  switchSidebar({ commit }: { commit: Commit }, closeAll = false): void {
    // const ifcManager: IFCManager = getters["ifc/getIFCManager"];
    // const { modelID: modelId }: IFCModel = getters["getIfcModel"];
    // const ids: number[] = getters["getIfcHidden"];
    // ifcManager.showItems(modelId, ids);

    // commit("resetIfcBuilding");
    commit("setRightSidebar", false);
    commit("setInspectorMode", "");
    commit("setIFCModelItemInspector", {});

    commit("setIfcCurrentModelIdInLeftSideBar", null);

    commit("setLeftSidebarIfc", false);

    if (closeAll) {
      commit("setLeftSidebar", false);
    } else {
      commit("setLeftSidebar", true);
    }
  },

  /**
   * Get data on a layer item using category and id.
   *
   * @param {{ commit: Commit; getters: any; }} { commit, getters }
   * @param {{ id: string; category: ViewerObjectTypeCategory.POINTCLOUDS | ViewerObjectTypeCategory.MODELS | ViewerObjectTypeCategory.POINT_OF_INTEREST | ViewerObjectTypeCategory.MEASUREMENTS; }} payload
   * @returns {(LayerEditorPointcloudData | LayerEditorModelData | LayerEditorPointData | void)}
   */
  getLayerItemData(
    {
      commit,
      getters,
    }: {
      commit: Commit;
      getters: any;
    },
    payload: {
      id: string;
      category:
        | ViewerObjectTypeCategory.POINTCLOUDS
        | ViewerObjectTypeCategory.MODELS
        | ViewerObjectTypeCategory.POINT_OF_INTEREST
        | ViewerObjectTypeCategory.MEASUREMENTS;
    }
  ): LayerEditorPointcloudData | LayerEditorModelData | LayerEditorPointData | void {
    const { id, category } = payload;

    const layers: ViewerLayerDataInterface = getters["getLayerData"];
    const layerItem = layers[category].items[id];

    if (!layerItem) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Could not find layer item",
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
      return;
    }

    return layerItem;
  },

  /**
   * Toggle visibility of all layers in a layer group, e.g. `ViewerLayerDataCategoryKey.POINT_OF_INTEREST`, `ViewerLayerDataCategoryKey.POINTCLOUDS`, `ViewerLayerDataCategoryKey.MODELS` or `ViewerLayerDataCategoryKey.MEASUREMENTS`.
   *
   * @async
   * @param {{ state: State & SidebarsState; rootGetters: any; }} { state, rootGetters }
   * @param {string} layername
   * @returns {Promise<void>}
   */
  async toggleLayerVisibility(
    {
      state,
      rootGetters,
      dispatch,
    }: { state: State & SidebarsState; rootGetters: any; dispatch: Dispatch },
    layername: ViewerLayerDataCategoryKey
  ): Promise<void> {
    const { layerData } = state;
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const layer = layerData[layername];
    const { visible, items } = layer;

    // Items in each layer must be converted to an array to be iterated over.
    const layerItems = Object.values(items);

    // Toggle layer visibility. Individual items will be set in the switch statement below.
    Vue.set(layer, "visible", !visible);

    layerItems.forEach((item) => {
      const { object, type } = item;
      const { annotation, modelID } = object;

      if (typeof annotation !== "undefined") {
        Vue.set(annotation, "_visible", !visible);
        Vue.set(object, "visible", !visible);
      } else {
        Vue.set(object, "visible", !visible);
      }

      if (type === "ifc" && typeof modelID !== "undefined") {
        try {
          const modelSubset = ifcManager.getSubset(modelID, undefined, "full-model");
          Vue.set(modelSubset, "visible", !visible);

          // handle wireframe show/hide
          dispatch("wireframeHandler", {
            modelId: modelID,
            subset: modelSubset,
            action: WireframeAction.UPDATE,
          });
        } catch (error) {
          console.error("Subset failed ", error);
        }
      }
    });
  },

  /**
   * Toggle visibility of a layer item. Used with {@link togglePointOfInterestSphere} and {@link togglePointOfInterestLabel}.
   *
   * @async
   * @param {{ rootGetters: any; }} { rootGetters }
   * @param {{ item: LayerEditorPointcloudData & LayerEditorModelData & LayerEditorPointData; type: ViewerObjectTypeCategory; }} payload
   * @returns {Promise<void>}
   */
  async toggleLayerItemVisibility(
    {
      state,
      rootGetters,
      dispatch,
    }: { state: SidebarsState; rootGetters: any; dispatch: Dispatch },
    payload: {
      item: LayerEditorPointcloudData & LayerEditorModelData & LayerEditorPointData;
      type: ViewerLayerDataCategoryKey;
      visible?: boolean;
    }
  ): Promise<void> {
    const { item, visible: payloadVisible, type: payloadType } = payload;
    const { object, type } = item;

    const { visible, annotation, modelID } = object;

    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];

    if (typeof annotation !== "undefined") {
      // This is an annotation item and we need to toggle the visibility on both the annotation and its containing object.
      const { _visible } = annotation;
      Vue.set(annotation, "_visible", payloadVisible ?? !_visible);
      Vue.set(object, "visible", annotation._visible);
    } else {
      // This is anything but an annotation (POI) or IFC model item.
      Vue.set(object, "visible", payloadVisible ?? !visible);
    }

    if ((type as string) === "ifc" && typeof modelID !== "undefined") {
      // This is an IFC model item and we need to toggle the visibility of the entire model.
      try {
        const modelSubset = ifcManager.getSubset(modelID, undefined, "full-model");
        Vue.set(modelSubset, "visible", payloadVisible ?? !visible);

        // handle wireframe show/hide
        dispatch("wireframeHandler", { modelId: modelID, action: WireframeAction.UPDATE });
      } catch (error) {
        console.error("Subset failed ", error);
      }
    } else {
      // This is anything but an IFC model item. We want to check if all items in the layer are hidden or not, and match the group visible icon depending on if there are any visible layers in the group or not.
      const layerGroup = state.layerData[payloadType];
      const { items } = layerGroup || {};

      // Let's make sure nothing breaks if the layer group is `undefined`.
      if (!items) {
        return;
      }

      const hasVisibleLayers = Object.values(items).some((item) => item.object.visible);

      // Now that we have the visibility of all layers in the group, we can set the group visibility.
      Vue.set(layerGroup, "visible", hasVisibleLayers);
    }
  },

  /**
   * Closes and clears right sidebar (a.k.a. "Inspector").
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State & SidebarsState; }} { commit, dispatch, state }
   * @returns {Promise<void>}
   */
  // async closeInspector({
  //   state,
  //   dispatch,
  //   commit,
  // }: {
  //   commit: Commit;
  //   dispatch: Dispatch;
  //   state: State & SidebarsState;
  // }): Promise<void> {
  //   // VisibleSpheres, LayerData
  //   const { inspectorMode, measureInspector } = state;
  //   if (inspectorMode === ViewerInspectorMode.POINT_OF_INTEREST) {
  //     dispatch("editPoint", {
  //       category: measureInspector.type,
  //       id: measureInspector.id,
  //     });
  //   }

  //   commit("setInspectorMode", null);
  //   commit("setRightSidebar", false);
  //   commit("setCurrentEditingModel", null);
  //   dispatch("ifcClearSelection");
  //   commit("setSelectedLayerItemId");
  // },

  /**
   * Focus to the specified layer item.
   *
   * @param {{ getters: any; state: State & SidebarsState; }} { getters, state }
   * @param {{ item: any; type: ViewerObjectTypeCategory; }} payload
   * @returns {void}
   */
  focusToLayerItem(
    { getters, state }: { getters: any; state: State & SidebarsState },
    payload: {
      id: string;
      type:
        | ViewerObjectTypeCategory.POINTCLOUDS
        | ViewerObjectTypeCategory.MODELS
        | ViewerObjectTypeCategory.MEASUREMENTS
        | ViewerObjectTypeCategory.POINT_OF_INTEREST;
    }
  ): void {
    const { id, type } = payload;
    const {
      layerData: {
        [type]: {
          items: { [id]: item },
        },
      },
    } = state;

    if (!id || id === "") {
      return;
    }

    const potreeScene = getters["getPotreeScene"];

    let cameraPosition;
    let itemPosition;

    switch (type) {
      case ViewerObjectTypeCategory.POINTCLOUDS:
        cameraPosition = (item as LayerEditorPointcloudData).object.boundingBox.max;
        itemPosition = (item as LayerEditorPointcloudData).object.position;
        break;

      case ViewerObjectTypeCategory.MODELS:
        cameraPosition = {
          x: (item as LayerEditorModelData).object.position.x + 150,
          y: (item as LayerEditorModelData).object.position.y + 150,
          z: (item as LayerEditorModelData).object.position.z + 150,
        };
        itemPosition = (item as LayerEditorModelData).object.position;
        break;

      case ViewerObjectTypeCategory.MEASUREMENTS:
      case ViewerObjectTypeCategory.POINT_OF_INTEREST:
        cameraPosition = (item as LayerEditorPointData).cameraPosition;
        itemPosition = (item as LayerEditorPointcloudData).object.points[0].position;
        break;
    }

    (window as any).Potree.Utils.moveTo(potreeScene, cameraPosition, itemPosition);
  },
};

export default sidebarsActions;
