/**
 * Convert degree value to radian.
 * radians = degrees × π / 180°
 * @param {(number | string)} number
 * @returns {number}
 */
export const degreesToRadian = (number: number | string): number => {
  return Number(number) * (Math.PI / 180);
};

/**
 * Convert radian value to degrees
 *  degrees = radians × 180° / π
 * @param {(number | string)} number
 * @returns {number}
 */
export const radiansToDegree = (number: number | string): number => {
  return Number(number) * (180 / Math.PI);
};
