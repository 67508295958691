
import Vue from "vue";

import DeleteUserDialog from "@/components/account-management/single-user/delete-dialog.vue";
import EditUserDialog from "@/components/account-management/single-user/edit-dialog.vue";
import RoleChip from "@/components/role-chip.vue";
import { UserProfileInterface } from "@/types";
import { Roles, UserStatus } from "@/types/enums";
import { emailRules, timestampToLocaleDate } from "@/utilities";

interface Data {
  editUser: UserProfileInterface;
  emailRules: (value: string) => (boolean | string)[];
  headers: Record<string, string>[];
  search: string;
  timestampToLocaleDate: (timestamp: string | number) => string;
}

export default Vue.extend({
  name: "AccountManagementUserList",
  components: {
    DeleteUserDialog,
    EditUserDialog,
    RoleChip,
  },
  data: (): Data => ({
    editUser: {} as UserProfileInterface,
    emailRules,
    headers: [
      { text: "", value: "status" },
      { text: "email", value: "email" },
      { text: "role", value: "role" },
      { text: "created", value: "createdAt" },
      { text: "actions", value: "actions" },
    ],
    search: "",
    timestampToLocaleDate,
  }),
  methods: {
    /**
     * Determine if a user account invitation has expired.
     *
     * @param {object} parameters - The parameters for the method.
     * @param {number} [parameters.expirationDays=30] - The number of days after which an invitation is considered expired. Optional. Default is 30.
     * @param {string} [parameters.status] - The user's status. Optional.
     * @param {number} parameters.created - The timestamp when the user was created.
     * @returns {boolean} - Returns true if the user's status is `pending` and the number of days passed is more than 30, otherwise false.
     */
    inviteExpired({
      expirationDays = 30,
      status,
      created,
    }: {
      expirationDays?: number;
      status?: string;
      created: number;
    }): boolean {
      // Calculate the number of days passed since the user was created by subtracting the user's creation timestamp from the current timestamp and dividing that by the number of milliseconds in a day.
      const daysPassed = Math.floor((Date.now() - created) / (1000 * 60 * 60 * 24));
      // Return true if the user's status is `pending` and the number of days passed is more than 30, otherwise false.
      return status === UserStatus.PENDING && daysPassed > expirationDays;
    },

    /**
     * Set user status color.
     *
     * @param {string} [status] - User status. Optional.
     * @returns {string} - Returns color based on user status.
     */
    statusColor(status?: string): string {
      // Define a default color to use if the user's status is not provided.
      let color = "grey--text";

      // If the user's status is provided...
      if (status) {
        // Define a mapping of status values to colors.
        const statusColors: Record<string, string> = {
          active: "green",
          disabled: "red",
          pending: "blue",
        };
        // Set the color to the color associated with the user's status.
        color = statusColors[status];
      }

      // Return the color.
      return color;
    },

    /**
     * Show edit user dialog.
     *
     * @param {EditableUser} item - User object.
     * @returns {void} - Returns void.
     */
    async editDialog(item: UserProfileInterface): Promise<void> {
      if (this.canEdit(item)) {
        this.$store.commit("AccountManagement/setEditDialogState", true);
        this.editUser = item;
      }
      return;
    },

    /**
     * Show delete user dialog.
     *
     * @param {EditableUser} item - User object.
     * @returns {Promise<void>} - Returns void.
     */
    async deleteDialog(item: UserProfileInterface): Promise<void> {
      this.$store.commit("AccountManagement/setDeleteDialogState", true);
      this.editUser = item;
    },

    /**
     * Determine if user can be edited.
     *
     * @param {EditableUser} user - User object.
     * @returns {boolean} - Returns true if user is not self, status is `disabled`, can be edited and current user `role` is allowed to edit.
     */
    canEdit(user: UserProfileInterface): boolean {
      const { sk: email } = this.$store.getters["User/getUserProfile"];
      const isSelf = user.email === email;
      const hasAccess = this.isOwner || (user.role !== Roles.OWNER && this.isAdmin);

      return !isSelf && hasAccess;
    },

    /**
     * Determine if user can be deleted.
     *
     * @param {EditableUser} item - User object.
     * @returns {boolean} - Returns true if user status is `disabled` or `pending` and `canEdit` returns true.
     */
    canDelete(item: UserProfileInterface): boolean {
      return (
        (item.status === UserStatus.DISABLED || item.status === UserStatus.PENDING) &&
        this.canEdit(item)
      );
    },
  },
  computed: {
    isOwner(): boolean {
      return this.$store.getters["User/getIsOwner"];
    },

    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    /**
     * Get loading state.
     *
     * @returns {boolean} - Returns loading state.
     */
    isLoading(): boolean {
      return this.$store.getters["AccountManagement/getLoadingState"];
    },

    /**
     * Get users list.
     *
     * @returns {UserProfileInterface[]}
     */
    userList(): UserProfileInterface[] {
      const users: UserProfileInterface[] = this.$store.getters["AccountManagement/getUserList"];
      return users;
    },
  },
  mounted() {
    const hasFetched = this.$store.getters["AccountManagement/getFetchedState"];
    if (!hasFetched) {
      this.$store.dispatch("AccountManagement/fetchUsers");
    }
  },
});
