var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',[_c('v-tabs',{style:({
      position: 'fixed',
      top: '48px',
      width: '100%',
      zIndex: 99,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    }),attrs:{"slider-color":"primary","grow":"","centered":""},model:{value:(_vm.inspectorTab),callback:function ($$v) {_vm.inspectorTab=$$v},expression:"inspectorTab"}},[_c('v-tab',{attrs:{"href":"#info"}},[_vm._v("Info")]),_c('v-tab',{attrs:{"href":"#details","disabled":_vm.isPointcloudInspector || _vm.isModelInspector}},[_vm._v("Details")])],1),_c('v-tabs-items',{style:({ marginTop: '96px' }),attrs:{"continuous":""},model:{value:(_vm.inspectorTab),callback:function ($$v) {_vm.inspectorTab=$$v},expression:"inspectorTab"}},[_c('TabInfo'),_c('TabDetails')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }