
import Vue from "vue";

import RoleChip from "@/components/role-chip.vue";
import { User } from "@/store/modules/AccountManagement/types";
import { VForm } from "@/types";
import { Roles } from "@/types/enums";
import { SnackbarColors } from "@/types/vuetify";
import { emailRules, generatePassword, passwordHint, passwordRules } from "@/utilities";

export default Vue.extend({
  name: "CreateUsersComponent",
  components: {
    RoleChip,
  },
  data: () => ({
    email: "",
    emailRules,
    error: "",
    password: "",
    passwordHint,
    passwordRules,
    responseMsg: "",
    role: Roles.USER,
    showMessage: false,
    user: {} as User,
    users: [] as Array<User>,
    valid: true,
    working: false,
  }),
  methods: {
    remove(user: User) {
      this.users.splice(this.users.indexOf(user), 1);
      this.users = [...this.users];
    },

    async validate() {
      if (this.$refs.form as VForm) {
        this.working = true;
        await this.$store.dispatch("AccountManagement/createUsers", this.users);
        this.clearForm();
        this.working = false;
      }
    },

    addUser() {
      const form = this.$refs.form as VForm;
      if (form?.validate()) {
        const { password, role } = this;
        const email = this.email.toLowerCase();

        if (this.users.find((user) => user.email === email)) {
          this.$store.commit(
            "Utilities/showSnackbar",
            {
              message: `<strong>${email}</strong> already added`,
              color: SnackbarColors.ERROR,
            },
            { root: true }
          );
        } else {
          this.users.push({ email, password, role });
          this.clearInputs();
        }
      }
    },

    clearInputs() {
      const form = this.$refs.form as VForm;
      form.resetValidation();

      this.password = generatePassword();
      this.email = "";
      this.role = Roles.USER;
    },

    clearForm() {
      this.clearInputs();
      //reset users array
      this.users = [];
    },
  },
  computed: {
    isOwner(): boolean {
      return this.$store.getters["User/getIsOwner"];
    },

    roles(): Roles[] {
      return [Roles.USER, Roles.ADMIN, ...(this.isOwner ? [Roles.OWNER] : [])];
    },
  },
  mounted() {
    this.password = generatePassword();
    this.$store.commit("AccountManagement/setLoadingState", false);
  },
});
