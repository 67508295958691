
import Vue from "vue";

export default Vue.extend({
  name: "ReadMoreComponent",
  props: {
    text: String,
    breakPoint: {
      type: Number,
      default: 80,
      required: false,
    },
  },
  data: () => ({
    readMore: true,
  }),
  computed: {
    truncatedText(): string {
      return `${this.text.slice(0, this.breakPoint)}...`;
    },
    showButton(): boolean {
      return this.text.length > this.breakPoint;
    },
  },
});
