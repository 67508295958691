import Vue from "vue";
import { MutationTree } from "vuex";

import {
  UserProfileInterface,
  UserProjectAccessInterface,
  UserProjectsAccessListInterface,
} from "@/types";

import { getDefaultState } from "./state";
import { State } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setLoadingState(state, loading: boolean) {
    state.loading = loading;
  },

  setFetchedState(state, fetched: boolean) {
    state.fetched = fetched;
  },

  setUserList(state, userList: UserProfileInterface[]) {
    state.userList = userList;
  },

  projectAccessupdateUserList(
    state,
    payload: {
      userEmail: string;
      projects: UserProjectsAccessListInterface[];
    }
  ) {
    const { userEmail, projects } = payload;

    // Find user in user list, so we can update the project access list on that user.
    const userIndex = state.userList.findIndex((user) => user.email === userEmail);
    if (userIndex === -1) {
      // User does not exist in the user list, so we can't update.
      return;
    }

    let newList: Partial<UserProjectAccessInterface>[] = projects.map((project) => {
      const { add, projectId, role } = project;
      return {
        id: projectId,
        ...(add && { role }),
      };
    });

    // User has existing project access, so we need to remove any projects that are no longer in the list or update the project access if it exists.
    const projectAccess = state.userList[userIndex].projectAccess;
    if (projectAccess) {
      newList = newList
        .concat(projectAccess)
        .reduce((updatedList: Partial<UserProjectAccessInterface>[], project) => {
          // Find existing project in the new list.
          const projectIndex = newList.findIndex((newProject) => newProject.id === project.id);

          // Keep existing project as is since it's not part of the new items.
          if (projectIndex === -1) {
            updatedList.push(project);
            return updatedList;
          }

          // A new project. If there's not role it means the project was removed.
          const { id, role } = newList[projectIndex];
          if (role) {
            updatedList.push({
              id,
              role,
            });
          }

          return updatedList;
        }, []);
    }

    if (newList.length < 1) {
      Vue.delete(state.userList[userIndex], "projectAccess");
    } else {
      Vue.set(state.userList[userIndex], "projectAccess", newList);
    }
  },

  createUpdateUserList(state, user: UserProfileInterface) {
    state.userList.push(user);
  },

  deleteUpdateUserList(state, email: string) {
    state.userList = state.userList.filter((user) => user.email !== email);
  },

  setUserGroupList(state, userGroupList: Record<string, string[]>) {
    state.userGroupList = userGroupList;
  },

  setImportDialogState(state, dialogState) {
    state.importDialog = dialogState;
  },

  setEditDialogState(state, dialogState) {
    state.editDialog = dialogState;
  },

  setEditWorkingState(state, workingState) {
    state.editWorking = workingState;
  },

  setDeleteDialogState(state, dialogState) {
    state.deleteDialog = dialogState;
  },

  setDeleteWorkingState(state, workingState) {
    state.deleteWorking = workingState;
  },
};

export default mutations;
