import { Group, LineSegments, Material, Object3D, Scene, Vector3 } from "three";
import Vue from "vue";
import { MutationTree } from "vuex";

import { attachmentsMutations } from "./attachments";
import { bottomMenuMutations } from "./bottom-menu";
import { modelsMutations } from "./models";
import { pointsMutations } from "./points";
import { settingsMutations } from "./settings";
import { sidebarsMutations } from "./sidebars";
import { SidebarsState } from "./sidebars/types";
import { getDefaultState } from "./state";
import { transformMutations } from "./transform";
import { DeleteDialogInterface, State } from "./types";

const mutations: MutationTree<State & SidebarsState> = {
  /**
   * Spread mutations from other modules.
   */
  ...attachmentsMutations,
  ...modelsMutations,
  ...pointsMutations,
  ...sidebarsMutations,
  ...transformMutations,
  ...bottomMenuMutations,
  ...settingsMutations,

  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  deleteLoadingProgressItem(state, id) {
    Vue.delete(state.loadingProgress, id);
  },

  setDeleteDialogState(state, dialogState) {
    state.deleteDialog.state = dialogState;
  },

  setDeleteDialog(state, dialog: DeleteDialogInterface) {
    state.deleteDialog = dialog;
  },

  resetDeleteDialog(state) {
    state.deleteDialog = {
      state: false,
      id: "",
      attachmentData: undefined,
      type: undefined,
      title: undefined,
      message: "",
    };
  },

  setScene(state, scene: Scene) {
    state.scene = scene;
  },

  /**
   * This sets the Potree scene. This is not the same as the `scene` in the `state` object. The `scene` in the `state` object is a `THREE.Scene` object, while the `scene` in the `potree` object is the scene that Potree sets up, which has Potree specific methods.
   */
  setPotreeScene(state, scene) {
    state.potreeScene = scene;
  },

  setLoadingProgressItem(state, payload) {
    const { id, progress } = payload;
    Vue.set(state.loadingProgress, id, progress);
  },

  setViewer(state, viewer) {
    state.viewer = viewer;
  },

  setViewerName(state, viewerName) {
    state.viewerName = viewerName;
  },

  setLoadingState(state) {
    state.loading = !state.loading;
  },
};

export default mutations;
