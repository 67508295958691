
import Vue from "vue";

import Author from "@/components/author.vue";
import CoverPlaceholder from "@/components/cover-placeholder.vue";
import { FileBrowserDialog, FileSelectDialog } from "@/components/file-browser";
import DeleteDialog from "@/components/project/delete-dialog.vue";
import { Project } from "@/store/modules/Projects/types";
import {
  ProjectUserAccessInterface,
  Roles,
  UpdatedProjectUsersInterface,
  UserProfileInterface,
  VForm,
} from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { descriptionRules, titleRules, usernameRules } from "@/utilities";

interface Data {
  canDrop: boolean;
  canEdit: ProjectUserAccessInterface[];
  canManage: ProjectUserAccessInterface[];
  canView: ProjectUserAccessInterface[];
  descriptionRules: (value: string) => Array<boolean | string>;
  isDragNDrop: boolean;
  newProject: Pick<Project, "description" | "image" | "title">;
  path: string;
  processingUsers: boolean;
  project: Partial<Project>;
  roles: typeof Roles;
  titleRules: (value: string) => Array<boolean | string>;
  updatedList: UpdatedProjectUsersInterface;
  userList: UserProfileInterface[];
  usernameRules: (value: string) => Array<boolean | string>;
  valid: boolean;
}

export default Vue.extend({
  name: "ProjectEditCreate",
  components: {
    Author,
    CoverPlaceholder,
    DeleteDialog,
    FileBrowserDialog,
    FileSelectDialog,
  },
  data: (): Data => ({
    canDrop: false,
    canEdit: [],
    canManage: [],
    canView: [],
    descriptionRules,
    isDragNDrop: false,
    newProject: { description: "", image: "", title: "" },
    path: "cover",
    processingUsers: false,
    project: {} as Project,
    roles: Roles,
    titleRules,
    updatedList: { added: [], removed: [] },
    userList: [],
    usernameRules,
    valid: true,
  }),
  methods: {
    /**
     * Cancel creation of new project and go back to project list.
     *
     * @returns {void}
     */
    cancelCreate(): void {
      this.$destroy();
      this.$store.dispatch("Utilities/reroute", "projects");
    },

    /**
     * Opens the `DeleteDialog` to confirm the delete project action.
     *
     * @returns {void}
     */
    deleteDialogConfirm(): void {
      this.$store.commit("Projects/setDeleteDialog", true);
    },

    // Handle dropped items.
    async handleDragNDrop(event: { dataTransfer: DataTransfer }) {
      // reset drop zone
      this.canDrop = false;
      const {
        dataTransfer: { files },
      } = event;
      // check for files and select first
      // this process does not handle multiple files
      if (files.length === 1) {
        const image = files[0];
        // make sure file is of image type
        const isImage = image.type.includes("image/");
        // upload image
        if (isImage) {
          this.isDragNDrop = true;

          // set and begin upload
          this.$store.commit("Storage/setIsImageResize", { resize: true, width: 360, height: 190 });
          this.$store.commit("Storage/setPath", this.path);
          this.$store.commit("Storage/setUploadFiles", [image]);
          //await this.$store.dispatch("Storage/uploadActivityFiles");
          this.$store.commit("Storage/setShowFileInput", false);
          this.$store.commit("Storage/setUploadDialogState", true);
          await this.$store.dispatch("Storage/uploadFiles");

          // get response and show resized image
          const uploadResponse = this.$store.getters["Storage/getContextItem"];
          const { objectName } = uploadResponse;
          this.image = objectName;
        } else {
          // inform user only image file types can be processed
          this.$store.commit("Utilities/showSnackbar", {
            message: "File is not of type image",
            color: SnackbarColors.ERROR,
          });
        }
      } else {
        // inform user only one file can be uploaded
        this.$store.commit("Utilities/showSnackbar", {
          message: "Project cover image can only contain one image",
          color: SnackbarColors.ERROR,
        });
      }
    },

    // Open file browser for file selection.
    async handleSelectFile() {
      // Set root path for items.
      this.$store.commit("Storage/setPath", this.path);
      this.$store.commit("Storage/setIsSingleUploadSelect", true);
      this.$store.commit("Storage/setIsImageResize", { resize: true, width: 360, height: 190 });

      // Open file browser.
      this.$store.commit("Storage/setFileBrowserDialogState", true);
    },

    addUserRole(list: Pick<ProjectUserAccessInterface, "id" | "email">[]) {
      const lastEntry = list.at(-1);
      if (!lastEntry) {
        return;
      }

      const { id, email } = lastEntry;
      const { added, removed } = this.updatedList;
      const newEntry = !added.some((item) => item.id === id);

      // Since there is no information about the role in the `list` parameter we have to veirfy what list the user was added to by comparing the users ID. This is required to determine what role the user was assigned in the project. If we can't determine the role we assign the user the `GUEST` role as a last resort.
      let role: Roles.ADMIN | Roles.EDITOR | Roles.GUEST;
      if (this.canView.some((item) => item.id === id)) {
        role = Roles.GUEST;
      } else if (this.canEdit.some((item) => item.id === id)) {
        role = Roles.EDITOR;
      } else if (this.canManage.some((item) => item.id === id)) {
        role = Roles.ADMIN;
      } else {
        role = Roles.GUEST;
      }

      if (newEntry) {
        added.push({
          id,
          email,
          role: role,
        });
      }

      const removedIndex = removed.findIndex((item) => item === email);
      if (removedIndex !== -1) {
        removed.splice(removedIndex, 1);
      }
    },

    removeUserRole(item: Pick<ProjectUserAccessInterface, "id" | "email">, role: Roles) {
      const { id, email } = item;
      let selectedList: ProjectUserAccessInterface[] = [];

      switch (role) {
        case Roles.ADMIN:
          selectedList = this.canManage;
          break;

        case Roles.EDITOR:
          selectedList = this.canEdit;
          break;

        case Roles.GUEST:
          selectedList = this.canView;
          break;
      }

      const index = selectedList.findIndex((item) => item.id === id);
      selectedList.splice(index, 1);

      const { added, removed } = this.updatedList;
      const newEntry = removed.some((item) => item === id);

      if (!newEntry) {
        removed.push(email);
      }

      const addedIndex = added.findIndex((item) => item.id === id);
      if (addedIndex !== -1) {
        added.splice(addedIndex, 1);
      }
    },

    userIdToNameEmail(id: string): string {
      const { name, email } = this.userList.find((user) => user.uuid === id) || {};

      if (name) {
        const { first, last } = name;

        if (!!first || !!last) {
          if (!!first && !!last) {
            return `${first} ${last} (${email})`;
          } else {
            return `${first} (${email})` || `${last} (${email})`;
          }
        }
      }

      return email || "unknown";
    },

    userProjectRoleList(list: ProjectUserAccessInterface[]) {
      const updatedList = list.map((item) => item.id);

      return updatedList;
    },

    async createProject(): Promise<void> {
      try {
        await this.$store.dispatch("Projects/createProject", {
          ...this.project,
          image: this.image,
        });
      } catch (error: any) {
        this.$store.commit("Utilities/showSnackbar", {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        });
      }
    },

    async updateProject(): Promise<void> {
      try {
        await this.$store.dispatch("Projects/updateProject", {
          updatedList: this.updatedList,
          project: {
            ...this.project,
            image: this.image,
          },
        });

        // Reset updated list since we have successfully updated the project at this point.
        this.updatedList = {
          added: [],
          removed: [],
        };
      } catch (error: any) {
        this.$store.commit("Utilities/showSnackbar", {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        });
      }
    },

    async validate(): Promise<void> {
      if ((this.$refs.form as VForm).validate()) {
        const routeName = this.$route.name;

        switch (routeName) {
          case "projectEdit":
            await this.updateProject();
            break;
          case "projectCreate":
            await this.createProject();
            break;
        }
      }
    },

    clearForm() {
      (this.$refs.form as VForm).reset();
    },
  },
  computed: {
    availableUsers(): Pick<ProjectUserAccessInterface, "id" | "email" | "label">[] {
      const combinedList = [...this.canManage, ...this.canEdit, ...this.canView];
      const availableList = this.userList.reduce((available, user) => {
        const { uuid, email } = user;
        const notSelected = !combinedList.some((item) => item.id === uuid);

        if (notSelected) {
          available.push({
            id: uuid,
            email,
            label: this.userIdToNameEmail(uuid),
          });
        }

        return available;
      }, [] as Pick<ProjectUserAccessInterface, "id" | "email" | "label">[]);

      return availableList;
    },

    isOwner() {
      return this.$store.getters["User/getIsOwner"];
    },

    isAdmin() {
      return this.$store.getters["User/getIsAdmin"];
    },

    isEdit(): boolean {
      const {
        params: { projectId },
        name: routeName,
      } = this.$route;

      if (projectId && routeName === "projectEdit") {
        return true;
      }

      return false;
    },

    canDeleteProject(): boolean {
      const editRoute = this.$route.name === "projectEdit";
      return this.isOwner && editRoute;
    },

    image: {
      get() {
        // use context item objec to determine image
        const contextItem = this.$store.getters["Storage/getContextItem"];
        if (contextItem.objectName && contextItem.objectName !== "") {
          return contextItem.objectName;
        }
        // default is empty
        return "";
      },
      set(objectName) {
        this.$store.commit("Storage/setContextItem", { objectName });
      },
    },

    isLoading() {
      return this.$store.getters["Projects/getLoadingState"];
    },

    isUploading: {
      get() {
        return this.$store.getters["Storage/getIsUploading"];
      },
      set(state) {
        this.$store.commit("Storage/setIsUploading", state);
      },
    },

    titleInput(): HTMLInputElement {
      return this.$refs.titleInput as HTMLInputElement;
    },
  },
  beforeCreate() {
    // Reset context item for computed image to fetch current project image.
    this.$store.commit("Storage/resetContextItem");
  },
  beforeMount() {
    // This component is only for admins.
    if (!this.isAdmin) {
      this.$router.push({ name: "projects" });
      return;
    }

    this.$store.commit("Storage/setPath", this.path);
  },
  async mounted() {
    const {
      params: { projectId },
      name: routeName,
    } = this.$route;

    // Set processing state to true to show loading indicator while handling users.
    this.processingUsers = true;

    const hasFetchedUsers = this.$store.getters["AccountManagement/getFetchedState"];
    if (!hasFetchedUsers) {
      this.$store.commit("Projects/setLoadingState", true);
      await this.$store.dispatch("AccountManagement/fetchUsers", { nonAdmin: true });
      // For correct naming and no delay we need to preload files in current map.
      await this.$store.dispatch("Storage/fetchFiles", this.path);
      this.$store.commit("Projects/setLoadingState", false);
    }

    // Generate list of users that can be assigned a role to this project.
    const users = this.$store.getters["AccountManagement/getUserList"];
    users.forEach((user: UserProfileInterface) => {
      const { role, projectAccess, uuid, email } = user;

      if (role === Roles.USER) {
        this.userList.push(user);

        const accessProject = projectAccess?.find((project) => project.id === projectId);

        if (accessProject) {
          const { role: projectRole } = accessProject;
          const projectUser = {
            id: uuid,
            email,
            label: this.userIdToNameEmail(uuid),
            role: projectRole,
          };

          switch (projectRole) {
            case Roles.ADMIN:
              this.canManage.push(projectUser);
              break;

            case Roles.EDITOR:
              this.canEdit.push(projectUser);
              break;

            case Roles.GUEST:
              this.canView.push(projectUser);
              break;
          }
        }
      }
    });

    // Users are processed, hide loading indicator.
    this.processingUsers = false;

    const project: Project = this.$store.getters["Projects/getProject"](projectId);

    switch (routeName) {
      case "projectCreate":
        this.project = this.newProject;
        this.$nextTick(() => this.titleInput.focus());
        break;
      case "projectEdit":
        if (!project) {
          await this.$store.dispatch("Projects/fetchProject", projectId);
        }
        this.project = this.$store.getters["Projects/getProject"](projectId);
        this.image = this.project.image || "";
        break;
    }
  },
});
