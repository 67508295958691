import { ConsoleLogger } from "@aws-amplify/core";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { GeneralFileBrowser } from "@/components/file-browser";
import store from "@/store";
import { FileBrowserAccessRole, Roles, UserProfileInterface } from "@/types";
import AddUsers from "@/views/add-users.vue";
import AppContainer from "@/views/app-container.vue";
import Dashboard from "@/views/dashboard.vue";
import Login from "@/views/login.vue";
import PasswordReset from "@/views/password-reset.vue";
import PointCloudViewer from "@/views/point-cloud-viewer.vue";
import {
  Project,
  ProjectContainer,
  ProjectEditCreate,
  ProjectFileBrowser,
  Projects,
  ProjectsContainer,
} from "@/views/projects";
import { Settings, SettingsContainer, Tags } from "@/views/settings";
import UserManagement from "@/views/user-management.vue";
import UserProfile from "@/views/user-profile.vue";
import { ViewerEditCreate, Viewers, ViewersContainer } from "@/views/viewers";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/password-reset",
    name: "passwordReset",
    component: PasswordReset,
  },
  {
    path: "/",
    component: AppContainer,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "settings",
        component: SettingsContainer,
        children: [
          {
            path: "/",
            name: "settings",
            component: Settings,
          },
          {
            path: "/settings/tags",
            name: "settingsTags",
            component: Tags,
          },
        ],
      },
      {
        path: "projects",
        component: ProjectsContainer,
        children: [
          {
            path: "/",
            name: "projects",
            component: Projects,
          },
          {
            path: "create-project",
            name: "projectCreate",
            component: ProjectEditCreate,
          },
          {
            path: ":projectId",
            component: ProjectContainer,
            children: [
              {
                path: "/",
                name: "project",
                component: Project,
              },
              {
                path: "file-browser",
                name: "projectFileBrowser",
                component: ProjectFileBrowser,
              },
              {
                path: "edit",
                name: "projectEdit",
                component: ProjectEditCreate,
              },
              {
                path: "viewers",
                component: ViewersContainer,
                children: [
                  {
                    path: "/",
                    name: "viewers",
                    component: Viewers,
                  },
                  {
                    path: "create-viewer",
                    name: "viewerCreate",
                    component: ViewerEditCreate,
                  },
                  {
                    path: ":viewerId/edit",
                    name: "viewerEdit",
                    component: ViewerEditCreate,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "file-browser",
        name: "fileBrowser",
        component: GeneralFileBrowser,
      },
      {
        path: "user-management",
        name: "userManagement",
        component: UserManagement,
      },
      {
        path: "add-users",
        name: "addUsers",
        component: AddUsers,
      },
      {
        path: "user-profile",
        name: "userProfile",
        component: UserProfile,
      },
      {
        path: "viewer/:projectId/:viewerId",
        name: "viewer",
        component: PointCloudViewer,
        // meta: {
        //   requiresAuth: true,
        // },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Verify that user is authenticated with navigation guard.
router.beforeEach(async (to, from, next) => {
  const { name, matched, path, params } = to;
  const { name: fromName, params: fromParams } = from;

  // Store current route name in state for easy access.
  store.commit("Utilities/setCurrentPathName", name);

  // Route might not always be available, store current route in state for access.
  const storePath = store.getters["Utilities/getCurrentPath"];
  const ignoreRoutes = ["login", "password-reset", "dashboard"];
  if (!storePath && name && !ignoreRoutes.includes(name)) {
    store.commit("Utilities/setCurrentPath", path);
  }

  // Determine if route requires authentication.
  const requiresAuth = matched.some((route) => route.meta.requiresAuth);

  // Route requires authentication.
  if (requiresAuth) {
    let authenticatedUser: CognitoUserInterface | undefined;

    try {
      // Suppress Amplify configuration warning comment durring debug.
      ConsoleLogger.LOG_LEVEL = 1;

      // Get authenticated user, if this fails we will redirect user to login.
      authenticatedUser = await Auth.currentAuthenticatedUser();

      // User is authenticated, continue.
      store.commit("User/setAuthenticatedUser", authenticatedUser);
    } catch {
      // User is not authenticated, re-route to login.
      next("/login");
      return;
    }

    // Check if user profile is set, else fetch it.
    const userProfile: UserProfileInterface = store.getters["User/getUserProfile"];
    if (userProfile.uuid === "") {
      // Missing user profile. We should probably fetch it. The profile will be set in the store by the dispatch action.
      try {
        await store.dispatch("User/fetchUserProfile", {
          notSelf: false,
        });
      } catch {
        // User is not authenticated, re-route to login.
        next("/login");
        return;
      }
    }

    const { rootFileAccess, projectAccess, role } = userProfile;
    const adminRoles = [Roles.OWNER, Roles.ADMIN];

    // Simplify user navigation if user is not an admin.
    if (!adminRoles.includes(role)) {
      // Check if user has access to the organization file browser.
      if (name === "fileBrowser") {
        const fileBrowserRoles = Object.values(FileBrowserAccessRole);
        const canAccess = rootFileAccess ? fileBrowserRoles.includes(rootFileAccess) : false;

        // User does not have access to the organization file browser, re-route to dashboard.
        if (!canAccess) {
          next("/projects");
          return;
        }
      }

      // If user only has `guest` access to a project, the `project` route will be redudant, since it will only show `viewers` card.
      // if (name === "project") {
      //   const { projectId } = params;
      //   const { role: userProjectRole } =
      //     projectAccess?.find((project) => project.id === projectId) || {};

      //   if (userProjectRole === Roles.GUEST) {
      //     if (fromName === "viewers" && fromParams.projectId === projectId) {
      //       next({ name: "projects" });
      //     } else {
      //       next({ name: "viewers", params: { projectId } });
      //     }
      //     return;
      //   }
      // }
    }

    const currentPath = store.getters["Utilities/getCurrentPath"];

    if (currentPath) {
      // There is a current path, re-route to it.
      router.push({ path: currentPath });
      // Make sure to clear the current path after re-routing since it is no longer needed.
      store.commit("Utilities/clearCurrentPath");
    } else {
      // There is no current path, continue.
      next();
    }
    return;
  }

  // Route does not require authentication, continue.
  next();
  return;
});

export default router;
