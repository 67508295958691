
import Vue from "vue";

import { getContainerTitle } from "@/utilities/containers";

export default Vue.extend({
  name: "ViewerItemUploadDialog",
  computed: {
    isLoading(): boolean {
      return this.$store.getters["Storage/getLoadingState"];
    },
    dialog: {
      get(): boolean {
        return this.$store.getters["Viewers/getUploadNameDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Viewers/setUploadNameDialogState", state);
      },
    },
    itemName: {
      get(): string {
        return this.$store.getters["Viewers/getUploadItem"].name;
      },
      set(name: string) {
        this.$store.commit("Viewers/setUploadItem", {
          ...this.$store.getters["Viewers/getUploadItem"],
          name,
        });
      },
    },
    itemType() {
      const type = this.$store.getters["Viewers/getUploadItem"].type;
      const title = getContainerTitle(type);
      if (title) {
        return title;
      }

      return "";
    },
  },
});
