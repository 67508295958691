import { Amplify, API, Auth } from "aws-amplify";
import { ActionTree, Dispatch } from "vuex";

import { AuthorInterface, AuthorsObjectInterface } from "@/types";
import { ClientLocalStorageData } from "@/types/local-storage";
import { SnackbarColors } from "@/types/vuetify";
import { coreApiPost } from "@/utilities/core-api";
import { getClientData, setClientData } from "@/utilities/local-storage";

import router from "../../../router/index";
import { SelectedClient, State } from "./types";

const apiRoot = "/account";
const actions: ActionTree<State, unknown> = {
  errorSnackbar({ commit }, error: unknown) {
    // Error occured. Turn off loading state.
    commit("setLoadingState", false);
    // Show error message in snackbar.
    commit(
      "Utilities/showSnackbar",
      {
        // If error isn't a string, show entire error object as string.
        message: typeof error === "string" ? error : JSON.stringify(error),
        color: SnackbarColors.ERROR,
      },
      { root: true }
    );
  },

  async checkUserClients({ state, dispatch, commit }) {
    const { userCredentials } = state.userActionPayload;
    const { email } = userCredentials;
    // Get user client data from API. Login doesn't use "coreApiPost" and "apiRoot", since it only has one action.
    const apiName = "core";
    const path = "/login";
    const headers = {
      "Content-Type": "application/json",
    };
    // Reset local states.
    dispatch("resetAllLocalStates");

    try {
      const userData = await API.post(apiName, path, {
        headers: headers,
        body: {
          email,
        },
      });

      commit("setLoadingState", false);
      // Handle user data response from API.
      if (userData.length < 1) {
        // No user found. Reset local states.
        await dispatch("resetAllLocalStates");
        // Display error.
        dispatch("errorSnackbar", "Incorrect input");
      } else {
        commit("setClients", userData);
        if (userData.length === 1) {
          commit("setSelectedClient", userData[0]);
          dispatch("initiate");
        }
      }
    } catch (error) {
      // Display error.
      dispatch("errorSnackbar", error);
    }
  },

  async finalize({ getters, dispatch, commit, state }): Promise<void> {
    const { selectedClient, userActionPayload } = state;
    const { action, userCredentials } = userActionPayload;
    const { email, password, permanentPassword } = userCredentials;

    await dispatch("setAmplifyConfig", selectedClient);

    // Set `openpointClientAuthData` for web storage access to client user.
    const clientData = selectedClient as ClientLocalStorageData;
    setClientData(clientData);

    try {
      // User is loggin in.
      if (action === "login") {
        const inputPassword = !permanentPassword ? password : permanentPassword;
        const user = await Auth.signIn(email, inputPassword);

        const storageKeyPrefix = user.keyPrefix;
        const currentClientData = getClientData();
        const newClientData = {
          ...currentClientData,
          storageKeyPrefix,
        };
        setClientData(newClientData);

        commit("setAuthenticatedUser", user);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          commit("setUserConfirmationState", false);
          commit("setLoadingState", false);
        } else {
          // Get user profile data once user is authenticated and any challanges have processed.
          await dispatch("fetchUserProfile", {
            notSelf: false,
          });
          router.push("/projects");
        }
      }
      // User is trying to reset password.
      if (action === "passwordReset") {
        await Auth.forgotPassword(email);
        commit("setInstructionsSent", true);
      }
    } catch (error: unknown) {
      // Reset local states.
      if (getters["getClients"].length < 2) {
        commit("setClients", []);
      }
      commit("setSelectedClient", {});
      await dispatch("setAmplifyConfig");
      // Display error.
      dispatch("errorSnackbar", String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"));
    }
    commit("setLoadingState", false);
  },

  async initiate({ commit, state, dispatch }) {
    commit("setLoadingState", true);
    const { isConfirmedUser } = state;

    if (!state.clients.length) {
      await dispatch("checkUserClients");
    } else {
      if (!isConfirmedUser) {
        await dispatch("setPermanentPassword");
      } else {
        await dispatch("finalize");
      }
    }
  },

  async setPermanentPassword({ dispatch, commit, state }) {
    const {
      authenticatedUser,
      userActionPayload: {
        userCredentials: { permanentPassword },
      },
    } = state;

    if (typeof permanentPassword === "string") {
      try {
        await Auth.completeNewPassword(authenticatedUser, permanentPassword);
        await dispatch(
          "AccountManagement/setUserState",
          {
            email: state.authenticatedUser?.username,
            userState: true,
          },
          { root: true }
        );
        // Once permanent password is set return to finalize.
        dispatch("finalize");
      } catch (error) {
        // Display error.
        dispatch("errorSnackbar", String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"));
      }
      commit("setLoadingState", false);
    }
  },

  /**
   * Returns the author profile of the given author id.
   *
   * - If the author id is the same as the current user id, the current user profile is returned.
   * - If the author id is not the same as the current user id, the author profile is fetched using `fetchUserProfile` if it is not already in the store.
   *
   * @async
   * @param {{ dispatch: any; getters: any; }} { dispatch, getters }
   * @param {Partial<AuthorInterface>} payload
   * @returns {Promise<AuthorInterface>}
   */
  async getAuthor(
    { dispatch, getters }: { dispatch: any; getters: any },
    payload: Partial<AuthorInterface>
  ): Promise<AuthorInterface> {
    const { id, reference } = payload;

    const userProfile = getters["getUserProfile"];

    const { sk, userName, uuid: userId } = userProfile;

    // Used when we are not able to determine the author.
    const unknownAuthor = {
      id: "",
      name: "",
      reference: reference ?? "unknown reference",
    };

    // There is no `userId` and no author id provided or the author id is an empty string.
    if (!userId && !!id) {
      return unknownAuthor;
    }

    // If the author id is the same as the current user id we can stop here and return author using current user profile data.
    if (id === userId) {
      return {
        id: userId,
        name: userName,
        reference: sk,
      };
    }

    // Test if the author profile is already in the store.
    const authors: AuthorInterface[] = getters["getAuthorProfiles"];
    const author = authors.find((profile) => profile.id === id);

    if (author) {
      // Author profile is already in the store, return it.
      return author;
    } else {
      // Author profile is not in the store, fetch it, this will also add it to the store.
      await dispatch("fetchUserProfile", {
        id,
        notSelf: true,
      });

      // Get the updated author profiles from the store.
      const updatedAuthors: AuthorInterface[] = getters["getAuthorProfiles"];
      const updatedAuthor = updatedAuthors.find((profile) => profile.id === id);

      // Return the author profile. If for whatever reason the author profile is not in the store at this point, return the unknown author.
      return updatedAuthor ?? unknownAuthor;
    }
  },

  async getAuthorsObject(
    { dispatch }: { dispatch: Dispatch },
    item: {
      createdBy: AuthorInterface;
      updatedBy: AuthorInterface;
      updatedAt: number;
    }
  ): Promise<AuthorsObjectInterface> {
    const { createdBy, updatedAt, updatedBy } = item;
    const author = await dispatch("getAuthor", createdBy);

    let updateAuthor;

    if (updatedAt) {
      updateAuthor = await dispatch("getAuthor", updatedBy);
    }

    return {
      author,
      updateAuthor,
    };
  },

  async fetchUserProfile(
    { dispatch, commit },
    payload: {
      id?: string;
      notSelf?: boolean;
    }
  ) {
    const { id, notSelf } = payload || {};

    // Can't fetch non-self profile without id.
    if (notSelf && (!id || id === "")) {
      return;
    }

    try {
      // User profile fetched using users Cognito UUID (a.k.a `sub`).
      const userProfileResponse = await coreApiPost(`${apiRoot}/get-user-profile`, {
        id,
        notSelf,
      });

      if (notSelf) {
        // Getting other users profile.
        commit("setAuthorProfiles", userProfileResponse);
      } else {
        // Getting own profile.
        commit("setUserProfile", userProfileResponse);
        commit("setUserProfileImage");
      }
    } catch (error: unknown) {
      // Display error.
      dispatch("errorSnackbar", String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"));
    }
  },

  async updateUserProfile({ commit, dispatch, getters }) {
    commit("setLoadingState", true);
    const userProfile = getters["getUserProfile"];
    const { imageSource, name } = userProfile;

    try {
      await coreApiPost(`${apiRoot}/update-user-profile`, {
        imageSource,
        name,
      });
      commit("setUserProfile", {
        ...userProfile,
        imageSource,
        name,
      });
      commit("setUserProfileImage");

      // commit(
      //   "Utilities/showSnackbar",
      //   {
      //     message: "Your profile was successfully updated",
      //     color: "success",
      //   },
      //   { root: true }
      // );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      // Display error.
      dispatch("errorSnackbar", message);
    }
    commit("setLoadingState", false);
  },

  async logOut({ dispatch }) {
    try {
      // Amplify auth signout.
      await Auth.signOut();
      // Reroute user back to login.
      router.replace("/login");
      // Reset local states.
      await dispatch("resetAllLocalStates");
      // Reset all modules.
      await dispatch("resetAllModules");
    } catch (error) {
      // Display error.
      dispatch("errorSnackbar", error);
    }
  },

  async resetAllLocalStates({ dispatch, commit }) {
    localStorage.removeItem("openpointClientAuthData");
    commit("setClients", []);
    commit("setSelectedClient", {});
    commit("setAuthenticatedUser", null);
    await dispatch("setAmplifyConfig");
  },

  resetAllModules({ commit }) {
    commit("AccountManagement/resetState", null, { root: true });
    commit("User/resetState", null, { root: true });
    commit("Projects/resetState", null, { root: true });
    commit("PointCloudViewer/resetState", null, { root: true });
    commit("Viewers/resetState", null, { root: true });
  },

  setAmplifyConfig(_, payload: SelectedClient) {
    const { identityPoolId, userPoolId, userPoolWebClientId, region, bucket } = payload || {};
    Amplify.configure({
      Auth: {
        region: region ? region : null,
        identityPoolId: identityPoolId ? identityPoolId : null,
        userPoolId: userPoolId ? userPoolId : null,
        userPoolWebClientId: userPoolWebClientId ? userPoolWebClientId : null,
      },
      Storage: {
        AWSS3: {
          region: region ? region : null,
          bucket: bucket ? bucket : null,
        },
      },
    });
  },
};

export default actions;
