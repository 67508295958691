
import Vue from "vue";

import { getClientData } from "@/utilities/local-storage";

export default Vue.extend({
  name: "Dashboard",
  computed: {
    isAdmin() {
      return this.$store.getters["User/getIsAdmin"];
    },

    hasRootFileAccess() {
      return this.$store.getters["User/getHasRootFileAccess"];
    },

    clientDisplayname() {
      const clientData = getClientData();
      let clientDisplayname = "";
      if (clientData) {
        const { displayName } = clientData;
        clientDisplayname = displayName ? displayName : "";
      }
      return clientDisplayname;
    },
  },
});
