
import Vue from "vue";

import { ModelInpsectorAttachmentInterface } from "@/types";
import { IFCModelItemInterface } from "@/types/viewer";
import { coreApiPost } from "@/utilities/core-api";

import IFCStoreyGroup from "./storey-group.vue";

export default Vue.extend({
  name: "IFCStoreys",
  components: {
    IFCStoreyGroup,
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    getCurrentLeftSideBarModelId() {
      return this.$store.getters["PointCloudViewer/getIfcCurrentModelIdInLeftSideBar"];
    },
  },
  computed: {
    state: {
      get(): boolean {
        return this.$store.getters["PointCloudViewer/getLeftSidebarIfc"];
      },
      set(value: boolean) {
        this.$store.commit("PointCloudViewer/setLeftSidebarIfc", value);
      },
    },

    ifcBuilding() {
      const building = this.$store.getters["PointCloudViewer/getIfcBuilding"];
      return building;
    },
    modelTagsLoading() {
      return this.$store.getters["PointCloudViewer/getModelTagsLoading"];
    },
  },
  /**
   * On component mount, get attachments associated with model using `coreApiPost` on endpoint `/viewer/get-model-inspector-attachments`when mounting `storeys` component in left sidebar (i.e. when user clicks interactive IFC model in layers).
   */
  async mounted() {
    // Get current model from store.
    const currentModel: IFCModelItemInterface =
      this.$store.getters["PointCloudViewer/getIfcCurrentModel"];
    // Extract models index (number) from `ifcModels` array (to later be able to set attachments for said model based on index).
    const modelIndex = this.$store.state.PointCloudViewer.ifcModels.findIndex(
      (ifcModel: IFCModelItemInterface) => ifcModel.modelId === currentModel.modelId
    );
    // Model container ID (the model containers database entry sort key), not to be confused with `modelId` which is internally created for IFC models when loading them into viewer.
    const modelContainerId = currentModel.modelContainerId;
    // Current model attachments, used to make sure we are only getting attachments if `undefined`, i.e. we have not fetched them yet.
    const modelAttachments = currentModel.attachments;

    if (!modelAttachments) {
      try {
        // Current model `attachments` key is undefined. Get attachments from endpoint.
        const attachments: ModelInpsectorAttachmentInterface[] | undefined = await coreApiPost(
          "/viewer/get-ifc-model-attachments",
          {
            modelContainerId,
          }
        );

        // Set attachments on current model (if empty or undefined, will apply empty array as fallback).
        this.$store.commit("PointCloudViewer/setIFCModelItemAttachments", {
          modelIndex,
          attachments,
        });
      } catch (error) {
        console.error(error);
      }
    }

    // Switch of component loading state, to reveal destructured IFC model.
    this.loading = false;
  },
});
