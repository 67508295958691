import { MutationTree } from "vuex";

import { getDefaultState } from "./state";
import { Project, State } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setLoadingState(state, loadingState: boolean) {
    state.loading = loadingState;
  },

  setSelectDialogState(state, dialogState: boolean) {
    state.selectDialog = dialogState;
  },

  setUploadingState(state, uploading: boolean) {
    state.uploading = uploading;
  },

  setProjects(state, projects: Project[]) {
    state.list = projects;
  },

  setFetchedState(state, fetched: boolean) {
    state.fetched = fetched;
  },

  setProject(state, payload: Project) {
    const { id } = payload;
    const project = state.list.find((listProject) => listProject.id === id);
    // If project is already in project list.
    if (project) {
      for (const listProject of state.list) {
        if (listProject.id === id) {
          Object.assign(listProject, payload);
          break;
        }
      }
    } else {
      if (state.list.length) {
        // If project list has items.
        state.list.push(payload);
      } else {
        // First project in list.
        state.list = [payload];
      }
    }
  },

  /**
   * This will remove a project from the states project list. Usefull when a project is deleted.
   *
   * @param {State} state
   * @param {string} projectId ID of project to remove
   */
  removeProject(state: State, projectId: string) {
    const projectIndex = state.list.findIndex((project) => project.id === projectId);
    if (projectIndex !== -1) {
      state.list.splice(projectIndex, 1);
    }
  },

  setDeleteDialog(state, dialogState: boolean) {
    state.deleteDialog = dialogState;
  },

  setIsDeleted(state, isDeleted: string) {
    state.isDeleted.push(isDeleted);
  },
};

export default mutations;
