import { State } from "./types";

const getDefaultState = (): State => {
  return {
    cancelUploadDialog: false,
    contextItem: {
      id: "",
      name: "",
      objectName: "",
      type: "",
    },
    createFolderDialog: false,
    deleteDialog: false,
    esitmatedUploadTime: {
      start: 0,
      estimate: 0,
    },
    fetchedModels: [],
    fetchedWireframes: [],
    fileBrowserDialog: false,
    files: [],
    filesInFolder: [],
    fileUploadProgress: {},
    folderCreationProgress: false,
    isImageResize: {
      resize: false,
    },
    isProfileImage: false,
    isSingleUploadSelect: false,
    isUploadAborted: false,
    isUploading: false,
    itemInfoDialog: false,
    loading: false,
    path: "",
    projectStorageRoot: "__project_root__/",
    renameItemDialog: false,
    selectFileDialog: false,
    shareDialog: false,
    shareUrl: "",
    showFileInput: true,
    totalUploadSize: 0,
    uploadAllFiles: [],
    uploadDialog: false,
    uploadFiles: [],
    uploadRequests: [],
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
