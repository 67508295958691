
import Vue from "vue";

import CoverPlaceholder from "@/components/cover-placeholder.vue";

export default Vue.extend({
  name: "PointCloudList",
  components: {
    CoverPlaceholder,
  },
  data: () => ({
    defaultProjectPic: "../assets/images/project_default.jpg",
  }),
  methods: {
    getImgUrl(localImage: string) {
      var images = require.context("../../assets/images/", false, /\.png$/);
      return images("./" + localImage + ".png");
    },
    editViewer(viewerId: string) {
      // Only admin group memebers can edit viewer.
      if (this.isAdmin) {
        const projectId = this.$store.getters["Viewers/getProjectId"];
        this.$router.push({
          name: "viewerEdit",
          params: { projectId, viewerId },
        });
      }
    },
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    isLoading() {
      return this.$store.getters["Viewers/getLoadingState"];
    },
    viewers() {
      const projectId = this.$store.getters["Viewers/getProjectId"];
      const viewers = this.$store.getters["Viewers/getViewers"](projectId);
      const isFetched = this.$store.getters["Viewers/getFetchedState"](projectId);

      if (!isFetched && projectId) {
        this.$store.dispatch("Viewers/fetchViewers", projectId);
      }

      return viewers;
    },
  },
  mounted() {
    const projectId = this.$route.params.projectId;
    this.$store.commit("Viewers/setProjectId", projectId);
  },
});
