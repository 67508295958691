
import Vue from "vue";

import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import {
  AttachmentSectionLineItem,
  AttachmentType,
  InspectorAttachmentTypeInterface,
  VForm,
} from "@/types";
import { SnackbarColors } from "@/types/vuetify";

import AttachmentHeader from "./attachment-header.vue";
import ListItems from "./list-items.vue";

interface Data {
  dialog: boolean;
  note: Partial<InspectorAttachmentTypeInterface>;
  description: string;
}

const defaultNote = {
  type: AttachmentType.NOTE,
  items: [],
};

export default Vue.extend({
  name: "NoteAttachmentType",
  components: {
    AttachmentHeader,
    ListItems,
  },
  data: (): Data => ({
    dialog: false,
    note: defaultNote,
    description: "",
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.description = "";
    },

    async validateForm() {
      const {
        $refs: { formAddNote },
        note: { id },
        description,
      } = this;

      const form = formAddNote as VForm;

      if (description !== "") {
        await this.$store.dispatch("PointCloudViewer/createAttachment", {
          sectionId: id,
          lineItem: {
            description,
          },
          sectionLineItem: AttachmentSectionLineItem.LINE_ITEM,
        });

        // Reset form and data.
        form.reset();
        this.closeDialog();
      } else {
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: "Note can not be empty",
            color: SnackbarColors.ERROR,
            timeout: 2,
          },
          { root: true }
        );
      }
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },
  },
  async mounted() {
    Vue.set(this.note, "items", []);

    const {
      inspector: { attachments },
    } = this;

    const attachment = attachments?.find(
      (item: InspectorAttachmentTypeInterface) => item.type === AttachmentType.NOTE
    );

    if (!attachment) {
      return;
    }

    const { createdAt, createdBy, id, items } = attachment;
    Vue.set(this.note, "createdAt", createdAt);
    Vue.set(this.note, "createdBy", createdBy);
    Vue.set(this.note, "id", id);
    Vue.set(this.note, "items", items ?? []);
  },
  destroyed() {
    this.note = defaultNote;
  },
});
