
import Vue from "vue";

import { SnackbarColors } from "@/types";

export default Vue.extend({
  name: "ProjectDeleteDialog",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleteInputConfirm: "",
  }),
  methods: {
    async confirmDeleteAction() {
      const viewers = this.$store.getters["Viewers/getViewers"](this.id);
      const files = this.$store.getters["Storage/getFileBrowserItems"](this.id);

      if ((viewers && viewers.length > 0) || (files && files.length > 0)) {
        // If viewers or files are loaded and not empty, we know in advance that we can't delete the project so we don't need to send the request (which will fail anyway, since it checks for files and viewers).
        this.$store.commit("Utilities/showSnackbar", {
          message:
            "Project has viewers and/or files associated with it. You can only delete a project with no viewers and files.",
          color: SnackbarColors.ERROR,
          timeout: -1,
        });

        return;
      }

      await this.$store.dispatch("Projects/deleteProject", this.id);
      this.dialogState = false;
    },
  },
  computed: {
    disableConfirm(): boolean {
      const isInputDelete = this.deleteInputConfirm === "delete";
      return !isInputDelete || this.loading;
    },

    loading(): boolean {
      return this.$store.getters["Projects/getLoadingState"];
    },

    dialogState: {
      get(): boolean {
        return this.$store.getters["Projects/getDeleteDialog"];
      },
      set(value: boolean) {
        this.$store.commit("Projects/setDeleteDialog", value);
      },
    },
  },
});
