
import Vue from "vue";

import DeleteLayerItem from "./delete-layer-item.vue";
import EnterIFCModel from "./enter-ifc-model.vue";
import FocusToItem from "./focus-to-item.vue";
import ResetIFCModel from "./reset-ifc-model.vue";
import ToggleVisibility from "./toggle-visibility.vue";

export default Vue.extend({
  name: "ViewerLayerItemActions",
  props: {
    itemkey: String,
    item: Object,
    type: String,
  },
  components: {
    DeleteLayerItem,
    EnterIFCModel,
    FocusToItem,
    ResetIFCModel,
    ToggleVisibility,
  },
});
