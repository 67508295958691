
import Vue from "vue";

import { UserProfileInterface } from "@/types";

export default Vue.extend({
  name: "AppContainerLeftSidebarUserSection",
  props: {
    sidebar: {
      type: Boolean,
      default: false,
    },
    miniSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    avatarSize: 46,
  }),
  methods: {
    signOut() {
      this.$store.dispatch("User/logOut");
    },
  },
  computed: {
    userProfile(): UserProfileInterface {
      return this.$store.getters["User/getUserProfile"];
    },

    profileImage(): string {
      return this.userProfile.profileImage;
    },

    userName(): string {
      return this.$store.getters["User/getUserName"];
    },
  },
});
