import { GetterTree } from "vuex";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getIFCManager: (state) => {
    return state.ifcManager;
  },

  getIFCLoader: (state) => {
    return state.ifcLoader;
  },
};

export default getters;
