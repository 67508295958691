
import Vue from "vue";

import { DragNDropWindow } from "@/components/file-browser";

import Footer from "./footer.vue";
import { AdminMenu, Logotype, Menu, UserSection } from "./left-sidebar";

export default Vue.extend({
  name: "Login",
  components: {
    AdminMenu,
    DragNDropWindow,
    Footer,
    Logotype,
    Menu,
    UserSection,
  },
  data: () => ({
    sidebar: true,
    userMenu: false,
  }),
  methods: {
    toggleSidebar() {
      !this.isXSmall ? (this.sidebar = !this.sidebar) : undefined;
    },
  },
  computed: {
    miniSidebar(): boolean {
      return this.isXSmall || !this.sidebar;
    },
    isXSmall() {
      return this.$vuetify.breakpoint.xs;
    },
  },
});
