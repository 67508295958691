
import Vue from "vue";

import { AttachmentSectionLineItem, ViewerObjectTypeCategory } from "@/types";

export default Vue.extend({
  name: "PointCloudViewerDeleteDialog",
  data: () => ({
    working: false,
  }),
  methods: {
    async confirmDeleteAction() {
      this.working = true;
      // Make sure `type` is present.
      if (!this.type) {
        this.working = false;
        return;
      }

      // Type dispatch action switch.
      switch (this.type) {
        case ViewerObjectTypeCategory.POINT_OF_INTEREST:
        case ViewerObjectTypeCategory.MEASUREMENT_DISTANCE:
        case ViewerObjectTypeCategory.MEASUREMENT_HEIGHT:
        case ViewerObjectTypeCategory.MEASUREMENTS:
        case ViewerObjectTypeCategory.MEASUREMENT:
          await this.$store.dispatch("PointCloudViewer/deletePoint");
          break;

        case AttachmentSectionLineItem.LINE_ITEM:
        case AttachmentSectionLineItem.SECTION:
          await this.$store.dispatch("PointCloudViewer/deleteAttachment", this.deleteAttachment);
          break;
      }

      this.working = false;
    },
  },
  computed: {
    dialogState: {
      get(): boolean {
        return this.$store.getters["PointCloudViewer/getDeleteDialog"].state;
      },
      set(value: boolean) {
        this.$store.commit("PointCloudViewer/setDeleteDialogState", value);
      },
    },

    type() {
      return this.$store.getters["PointCloudViewer/getDeleteDialog"].type;
    },

    deleteAttachment() {
      return this.$store.getters["PointCloudViewer/getDeleteDialog"].deleteAttachment;
    },

    title() {
      return this.$store.getters["PointCloudViewer/getDeleteDialog"].title ?? "Confirm delete";
    },

    message() {
      return this.$store.getters["PointCloudViewer/getDeleteDialog"].message;
    },
  },
});
