
import Vue from "vue";

import { FileSelectDialog } from "@/components/file-browser";
import RoleChip from "@/components/role-chip.vue";
import { VForm } from "@/types";
import { nameRules, timestampToLocaleDateTimeSeconds } from "@/utilities";

export default Vue.extend({
  name: "UserProfile",
  components: {
    RoleChip,
    FileSelectDialog,
  },
  data: () => ({
    nameRules,
    valid: true,
    path: "profileImage",
  }),
  methods: {
    selectProfileImage() {
      this.$store.commit("Storage/setPath", this.path);
      this.$store.commit("Storage/setIsImageResize", { resize: true, width: 128, height: 128 });
      this.$store.commit("Storage/setIsProfileImage", true);
      this.$store.commit("Storage/setIsSingleUploadSelect", true);
      this.$store.commit("Storage/setSelectFileDialogState", true);
    },
    deleteProfileImage() {
      // TODO: Handle error properly
    },
    validate() {
      if ((this.$refs.form as VForm).validate()) {
        this.$store.dispatch("User/updateUserProfile");
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["User/getLoadingState"];
    },

    userRole() {
      return this.$store.getters["User/getAuthUserRole"].role;
    },

    userEmail() {
      return this.$store.getters["User/getUserEmail"];
    },

    firstName: {
      get() {
        return this.$store.getters["User/getUserProfile"].name.first;
      },
      set(value: string) {
        const currentData = this.$store.getters["User/getUserProfile"];
        this.$store.commit("User/setUserProfile", {
          ...currentData,
          name: {
            first: value,
            last: this.lastName,
          },
        });
      },
    },

    lastName: {
      get() {
        return this.$store.getters["User/getUserProfile"].name.last;
      },
      set(value: string) {
        const currentData = this.$store.getters["User/getUserProfile"];
        this.$store.commit("User/setUserProfile", {
          ...currentData,
          name: {
            first: this.firstName,
            last: value,
          },
        });
      },
    },

    profileImage() {
      return this.$store.getters["User/getUserProfile"].profileImage;
    },

    uuid() {
      return this.$store.getters["User/getUserProfile"].uuid;
    },

    createdAt() {
      const createdAt = this.$store.getters["User/getUserProfile"].createdAt;
      return timestampToLocaleDateTimeSeconds(createdAt as number);
    },

    updatedAt(): number | undefined {
      return this.$store.getters["User/getUserProfile"].updatedAt;
    },

    updatedAtTime(): string {
      const updatedAt = this.updatedAt || -1;
      return timestampToLocaleDateTimeSeconds(updatedAt);
    },
  },
});
