import { MutationTree } from "vuex";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";

const bottomMenuMutations: MutationTree<State & SidebarsState & ModelsState> = {
  /**
   * sets potree-viewer-Camera to orthographic & state in graphicsInspector to orthographic
   * NOTE: we are not changing point quality renderer or point type ATM. We only change camera.
   * Saved commented out code for future reference.
   * If high quality points is on, we need to disable it for the pointcloud to be shown.
   * NOTE: We don't change the pointQuality-state as we use are only temporarily disabling
   * high pointcloud. We only change viewer state regarding this.
   * TODO: find out how to display pointclouds in orthographic
   * @param state
   * @param payload pointQuality
   */
  setOrtographicTopView(state, payload) {
    const { pointQuality } = payload;

    // set camera mode in potree-viewer to ortographic
    if (!state.viewer) {
      return;
    }

    state.viewer.scene.cameraMode = 0;
    // set camera mode in graphicsInspector(vue-state) to ortographic
    state.graphicsInspector.cameraMode = "orthographic";

    // ##########
    // SAVED CODE BELOW FOR WHEN WE WANT TO IMPLEMENT HQ SPLAT RENDERER WITH PARALOID
    // ##########
    // if (pointQuality === "high") {
    // remove hqRenderer/splatRenderer
    //state.viewer.useHQ = false;
    // }

    // change all pointclouds shader
    /*  state.viewer.scene.scenePointCloud.children.forEach((child: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (child.hasOwnProperty("pcoGeometry")) {
        if (pointQuality === "high") {
          // circle, TODO: eval between RECTANGLE(SQUARE) & CIRCLE
          child.material.shape = PointShape.CIRCLE;
          // adaptive size, TODO: eval. FIXED & ATTENUATED
          child.material.pointSizeType = PointSizeType.ADAPTIVE;
        }
        child.material.uniforms.useOrthographicCamera.value = true;
      }
    }); */
    // set camera to topview
    state.viewer.setTopView();
  },
  /**
   * We change from orthographic to perspective view
   * NOTE: we are not changing point quality ATM. We only change camera.
   * Saved commented out code for future reference.
   * if pointcloud quality state is high we re-enable all settings for high quality
   * points again. This was disabled in orthographic as pointclouds won't display otherwise.
   * @param state
   * @param payload pointquality
   */
  setPerspectiveView(state, payload) {
    const { pointQuality } = payload;

    if (!state.viewer) {
      return;
    }
    // set camera mode in potree-viewer to perspective
    state.viewer.scene.cameraMode = 1;
    state.graphicsInspector.cameraMode = "perspective";
    // TODO: change camera pitch & yaw to something more reasonable

    // ##########
    // SAVED CODE BELOW FOR WHEN WE WANT TO IMPLEMENT HQ SPLAT RENDERER WITH PARALOID
    // ##########
    // create hqRenderer/splatRenderer
    // if (pointQuality === "high") {
    //state.viewer.useHQ = true;
    // }

    /*  state.viewer.scene.scenePointCloud.children.forEach((child: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (child.hasOwnProperty("pcoGeometry")) {
        if (pointQuality === "high") {
          // paraboloid
          child.material.shape = PointShape.PARABOLOID;
          // adaptive size
          child.material.pointSizeType = PointSizeType.ADAPTIVE;
        }
        child.material.uniforms.useOrthographicCamera.value = false;
      }
    }); */
  },
};

export default bottomMenuMutations;
