import { ClientLocalStorageData } from "@/types/local-storage";

const getClientData = (): ClientLocalStorageData => {
  // get client auth data from local storage
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const localClientData = localStorage.getItem("openpointClientAuthData")!;
  return JSON.parse(localClientData);
};

const setClientData = (data: ClientLocalStorageData): void => {
  // stringify spread of new data
  const newData = JSON.stringify({ ...data });

  // update client auth data
  localStorage.setItem("openpointClientAuthData", newData);
};
export { getClientData, setClientData };
