/**
 * Regex find and replace any set of escaped unicode strings, e.g. \X2\00F6\X0\ to 00F6.
 * Using regex result, convert unicode to proper character with String.fromCharCode.
 *
 * @param string
 * @returns {string}
 *
 * @author Axel Roussille Åberg
 */
export const convertUnicode = (string: string): string => {
  const regex = /\\..\\([a-f0-9]{4})\\..\\/gi;

  /**
   * Takes in regex match group containing unicode HEX to return proper letter.
   *
   * @param _ Matching regex result.
   * @param hex Matching group 1.
   * @returns {string} converted unicode hex.
   */
  const unicodeCharReplace = (_: string, hex: string): string =>
    String.fromCharCode(parseInt(hex, 16));

  return string.replace(regex, unicodeCharReplace);
};

/**
 * Convert string to proper title case and remove non-alphanumeric characters.
 *
 * @param string
 * @returns {string}
 */
export const safeFileNameString = (input: string): string => {
  const output = removeDiacritics(input);

  return (
    output
      // Replace non-alphanumeric characters with `-`.
      .replace(/[^a-zA-Z0-9]+/g, "-")
      // Trim `-` from start and end.
      .replace(/^-+|-+$/g, "")
      // Convert to lowercase.
      .toLowerCase()
  );
};

/**
 * Apply NFD: Normalization Form Canonical Decomposition on string and remove diacritics (e.g. ö -> o).
 *
 * @param string
 * @returns {string}
 */
export const removeDiacritics = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
