
import Vue from "vue";

import { AuthorInterface, AuthorsObjectInterface } from "@/types";
import { timestampToLocaleDateTime } from "@/utilities";

interface Data {
  author?: AuthorInterface;
  updateAuthor?: AuthorInterface;
  timestampToLocaleDateTime: (timestamp: string | number) => string;
}
export default Vue.extend({
  name: "AuthorComponent",
  props: {
    item: {
      type: Object as () => Record<string, unknown>,
      required: true,
    },
    wrapper: {
      type: String,
      default: "list",
      required: false,
    },
    color: {
      type: String,
      default: "grey--text text--darken-1",
      required: false,
    },
    listColumn: {
      type: Boolean,
      default: true,
      required: false,
    },
    small: {
      type: Boolean,
      default: true,
      required: false,
    },
    strongTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: (): Data => ({
    author: {} as AuthorInterface,
    updateAuthor: {} as AuthorInterface,
    timestampToLocaleDateTime,
  }),
  methods: {
    /**
     * Create the authors object which holds both auther and optional update author data.
     *
     * @returns {Promise<void>}
     */
    async fetchAuthorsObject(): Promise<AuthorsObjectInterface> {
      return this.$store.dispatch("User/getAuthorsObject", this.item);
    },
  },
  watch: {
    item: {
      async handler() {
        const { author, updateAuthor } = await this.fetchAuthorsObject();
        this.author = author;
        this.updateAuthor = updateAuthor;
      },
    },
  },
  async beforeMount() {
    const { author, updateAuthor } = await this.fetchAuthorsObject();
    this.author = author;
    this.updateAuthor = updateAuthor;
  },
});
