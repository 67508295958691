/**
 * @file This files containse all the general purpose API types, interface and enums.
 */

/**
 * Standard API response codes.
 *
 * @export
 * @enum {number}
 */
export enum HTTPResponseCodes {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}
