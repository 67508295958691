
import Vue from "vue";

import { Tag } from "@/components/tags";
import { IFCModelItemInterface, ModelData, TagItemInterface, ViewerInterface } from "@/types";

interface Data {
  /**
   * String to filter tags by.
   *
   * @default ""
   * @type {string}
   */
  tagFilter: string;

  /**
   * Whether to show all tags or not.
   *
   * @default false
   * @type {boolean}
   */
  showAll: boolean;

  /**
   * Number of tags to show when not showing all.
   *
   * @default 5
   * @type {number}
   */
  visibleTagLimit: number;
}

export default Vue.extend({
  name: "PointCloudViewerTagsFilter",
  components: {
    Tag,
  },
  data: (): Data => ({
    tagFilter: "",
    showAll: false,
    visibleTagLimit: 10,
  }),
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    resetFilter(): void {
      this.tagFilter = "";
      this.filteredTags = this.availableTags;
      this.showAll = false;
    },

    tagFilterReset(): void {
      this.$store.dispatch("Tags/tagFilterReset");
    },
  },
  computed: {
    hasSelectedTags(): boolean {
      return this.$store.getters["Tags/getSelectedViewerTags"].length > 0;
    },

    visibleTags(): TagItemInterface[] {
      return this.filteredTags.slice(
        0,
        this.showAll ? this.filteredTags.length : this.visibleTagLimit
      );
    },

    hasTags(): boolean {
      return this.availableTags.length > 0;
    },

    hasMoreTags(): boolean {
      return this.availableTags.length > this.visibleTagLimit;
    },

    hasMoreTagsCount(): number {
      const availableCount =
        this.availableTags.length > this.visibleTagLimit
          ? this.availableTags.length - this.visibleTagLimit
          : this.availableTags.length;
      const filteredCount =
        this.filteredTags.length > this.visibleTagLimit
          ? this.filteredTags.length - this.visibleTagLimit
          : this.filteredTags.length;
      const isFiltered = this.filteredTags.length < this.availableTags.length;
      return isFiltered ? filteredCount : availableCount;
    },

    viewerTags(): TagItemInterface[] {
      return this.$store.getters["Tags/getViewerTags"] || [];
    },

    viewer(): ViewerInterface | undefined {
      const {
        params: { projectId, viewerId },
      } = this.$route;

      // Start by getting the viewer so we can determine which models are used.
      const viewer: ViewerInterface | undefined = this.$store.getters["Viewers/getViewer"]({
        projectId,
        viewerId,
      });

      return viewer;
    },

    viewerIFCmodels(): IFCModelItemInterface[] {
      // Get the IFC models from the store. We will use these in the reducer to determine if the model is an IFC model.
      const storeIfcModels: IFCModelItemInterface[] | undefined =
        this.$store.getters["PointCloudViewer/getIFCModels"];

      const { models } = this.viewer || {};

      // Loop through all the models and check if they are IFC models. If they are, we add them to the resulting array so we can process them in `viewerIFCAutoTags`.
      const ifcModels = models?.reduce((ifcModelsArray: IFCModelItemInterface[], model) => {
        const ifcModel = storeIfcModels?.find((ifcModel) => ifcModel.modelContainerId === model.id);
        if (model.type === "ifc" && ifcModel) {
          ifcModelsArray.push(ifcModel);
        }

        return ifcModelsArray;
      }, []);

      return ifcModels ?? [];
    },

    viewerIFCAutoTags(): TagItemInterface[] {
      // We start by assuming there are no models with auto tags. This way we can return an empty array if there are no models with auto tags.
      let modelTags: TagItemInterface[] = [];

      // Finally we loop through all the models and check if they have auto tags. If they do, we add them to the array.
      this.viewerIFCmodels?.forEach((model) => {
        const { autoTags } = model;

        // Make sure the model has auto tags.
        if (autoTags) {
          modelTags.push(...autoTags);
        }
      });

      return modelTags;
    },

    availableTags(): TagItemInterface[] {
      return this.viewerTags?.concat(this.viewerIFCAutoTags) || [];
    },

    filteredTags: {
      get(): TagItemInterface[] {
        return this.availableTags?.filter((tag) => {
          if (this.tagFilter) {
            return tag.title?.toLowerCase().includes(this.tagFilter?.toLowerCase());
          }

          return tag;
        });
      },
      set(tags: TagItemInterface[]) {
        tags;
      },
    },
  },
});
