import { Upload } from "@aws-sdk/lib-storage";
import Vue from "vue";
import { MutationTree } from "vuex";

import { FileBrowserFile, FileBrowserFolder, FileBrowserList } from "@/types/file";

import { getDefaultState } from "./state";
import { FileUpload, FileUploadProgress, FileUploadStatus, State } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setPath(state, path: string) {
    state.path = path;
  },

  setFetchedModels(state, payload) {
    state.fetchedModels.push(payload);
  },

  setFetchedWireframe(state, payload) {
    state.fetchedWireframes.push(payload);
  },

  setFileBrowserDialogState(state, dialogState: boolean) {
    state.fileBrowserDialog = dialogState;
  },

  setDeleteDialogState(state, dialogState: boolean) {
    state.deleteDialog = dialogState;
  },

  setShareDialogState(state, dialogState: boolean) {
    state.shareDialog = dialogState;
  },

  setSelectFileDialogState(state, dialogState: boolean) {
    state.selectFileDialog = dialogState;
  },

  setUploadDialogState(state, dialogState: boolean) {
    state.uploadDialog = dialogState;
  },

  setCancelUploadDialogState(state, dialogState: boolean) {
    state.cancelUploadDialog = dialogState;
  },

  setCreateFolderDialogState(state, dialogState: boolean) {
    state.createFolderDialog = dialogState;
  },

  setShareUrl(state, url: string) {
    state.shareUrl = url;
  },

  setContextItem(
    state,
    payload: {
      id: string;
      name: string;
      objectName: string;
      type: string;
    }
  ) {
    state.contextItem = payload;
  },

  setIsImageResize(state, payload: { resize: boolean; width?: number; height?: number }) {
    state.isImageResize = payload;
  },

  resetIsImageResize(state) {
    state.isImageResize = {
      resize: false,
    };
  },

  setIsProfileImage(state, isProfileImage) {
    state.isProfileImage = isProfileImage;
  },

  setIsSingleUploadSelect: (state, isSingleSelect: boolean) => {
    state.isSingleUploadSelect = isSingleSelect;
  },

  setShowFileInput(state, show: boolean) {
    state.showFileInput = show;
  },

  setUploadFiles(state, items: File[]) {
    state.uploadFiles = items;
  },

  removeFromUploadFiles(state, item: File) {
    state.uploadFiles = state.uploadFiles.filter(({ name }) => {
      name != item.name;
    });
  },

  resetUploadAllFiles(state, reset: boolean) {
    if (reset) state.uploadAllFiles = [];
  },

  addUploadAllFiles(state, file: File) {
    state.uploadAllFiles.push({
      file: file,
      path: state.path,
      status: FileUploadStatus.UPLOADING,
    } as FileUpload);
  },

  updateUploadAllFiles(state, input: { file: File; status?: FileUploadStatus; path?: string }) {
    state.uploadAllFiles.map((fileUpload) => {
      if (
        fileUpload.file.name === input.file.name &&
        fileUpload.status != FileUploadStatus.CANCELED &&
        fileUpload.status != FileUploadStatus.UPLOADED
      ) {
        input.path?.length ? (fileUpload.path = input.path) : undefined;
        input.status?.length ? (fileUpload.status = input.status) : undefined;
      }
    });
  },

  setFilesInFolder(state, files: (FileBrowserFile | FileBrowserFolder | null)[]) {
    state.filesInFolder = files;
  },

  setTotalUploadSize(state, size: number) {
    state.totalUploadSize += size;
  },

  resetTotalUploadSize(state) {
    state.totalUploadSize = 0;
  },

  setEsitmatedUploadTime(state, payload) {
    state.esitmatedUploadTime = {
      ...state.esitmatedUploadTime,
      ...payload,
    };
  },

  resetEsitmatedUploadTime(state) {
    state.esitmatedUploadTime = {
      start: 0,
      estimate: 0,
    };
  },

  setIsUploading(state, isUploading) {
    state.isUploading = isUploading;
  },

  resetContextItem(state) {
    state.contextItem = {
      id: "",
      name: "",
      objectName: "",
      type: "",
    };
  },

  setLoadingState(state, loadingState: boolean) {
    state.loading = loadingState;
  },

  setFileBrowserItems(state, folderData: FileBrowserList) {
    const folder = state.files.find((browserFolder) => browserFolder.id === folderData.id);
    // if folder is already in folder list
    if (folder) {
      for (const listFolder of state.files) {
        if (listFolder.id === folderData.id) {
          Object.assign(listFolder, folderData);
          break;
        }
      }
    } else {
      if (state.files.length) {
        // if folder list has items
        state.files.push(folderData);
      } else {
        // no folder in list
        state.files = [folderData];
      }
    }
  },

  setFile(state, payload) {
    const { path, file }: { path: string; file: FileBrowserFile | FileBrowserFolder } = payload;
    const folder = state.files.find((folder) => folder.id === path);

    if (folder) {
      folder.files.push(file);
    }
  },

  setItemInfoDialogState(state, dialogState) {
    state.itemInfoDialog = dialogState;
  },

  setRenameItemDialogState(state, dialogState) {
    state.renameItemDialog = dialogState;
  },

  removeItem(state, payload) {
    const { path, id }: { path: string; id: string } = payload;
    const folder = state.files.find((folder) => folder.id === path);

    if (folder) {
      folder.files = folder.files.filter((item) => item?.id !== id);
    }
  },

  addUploadProgress(state, progressObject: FileUploadProgress) {
    Vue.set(state.fileUploadProgress, progressObject.fileName, progressObject);
  },

  updateUploadProgress(state, payload: { id: string; progress: number }) {
    const { id, progress } = payload;

    state.uploadAllFiles.map((fileUpload) => {
      if (
        fileUpload.file.name === id &&
        progress === 100 &&
        fileUpload.status === FileUploadStatus.UPLOADING
      ) {
        fileUpload.status = FileUploadStatus.UPLOADED;
      }
    });

    Vue.set(state.fileUploadProgress[id], "progress", progress);
  },

  deleteUploadProgress(state, id) {
    Vue.delete(state.fileUploadProgress, id);
  },

  setUploadObject(state, uploadRequest: Upload) {
    state.uploadRequests.push(uploadRequest);
  },

  removeFromUploadRequests(state, file: File) {
    state.uploadRequests = state.uploadRequests.filter(({ params }) => {
      params.Body.name != file.name;
    });
  },

  setIsUploadAbortedState(state, abortState) {
    state.isUploadAborted = abortState;
  },
};

export default mutations;
