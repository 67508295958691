
import Vue from "vue";

import Author from "@/components/author.vue";
import { AttachmentSectionLineItem, AvailableAttachmentInterfaces } from "@/types";

interface Data {
  isOpen: number;
}

export default Vue.extend({
  name: "InspectorAttachmentHeader",
  props: {
    item: {
      type: Object,
      required: true,
    },
    openDialog: {
      type: Function,
      required: true,
    },
  },
  components: { Author },
  data: (): Data => ({
    isOpen: 0,
  }),
  methods: {
    /**
     * Uses the `createdBy` object to determine if the current user can delete the item.
     *
     * Can delete:
     * - `Roles.SU`, `Roles.OWNER`, `Roles.ADMIN`: all, by default.
     * - `Roles.EDITOR`: When user is assigned the editor role they can only delete items they created.
     *
     * @returns {boolean}
     */
    showDeleteButton(): boolean {
      const { createdBy } = this.item;

      if (createdBy && createdBy.id) {
        return this.$store.getters["User/getCanEdit"](createdBy.id);
      }

      return false;
    },

    deleteAttachmentConfirmation() {
      if (!this.showDeleteButton()) {
        return;
      }

      const {
        item: { id, items, type },
      } = this;

      const itemCount = items?.length || 0;
      this.$store.commit("PointCloudViewer/setDeleteDialog", {
        state: true,
        type: AttachmentSectionLineItem.SECTION,
        message: `Delete ${type} attachment (including ${itemCount} line ${
          itemCount > 1 ? "items" : "item"
        })?`,
        deleteAttachment: {
          sectionId: id,
          sectionLineItem: AttachmentSectionLineItem.SECTION,
        },
      });
    },

    toggleExpand() {
      this.isOpen = this.isOpen === 0 ? 1 : 0;
    },

    /**
     * Copy the stringified object to clipboard.
     *
     * @returns {void}
     */
    copyToClipboard(item: AvailableAttachmentInterfaces): void {
      this.$store.dispatch("Utilities/copyToClipboard", {
        string: JSON.stringify(item),
        reference: this.item.type,
      });
    },
  },
  computed: {
    addIconColor(): string {
      switch (this.item.type) {
        case "file":
          return "warning";
        case "note":
          return "primary";
        case "link":
          return "purple";
        default:
          return "grey";
      }
    },
  },
});
