
import Vue from "vue";

export default Vue.extend({
  name: "PointCloudViewerBottomToolbar",
  data: () => ({
    orthographic: false,
    toolBarMenu: [
      {
        title: "POI",
        icon: "mdi-map-marker-plus",
        action: "createPointOfInterest",
        tooltip: "Create a new point of interest",
      },
      {
        title: "distance",
        icon: "mdi-vector-polyline-plus",
        action: "createDistansMeasurement",
        tooltip: "create a new distance measurement",
      },
      {
        title: "height",
        icon: "mdi-angle-right",
        action: "createHeightMeasurement",
        tooltip: "create a new height measurement",
      },
      {
        title: "2d-top-view",
        icon: "mdi-floor-plan",
        action: "viewerTopView",
        tooltip: "toggle top/perspective view",
      },
      {
        title: "focus",
        icon: "mdi-image-filter-center-focus",
        action: "viewerFocus",
        tooltip: "center contents",
      },

      {
        title: "layer-editor",
        icon: "mdi-layers-outline",
        action: "layerEditor",
        tooltip: "layer editor",
      },
      {
        title: "settings-editor",
        icon: "mdi-tune",
        action: "settingsEditor",
        tooltip: "settings",
      },
    ],
  }),
  methods: {
    itemClick(menuItem: any) {
      this.$store.dispatch("PointCloudViewer/menuHandler", menuItem.action);
      if (menuItem.action === "viewerTopView") {
        this.orthographic = !this.orthographic;
      }
    },
  },
});
