import { GetterTree } from "vuex";

import router from "@/router";
import { TagItemInterface } from "@/types";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getDeleteDialogState: (state): boolean => {
    return state.deleteDialogState;
  },

  getEditDialogState: (state): boolean => {
    return state.editDialogState;
  },

  getFetchState: (state): boolean => {
    return state.fetched;
  },

  getTags: (state): TagItemInterface[] | [] => {
    return state.tags;
  },

  getLoadingState: (state): boolean => {
    return state.loading;
  },

  getSelectedTag: (state): TagItemInterface | undefined => {
    return state.selectedTag;
  },

  getTagListCount: (state): number => {
    return state.tagListCount;
  },

  getTagListFilterCount: (state): number => {
    return state.tagListFilterCount;
  },

  getViewerTags: (state) => {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    const viewerTagsObject = state.viewerTags.find((viewer) => viewer.id === viewerId);
    const viewerTags = viewerTagsObject?.tags;

    if (!Array.isArray(viewerTags) || !viewerTags) {
      return [];
    }

    return viewerTags;
  },

  getViewerIFCAutoTags: (state) => {
    return state.viewerIFCAutoTags;
  },

  /**
   * Get the currently selected tags for the viewer. The selected tags are set during the mutation `setViewer` which is invoked as the final step durring `fetchViewers` and `fetchViewer`. These are all in the namespace `Viewers`.
   */
  getSelectedViewerTags: (state) => {
    return state.viewerSelectedTags ?? [];
  },

  getHiddenTagItems: (state) => {
    return state.viewerHiddenTagItems ?? [];
  },

  getTagsUndo: (state) => {
    return state.tagsUndo;
  },
};

export default getters;
