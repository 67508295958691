
import Vue from "vue";

import { ViewerLayerDataCategoryKey } from "@/types";

export default Vue.extend({
  name: "ViewerLayerItemActionFocusToItem",
  props: {
    itemkey: String,
    item: Object,
    type: String,
  },
  methods: {
    focusToItem(id: string, type: string) {
      const payload = { id, type };
      this.$store.dispatch("PointCloudViewer/focusToLayerItem", payload);
    },
  },
  computed: {
    canFocusToObject(): boolean {
      let canFocusToObject = false;

      switch (this.type) {
        case ViewerLayerDataCategoryKey.POINTCLOUDS:
        case ViewerLayerDataCategoryKey.MODELS:
        case ViewerLayerDataCategoryKey.MEASUREMENTS:
        case ViewerLayerDataCategoryKey.POINT_OF_INTEREST:
          canFocusToObject = true;
          break;
      }

      return canFocusToObject;
    },
  },
});
