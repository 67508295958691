
import Vue from "vue";

export default Vue.extend({
  name: "Snackbar",
  computed: {
    snackbar: {
      get() {
        return this.$store.getters["Utilities/getSnackbarVisible"];
      },
      set() {
        this.$store.commit("Utilities/closeSnackbar");
      },
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("Utilities/closeSnackbar");
    },
  },
});
