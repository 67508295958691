
import Vue from "vue";

import { Roles } from "@/types/enums";

export default Vue.extend({
  name: "SettingsContainer",
  methods: {
    reroute() {
      this.$store.dispatch("Utilities/reroute", "settings");
    },
  },
  computed: {
    getToolbarTitle() {
      const routeName = this.$route.name;
      let title = "";

      switch (routeName) {
        case "settingsTags":
          title = "Tags";
          break;

        default:
          title = "Settings";
          break;
      }

      return title;
    },
  },
  beforeMount() {
    // Make sure the user has admin access.
    const userRole = this.$store.getters["User/getAuthUserRole"].role;
    const hasAdminAccess = userRole === Roles.ADMIN || userRole === Roles.OWNER;
    if (!hasAdminAccess) {
      this.$router.push({ name: "dashboard" });
    }
  },
});
