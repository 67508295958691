import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import { UpdatedProjectUsersInterface, UserProjectsAccessListInterface } from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { coreApiPost } from "@/utilities/core-api";

import { Project, State } from "./types";

const apiRoot = "/project";

const actions: ActionTree<State, unknown> = {
  async fetchProjects({ commit }) {
    commit("setLoadingState", true);

    try {
      // get user client data from API
      const responseData = await coreApiPost(`${apiRoot}/get-projects`);
      commit("setProjects", responseData);
      commit("setFetchedState", true);
    } catch (error: unknown) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );

      // There was an error fetching projects. This can be intentional (e.g if user does not have sufficient permissions) or unintentional (e.g. network error). In either case, we should reroute to the dashboard.
      router.push({ name: "dashboard" });
    }
    commit("setLoadingState", false);
  },

  async fetchProject({ commit }, projectId) {
    commit("setLoadingState", true);

    try {
      // get project
      const projectResponse = await coreApiPost(`${apiRoot}/get-project`, { projectId });
      commit("setProject", projectResponse);
    } catch (error: unknown) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );

      // There was an error fetching projects. This can be intentional (e.g if user does not have sufficient permissions) or unintentional (e.g. network error). In either case, we should reroute to the projects page.
      router.push({ name: "projects" });
    }
    commit("setLoadingState", false);
  },

  async updateProject(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: {
      updatedList: UpdatedProjectUsersInterface;
      project: Project;
    }
  ) {
    const { updatedList, project } = payload;
    const { title, id } = project;
    const { added, removed } = updatedList;

    commit("setLoadingState", true);

    try {
      // Update project user access.
      const addedPromises = added.map(async (item) => {
        const { email, role } = item;
        const addProject: UserProjectsAccessListInterface = {
          add: true,
          projectId: id,
          role,
        };

        const request = await dispatch(
          "AccountManagement/setUserProjectAccess",
          {
            userEmail: email,
            projects: [addProject],
          },
          { root: true }
        );
        return request;
      });

      const removedPromises = removed.map(async (userEmail) => {
        const removeProject = {
          add: false,
          projectId: id,
        };

        const request = await dispatch(
          "AccountManagement/setUserProjectAccess",
          {
            userEmail,
            projects: [removeProject],
          },
          { root: true }
        );
        return request;
      });

      await Promise.all([...addedPromises, ...removedPromises]);

      // Update project data.
      await coreApiPost(`${apiRoot}/update-project`, { ...project });

      // Update project data in store.
      commit("setProject", project);

      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${title}</strong> successfully updated.`,
          color: "success",
        },
        { root: true }
      );
    } catch (error: any) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  async createProject(
    { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: {
      title: string;
      description?: string;
      image?: string;
    }
  ) {
    const { title } = payload;

    const requiredVariables = [
      {
        test: !!title,
        message: "Title",
      },
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    commit("setLoadingState", true);

    try {
      // get user client data from API
      const response = await coreApiPost(`${apiRoot}/create-project`, payload);
      const { id, createdAt, createdBy } = response;

      const { role } = rootGetters["User/getUserProfile"];

      commit("setProject", {
        ...payload,
        id,
        createdAt,
        createdBy,
        role,
      });
      commit(
        "Utilities/showSnackbar",
        {
          message: `${title} was successfully created`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // Reroute to projects page.
      dispatch("Utilities/reroute", "projects", { root: true });
    } catch (error: any) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  /**
   * Delete a project from the database.
   *
   * **NOTE** Users can only delete a project that has no files or viewers.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @param {string} id
   * @returns {Promise<void>}
   */
  async deleteProject(
    { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    id: string
  ): Promise<void> {
    commit("setLoadingState", true);

    // Get project from store before deleting it so we can display the project title once it has been deleted.
    const project = getters["getProject"](id);

    try {
      // Send delete request to API.
      await coreApiPost(`${apiRoot}/delete-project`, { id });

      // Remove project from store.
      commit("removeProject", id);

      // Add project id to deleted projects array. This is to prevent the project fetch from running in the title bar, since it will request a project that no longer exists.
      commit("setIsDeleted", id);

      // Display success message.
      commit(
        "Utilities/showSnackbar",
        {
          message: `Project ${project.title} was successfully deleted`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );

      // Reroute to projects page.
      await dispatch("Utilities/reroute", "projects", { root: true });
    } catch (error: any) {
      // Display error.
      commit(
        "Utilities/showSnackbar",
        {
          message: error.response.data ?? error.toString(),
          color: SnackbarColors.ERROR,
          timeout: -1,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },
};

export default actions;
