import { ActionTree, Commit, Dispatch } from "vuex";

import { CameraMode } from "@/types/viewer";

import { SidebarsState } from "../sidebars/types";
import { State } from "../types";

const bottomMenuActions: ActionTree<State & SidebarsState, unknown> = {
  /**
   * Handle menu actions.
   *
   * @param {{ dispatch: Dispatch; commit: Commit; }} { dispatch, commit }
   * @param {string} action
   * @returns {void}
   */
  menuHandler({ dispatch }, action: string) {
    switch (action) {
      case "createPointOfInterest":
        dispatch("createPointOfInterest");
        break;
      case "createDistansMeasurement":
        dispatch("createDistansMeasurement");
        break;
      case "createHeightMeasurement":
        dispatch("createHeightMeasurement");
        break;
      case "settingsEditor":
        dispatch("showSettingsEditor");
        break;
      case "viewerFocus":
        dispatch("fitToScreen");
        break;
      case "layerEditor":
        dispatch("showLayerEditor");
        break;
      case "viewerTopView":
        dispatch("ortographicTopView");
        break;
    }
  },
  /**
   * Layer editor is on the left sidebar.
   * We keep track if left sidebar is open with leftSidebar state.
   * In the Layer editor we are able to enter an ifc-model. When we do we enter
   * another editor called IFCmodell which is also on the left sidebar.
   * To keep them apart we also keep track of when IFCmodell is open with leftSidebarIfc state.
   *
   * Shows & hides the left sidebar.
   */
  showLayerEditor({ state, commit }) {
    if (state.leftSidebarIfc && state.leftSidebar) {
      commit("setLeftSidebar", false);
      commit("setLeftSidebarIfc", false);
      return;
    }
    if (state.leftSidebarIfc && !state.leftSidebar) {
      commit("setLeftSidebar", false);
      commit("setLeftSidebarIfc", true);
      return;
    }
    if (!state.leftSidebarIfc && state.leftSidebar) {
      commit("setLeftSidebar", false);
      return;
    }
    if (!state.leftSidebarIfc && !state.leftSidebar) {
      commit("setLeftSidebar", true);
      return;
    }
  },

  /**
   * Zoom and fit scene to screen.
   *
   * @param {{ state: State; }} { state }
   * @returns {void}
   */
  fitToScreen({ state }: { state: State }): void {
    state.viewer?.fitToScreen();
  },

  /**
   * Show viewer settings.
   *
   * TODO: allow saving settings
   *
   * @param {{ commit: Commit; state: State; }} { commit, state }
   * @returns {void}
   */
  showSettingsEditor({
    commit,
    state,
    dispatch,
  }: {
    commit: Commit;
    state: State & SidebarsState;
    dispatch: Dispatch;
  }): void {
    const { inspectorMode } = state;
    if (inspectorMode === "viewerSettings") {
      commit("setRightSidebar", false);
      commit("setInspectorMode", null);
    } else {
      commit("setRightSidebar", true);
      commit("setInspectorMode", "viewerSettings");
    }
    // clear any selection
    dispatch("ifcClearSelection");
  },
  ortographicTopView({
    commit,
    getters,
  }: {
    commit: Commit;
    state: State & SidebarsState;
    dispatch: Dispatch;
    getters: any;
  }) {
    const graphicsInspector = getters["getGraphicsInspector"];
    const { cameraMode, pointQuality } = graphicsInspector;

    if (cameraMode === CameraMode.PERSPECTIVE) {
      // switch state to ortographic
      commit("updateGraphicsInspector", { cameraMode: CameraMode.ORTHOGRAPHIC });
      commit("setOrtographicTopView", { pointQuality });
      commit("setOrbitControlPan", true);
    } else {
      // switch state to perspective
      commit("updateGraphicsInspector", { cameraMode: CameraMode.PERSPECTIVE });
      commit("setPerspectiveView", { pointQuality });
      commit("setOrbitControlPan", false);
    }
  },
};

export default bottomMenuActions;
