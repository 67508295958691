
import Vue from "vue";

export default Vue.extend({
  name: "LoginMultiClientList",
  props: {
    multiClient: {
      type: Boolean,
      required: true,
    },
    login: {
      type: Boolean,
      default: true,
    },
    onSelect: Function,
  },
  methods: {
    handleClientSelect(clientData: Record<string, unknown>) {
      this.$store.commit("User/setSelectedClient", clientData);
      this.onSelect();
    },
  },
  computed: {
    clients() {
      // filter out potential null/undefined from list
      const clientList = this.$store.getters["User/getClients"].filter(
        (user: { [key: string]: string | undefined }) => user
      );
      return clientList;
    },
  },
});
