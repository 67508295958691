
import Vue from "vue";

export default Vue.extend({
  name: "UploadCancelDialog",
  props: {
    onDestroy: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async cancelUploads() {
      // close warning dialog
      this.dialog = false;
      // abort
      this.$store.dispatch("Storage/abortUpload");
      // run destroy function from parent
      this.onDestroy();
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["Storage/getCancelUploadDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Storage/setCancelUploadDialogState", state);
      },
    },
  },
});
