
import Vue from "vue";

import { HeaderLogotype, LoginBackground, MultiClientList } from "@/components/login";
import { VForm } from "@/types";
import { emailRules, passwordHint, passwordRules } from "@/utilities";

export default Vue.extend({
  name: "Login",
  components: {
    LoginBackground,
    HeaderLogotype,
    MultiClientList,
  },
  data: () => ({
    error: null,
    valid: true,
    email: "",
    emailRules,
    password: "",
    newPassword: "",
    passwordRules,
    passwordHint,
    select: null,
  }),
  methods: {
    async validateLogin(): Promise<void> {
      const form = this.$refs.formLogin as VForm;

      if (form.validate()) {
        this.$store.commit("User/setUserActionPayload", {
          action: "login",
          userCredentials: {
            email: this.email.toLowerCase(),
            password: this.password,
            permanentPassword: this.newPassword,
          },
        });
        await this.$store.dispatch("User/initiate");
      }
    },
    onClientSelect(): void {
      this.validateLogin();
    },
    backToLogin(): void {
      this.multiClient = [];
      this.emailInput.focus();
    },
  },
  computed: {
    multiClient: {
      get(): boolean {
        return this.$store.getters["User/getClients"].length > 1;
      },
      set(reset): void {
        return this.$store.commit("User/setClients", reset);
      },
    },
    setNewPassword() {
      return !this.$store.getters["User/getConfirmedUserState"];
    },
    emailInput(): HTMLInputElement {
      return this.$refs.emailInput as HTMLInputElement;
    },
  },
  mounted() {
    const emailParameter = this.$route.query.email as string | null;
    const passwordParameter = this.$route.query.password as string | null;
    if (emailParameter && passwordParameter) {
      const fullPath = this.$route.fullPath;
      const passwordFromPath = fullPath.replace(/.*password=(.*)/, "$1");
      this.password = decodeURIComponent(passwordFromPath);
      this.email = emailParameter;
    } else {
      this.emailInput.focus();
    }
  },
});
