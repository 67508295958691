
import Vue from "vue";

import { TagItemInterface } from "@/types";

export default Vue.extend({
  name: "TagChip",
  props: {
    tagData: Object as () => TagItemInterface,
    // Used to change click action. Default is false. If true, then clicking on a tag will use the `filterTag` method instead.
    filter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewerIFCModelAutoTagHover() {
      this.$store.dispatch("PointCloudViewer/viewerIFCModelAutoTagHover", this.tagData);
    },

    async filterTag() {
      this.$store.dispatch("Tags/tagFilter", {
        tagData: this.tagData,
        isSelected: this.isSelected,
      });
    },

    async editTag(tag: TagItemInterface) {
      const { tableItemId, id, title } = tag;
      const getTag = await this.$store.dispatch("Tags/fetchTag", { tableItemId, id, title });
      this.$store.commit("Tags/setSelectedTag", getTag);
      this.editDialogState = true;
    },
  },
  computed: {
    isSelected(): boolean {
      return this.$store.getters["Tags/getSelectedViewerTags"]?.some(
        (tag: TagItemInterface) => this.tagData.id === tag.id
      );
    },

    color(): string {
      const {
        tagData: { isAuto },
        isSelected,
      } = this;

      if (isSelected) {
        if (isAuto) {
          return "primary lighten-1";
        }
        return "warning lighten-1";
      } else {
        if (isAuto) {
          return "primary";
        }
        return "warning";
      }
    },

    editDialogState: {
      get(): boolean {
        return this.$store.getters["Tags/getEditDialogState"];
      },
      set(value: boolean) {
        return this.$store.commit("Tags/setEditDialogState", value);
      },
    },
  },
});
