import { MutationTree } from "vuex";

import { ViewerInterface } from "@/types";

import { getDefaultState } from "./state";
import { PushItem, State, UploadItem } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setProjectId(state, id: string) {
    state.projectId = id;
  },

  setViewerId(state, id: string) {
    state.viewerId = id;
  },

  setUploadNameDialogState(state, dialogState: boolean) {
    state.uploadNameDialog = dialogState;
  },

  setSelectDialogState(state, dialogState: boolean) {
    state.selectDialog = dialogState;
  },

  setUploadSelectDone(state, isDone: boolean) {
    state.uploadSelectDone = isDone;
  },

  setUploadItem(state, item: UploadItem) {
    state.uploadItem = item;
  },

  resetUploadItem(state) {
    state.uploadItem = {
      name: "",
      type: null,
      items: [],
    };
  },

  setPushItem(state, item: PushItem) {
    state.pushItem = item;
  },

  setPotreeConverterDialogState(state, dialogState) {
    state.potreeConverterDialog = dialogState;
  },

  setPointCloudUploadDialogItemData(state, data) {
    state.pointCloudUploadDialogItemData = data;
  },

  resetPushItem(state) {
    state.pushItem = undefined;
  },

  setIsPointcloudContainer(state, isPointcloudContainer: boolean) {
    state.isPointcloudContainer = isPointcloudContainer;
  },

  setFetchedState(state, projectId: string) {
    state.fetched.push(projectId);
  },

  setLoadingState(state, loadingState: boolean) {
    state.loading = loadingState;
  },

  setUploadingState(state, uploadingState: boolean) {
    state.uploading = uploadingState;
  },

  setViewers(state, payload: Record<string, string & ViewerInterface[]>) {
    const { projectId, viewers } = payload;
    const hasFetched = state.fetched.includes(projectId);
    if (!hasFetched) {
      const project = state.projectViewers.find((project) => project.id === projectId);
      if (!project) {
        const projectViewers = {
          id: projectId,
          viewers,
        };
        state.projectViewers.push(projectViewers);
      } else {
        project.viewers = viewers;
      }
    }
  },

  setViewer(state, payload: Record<string, string & ViewerInterface>) {
    const { projectId, viewer } = payload;
    const project = state.projectViewers.find((project) => project.id === projectId);

    // If viewer is already in project viewers list.
    if (project) {
      const hasViewer = project.viewers.find((listViewer) => listViewer.id === viewer.id);
      // Viewer exists in viewer list.
      if (hasViewer) {
        // Loop and update on match then break loop.
        project.viewers.forEach((listViewer) => {
          if (listViewer.id === viewer.id) {
            state.viewer = listViewer;
          }
        });
      } else {
        // Viewer does not exists in viewer list.
        project.viewers.push(viewer);
      }
    } else {
      // Project does not have any viewers.
      const projectViewer = {
        id: projectId,
        viewers: [viewer],
      };
      state.projectViewers.push(projectViewer);
    }
  },

  /**
   * This will remove a viewer from the states project viewers list. Usefull when a viewer is deleted.
   *
   * @param {State} state
   * @param {{ projectId: string; viewerId: string; }} payload
   */
  removeViewer(
    state: State,
    payload: {
      projectId: string;
      viewerId: string;
    }
  ) {
    const { projectId, viewerId } = payload;
    const project = state.projectViewers.find((project) => project.id === projectId);
    if (project) {
      const index = project.viewers.findIndex((viewer) => viewer.id === viewerId);
      if (index !== -1) {
        project.viewers.splice(index, 1);
      }
    }
  },

  setDeleteDialog(state, dialogState: boolean) {
    state.deleteDialog = dialogState;
  },
};

export default mutations;
