
import Vue from "vue";

import { IFCModelGroupItemInterface } from "@/types";
import { IFCModelItemInterface } from "@/types/viewer";

// import { modelAttachmentIdObject } from "@/utilities";
import AttachmentDots from "../layers/attachment-dots.vue";

export default Vue.extend({
  name: "IFCStoreyGroupRecursive",
  components: {
    AttachmentDots,
  },
  props: {
    items: {
      type: Array as () => IFCModelGroupItemInterface[],
      required: true,
    },
  },
  methods: {
    getCurrentModel() {
      const c = this.$store.getters["PointCloudViewer/getIfcCurrentModel"];
      return c;
    },
    getCurrentLeftSideBarModelId() {
      return this.$store.getters["PointCloudViewer/getIfcCurrentModelIdInLeftSideBar"];
    },
  },
  computed: {
    groupItems(): IFCModelGroupItemInterface[] {
      const items = this.items;

      const currentIFCModel: IFCModelItemInterface =
        this.$store.getters["PointCloudViewer/getIfcCurrentModel"];
      const modelAttachments = currentIFCModel?.attachments;

      if (modelAttachments) {
        return items.map((item) => {
          const { globalId } = item;

          const attachments = modelAttachments
            .reduce((array: any[], modelAttachment) => {
              const { attachments, globalId: modelAttachmentGlobalId } = modelAttachment;

              if (attachments.length > 0 && modelAttachmentGlobalId === globalId) {
                return [...array, ...attachments];
              }

              return [...array];
            }, [])
            .reverse();

          return {
            ...item,
            attachments,
          };
        });
      }

      return items;
    },
  },
});
