
import { Material, MeshBasicMaterial, MeshLambertMaterial } from "three";
import Vue from "vue";

import { PointInspectorInterface } from "@/store/modules/PointCloudViewer/types";
import { IFCModelItemInspectorInterface, VRefs, XYZInterface } from "@/types";
import { timestampToLocaleDate } from "@/utilities";

import ViewerInspector from "./inspector/inspector.vue";
import { SettingsColorPicker } from "./settings";
import ViewerGraphicsSettings from "./viewer-graphics-settings.vue";

interface Data {
  defaultWidth: number;
  minWidth: number;
  currentWidth: number;
  drawerBorderSize: number;
  defaultInspectorTab: string;
  timestampToLocaleDate: (timestamp: number) => string;
}

/* interface Data {
  isProduction: boolean;
  inspectorTabs: string;
  selectedModelId: string | null;
  selectMaterialDisabled: boolean;
  materialColorPickerDisabled: boolean;
} */
export default Vue.extend({
  name: "PointCloudViewerRightSidebar",
  components: {
    ViewerGraphicsSettings,
    ViewerInspector,
  },
  data: (): Data => ({
    defaultWidth: 400,
    minWidth: 300,
    currentWidth: 400,
    drawerBorderSize: 3,
    defaultInspectorTab: "info",
    timestampToLocaleDate,
  }),
  methods: {
    closeSidebar() {
      this.sidebarState = false;
      this.$store.commit("PointCloudViewer/setSelectedLayerItemId", undefined);
    },

    showInspectorElement(author?: string) {
      if (author) {
        return this.$store.dispatch("PointCloudViewer/showInspectorElement", author);
      }

      return "undefined";
    },

    /**
     * Copy the stringified object to clipboard.
     *
     * @returns {void}
     */
    copyInfo(): void {
      const {
        params: { projectId },
      } = this.$route;

      const inspectorMode = this.inspectorMode;

      // Setup initial inspector object and include project ID since this is not part of the inspector object but might be useful for debugging purposes.
      let inspector:
        | IFCModelItemInspectorInterface
        | PointInspectorInterface
        | { projectId: string } = {
        projectId: projectId,
      };

      // Next we determine which inspector mode is active and add it to the inspector object.
      if (inspectorMode === "ifcModelItemInspector") {
        const ifcModelItemInspector = this.getIFCModelItemInspector;
        inspector = {
          ...inspector,
          ...ifcModelItemInspector,
        };
      }
      if (inspectorMode === "pointOfInterest" || inspectorMode === "measurements") {
        const pointInspector = this.getPointInspector;
        inspector = {
          ...inspector,
          ...pointInspector,
        };

        // Object in points are of `Measure` type and cause issues when stringifying. Set object to optional and delete it if it exists.
        if ("object" in inspector) {
          delete (inspector as { object?: unknown }).object;
        }
      }

      // Send the stringified inspector object to the clipboard.
      this.$store.dispatch("Utilities/copyToClipboard", {
        string: JSON.stringify(inspector),
        reference: "Inspector item",
      });
    },

    setBorderWidth() {
      const drawer = (this.$refs.drawer as VRefs).$el;

      if (!drawer) {
        return;
      }

      const element: HTMLElement | null = drawer.querySelector(".v-navigation-drawer__border");

      if (!element) {
        return;
      }

      element.style.zIndex = String(99);
      element.style.width = `${this.drawerBorderSize}px`;
      element.style.cursor = "ew-resize";
    },

    setDrawerEvents() {
      const drawer = this.$refs.drawer as VRefs;

      if (!drawer) {
        return;
      }

      const minSize = this.drawerBorderSize;
      const element = drawer.$el as HTMLElement;

      const drawerBorder: HTMLElement | null = element.querySelector(
        ".v-navigation-drawer__border"
      );
      const direction = element.classList.contains("v-navigation-drawer--right") ? "right" : "left";

      if (!drawerBorder) {
        return;
      }

      const resize = (event: MouseEvent) => {
        document.body.style.cursor = "ew-resize";
        const widthValue =
          direction === "right" ? document.body.scrollWidth - event.clientX : event.clientX;
        element.style.width =
          widthValue >= this.minWidth ? `${widthValue}px` : `${this.minWidth}px`;
        drawerBorder.addEventListener("dblclick", reset, false);
      };

      const reset = () => {
        this.drawerWidth = this.defaultWidth;
        drawerBorder.removeEventListener("dblclick", reset, false);
      };

      drawerBorder.addEventListener(
        "mousedown",
        (event: MouseEvent) => {
          if (event.offsetX < minSize) {
            element.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          element.style.transition = "";
          this.drawerWidth = Number(element.style.width);
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
  },

  computed: {
    drawerWidth: {
      get() {
        return this.currentWidth;
      },
      set(value: number) {
        this.currentWidth = value;

        // OPTIONAL RESET TO DEFAULT WIDTH ON CLOSE, I.E `this.rightSidebarState = false`
        // if (this.rightSidebarState) {
        //   this.currentWidth = value;
        // } else {
        //   this.currentWidth = this.defaultWidth;
        // }
      },
    },

    sidebarState: {
      get(): boolean {
        return this.$store.getters["PointCloudViewer/getRightSidebar"];
      },
      set(value: boolean) {
        !value && this.$store.dispatch("PointCloudViewer/ifcClearSelection");
        this.$store.commit("PointCloudViewer/setRightSidebar", value);
      },
    },

    getCommentInput: {
      get(): string {
        return this.$store.getters["PointCloudViewer/getMeasureCommentInput"];
      },
      set(value: string) {
        this.$store.commit("PointCloudViewer/setMeasureCommentInput", value);
      },
    },

    getInspectorName: {
      get(): string {
        return this.getPointInspector.title;
      },
      set(value: string) {
        const measureInspector = this.getPointInspector;
        measureInspector.title = value;

        this.$store.commit("PointCloudViewer/setMeasureInspector", measureInspector);
      },
    },

    inspectorMode(): string {
      return this.$store.getters["PointCloudViewer/getInspectorMode"];
    },

    getIFCModelItemInspector(): IFCModelItemInspectorInterface {
      return this.$store.getters["PointCloudViewer/getIFCModelItemInspector"];
    },

    getPointInspector(): PointInspectorInterface {
      return this.$store.getters["PointCloudViewer/getMeasureInspector"];
    },

    getUserRole(): string {
      if (this.$store.getters["Projects/getProject"](this.$route.params.projectId)) {
        return this.$store.getters["Projects/getProject"](this.$route.params.projectId).role;
      } else {
        return "unknown";
      }
    },

    pointInspectorTabDetailsPoint(): Record<string, XYZInterface>[] {
      return this.getPointInspector.object.points?.filter((point: any) => point.position) ?? [];
    },

    pointInspectorTabDetailsPointsVModel: {
      get(): number[] {
        return this.pointInspectorTabDetailsPoint?.map((_, index) => index) ?? [];
      },
      set(value: number[]) {
        return value;
      },
    },
  },
  mounted() {
    this.setBorderWidth();
    this.setDrawerEvents();
  },
});
