
import Vue from "vue";

import { ViewerInspectorMode } from "@/types";

import ModelPointDetails from "./model-point-details.vue";
import PointDetails from "./point-details.vue";

export default Vue.extend({
  name: "ViewerInspectorTabItemsDetails",
  components: {
    ModelPointDetails,
    PointDetails,
  },
  computed: {
    inspectorMode(): string {
      return this.$store.getters["PointCloudViewer/getInspectorMode"];
    },

    isPointInspector(): boolean {
      return (
        this.inspectorMode === ViewerInspectorMode.POINT_OF_INTEREST ||
        this.inspectorMode === ViewerInspectorMode.MEASUREMENTS
      );
    },

    isIFCModelItem(): boolean {
      return this.inspectorMode === ViewerInspectorMode.IFC_MODEL_ITEM;
    },
  },
});
