var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel-content',[_vm._l((_vm.items),function(group,index){return [_c('v-divider',{key:'div-' + index}),_c('v-expansion-panels',{key:group.id,attrs:{"flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":"","color":"grey lighten-4"},on:{"mouseenter":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
              id: group.id,
              modelId: _vm.getCurrentLeftSideBarModelId(),
            })},"mouseleave":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
              id: group.id,
              modelId: _vm.getCurrentLeftSideBarModelId(),
            })}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/ifcInteraction', {
                  action: 'isolation',
                  payload: group,
                })}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-select-group")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/ifcInteraction', {
                  action: 'visibility',
                  payload: group,
                })}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters["PointCloudViewer/getIfcHidden"].includes(group.id) ? "mdi-eye-off" : "mdi-eye"))])],1),_c('v-btn',{attrs:{"icon":"","small":"","plain":""}},[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-chevron-down")])],1)]},proxy:true}],null,true)},[_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(group.displayname))])]),(group.items.length > 0)?_c('IFCStoreyGroupRecursive',{attrs:{"items":group.items}}):_vm._e()],1)],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }