
import Vue from "vue";

export default Vue.extend({
  name: "ViewerLayerItemActionEnterIFCModel",
  props: {
    itemkey: String,
    item: Object,
    type: String,
  },
  methods: {
    enterIFCModel() {
      this.$store.dispatch("PointCloudViewer/ifcModelDestructure", this.itemkey);
    },
  },
});
