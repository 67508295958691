
import Vue from "vue";

import { AvailableAttachmentInterfaces } from "@/types";

export default Vue.extend({
  name: "ViewerLayerItemAttachmentDots",
  props: {
    attachments: {
      type: Array as () => AvailableAttachmentInterfaces[],
      default: () => [],
      required: true,
    },
  },
  methods: {
    color(type: string) {
      switch (type) {
        case "note":
          return "primary";
        case "link":
          return "purple";
        case "file":
          return "warning";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("PointCloudViewer/sortAttachmentItems", {
      sortSelectedAttachments: false,
      attachmentList: this.attachments,
    });
  },
});
