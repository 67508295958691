
import Vue from "vue";

import { PointCloudDialogItemData } from "@/types";
import { formatBytes, timestampToLocaleDateTimeSeconds } from "@/utilities";

export default Vue.extend({
  name: "PotreeConverterDialog",
  data: () => ({
    formatBytes,
    timestampToLocaleDateTimeSeconds,
  }),
  methods: {
    closeDialog(): void {
      this.dialog = false;
      setTimeout(() => {
        this.$store.commit("Viewers/setPointCloudUploadDialogItemData", undefined);
      }, 350);
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["Viewers/getPotreeConverterDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Viewers/setPotreeConverterDialogState", state);
      },
    },
    item(): PointCloudDialogItemData {
      return this.$store.getters["Viewers/getPointCloudUploadDialogItemData"];
    },
  },
});
