import { FetchedModelInterface, FetchedWireFrame } from "@/types";
import { ContextItem, FileBrowserFile, FileBrowserFolder, FileBrowserList } from "@/types/file";

interface State {
  cancelUploadDialog: boolean;
  contextItem: ContextItem;
  createFolderDialog: boolean;
  deleteDialog: boolean;
  esitmatedUploadTime: {
    start: number;
    estimate: number;
  };
  fetchedModels: FetchedModelInterface[];
  fetchedWireframes: FetchedWireFrame[];
  fileBrowserDialog: boolean;
  files: FileBrowserList[];
  filesInFolder: (FileBrowserFile | FileBrowserFolder | null)[];
  fileUploadProgress: Record<string, FileUploadProgress>;
  folderCreationProgress: boolean;
  isImageResize: {
    resize: boolean;
    width?: number;
    height?: number;
  };
  isProfileImage: boolean;
  isSingleUploadSelect: boolean;
  isUploadAborted: boolean;
  isUploading: boolean;
  itemInfoDialog: boolean;
  loading: boolean;
  path: string;
  projectStorageRoot: string;
  renameItemDialog: boolean;
  selectFileDialog: boolean;
  shareDialog: boolean;
  shareUrl: string;
  showFileInput: boolean;
  totalUploadSize: number;
  uploadAllFiles: Array<FileUpload>;
  uploadDialog: boolean;
  uploadFiles: File[];
  uploadRequests: Record<string, any>[];
}

type FileUploadProgress = {
  fileName: string;
  progress: number;
};

type FileUpload = {
  file: File;
  path: string;
  status: FileUploadStatus;
};

enum FileUploadStatus {
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  CANCELED = "canceled",
  NONE = "none", //unused at the moment
}

export { FileUpload, FileUploadProgress, FileUploadStatus, State };
