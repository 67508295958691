const imageResize = async (origin: File, width = 256, height = 256): Promise<Blob | null> => {
  // create a canvas for image
  const canvas = document.createElement("canvas");

  // get canvas context
  const canvasContext = <CanvasRenderingContext2D>canvas.getContext("2d");

  // read origin file data to usable image source (base64)
  const imageSource: string = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(<string>reader.result);
    // start file read
    reader.readAsDataURL(origin);
  });

  // resolve canvas element
  await new Promise((resolve) => {
    // source image data container
    const image = new Image();

    // onload will trigger once image is assigned source
    image.onload = () => {
      // original image natural width and height
      const originWidth = image.naturalWidth;
      const originHeight = image.naturalHeight;

      // maximum width or height
      const maxSize = Math.max(width, height);

      // desired aspect ratio
      const aspectRatio = width / height;

      // calculcate scale factor
      const scale = Math.max(maxSize / originWidth, maxSize / originHeight);

      // scale image
      image.width = originWidth * scale;
      image.height = originHeight * scale;

      // assign rescaled image variables
      const inputWidth = image.width;
      const inputHeight = image.height;

      // scaled image aspect ratio
      const imageRatio = inputWidth / inputHeight;

      // if image ratio is greater than desired ratio
      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (imageRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (imageRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      // calculate offsets to center image on canvas
      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      // set canvas to match the output size
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      // draw image on canvas with X and Y offset as needed and set size
      canvasContext.drawImage(image, outputX, outputY, inputWidth, inputHeight);

      // resolve promise
      // return canvas
      resolve(canvas);
    };

    // start image.onload by assigning source
    image.src = imageSource;
  });

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 0.75));
};

export { imageResize };
