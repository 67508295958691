import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import {
  AttachmentSectionLineItem,
  AttachmentType,
  AvailableAttachmentInterfaces,
  IFCModelItemInterface,
  InspectorAttachmentTypeInterface,
  ViewerInspectorMode,
} from "@/types";
import { coreApiPost } from "@/utilities";

import { ModelsState } from "../models/types";
import { CurrentInspectorTuple, SidebarsState } from "../sidebars/types";
import { State } from "../types";
import { AttachmentsState } from "./types";

const apiRoot = "/viewer";

const attachmentActions: ActionTree<
  State & AttachmentsState & ModelsState & SidebarsState,
  unknown
> = {
  /**
   * Creates an attachment section or line item.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @param {{ attachmentType: AttachmentType; id: string; isIFCitem?: boolean; tableItemId?: string; }} payload
   * @returns {Promise<void>}
   */
  async createAttachment(
    { dispatch, commit, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: {
      attachmentType?: AttachmentType;
      lineItem?: AvailableAttachmentInterfaces;
      sectionId?: string;
      sectionLineItem: AttachmentSectionLineItem;
    }
  ): Promise<void> {
    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    const { attachmentType, sectionLineItem, lineItem, sectionId } = payload;

    // Get current inspector data.
    const currentInspector: CurrentInspectorTuple = getters["getCurrentInspector"];

    const { type: itemType } = currentInspector || {};
    const {
      id,
      tableItemId,
    }: {
      id?: string;
      tableItemId?: string;
    } = getters["getCurrentInspectorIds"] || {};

    // Get current model if it's an IFC item.
    const currentModel: IFCModelItemInterface | undefined = getters["getIfcCurrentModel"];
    const { modelContainerId } = currentModel || {};

    // Check if it's an IFC model item. This will require some extra checks.
    const isIFCModelitem = itemType === ViewerInspectorMode.IFC_MODEL_ITEM;

    const requiredVariables = [
      {
        test: !!id,
        message: "item ID",
      },
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!viewerId,
        message: "viewer ID",
      },
      {
        test: !!sectionLineItem,
        message: "section or line item declaration",
      },
      ...(sectionLineItem === AttachmentSectionLineItem.SECTION
        ? [
          {
            test: !!attachmentType,
            message: "attachment type",
          },
        ]
        : []),
      ...(sectionLineItem === AttachmentSectionLineItem.LINE_ITEM
        ? [
          {
            test: !!sectionId,
            message: "section id for line item",
          },
          {
            test: !!lineItem,
            message: "line item",
          },
        ]
        : []),
      ...(isIFCModelitem
        ? [
          {
            test: !!modelContainerId,
            message: "model container ID",
          },
        ]
        : []),
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    commit("setLoadingState", true);

    // All good, continue with the try/catch.
    try {
      const postPayload = {
        attachmentType,
        id,
        itemType,
        lineItem,
        modelContainerId,
        projectId,
        sectionId,
        sectionLineItem,
        tableItemId,
        viewerId,
      };

      // Send post request to create database entry.
      const postResponse = await coreApiPost(`${apiRoot}/create-attachment`, postPayload);

      // Determine which category the attachment type belongs to so we can add it to the correct layer item.
      const category = getters["getInspectorModeAsLayerCategory"];

      // Add attachment section to corresponding layer- or IFC model-item and update inspector.
      commit("addAttachment", {
        ...(sectionLineItem === AttachmentSectionLineItem.SECTION
          ? {
            attachment: {
              type: attachmentType,
              items: [],
              ...postResponse,
            },
          }
          : {}),
        ...(sectionLineItem === AttachmentSectionLineItem.LINE_ITEM
          ? {
            lineItem: {
              ...lineItem,
              ...postResponse,
            },
            sectionId,
          }
          : {}),
        category,
        id,
        isIFCModelitem,
        modelContainerId,
        sectionLineItem,
        ...(postResponse.tableItemId && { tableItemId: postResponse.tableItemId }),
      });

      // Sort attachment items.
      dispatch("sortAttachmentItems");

      // Show success message.
      const successMessage =
        sectionLineItem === AttachmentSectionLineItem.SECTION
          ? `<strong>${attachmentType}</strong> attachment created`
          : `line item added`;

      commit(
        "Utilities/showSnackbar",
        {
          message: successMessage,
          color: "success",
          timeout: 2,
        },
        { root: true }
      );
    } catch (error: any) {
      commit(
        "Utilities/showSnackbar",
        {
          message: error.toString(),
          color: "error",
          timeout: 2,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Deletes an attachment section or line item.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any }} { dispatch, commit, getters }
   * @param {{ lineItemId?: string; sectionId: string; sectionLineItem: AttachmentSectionLineItem; }} payload
   * @returns {Promise<void>}
   */
  async deleteAttachment(
    { dispatch, commit, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: {
      lineItemId?: string;
      sectionId: string;
      sectionLineItem: AttachmentSectionLineItem;
    }
  ): Promise<void> {
    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    const { lineItemId, sectionId, sectionLineItem } = payload;

    // Get current inspector data.
    const currentInspector: CurrentInspectorTuple = getters["getCurrentInspector"];

    const { type: itemType, attachments } = currentInspector || {};
    const { id, tableItemId } = getters["getCurrentInspectorIds"] || {};
    const attachmentType = attachments?.find((attachment) => attachment.id === sectionId)?.type;

    // Get current model if it's an IFC item.
    const currentModel: IFCModelItemInterface | undefined = getters["getIfcCurrentModel"];
    const { modelContainerId } = currentModel || {};

    // Check if it's an IFC model item. This will require some extra checks.
    const isIFCModelitem = itemType === ViewerInspectorMode.IFC_MODEL_ITEM;

    const requiredVariables = [
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!viewerId,
        message: "viewer ID",
      },
      {
        test: !!sectionLineItem,
        message: "section or line item declaration",
      },
      {
        test: !!sectionId,
        message: "section ID",
      },
      ...(sectionLineItem === AttachmentSectionLineItem.LINE_ITEM
        ? [
          {
            test: !!lineItemId,
            message: "line item ID",
          },
        ]
        : []),
      ...(isIFCModelitem
        ? [
          {
            test: !!modelContainerId,
            message: "model container ID",
          },
          {
            test: !!tableItemId,
            message: "table item ID",
          },
        ]
        : []),
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    commit("setLoadingState", true);

    // All good, continue with the try/catch.
    try {
      const postPayload = {
        id,
        itemType,
        lineItemId,
        modelContainerId,
        projectId,
        sectionId,
        sectionLineItem,
        tableItemId,
        viewerId,
      };

      // Send post request to create database entry.
      await coreApiPost(`${apiRoot}/delete-attachment`, postPayload);

      // Data was succefully updated on backend, reset the delete dialog.
      commit("resetDeleteDialog");

      // Determine which category the attachment type belongs to so we can add it to the correct layer item.
      const category = getters["getInspectorModeAsLayerCategory"];

      // Add attachment section to corresponding layer- or IFC model-item and update inspector.
      commit("deleteAttachment", {
        category,
        id,
        isIFCModelitem,
        lineItemId,
        modelContainerId,
        sectionId,
        sectionLineItem,
      });

      // Sort attachment items.
      dispatch("sortAttachmentItems");

      // // Show success message.
      const successMessage =
        sectionLineItem === AttachmentSectionLineItem.SECTION
          ? `<strong>${attachmentType}</strong> attachment deleted`
          : `line item deleted`;

      commit(
        "Utilities/showSnackbar",
        {
          message: successMessage,
          color: "success",
          timeout: 2,
        },
        { root: true }
      );
    } catch (error: any) {
      commit(
        "Utilities/showSnackbar",
        {
          message: error.toString(),
          color: "error",
          timeout: 2,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Sorts the attachment items.
   *
   * @param {{ state: State & AttachmentsState; }} { state }
   * @param {{ sortSelectedAttachments?: boolean; attachmentList?: InspectorAttachmentTypeInterface[]; }} payload
   * @returns {void}
   */
  sortAttachmentItems(
    { state }: { state: State & AttachmentsState },
    payload: {
      sortSelectedAttachments?: boolean;
      attachmentList?: InspectorAttachmentTypeInterface[];
    }
  ): void {
    const { attachmentTypes, selectedAttachmentTypes } = state;
    const { sortSelectedAttachments = true, attachmentList = [] } = payload || {};

    if (sortSelectedAttachments) {
      selectedAttachmentTypes.sort((a, b) => {
        return attachmentTypes.indexOf(a) > attachmentTypes.indexOf(b) ? 1 : -1;
      });

      return;
    }

    if (attachmentList.length > 0) {
      attachmentList.sort((a, b) => {
        const typeA = a.type;
        const typeB = b.type;
        return attachmentTypes.indexOf(typeA) > attachmentTypes.indexOf(typeB) ? 1 : -1;
      });

      return;
    }
  },
};

export default attachmentActions;
