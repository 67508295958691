
import Vue from "vue";

import RoleChip from "@/components/role-chip.vue";
import { Project } from "@/store/modules/Projects/types";
import {
  FileBrowserAccessRole,
  ProjectUserAccessInterface,
  Roles,
  SnackbarColors,
  UserProfileInterface,
  UserProjectAccessInterface,
  UserProjectsAccessListInterface,
  UserStatus,
} from "@/types";
import { timestampToLocaleDate } from "@/utilities";

interface Data {
  loadingProjects: boolean;
  newUserRole?: Roles;
  projectAccessHeaders: Record<string, string>[];
  projects: Project[];
  roles: typeof Roles;
  selectableProjectAccessRoles: Roles[];
  selectedProjectAccessRole: Roles.USER | Roles.GUEST | Roles.EDITOR | Roles.ADMIN;
  selectedProjects: Project[];
  timestampToLocaleDate: (timestamp: string | number) => string;
  user?: UserProfileInterface;
  userProjectRoles?: Roles[];
  userRole?: Roles;
  userRootFileAccess?: FileBrowserAccessRole;
  userStatus?: boolean;
  userStatuses: typeof UserStatus;
}

export default Vue.extend({
  name: "SingleUserEditDialog",
  props: {
    item: {
      type: Object as () => UserProfileInterface,
      required: true,
    },
  },
  components: {
    RoleChip,
  },
  data: (): Data => ({
    loadingProjects: false,
    newUserRole: undefined,
    projects: [],
    projectAccessHeaders: [
      { text: "Project", value: "title" },
      { text: "User role", value: "role" },
    ],
    roles: Roles,
    selectableProjectAccessRoles: [Roles.USER, Roles.GUEST, Roles.EDITOR, Roles.ADMIN],
    selectedProjectAccessRole: Roles.USER,
    selectedProjects: [],
    timestampToLocaleDate,
    user: undefined,
    userProjectRoles: undefined,
    userRole: undefined,
    userRootFileAccess: undefined,
    userStatus: undefined,
    userStatuses: UserStatus,
  }),
  methods: {
    goToProject(id: string): void {
      this.clearEditDialog();
      this.$router.push({ name: "projectEdit", params: { projectId: id } });
    },

    setUserFilebrowserAccess(): void {
      this.$store.dispatch("AccountManagement/setUserFileBrowserAccess", {
        editUser: this.user?.email,
        rootFileAccess: this.userRootFileAccess ? FileBrowserAccessRole.ADMIN : undefined,
      });
    },

    /**
     * Change user role.
     * @param {string} email - User email.
     * @param {string} toRole - User role.
     * @returns {Promise<void>} - Returns void.
     */
    async setUserRole(): Promise<void> {
      const { email, role: currentRole } = this.user || {};

      if (!currentRole || !this.userRole) {
        return;
      }

      try {
        // Change user role.
        await this.$store.dispatch("AccountManagement/setUserRole", {
          email,
          fromRole: currentRole,
          toRole: this.userRole,
        });

        // Set edited current role to selected role.
        if (this.user?.role) {
          this.user.role = this.userRole;
        }
      } catch (error: any) {
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: `Error changing user role: ${error.toString()}`,
            color: SnackbarColors.ERROR,
          },
          { root: true }
        );
      }
    },

    /**
     * Change user state.
     *
     * @param {string} parameters.email - User email.
     * @param {boolean} parameters.userState - User state.
     * @returns {Promise<void>} - Returns void.
     */
    async setUserState(): Promise<void> {
      switch (this.user?.status) {
        case UserStatus.ACTIVE:
          this.userStatus = false;
          break;
        case UserStatus.PENDING:
        case UserStatus.DISABLED:
          this.userStatus = true;
          break;
      }

      try {
        await this.$store.dispatch("AccountManagement/setUserState", {
          email: this.user?.email,
          userState: this.userStatus,
        });
        const users: UserProfileInterface[] = this.$store.getters["AccountManagement/getUserList"];
        const user = users.find((user) => user.uuid === this.user?.uuid);
        if (user && this.user?.status) {
          this.user.status = this.userStatus ? UserStatus.ACTIVE : UserStatus.DISABLED;
        }
      } catch (error: any) {
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: `Error changing user state: ${error.toString()}`,
            color: SnackbarColors.ERROR,
          },
          { root: true }
        );
      }
    },

    clearEditDialog(): void {
      this.dialog = false;
      this.selectedProjects = [];
      this.selectedProjectAccessRole = Roles.USER;
      return;
    },

    async saveUserProjectAccess(user: UserProfileInterface): Promise<void> {
      const { email } = user;
      const selectedRole = this.selectedProjectAccessRole as
        | Roles.ADMIN
        | Roles.EDITOR
        | Roles.GUEST;
      const selectedProjects = this.selectedProjects;

      const projects: UserProjectsAccessListInterface[] = selectedProjects.map((project) => {
        const { id } = project;

        const addRoles = [Roles.ADMIN, Roles.EDITOR, Roles.GUEST];
        const add = addRoles.includes(selectedRole);

        return {
          add,
          projectId: id,
          ...(add && { role: selectedRole }),
        };
      });

      this.isWorking = true;

      try {
        await this.$store.dispatch("AccountManagement/setUserProjectAccess", {
          userEmail: email,
          projects,
        });

        this.selectedProjects = [];
        this.selectedProjectAccessRole = Roles.USER;
      } catch (error: any) {
        this.$store.commit("Utilities/showSnackbar", {
          message: `Error setting user project access: ${error.toString()}`,
          color: SnackbarColors.ERROR,
        });
      }

      this.isWorking = false;
    },

    async getProjects(): Promise<void> {
      this.loadingProjects = true;
      // If projects are already loaded, use existing state.
      const hasFetched = this.$store.getters["Projects/getFetchedState"];
      if (!hasFetched) {
        await this.$store.dispatch("Projects/fetchProjects");
      }
      this.loadingProjects = false;
      this.projects = this.$store.getters["Projects/getProjects"] ?? [];
      return;
    },

    getUserProjectRole(projectId: string): string {
      const defaultRole = "none";

      if (this.userProjectAccess) {
        const project = (this.userProjectAccess as UserProjectAccessInterface[])?.find(
          (item) => item.id === projectId
        );
        return project?.role ?? defaultRole;
      }

      return defaultRole;
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["AccountManagement/getEditDialogState"];
      },

      set(state: boolean) {
        this.$store.commit("AccountManagement/setEditDialogState", state);
      },
    },

    isWorking: {
      get(): boolean {
        return this.$store.getters["AccountManagement/getEditWorkingState"];
      },
      set(state: boolean): void {
        this.$store.commit("AccountManagement/setEditWorkingState", state);
      },
    },

    isOwner(): boolean {
      return this.$store.getters["User/getIsOwner"];
    },

    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    /**
     * Get user roles.
     *
     * @returns {string[]} - Returns user roles.
     */
    userRoleList(): Roles[] {
      // Filter available roles based on current user role.
      if (this.isOwner) {
        return [Roles.OWNER, Roles.ADMIN, Roles.USER];
      }

      if (this.isAdmin) {
        return [Roles.ADMIN, Roles.USER];
      }

      // Return empty array if user role is not owner or admin.
      return [];
    },

    users(): UserProfileInterface[] {
      const users: UserProfileInterface[] = this.$store.getters["AccountManagement/getUserList"];

      return users;
    },

    userProjectAccess(): UserProjectAccessInterface[] | undefined {
      const { projectAccess } = this.users.find((user) => user.uuid === this.item.uuid) ?? {};
      return projectAccess;
    },
  },
  watch: {
    item: async function () {
      this.userStatus = this.item.status === UserStatus.ACTIVE;
      this.userRole = this.item.role;
      this.userRootFileAccess = this.item.rootFileAccess;
      this.user = this.item;

      if (this.item.role === Roles.USER) {
        await this.getProjects();
      }
    },
    userRole: function (newRole) {
      if (newRole === Roles.USER) {
        this.getProjects();
      }
    },
  },
});
