import Vue from "vue";
import { MutationTree } from "vuex";

import router from "@/router";
import {
  TagGroup,
  TagItemInterface,
  TagItemsInterface,
  TagUndo,
  ViewerInspectorMode,
} from "@/types";
import { sortArray } from "@/utilities";

import { getDefaultState } from "./state";
import { State } from "./types";

// Reusable function to sort tags by title.
const sortTags = (tags: TagItemInterface[]): void => {
  sortArray(tags, "title");
};

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  deleteTag(state, id: string) {
    // Filter out the tag that is being deleted.
    state.tags = state.tags.filter((tag) => tag.id !== id);
    sortTags(state.tags);
  },

  setDeleteDialogState(state, value: boolean) {
    state.deleteDialogState = value;
  },

  setEditDialogState(state, value: boolean) {
    state.editDialogState = value;
  },

  setFetchedState(state, value: boolean) {
    // Typecast `state.fetched` to a boolean because of conflict in `ViewerState` interface which also has a `fetched` property but is of type `string[]`.
    (state.fetched as boolean) = value;
  },

  setNewTag(state, newTag: TagItemInterface) {
    // If tags is not `undefined` push the new tag to the array, otherwise create a new array with the new tag.
    state.tags ? state.tags.push(newTag) : (state.tags = [newTag]);
    sortTags(state.tags);
  },

  setTags(state, tags: TagItemInterface[]) {
    state.tags = tags;
    sortTags(state.tags);
  },

  setLoadingState(state, value: boolean) {
    state.loading = value;
  },

  setSelectedTag(state, selectedTag: TagItemInterface) {
    state.selectedTag = selectedTag;
  },

  setTagListCount: (state, value: number) => {
    state.tagListCount = value;
  },

  setTagListFilterCount: (state, value: number) => {
    state.tagListFilterCount = value;
  },

  updateTags(state, updatedTag: TagItemInterface) {
    // Filter out the tag that is being updated and add the updated tag to the array.
    const filteredTags = state.tags.filter((tag) => tag.id !== updatedTag.id);
    state.tags = [...filteredTags, updatedTag];
    sortTags(state.tags);
  },

  /**
   * Set tags array for the viewer. Normally this is done once durring the `addViewerTagTitles` action in `Viewers` module. Fallback to an empty array if `tags` is `undefined`.
   *
   * @param {State} state
   * @param {TagItemInterface[]} [tags]
   */
  setViewerTags(state: State, tags?: TagItemInterface[]) {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    let viewerIndex = state.viewerTags.findIndex((viewer) => viewer.id === viewerId);

    if (viewerIndex === -1) {
      const tagsArray = Array.isArray(tags) ? tags : [];

      state.viewerTags.push({
        id: viewerId,
        tags: tagsArray,
      });
      viewerIndex = state.viewerTags.length - 1;
    }

    // Tags can contain string `no items` if there are no tags. So we need to check if `tags` is an array before we continue.
    const isArray = Array.isArray(tags);
    if (!isArray) {
      return;
    }

    state.viewerTags[viewerIndex].tags =
      tags?.reduce((updatedList: TagItemInterface[], tag) => {
        const { id, items } = tag;

        const tagIndex = updatedList.findIndex((item) => item.id === id);
        const hasTag = tagIndex !== -1;

        if (!hasTag) {
          // Adding new tag to the list.
          updatedList.push(tag);
        } else {
          // Updating existing tags items array.
          const existingTag = updatedList[tagIndex];
          const existingItems = existingTag.items ?? [];
          items?.forEach((item) => {
            const hasItem = existingItems.includes(item);
            if (!hasItem) {
              existingItems.push(item);
            }
          });
        }

        return updatedList;
      }, state.viewerTags[viewerIndex].tags ?? []) ?? [];
  },

  /**
   * This should only happen when `item.tag.title` is not already in the `viewerTags` array because the list should only contain unique tags. Items are added to the tag in the `addViewerTagItem` mutation and hold the reference to items using the tag.
   *
   * @param {State} state
   * @param {TagItemInterface} tag
   */
  addViewerLayerItemTag(state: State, tag: TagItemInterface) {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    const viewerIndex = state.viewerTags.findIndex((viewer) => viewer.id === viewerId);

    const hasViewerTag = state.viewerTags[viewerIndex].tags.some(
      (item) => item.title === tag.title
    );

    if (!hasViewerTag) {
      state.viewerTags[viewerIndex].tags.push({
        ...tag,
        items: [],
      });
    }
  },

  /**
   * Add a new item to a tag in the viewer. This is done when a tag is added in an inspector and does not yet exist in the `viewerTags` array.
   *
   * @param {State} state
   * @param {{ tagIndex: number; item: TagItemsInterface }} payload
   */
  addViewerTagItem(
    state: State,
    payload: {
      title: string;
      item: TagItemsInterface;
    }
  ) {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    const viewerIndex = state.viewerTags.findIndex((viewer) => viewer.id === viewerId);

    const { viewerTags, viewerIFCAutoTags } = state;
    const { title, item } = payload;

    const { expressId, isAuto, modelContainerId, type } = item;

    const itemsArray = isAuto ? viewerIFCAutoTags : viewerTags[viewerIndex].tags;

    const viewerTagIndex = state.viewerTags[viewerIndex].tags.findIndex(
      (item) => item.title === title
    );

    // No need to continue if there are no tags or if the tag index is `-1`.
    if (itemsArray.length < 1 || viewerTagIndex === -1) {
      return;
    }

    // Previous steps have confirmed that the tag is in viewer tags, we can now use the index of the tag in the viewer tags because it is guaranteed to exist.
    const tagItem = itemsArray[viewerTagIndex];

    // When dealing with `IFC_MODEL_ITEM` type we need to add `expressId` and `modelContainerId` so we can target the correct IFC model item when filtering with tags.
    if (modelContainerId && expressId && type === ViewerInspectorMode.IFC_MODEL_ITEM) {
      item.expressId = expressId;
      item.modelContainerId = modelContainerId;
    }

    // This is for all types of tags, including `IFC_MODEL_ITEM`. We need both the above `expressId` and `modelContainerId` object for filtering and the "regular" tag object for backend read/write.
    if (tagItem.items) {
      tagItem.items.push(item);
    } else {
      tagItem.items = [item];
    }
  },

  addSingleViewerTagItem(state: State, payload: { tagIndex: number; item: TagItemsInterface }) {
    const { viewerTags } = state;
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    const viewerIndex = state.viewerTags.findIndex((viewer) => viewer.id === viewerId);

    const { tagIndex, item } = payload;
    viewerTags[viewerIndex].tags[tagIndex].items?.push(item);
  },

  /**
   * Remove an item from `items` in a viewer tag. If the tag has no more items, remove the tag altogether from the `viewerTags` array.
   *
   * @param { State } state
   * @param {{ tagTitle: string; itemId: string }} payload
   */
  removeViewerTagItem(
    state: State,
    payload: {
      tagId: string;
      itemId: string;
      globalTags: TagItemInterface[];
    }
  ) {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;

    const viewerIndex = state.viewerTags.findIndex((viewer) => viewer.id === viewerId);

    // If `viewerTags` is `undefined` there is nothing to remove.
    if (typeof state.viewerTags[viewerIndex].tags === "undefined") {
      return;
    }

    const { tagId, itemId, globalTags } = payload;

    // Find the tag index using `tagId` in the `viewerTags` array.
    const tagIndex = state.viewerTags[viewerIndex].tags.findIndex((tag) => tag.id === tagId);

    // If `tagIndex` is `-1` we can't continue.
    if (tagIndex === -1) {
      return;
    }

    const tag = state.viewerTags[viewerIndex].tags[tagIndex];

    // Find the item using `itemId` (the id we are removing) index in the `items` array of the viewer tag.
    const itemIndex = state.viewerTags[viewerIndex].tags[tagIndex].items?.findIndex(
      (item) => item.id === itemId
    );

    const items = state.viewerTags[viewerIndex].tags[tagIndex].items;

    // Make sure `items` is not `undefined` and `itemIndex` is not `-1`. Otherwise we are trying to mutate an array that is `undefined` or remove an item that does not exist.
    if (typeof items === "undefined" || typeof itemIndex === "undefined" || itemIndex === -1) {
      return;
    }

    // Remove `itemId` from the tag `items` array.
    items.splice(itemIndex, 1);

    // Remove a tag from the `viewerTags` if tag has no more `items` and add tag back in global tags array.
    if (items.length < 1) {
      state.viewerTags[viewerIndex].tags.splice(tagIndex, 1);
      globalTags.push({
        ...tag,
        group: TagGroup.UNUSED,
      });
    }
  },

  /**
   * Add a tag to the `selectedTags` array if it's not already in the array.
   *
   * @param {State} state
   * @param {TagItemInterface} tag
   */
  pushSelectedViewerTag(state: State, tag: TagItemInterface) {
    const hasTag = state.viewerSelectedTags?.some((item) => item.title === tag.title);
    if (!hasTag) {
      state.viewerSelectedTags?.push(tag);
    }
  },

  /**
   * Remove a tag from the `selectedTags` array.
   *
   * @param {State} state
   * @param {TagItemInterface} tag
   */
  removeSelectedViewerTag(state: State, tag: TagItemInterface) {
    const hasTag = state.viewerSelectedTags?.some((item) => item.id === tag.id);
    if (hasTag) {
      const filteredList = state.viewerSelectedTags?.filter((item) => item.id !== tag.id);
      Vue.set(state, "viewerSelectedTags", filteredList);
    }
  },

  pushHiddenTagItem(state: State, id: any) {
    const hasItem = state.viewerHiddenTagItems.some((hiddenItemId) => hiddenItemId === id);

    if (!hasItem) {
      state.viewerHiddenTagItems.push(id);
    }
  },

  removeHiddenTagItem(state: State, id: any) {
    const hasTag = state.viewerHiddenTagItems.some((hiddenItemId) => hiddenItemId === id);
    if (hasTag) {
      const filteredList = state.viewerHiddenTagItems.filter((hiddenItemId) => hiddenItemId !== id);
      Vue.set(state, "viewerHiddenTagItems", filteredList);
    }
  },

  addTagUndo(state: State, tagsUndo: TagUndo) {
    state.tagsUndo = [...state.tagsUndo, tagsUndo];
  },

  removeTagUndo(state: State, { tagId }: { tagId: string }) {
    const filteredTagsUndo = state.tagsUndo.filter((tagUndo) => tagUndo.tagId !== tagId);
    Vue.set(state, "tagsUndo", filteredTagsUndo);
  },
};

export default mutations;
