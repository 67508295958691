
import Vue from "vue";

import Author from "@/components/author.vue";
import { formatBytes, timestampToLocaleDateTimeSeconds } from "@/utilities";

export default Vue.extend({
  name: "FileBrowserItemInfoDialog",
  data: () => ({
    formatBytes,
    timestampToLocaleDateTimeSeconds,
  }),
  components: {
    Author,
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Storage/getItemInfoDialogState"];
      },
      set(state) {
        this.$store.commit("Storage/setItemInfoDialogState", state);
      },
    },
    item() {
      return this.$store.getters["Storage/getContextItem"];
    },
  },
});
