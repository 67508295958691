import {
  AmbientLight,
  DirectionalLight,
  EdgesGeometry,
  Group,
  LineBasicMaterial,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  Scene,
} from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
import { ActionTree, Commit, Dispatch } from "vuex";
import {
  FlatMesh,
  IFCBEAM,
  IFCBUILDINGELEMENTPART,
  IFCBUILDINGELEMENTPROXY,
  IFCCHIMNEY,
  IFCCOLUMN,
  IFCCOVERING,
  IFCCURTAINWALL,
  IFCDOOR,
  IFCFOOTING,
  IFCMEMBER,
  IFCPILE,
  IFCPLATE,
  IFCRAILING,
  IFCRAMP,
  IFCRAMPFLIGHT,
  IFCROOF,
  IFCSHADINGDEVICE,
  IFCSLAB,
  IFCSTAIR,
  IFCSTAIRFLIGHT,
  IFCWALL,
} from "web-ifc";
import * as IFCTypes from "web-ifc";
import {
  IfcMesh,
  IfcModel,
  Node as IFCNode,
  SubsetConfig,
} from "web-ifc-three/IFC/BaseDefinitions";
import { IFCManager } from "web-ifc-three/IFC/components/IFCManager";
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel";
import { Subset } from "web-ifc-three/IFC/components/subsets/SubsetManager";
import { IFCLoader } from "web-ifc-three/IFCLoader";

import router from "@/router";
import {
  AttachmentObjectItemType,
  AttachmentSectionLineItem,
  FetchedModelInterface,
  FetchedWireFrame,
  IFCModelItemInterface,
  IFCStoreyGroupInterface,
  IFCStoreyGroupItemsInterface,
  IFCStoreyInterface,
  LayerEditorModelData,
  ModelData,
  ModelGeometry,
  ModelInpsectorAttachmentInterface,
  ModelTransformInterface,
  ModelTransformKeys,
  TagItemInterface,
  ViewerInspectorMode,
  ViewerInterface,
  ViewerLayerDataCategoryKey,
  Wireframe,
  WireframeAction,
  WireMeshAssociations,
  XYZInterface,
} from "@/types";
import { convertUnicode, coreApiPost, degreesToRadian, getBlobWithProgress } from "@/utilities";

import { SidebarsState } from "../sidebars/types";
import { TransformState } from "../transform/types";
import { State } from "../types";
import { currentSelection, ModelsState } from "./types";

/* const material = new MeshLambertMaterial({ OLD MATERIAL
  transparent: true,
  opacity: 0.6,
  color: 0xff88ff,
  depthTest: false,
}); */

const material = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.5,
  color: 0xe71f68,
  depthTest: true,
});
const selectedMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 1,
  color: 0xe71f68,
  depthTest: true,
});

// wireframe material
const edgeMat = new LineBasicMaterial({ color: 0x333333 });

const modelsActions: ActionTree<State & ModelsState & SidebarsState & TransformState, unknown> = {
  /**
   *  Show loading for all enabled models.
   *
   * @param {{ commit: Commit; }} actions
   * @param {ModelData[]} models
   * @returns {void}
   */
  initModelsLoading({ commit }: { commit: Commit }, models: ModelData[]): void {
    models.forEach((model) => {
      const { id, title } = model;
      const progress = {
        value: 0,
        spinner: false,
        id,
        title,
        type: "model",
      };
      commit("setLoadingProgressItem", { id, progress });
    });
  },

  /**
   * Once all point clouds have loaded models can be added to scene.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; rootGetters: any; }} { commit, dispatch, rootGetters }
   * @returns {Promise<void>}
   */
  async loadModels({
    commit,
    dispatch,
    rootGetters,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    rootGetters: any;
  }): Promise<void> {
    const { params: routeParams } = router.currentRoute;
    const { projectId, viewerId } = routeParams;

    // Getting initial viewer data from database.
    const { models }: ViewerInterface = rootGetters["Viewers/getViewer"]({ projectId, viewerId });

    // Filter out all models that are not enabled.
    const enabledModels = models.filter((model) => model.enabled);

    if (enabledModels.length > 0) {
      // Apply scene lights if there are models.
      dispatch("createModelLights");

      /**
       * Remove (splice) model from model array using model id.
       *
       * @param {string} modelId
       * @param {ModelData[]} modelsArray
       */
      const spliceModel = (modelIds: string[], modelsArray: ModelData[]) => {
        modelIds.forEach((modelId) => {
          const modelIndex = modelsArray.findIndex((model) => model.id === modelId);
          modelsArray.splice(modelIndex, 1);
        });
      };

      const getFetchedModels: FetchedModelInterface[] = rootGetters["Storage/getFetchedModels"];

      // Fetched models, no need to refetch.
      if (getFetchedModels.length > 0) {
        const fetchedModelIds = [];

        for (let index = 0; index < enabledModels.length; index++) {
          const model = enabledModels[index];
          const { id: enabledModelId, transform, type } = model;

          const fetchedModel = getFetchedModels.find((fetched) => fetched.id === enabledModelId);
          const inEnabledModels = enabledModels.find(
            (enabledModel) => fetchedModel && enabledModel.id === fetchedModel.id
          );

          if (fetchedModel && inEnabledModels) {
            const { id: fetchedModelId, data }: FetchedModelInterface = fetchedModel;
            const { object } = data;

            fetchedModelIds.push(fetchedModelId);
            commit("setLayerDataModelsItems", { id: fetchedModelId, data });

            switch (type) {
              case "ifc":
                commit("setLoadingState", true);
                await dispatch("destructureIfcModelOnLoad", {
                  id: fetchedModelId,
                  modelId: (object as IFCModel).modelID,
                  partialModel: object,
                  transform,
                  isLoading: false,
                });
                commit("setLoadingState", false);
                commit("setModelLoadingState", false);
                object && dispatch("addModelToPotreeJSEventListener", { model: object });
                object && dispatch("ifcSubsetRaycast");
                break;

              default:
                dispatch("addModelToScene", {
                  model: object,
                  transform,
                });
            }
          }
        }

        // Remove (splice) fetched models from `enabledModels` array.
        if (fetchedModelIds.length > 0) {
          spliceModel(fetchedModelIds, enabledModels);
        }
      }

      /**
       * Get model arrays by model type.
       *
       * @param {string} type The model type, e.g. ifc, fbx etc.
       * @returns {ModelData[] | []} Array of model(s) or empty array.
       */
      const filterModelType = (type: string): ModelData[] => {
        return enabledModels.filter((model: ModelData) => model.type === type);
      };
      const ifcModels = filterModelType("ifc");
      const fbxModels = filterModelType("fbx");

      let ifcLoader: undefined | IFCLoader = rootGetters["Ifc/getIFCLoader"];
      let ifcManager: undefined | IFCManager = rootGetters["Ifc/getIFCManager"];

      // If IFC loader and manager are `undefined` and there are any IFC models, instantiate IFC loader and setup manager + commit to IFC store module.
      if (ifcModels.length > 0 && !ifcLoader && !ifcManager) {
        ifcLoader = new IFCLoader();
        ifcManager = ifcLoader.ifcManager;

        await ifcManager.useWebWorkers(true, "/IFCjs/IFCWorker.js");
        ifcManager.applyWebIfcConfig({
          COORDINATE_TO_ORIGIN: false,
          USE_FAST_BOOLS: true,
        });
        ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);

        commit("Ifc/setIFCLoader", ifcLoader, { root: true });
        commit("Ifc/setIFCManager", ifcManager, { root: true });
      }

      const loadingModels = ifcModels.concat(fbxModels);

      if (loadingModels.length > 0) {
        await dispatch("initModelsLoading", loadingModels);

        // Deal with IFC models.
        if (ifcModels.length > 0 && ifcManager && ifcLoader) {
          await dispatch("loadModelTypes", {
            models: ifcModels,
            dispatcher: "loadIFC",
            loader: ifcLoader,
          });
        }

        // Deal with FBX models, instantiate FBX loader.
        if (fbxModels.length > 0) {
          const fbxLoader = new FBXLoader();

          await dispatch("loadModelTypes", {
            models: fbxModels,
            dispatcher: "loadFBX",
            loader: fbxLoader,
          });
        }
      }
    }
    commit("setModelLoadingState", false);
  },

  /**
   *  Load model array by model type.
   *
   * @param {{ dispatch: Dispatch; }} actions { dispatch }
   * @param {{models: ModelData[], dispatcher: string, loader: any}} payload {models, dispatcher, loader}
   * @returns {Promise<void>}
   */
  async loadModelTypes(
    { dispatch }: { dispatch: Dispatch },
    payload: { models: ModelData[]; dispatcher: string; loader: any }
  ): Promise<void> {
    const { models, dispatcher, loader } = payload;
    // const promises: [] = [];
    for (const model of models) {
      const { id: modelContainerId } = model;

      dispatch(
        "Viewers/addViewerTagTitles",
        { id: modelContainerId, type: ViewerInspectorMode.MODEL },
        { root: true }
      );

      const modelDataObject: { type: string; model: string } = await dispatch(
        "Storage/getContainer",
        model.path,
        { root: true }
      );

      // ! Once we have confirmed that the current IFC model item tags flow is working we can remove the comment below and the if statement.
      // // If modelDataObject.type === "ifc" then we need to check if there are any ViewerInspectorMode.IFC_MODEL_ITEM tags, by sending a post request using `await coreApiPost` with a payload of { modelContainerId: model.id, type: ViewerInspectorMode.IFC_MODEL_ITEM }.
      // if (modelDataObject.type === "ifc") {
      //   dispatch(
      //     "Viewers/addViewerTagTitles",
      //     { id: modelContainerId, type: ViewerInspectorMode.IFC_MODEL_ITEM },
      //     { root: true }
      //   );
      // }

      const modelData = Object.assign({}, model);
      modelData.path = modelDataObject.model;
      await dispatch(dispatcher, { modelData, loader });
    }
    // await Promise.all(promises);
    // const promises = models.map(async (model) => {
    //   const modelDataObject: { type: string; model: string } = await dispatch(
    //     "Storage/getContainer",
    //     model.path,
    //     { root: true }
    //   );
    //   const modelData = Object.assign({}, model);
    //   modelData.path = modelDataObject.model;
    //   const promise = await dispatch(dispatcher, { modelData, loader });
    //   return promise;
    // });
  },

  /**
   * Uniformly add a model to the available scene.
   *
   * @param {{ dispatch: Dispatch; getters: any; }} actions { dispatch, getters }
   * @param {{ model: any; transform: ModelTransformInterface; }} payload { model, transform }
   * @returns {Promise<void>}
   */
  async addModelToScene(
    { dispatch, getters, commit }: { dispatch: Dispatch; getters: any; commit: Commit },
    payload: { model: any; transform: ModelTransformInterface }
  ): Promise<void> {
    const { model, transform } = payload;
    const scene: Scene = getters["getScene"];

    dispatch("transformValuesLoop", { transform, model });
    model && dispatch("addModelToPotreeJSEventListener", { model });
    model && dispatch("ifcSubsetRaycast");
    // Add model to viewer scene.
    // detect if model is ifc & put it in threejs-Group if so.
    const hasModelID = Object.prototype.hasOwnProperty.call(model, "modelID");
    if (hasModelID) {
      const ifcTransformGroup = new Group();
      // TODO: this is used to easier identify ifc-models when we later wan't to add them
      // as transformSelection.
      ifcTransformGroup.name = model.modelID;
      ifcTransformGroup.add(model);

      // add mesh with modelID to group
      scene.children.forEach((child) => {
        // filter out all children that is ifc-mesh
        const isIfc = Object.prototype.hasOwnProperty.call(child, "modelID");
        // filter out all children that is Mesh & not groups.
        const isMesh = child.type === "Mesh";
        const ifcMesh = child as IfcMesh;
        // filter out all children that is matching modelID
        const hasMatchingModelID = ifcMesh.modelID === model.modelID;

        // put filtered children in the group
        isIfc && hasMatchingModelID && isMesh && ifcTransformGroup.add(ifcMesh);
      });
      // adjust group rotation
      //  ifcTransformGroup.rotateX(Math.PI * 0.5);
      // set group as transform-selection so we can use setSubsetTransformValues
      // TODO: should be able to set subsettransform-values without having to have a transformselection

      // commit("setTransformSelection", { building: ifcTransformGroup });
      // commit("setSubsetTransformValues", { axis: "x", type: "rotation" });
      // remove group from transform-selection. This was temporary and we can remove this
      // if we later can change subsettransformvalues wihtout transformseleciton
      // commit("setTransformSelection", null);
      scene.add(ifcTransformGroup);
    } else {
      scene.add(model);
    }
  },

  /**
   * Add lights to models once all models have finished loading.
   *
   * @param {{ getters: any; }} getters { getters }
   * @returns {void}
   */
  createModelLights({ getters }: { getters: any }): void {
    const scene: Scene = getters["getScene"];

    // set directional lights
    const directional = new DirectionalLight(0xffffff, 0.5);
    directional.position.set(10, 10, 10);
    directional.lookAt(0, 0, 0);

    // set ambient lights
    const ambient = new AmbientLight(0x555555);
    ambient.intensity = 2.5;

    // add new lights to scene
    scene.add(directional);
    scene.add(ambient);
  },

  /**
   * Sort models in left sidebar (a.k.a. "layer editor") using `localCompare`.
   *
   * Use local compare for return integer, see [MDN description](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#description) for details and warning on not using fixed integers
   *
   * @param {{ state: State; commit: Commit }} state { state, commit }
   * @returns {void}
   */
  sortModelsInLayerEditor({ state, commit }: { state: SidebarsState; commit: Commit }): void {
    const { layerData } = state;
    const models = Object.values(layerData.models.items) as LayerEditorModelData[];
    models.sort((a: any, b: any) => {
      const aTitle = a.title.toLowerCase();
      const bTitle = b.title.toLowerCase();
      return aTitle.localeCompare(bTitle);
    });

    const filteredItems: Record<string, LayerEditorModelData> = {};

    models.forEach((model: LayerEditorModelData) => {
      const {
        object: { uuid },
      } = model;
      filteredItems[uuid] = model;
    });
    commit("setLayerDataModels", filteredItems);
  },

  /**
   * We attach eventlisteners to be able to detect if model is hovered.
   *
   * DISABLED If we hover & click/mousedown on the model we open transformtools & ifclayersidebar DISABLED
   * @param payload : model that we will attach eventlisteners to.
   * For ifc this model is the ifcModel & for other formats its their group.
   */
  addModelToPotreeJSEventListener(
    { state }: { state: State; commit: Commit; dispatch: Dispatch },
    payload: { model: IfcModel }
  ) {
    const isIfc = Object.prototype.hasOwnProperty.call(payload.model, "modelID");
    if (!isIfc) {
      return;
    }

    // this is necessary to subscribe to potreejs-event, and it needs to be a defined fn if we want to later remove the listener
    const mouseOver = () => {
      // console.log("mouseOver");
    };

    // this should only be done once, not for every subset that is loaded.
    // If we ran this per subset we would iterate through all models mesh for every add of subset
    state?.viewer?.scene.scene.children.map((child: Group | IfcMesh) => {
      if (child.type === "Mesh" && child.modelID === payload.model.modelID) {
        child.addEventListener("mousemove", mouseOver);
      }
    });
  },

  /**
   * Loop transform values and apply if applicable to a `model` or `subset`.
   *
   * @param {*} _
   * @param {{ transform: ModelTransformInterface; subset?: Subset; model?: any }} payload
   * @returns {void}
   */
  transformValuesLoop(
    _: any,
    payload: { transform: ModelTransformInterface; subset?: Subset; model?: any }
  ): void {
    const { transform, subset, model } = payload;

    const tranformValues = (axisSet: XYZInterface) => {
      return Object.values(axisSet).some((axis) => Number(axis) !== 0);
    };

    Object.keys(transform).forEach((transformKey) => {
      const key = transformKey as ModelTransformKeys;
      const hasKeyValue = tranformValues(transform[key]);

      if (hasKeyValue) {
        let { x, y, z } = transform[key];

        // Values must be calculated to radian for rotation.
        if (key === ModelTransformKeys.ROTATION) {
          x = degreesToRadian(subset ? Number(x) + 90 : x);
          y = degreesToRadian(y);
          z = degreesToRadian(z);
        }

        const modelOrSubset = model ?? subset;
        modelOrSubset[key].set(Number(x), Number(y), Number(z));
      }
    });
  },

  /**
   * Edit model in inspector.
   *
   * @param {({ commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState })} { commit, dispatch, getters, state }
   * @param {string} id
   */
  editModel(
    {
      commit,
      dispatch,
      getters,
      state,
    }: { commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState },
    id: string
  ) {
    const {
      layerData: {
        [ViewerLayerDataCategoryKey.MODELS]: {
          items: { [id]: layerDataItem },
        },
      },
      rightSidebar,
    } = state;

    const { title, attachments } = layerDataItem;

    // We need to get the current point object before set it, so we can determine if it was previously a point of interest. If it was we need data from it to properly close it.
    const {
      id: previousPointObjectId,
      object: previousObject,
      type: previousPointObjectType,
    } = getters["getCurrentPointObject"] || {};

    if (
      previousPointObjectType &&
      previousPointObjectType === ViewerLayerDataCategoryKey.POINT_OF_INTEREST
    ) {
      const {
        annotation: { domElement },
      } = previousObject;

      dispatch("togglePointOfInterestLabel", {
        domElement,
        id: previousPointObjectId,
      });
    }

    commit("setSelectedAttachments", []);
    commit("setCurrentEditingModel", null);
    commit("setCurrentPointObject", null);

    commit("setModelInspector", {
      id,
      title,
      attachments,
      type: AttachmentObjectItemType.MODEL,
    });
    commit("setInspectorMode", ViewerInspectorMode.MODEL);
    commit("setCurrentInspector");

    attachments?.forEach((attachment) => {
      commit("addAttachment", {
        id,
        category: ViewerLayerDataCategoryKey.MODELS,
        attachment,
        sectionLineItem: AttachmentSectionLineItem.SECTION,
      });
      dispatch("sortAttachmentItems");
    });

    if (!rightSidebar) {
      commit("setRightSidebar", true);
    }

    // TODO: here is where transformcontrols is activated for all object that is not ifc.
    /*   commit("setTransformSelection", {
      building: "someBuilding",
    });
    commit("setRightSidebar", true); */
    //commit("setRightSideBarTransform", true); // activates transform-component that in turn activates transformcontrols
  },

  /**
   * Load individual FBX model and add to scene.
   *
   * @param {{ commit: Commit; getters: any; dispatch: Dispatch; state: State;  }} actions { commit, getters, dispatch, state }
   * @param {{ modelData: ModelData; loader: FBXLoader }} payload { modelData, loader }
   * @returns {Promise<void>}
   */
  async loadFBX(
    { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: State },
    payload: { modelData: ModelData; loader: FBXLoader }
  ): Promise<void> {
    const {
      modelData: { attachments, path, id, title, transform },
      loader,
    } = payload;
    const { loadingProgress } = state;
    const progress = {
      value: 0,
      spinner: false,
      id,
      title,
      type: "model",
    };
    commit("setLoadingProgressItem", { id, progress });

    const modelBlob = await getBlobWithProgress(path, loadingProgress[id]);
    loader.load(modelBlob, (model: Record<string, any>) => {
      dispatch("addModelToScene", {
        model,
        transform,
      });

      // add model to layer editor (a.k.a. left sidebar)
      const data = {
        id,
        type: "fbx",
        title,
        object: model,
        attachments,
      };

      commit("setLayerDataModelsItems", { id, data });

      if (Object.keys(loadingProgress).length === 0 && loadingProgress.constructor === Object) {
        dispatch("sortModelsInLayerEditor");
      }
      commit("deleteLoadingProgressItem", id);
      commit("Storage/setFetchedModels", { id, data }, { root: true });
    });
  },

  /**
   * Load individual IFC model and add to scene.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; state: State; }} actions { commit, dispatch, state }
   * @param {{ modelData: ModelData; loader: IFCLoader }} payload { modelData, loader }
   * @returns {Promise<void>}
   */
  async loadIFC(
    { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: State },
    payload: { modelData: ModelData; loader: IFCLoader }
  ): Promise<void> {
    const {
      modelData: { attachments, path, id, title, transform },
      loader,
    } = payload;
    const { loadingProgress } = state;

    // Get model blob and limit progress value to 50, since getting model file
    // is only a part of the total loading process.
    const modelBlob = await getBlobWithProgress(path, loadingProgress[id], 50);

    /**
     * Adds 20 to model loading progress value when done.
     *
     * @param {ProgressEvent<EventTarget>} event
     * @returns {void}
     */
    const modelProgress = (event: ProgressEvent<EventTarget>): void => {
      const { loaded, total } = event;
      const progress = Math.round(loaded / total);
      if (loadingProgress[id] && loadingProgress[id].value) {
        loadingProgress[id].value = loadingProgress[id].value + progress;
      }
    };
    // TODO: alternative way to exclude IFC-spaces
    /* loader.ifcManager.parser.setupOptionalCategories({
      [IFCSPACE]: false,
    }); */
    const model: IFCModel = await loader.loadAsync(modelBlob, modelProgress);

    // make typescript happy
    const materials = model.material as Material[];

    // we want to change all materials of the provided ifc-file to edgebaseMaterial
    // because we need the polygonoffset to not get a aliased wireframe-look
    const newMaterialArray = materials.map((m: Material, index) => {
      return new MeshBasicMaterial({
        color: (m as MeshLambertMaterial).color,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
        opacity: m.opacity,
        transparent: m.transparent,
        name:
          m.name.length > 0
            ? m.name
            : index === 0
            ? "Generic White Material"
            : "Material " + index.toString(),
      });
    });
    model.material = newMaterialArray;

    await dispatch("findMaterialNames", { model });

    const modelId = model.modelID;

    loadingProgress[id].value = loadingProgress[id].value + 15;

    await dispatch("destructureIfcModelOnLoad", {
      id,
      title,
      modelId,
      transform,
      partialModel: model,
    });

    dispatch("addModelToScene", {
      model,
      transform,
      sceneRotation: true,
    });

    model.removeFromParent();

    // Add model to layer editor (a.k.a. left sidebar)
    const data = {
      id,
      type: "ifc",
      title,
      object: model,
      attachments,
    };

    if (Object.keys(loadingProgress).length === 0 && loadingProgress.constructor === Object) {
      dispatch("sortModelsInLayerEditor");
    }

    await Promise.all([modelBlob, model]);
    commit("deleteLoadingProgressItem", id);
    commit("Storage/setFetchedModels", { id, data }, { root: true });
    commit("setLayerDataModelsItems", { id, data });
  },

  /**
   * All IFC model subsets must be corrected to +90 degrees on the X axis.
   * This should only happen once in a models life cycle, i.e. should not repeat if value is already set.
   *
   * @param {{ dispatch: Dispatch }} { dispatch }
   * @param {{ subset: Subset; transform: ModelTransformInterface }} payload
   * @returns {void}
   */
  ifcSubsetRotation(
    { dispatch }: { dispatch: Dispatch },
    payload: { subset: Subset; transform: ModelTransformInterface }
  ): void {
    const { subset, transform } = payload;
    if (subset.rotation.x === 0) {
      subset.rotateX(degreesToRadian(90));
    }
    dispatch("transformValuesLoop", { subset, transform });
  },

  /**
   * Reusable IFC model destructure. Used for both initial loading of IFC model and when setting pre-fetched IFC model on scene.
   *  -Using IFCjs/LoadAllGeometry we get all mesh-ids(expressIDs)
   *  -Using IFCjs/createSubset, we create subset with customID full-model so that we can get full model from IFCjs     without having to
   *    use IFCjs/LoadAllGeometry every time we wan't to display full model again, when coming from isolate or hidden mode etc.
   *  We also create subsets for, storeys,group of items & items. Items-subset is special in that it also has ids for the removed element data.
   *    -storeySubset storeyId
   *    -groupSubset  groupId
   *    -groupItemSubset itemId
   *  -We change & prune the ifc-datastructure we get using ifcSpatialStructure(see below).
   *    Holding all model data is too heavy,this is our optimization/adaption.
   *
   * <IFCPROJECT>
   *     <IFCSITE>
   *       <IFCBUILDING>
   *         <IFCBUILDINGSTOREY>
   *           <IFCBUILDINGELEMENTS>  <-- we cut the hierarchy here
   *             <IFCBUILDINGELEMENTS> <-- this is grouped/flattened & filtered from ifcSpaces, subelements of the first layer of elements
   *               <IFCBUILDINGELEMENTS> <-- this is removed/pruned, subelements of the first layer of elements. We only store their elements Id
   * Atm we are providing partialModel as model to be able to add materials to ifcModel. We should be able to use modelId from this   and skip sending modelId as param, but when we do we get an error on some files with
   * ifcApi
   *
   * @async
   * @param {{ state: State; commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any; }} { state, commit, dispatch, getters, rootGetters }
   * @param {{ id: string; modelId: number; isLoading?: boolean; }} payload
   * @returns {Promise<void>}
   */
  async destructureIfcModelOnLoad(
    {
      commit,
      dispatch,
      getters,
      state,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; getters: any; state: State; rootGetters: any },
    payload: {
      id: string;
      title: string;
      modelId: number;
      transform: ModelTransformInterface;
      isLoading?: boolean;
      partialModel: any;
    }
  ): Promise<void> {
    const { loadingProgress } = state;
    const { id, title, modelId, transform, isLoading = true, partialModel } = payload;

    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const scene: Scene = getters["getScene"];
    const wireMeshAssociations: WireMeshAssociations | any = {};

    const matrixArr = await ifcManager.ifcAPI.GetCoordinationMatrix(modelId);
    const matrix = new Matrix4().fromArray(matrixArr);
    ifcManager.setupCoordinationMatrix(matrix);

    // collect all geometry ids
    const ids: number[] = await dispatch("ifcGetAllIds", modelId);

    // create a subset of the full model using all ids(expressIds) retrieved in ifcGetAllIds
    const createModelSubset = await dispatch("ifcCreateModelSubset", { modelId, ids, transform });

    // get the whole ifc-hierarchy, even the elements that has no geometry
    const ifcSpatialStructure = await ifcManager.getSpatialStructure(modelId);

    // temporary selectors for IFC-hierarchy
    const ifcProject = ifcSpatialStructure.children[0];
    const ifcProjectBuilding = ifcProject.children[0];

    // removing ifcSpaces from the data hierarchy under ifcBuildingStorey-layer
    const ifcBuildingStoreys = await dispatch("ifcFilterSpaces", ifcProjectBuilding.children);

    // This will be used later in this action to set the auto IFC model tags. We can only do this after we have set the IFC model to the `ifcModels` state since we will be looking for the model in the state.
    const ifcStoreys: Pick<IFCStoreyInterface, "id" | "displayname">[] = [];

    // We need to collect all items in the model to be able to match global IDs for express IDs in tags. See dispatcher at the end of this action `Viewers/addViewerTagTitles`.
    const ifcItems: Record<string, string | number>[] = [];

    // For every storey (ifcBuildingStorey),
    //  -removes any ifcSpaces & groups all elements of the same type
    //    -storeyPromises                         all storeys
    //      <IFCStorey>:                          all groups in a storey
    //        <IFCStoreyGroupInterface>:          group of all items
    //          <IFCStoreyGroupItemsInterface>:   items
    const storeyPromises: Promise<IFCStoreyInterface>[] = ifcBuildingStoreys.map(
      async (storeyItem: IFCNode) => {
        const { expressID: storeyId, children: storeyChildren } = storeyItem;
        const displayname = await dispatch("ifcDisplayname", { modelId, id: storeyId });

        // removes any ifcSpaces & groups all elements of the same type in an array.So, one array-item per storey/level/floor
        const filteredGroups: IFCNode[] = await dispatch("ifcFilteredGroups", storeyChildren);

        const storeyIds: number[] = [storeyId];

        // For every group/type in the filtered storey
        // Ex of groupItem: IFCROOF: {expressID: 241, type: 'IFCROOF', children: (3) [{...},{...},{...}]}
        const groupPromises: Promise<IFCStoreyGroupInterface>[] = Object.values(filteredGroups).map(
          async (groupItem): Promise<IFCStoreyGroupInterface> => {
            const { expressID: groupId, children: groupChildren } = groupItem;
            const groupDisplayname = await dispatch("ifcDisplayname", { modelId, id: groupId });
            const groupIds: number[] = [groupId];

            // for every item in the group
            const groupItemPromises: Promise<IFCStoreyGroupItemsInterface>[] = groupChildren.map(
              async (item): Promise<IFCStoreyGroupItemsInterface> => {
                const { expressID: itemId, children: itemChildren } = item;

                // Extract GlobalId from item. This is used in `groupItems` and to match expressID with globalID in tags.
                const {
                  GlobalId: { value: globalId },
                } = await ifcManager.getItemProperties(modelId, itemId);

                // Add global ID to express ID object for tag translation.
                ifcItems.push({
                  expressId: itemId,
                  globalId,
                });

                const itemDisplayname = await dispatch("ifcDisplayname", { modelId, id: itemId });
                const groupItemIds: number[] = [];

                // collect _ALL_ ids of any children of item.
                // These ids is our only link/ref. to the pruned spatial-tree-hierarchy.
                const hasChildren = await dispatch("ifcNodeHasChildren", itemChildren);
                if (hasChildren) {
                  const getChildIds = await dispatch("ifcRecursiveChildren", item);
                  groupItemIds.push(...getChildIds);
                } else {
                  groupItemIds.push(itemId);
                }

                groupIds.push(...groupItemIds);

                const groupItems: IFCStoreyGroupItemsInterface = {
                  id: itemId,
                  ids: groupItemIds,
                  displayname: itemDisplayname,
                  globalId,
                };

                // We create a subset for later highlight-use.
                // The customID is used to make a collection of subsets to a kind of selection set.
                const groupItemSubset = ifcManager.createSubset({
                  modelID: modelId,
                  scene,
                  ids: groupItemIds,
                  removePrevious: false,
                  material,
                  customID: `${modelId}-${itemId}-highlight`,
                });
                groupItemSubset.visible = false;
                dispatch("ifcSubsetRotation", { subset: groupItemSubset, transform });

                return groupItems;
              }
            );

            const items = await Promise.all(groupItemPromises);

            commit("setWireframeMaterial", edgeMat);

            storeyIds.push(...groupIds);

            // Group of items
            const storeyGroup: IFCStoreyGroupInterface = {
              id: groupId,
              ids: groupIds,
              displayname: groupDisplayname,
              items,
            };

            // We create a groupSubset used for hightlighting of all items in the group AND the groupId.
            const groupSubset = ifcManager.createSubset({
              modelID: modelId,
              scene,
              ids: groupIds,
              removePrevious: false,
              material,
              customID: `${modelId}-${groupId}-highlight`,
            });
            groupSubset.visible = false;
            dispatch("ifcSubsetRotation", { subset: groupSubset, transform });

            //--------------------------------
            // Wireframe creation
            // -------------------------------
            // look for wireframe in fetched state/cached state
            let wireframe: undefined | Wireframe;
            const fetchedWireframeObject = await dispatch(
              "Storage/findFetchedWireframe",
              {
                id: groupId,
                modelContainerId: id,
              },
              { root: true }
            );
            // if we found a fetched wireframe-object we use it
            if (fetchedWireframeObject) {
              wireframe = fetchedWireframeObject.wireframe;

              await dispatch("transformValuesLoop", { subset: wireframe, transform });
              dispatch("ifcSubsetRotation", { subset: wireframe, transform });
            } else {
              // if no fetched wireframe, we create wireframe
              const edgesGeometry = new EdgesGeometry(groupSubset.geometry);
              wireframe = new LineSegments(edgesGeometry, edgeMat);
              await dispatch("transformValuesLoop", { subset: wireframe, transform });
              dispatch("ifcSubsetRotation", { subset: wireframe, transform });

              // save in fetched wireframe-state so we dont have to create again
              const fetchedWireframe: FetchedWireFrame = {
                id: groupId,
                modelContainerId: id,
                wireframe,
              };
              commit("Storage/setFetchedWireframe", fetchedWireframe, { root: true });
            }
            wireframe && scene.add(wireframe);

            // associate meshes(itemId) with the wireframe mesh
            if (typeof wireMeshAssociations[groupId.toString()] === "undefined") {
              wireMeshAssociations[groupId.toString()] = [storeyId];
              wireMeshAssociations[groupId.toString()].push(wireframe);
            } else {
              wireMeshAssociations[groupId.toString()].push(wireframe);
            }

            return storeyGroup;
          }
        );

        const groups = await Promise.all(groupPromises);

        const storey: IFCStoreyInterface = {
          id: storeyId,
          ids: storeyIds,
          displayname,
          groups,
        };

        // we create a subset for the storey to use for later highlighting. Using all ids for that storey/floor
        const storeySubset = ifcManager.createSubset({
          modelID: modelId,
          scene,
          ids: storeyIds,
          removePrevious: false,
          material,
          customID: `${modelId}-${storeyId}-highlight`,
        });
        storeySubset.visible = false;
        dispatch("ifcSubsetRotation", { subset: storeySubset, transform });

        // Set storey ID and displayname for later when we assign auto generated IFC model tags.
        ifcStoreys.push({ id: storeyId, displayname });

        return storey;
      }
    );

    const storeys = await Promise.all(storeyPromises);

    // setting up ifcModel to hold in state.
    const modelItem: IFCModelItemInterface = {
      modelContainerId: id,
      modelId,
      ids,
      storeys,
      hidden: [],
      isolated: [],
      highlighted: undefined,
      attachments: undefined,
      transform,
      wireMeshAssociations,
      groupsWithWireMeshItemsHidden: [],
      materials: partialModel.material,
    };

    // If the model has already been loaded, we don't need to reset it, to make sure that any changes made in the previous viewer are reset.
    if (!isLoading) {
      await dispatch("ifcResetModel", modelItem);
    }

    commit("setIfcModels", modelItem);

    await Promise.all([ids, createModelSubset]);

    if (isLoading) {
      // Only add auto generated tags if the model is loading. If the model is not loading, it means the model is being reloaded and the tags already exist.

      ifcStoreys.forEach((storey) => {
        const { id: storeyId, displayname } = storey;

        // Add auto generated IFC model tag to the IFC model in the store `ifcModels`.
        commit("addIFCModelAutoTag", {
          modelContainerId: id,
          tag: {
            expressId: storeyId,
            id: `${id}#${storeyId}`,
            isAuto: true,
            modelId,
            title: `${title} - ${displayname}`,
          },
        });
      });

      // Once everything is loaded, we can start looking for tags on the model items.
      if (ifcItems.length > 0) {
        dispatch(
          "Viewers/addViewerTagTitles",
          { id, ifcItems, type: ViewerInspectorMode.IFC_MODEL_ITEM },
          { root: true }
        );
      }

      loadingProgress[id].value = 99;
    }
  },

  /**
   * Creates a subset consisting of all express ids in model.
   * Subset is using customID: "full-model" and this is what is used to later retrieve/use this subset.
   * transform variable is not used here, but in later dispatch to adjust subset transform.
   *
   * @param {{ dispatch: Dispatch, getters: any, rootGetters: any }} { dispatch, getters, rootGetters }
   * @param {{ modelId: number, ids: number[], transform: ModelTransformInterface }} payload modelId, ids.
   * @returns { Promise<void> }
   */
  async ifcCreateModelSubset(
    { dispatch, getters, rootGetters }: { dispatch: Dispatch; getters: any; rootGetters: any },
    {
      modelId,
      ids,
      transform,
    }: { modelId: number; ids: number[]; transform: ModelTransformInterface }
  ): Promise<void> {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const scene: Scene = getters["getScene"];

    const config: SubsetConfig = {
      modelID: modelId,
      ids,
      scene,
      removePrevious: true,
      customID: "full-model",
      applyBVH: true,
    };
    const subset = ifcManager.createSubset(config);

    dispatch("ifcSubsetRotation", { subset, transform });
  },

  /**
   * Get all ids from model.
   *
   * @async
   * @param {{ rootGetters: any; }} { rootGetters }
   * @param {number} modelId
   * @returns {Promise<number[]>}
   */
  async ifcGetAllIds({ rootGetters }: { rootGetters: any }, modelId: number): Promise<number[]> {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const loadModelGeometry = await ifcManager.ifcAPI.LoadAllGeometry(modelId);
    const modelGeometry = loadModelGeometry as unknown as ModelGeometry;
    const geometryData = modelGeometry._data;
    const ids = Array.from(Object.values(geometryData), (item: FlatMesh) => item.expressID);
    return ids;
  },

  /**
   * Check if children has items.
   *
   * @param {*} _ State is not used.
   * @param {IFCNode[]} children Child items of current item.
   * @returns {boolean} Truthy falsy
   */
  ifcNodeHasChildren(_: any, children: IFCNode[]): boolean {
    return children.length !== 0;
  },

  /**
   * Infinite recursive child search.
   *
   * @async
   * @param {{ dispatch: Dispatch }} { dispatch }
   * @param {IFCNode} item IFC object with at least one (1) children array object.
   * @returns {Promise<number[]>} Array of express IDs.
   */
  async ifcRecursiveChildren(
    { dispatch }: { dispatch: Dispatch },
    item: IFCNode
  ): Promise<number[]> {
    const ids: Promise<number>[] = item.children.map(async (child: IFCNode) => {
      const { children: childChildren, expressID: childExpressID } = child;

      const doRecursive: boolean = await dispatch("ifcNodeHasChildren", childChildren);
      if (doRecursive) {
        return await dispatch("ifcRecursiveChildren", child);
      }
      return childExpressID;
    });

    const resolvedIds = await Promise.all(ids);
    return resolvedIds.flat();
  },

  /**
   * Desctructure IFC model for initial item toggle.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: ModelsState & SidebarsState & TransformState; }} { commit, dispatch, state }
   * @param {string} id Layer editor model id.
   */
  async ifcModelDestructure(
    {
      dispatch,
      state,
      commit,
    }: { commit: Commit; dispatch: Dispatch; state: ModelsState & SidebarsState & TransformState },
    id: string
  ) {
    const {
      layerData: {
        models: {
          items: {
            [id]: { object, title },
          },
        },
      },
    } = state;
    const modelId = object.modelID;
    const building = state.ifcModels.find((modelItem) => modelItem.modelId === modelId);

    if (building) {
      commit("setSelectedAttachments", []);
      commit("setCurrentEditingModel", null);
      commit("setCurrentPointObject", null);
      commit("setInspectorMode", null);

      commit("setIfcCurrentModelId", modelId);
      commit("setIfcBuilding", { title, building });
      commit("setLeftSidebar", false);
      commit("setLeftSidebarIfc", true);
      dispatch("showLayerEditor");
      // commit("setRightSideBarTransform", true);
      // commit("setTransformSelection", { building });
      commit("setIfcCurrentModelIdInLeftSideBar", modelId);
      dispatch("prepareIfcForTransform", building);
    }
  },
  /**
   * For transformcontrols to work with ifc we have placed the
   * IFCModelItemInterface in a threejs-group.
   * Here we get this group and sets it as the transformSelection
   * NOTE: only use it has atm is to enable ifcSubsetRaycast
   *
   * @param building IFCModelItemInterface
   */
  prepareIfcForTransform(
    { getters, commit, dispatch }: { getters: any; commit: Commit; dispatch: Dispatch },
    building
  ) {
    // using modelId we can find the ifcTransformGroup we should add to transformSelection
    const scene: Scene = getters["getScene"];

    // TODO: refactor to a getter
    const ifcTransformGroup = scene.children.find((child) => {
      // filter out all children that is ifc-mesh
      //  const isIfc = Object.prototype.hasOwnProperty.call(child, "modelID");
      return child.type === "Group" && child.name === building.modelId;
    });

    //commit("setTransformSelection", { building: ifcTransformGroup });
    // commit("setRightSidebar", true);
    dispatch("ifcSubsetRaycast");
    // TODO: here we activate transformcontrols for ifc
    // commit("setRightSideBarTransform", true); // activates transform-component that in turn activates transformcontrols
  },

  /**
   * Filter out objects with type `IFCSPACE` from array of children. These objects provide nothing useful as they are just empty objects.
   *
   * @param {*} _ State is not used.
   * @param {IFCNode[]} items Array of child items.
   * @returns {Promise<any[]>}
   */
  async ifcFilterSpaces(_: any, items: IFCNode[]): Promise<IFCNode[]> {
    const filtered = items.filter((child: IFCNode) => child.type !== "IFCSPACE");
    return filtered;
  },

  /**
   * Group all objects with equal `type` string.
   * for example: items input is [ {children:[],expressID:12563,type:"IFCROOF"}, {...}, {...} ...]
   * output group is then:   {IFCROOF: {children:[...] ...} ...}
   *
   * @param {*} _  State is not used.
   * @param {IFCNode[]} items Array of child items.
   * @returns {Promise<{[key: string]: IFCNode}>}
   */
  async ifcGroups(_: any, items: IFCNode[]): Promise<{ [key: string]: IFCNode }> {
    // Groups container object.
    const groups: { [key: string]: IFCNode } = {};

    items.forEach((item: IFCNode) => {
      const { type } = item;

      const inGroups: boolean = Object.keys(groups).includes(type);

      if (!inGroups) {
        groups[type] = {
          ...item,
          children: [],
        };
      }

      groups[type].children.push(item);
    });

    return groups;
  },

  /**
   * Combined filter and group function.
   * filtered removes any ifcSpaces-elements from the items
   * @param {{ dispatch: Dispatch; }} { dispatch }
   * @param {IFCNode[]} items Array of child items.
   * @returns {Promise<{[key: string]: IFCNode}>}
   */
  async ifcFilteredGroups(
    { dispatch }: { dispatch: Dispatch },
    items: IFCNode[]
  ): Promise<{ [key: string]: IFCNode }> {
    const filtered: { [key: string]: IFCNode } = await dispatch("ifcFilterSpaces", items);
    const grouped: { [key: string]: IFCNode } = await dispatch("ifcGroups", filtered);
    return grouped;
  },

  /**
   * Gets IFC geometry long name if available else uses value of name.
   *
   * @param {{ getters: any; }} getters { getters }
   * @param {{ modelId: number; id: number; }} { modelId, id }
   * @returns {Promise<IFCItemDisplaynameInterface[]>} Array of id and displayname objects.
   */
  async ifcDisplayname(
    { rootGetters }: { rootGetters: any },
    { modelId, id }: { modelId: number; id: number }
  ): Promise<string> {
    try {
      const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
      const itemProps = await ifcManager.getItemProperties(modelId, id);
      // Strip out IFC type from name value.
      const regex = /(.*?):.*/;
      // Ternary for long name (preferred) or name value
      const nameValue: string = itemProps.LongName
        ? itemProps.LongName.value
        : itemProps.Name?.value
        ? itemProps.Name.value.replace(regex, "$1")
        : undefined;
      const displayname = nameValue ? convertUnicode(nameValue) : "missing value for name";
      return displayname;
    } catch (error) {
      throw new Error(
        `Could not get displayname for ID ${id} in model ${modelId}. Error message: ${error}`
      );
    }
  },

  /**
   * IFC model interaction switch case dispatcher.
   *
   * @param {{ dispatch: Dispatch }} { dispatch } IFC model item to show/hide.
   * @param {{ action: string; payload: {id: number, ids: number[]} }} payload Switch action and payload items.
   * @returns {void}
   */
  ifcInteraction(
    { dispatch }: { dispatch: Dispatch },
    { action, payload: { id, ids } }: { action: string; payload: { id: number; ids: number[] } }
  ): void {
    const payload = { id, ids };
    switch (action) {
      case "click":
        dispatch("ifcToggleClick", payload);
        break;
      case "visibility":
        dispatch("ifcToggleVisible", payload);
        break;
      case "isolation":
        dispatch("ifcToggleIsolate", payload);
        break;

      default:
        throw new Error("Action does not exist on storey.");
    }
  },

  /**
   * Click selected IFC model item.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; state: ModelsState & SidebarsState; rootGetters: any; }} { commit, dispatch, getters, state, rootGetters } Vuex state, getters, rootGetters, commit and dispatch.
   * @param { number } id Node id to select.
   * @returns { Promise<void> }
   */
  async ifcToggleClick(
    {
      state,
      getters,
      rootGetters,
      commit,
      dispatch,
    }: {
      commit: Commit;
      dispatch: Dispatch;
      getters: any;
      state: ModelsState & SidebarsState;
      rootGetters: any;
    },
    { item, modelId }: { item: IFCStoreyGroupItemsInterface; modelId: number }
  ): // id: number
  Promise<void> {
    // const { attachments: modelAttachments }: IFCModelItemInterface = getters["getIfcCurrentModel"];
    const model = state.ifcModels.find((ifcModel) => ifcModel.modelId === modelId);

    const { attachments: modelAttachments } = model as IFCModelItemInterface;
    // Validation that there was model found, if we somehow trigger toggleclick before model is set to state.
    if (!model) {
      return;
    }
    const { id, ids } = item;

    //const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    // get current selection
    // we need to use currentSelection-state to check if any item on the model is selected.
    const previousSelectedModel: IFCModelItemInterface = getters["getCurrentSelection"];

    if (previousSelectedModel) {
      // remove subset with selection-id on all ifc-models.
      // TODO: this solves a bug when clicking between different ifcmodels & ui.
      // Sometimes you otherwise end up with selection highlights on multiple models.
      // best would be to correctly identify all specific models that has a highlight.
      // this could be done with getSubset with trycatch,as getSubset crashes if it can't find a subset.
      dispatch("ifcClearSelection");
    }

    // add the new subset selection & set currentModel & currentSelection
    dispatch("ifcAddSelection", { itemChildrenIds: ids, model: model, itemId: id });

    //  let { tags: modelTags } = model;

    const { rightSidebar } = state;
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const itemData = await ifcManager.getItemProperties(modelId, id);

    const ifcType = ifcManager.getIfcType(modelId, id);
    const itemPropertySets = await ifcManager.getPropertySets(modelId, id);

    const { Description, GlobalId, ObjectType, PredefinedType, Tag } = itemData;

    const description = Description ?? "";
    const globalId = GlobalId?.value ?? "";
    const objectType = ObjectType?.value ?? "";
    const predefinedType = PredefinedType?.value ?? "";
    const itemTag = Tag?.value ?? "";

    let tableItemId;

    const attachments = modelAttachments
      ?.map((modelAttachment) => {
        const {
          tableItemId: modelAttachmentTableItemId,
          globalId: modelAttachmentGlobalId,
          attachments,
        } = modelAttachment;

        if (modelAttachmentGlobalId === globalId) {
          tableItemId = modelAttachmentTableItemId;

          return attachments.map((attachment) => ({
            ...attachment,
            globalId,
            tableItemId: modelAttachmentTableItemId,
          }));
        }

        return undefined;
      })
      // Remove undefined items from resulting array.
      .filter((item) => item)
      .flat();

    const setName = await dispatch("ifcDisplayname", { modelId, id });

    const propertySetsMap =
      itemPropertySets && itemPropertySets.length
        ? itemPropertySets.map(async (propertySet) => {
            const {
              Description: description,
              GlobalId: { value: globalId },
              expressID,
              HasProperties: hasProperties,
            } = propertySet;

            const propertySetName = await dispatch("ifcDisplayname", { modelId, id: expressID });

            const propertyPromises = hasProperties.map(async (property: Record<string, number>) => {
              const setItemProperty = await ifcManager.getItemProperties(modelId, property.value);
              const {
                Name: { value: propertyName },
                NominalValue: { value: nominalValue },
              } = setItemProperty;
              let parsedValue;
              switch (nominalValue) {
                case "T":
                  parsedValue = true;
                  break;
                case "F":
                  parsedValue = false;
                  break;

                default:
                  parsedValue =
                    typeof nominalValue === "string" ? convertUnicode(nominalValue) : nominalValue;
                  break;
              }
              return {
                name: propertyName,
                value: parsedValue,
              };
            });

            const properties = await Promise.all(propertyPromises);

            return {
              name: propertySetName,
              description,
              globalId,
              id: expressID,
              properties,
            };
          })
        : [];

    const propertySets = await Promise.all(propertySetsMap);

    // Clear measure inspector and any existing data and attachments.
    commit("setSelectedAttachments", []);
    commit("setMeasureInspector", null);
    commit("setPointcloudInspector", null);
    commit("setModelInspector", null);
    commit("setCurrentPointObject", null);
    commit("setCurrentEditingModel", null);

    // Set model inspector.
    commit("setIFCModelItemInspector", {
      attachments,
      description,
      expressId: id,
      globalId,
      itemTag,
      modelId,
      name: setName,
      objectType,
      predefinedType,
      propertySets,
      tableItemId,
      type: ViewerInspectorMode.IFC_MODEL_ITEM,
      ifcType,
    });
    commit("setInspectorMode", ViewerInspectorMode.IFC_MODEL_ITEM);
    commit("setCurrentInspector");

    commit("setSelectedAttachments", attachments?.map((attachment) => attachment?.type) ?? []);
    await dispatch("sortAttachmentItems");

    if (!rightSidebar) {
      commit("setRightSidebar", true);
    }
  },

  /**
   * Isolate (i.e. hide all other) selected IFC geometry including its children.
   *
   * @param {{ commit: Commit; dispatch: Dispatch, getters: any; }} { commit, dispatch, getters }
   * @param {{ id: number; ids: number[]; }} payload Node item and id array to show/hide.
   * @returns {Promise<void>}
   */
  async ifcToggleIsolate(
    { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: { id: number; multiple?: boolean }
  ): Promise<void> {
    const { id, multiple } = payload;

    const model: IFCModelItemInterface = getters["getIfcCurrentModel"];
    const { modelId, ids: modelIds, transform } = model;
    const isolated: number[] = getters["getIfcIsolated"] ?? [];
    const isIsolated: boolean = isolated.includes(id);

    const ids = await dispatch("getExpressIdSubsetIds", { id });

    // We can't continue without ids.
    if (!ids) {
      return;
    }

    // If multiple is true, add the new set of ids to the existing isolated ids.
    const isolatedIds = multiple ? [...isolated, ...ids] : ids;

    // const isolatedIds = idsFromTags ? idsFromTags : ids;

    const hideIds = modelIds.filter((id) => !isolatedIds.includes(id));
    const isStoreyId = model.storeys.find((storey) => storey.id === id);

    const storeyIdList = model.storeys.map((storey) => storey.id);

    // storeyId is first in isolated array. Group should be isolated as we are isolating the storey with it in it.
    const isGroupOfLastStoreyIsolation =
      !isStoreyId && isolated.includes(id) && storeyIdList.includes(isolated[0]);

    // if there is isolated parts in model, but we didn't click the group inside the last clicked storey OR we
    // haven't clicked on items. Then we wan't to remove isolation and show all items.
    // Otherwise we wan't to isolate the clicked item.

    if (
      isIsolated &&
      !isGroupOfLastStoreyIsolation &&
      model.groupsWithWireMeshItemsHidden.length <= 0
    ) {
      commit("removeIfcIsolated", isolatedIds);
      commit("removeIfcHidden", hideIds);

      dispatch("wireframeHandler", { modelId: model.modelId, action: WireframeAction.SHOW });
      await dispatch("ifcCreateModelSubset", { modelId, ids: modelIds, transform }); // why do we need to await?
    } else {
      commit("addIfcIsolated", isolatedIds);

      dispatch("wireframeHandler", { modelId: model.modelId, action: WireframeAction.ISOLATE, id });
      commit("addIfcHidden", hideIds);
      await dispatch("ifcCreateModelSubset", { modelId, ids: isolatedIds, transform }); // why await,is something else reliant on this after here?
    }
  },

  /**
   * Handles isolation of tags. The difference when isolating tags is that we get multiple ids
   * from the tags to isolate. This is because tags can be storeys,groups OR items, all depending
   * on what the user has tagged in the model.
   * @param modelContainerId
   * @param isolatedIds
   * @returns void
   */
  async ifcToggleIsolateTags(
    {
      commit,
      dispatch,
      getters,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any },
    payload: { modelContainerId: string; ids?: number[] }
  ): Promise<void> {
    const { modelContainerId, ids: isolatedIds } = payload;

    const models: IFCModelItemInterface[] = getters["getIFCModels"];

    const model: IFCModelItemInterface | undefined = models.find((model) => {
      return model.modelContainerId === modelContainerId;
    });

    if (!model) {
      return;
    }
    const { modelId, ids: modelIds, transform } = model;
    commit("setIfcCurrentModelId", modelId);
    const isolated: number[] = getters["getIfcIsolated"] ?? [];
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const scene: Scene = getters["getScene"];
    const hideIds = modelIds.filter((id) => !isolatedIds?.includes(id));
    if (isolatedIds) {
      commit("addIfcIsolated", isolatedIds);
      commit("addIfcHidden", hideIds);

      const config: SubsetConfig = {
        modelID: modelId,
        ids: isolatedIds,
        scene,
        removePrevious: true,
        customID: "full-model",
        applyBVH: true,
      };
      const subset = ifcManager.createSubset(config);
      dispatch("ifcSubsetRotation", { subset, transform });

      dispatch("wireframeHandler", {
        modelId: model.modelId,
        action: WireframeAction.ISOLATE_TAGS,
        subset,
        ids: isolatedIds,
      });
    } else {
      commit("removeIfcHidden", hideIds);
      commit("removeIfcIsolated", isolated);
      commit("removeIfcHidden", hideIds);
      await dispatch("ifcCreateModelSubset", { modelId, ids: modelIds, transform });

      dispatch("wireframeHandler", { modelId, action: WireframeAction.RESET });
    }
  },

  /**
   * Toggle display/hide IFC geometries function.
   *
   * @param {{ commit: Commit; getters: any; dispatch: Dispatch,state:State }} Actions { commit, getters, dispatch }
   * @param {{ id: number, ids: number[] }} payload IFC model item to show/hide.
   * @returns {void}
   */
  async ifcToggleVisible(
    {
      commit,
      dispatch,
      getters,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any },
    payload: { id: number; ids: number[] }
  ): Promise<void> {
    const { id, ids } = payload;
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    /* const {
      modelId,
      ids: modelIds,
      transform,
    }: IFCModelItemInterface = getters["getIfcCurrentModel"]; */

    const model: IFCModelItemInterface = getters["getIfcCurrentModel"];
    const scene: Scene = getters["getScene"];
    const { modelId, ids: modelIds, transform } = model;

    const sideBarModelId: number = getters["getIfcCurrentModelIdInLeftSideBar"];
    // if there is a selection, clear selection if this item we want to togglevisible is the selection or if another model
    // is selected, clear that model selection
    const ifcSelection: currentSelection = getters["getCurrentSelection"];
    if (ifcSelection) {
      const { itemId } = ifcSelection;
      // if there is another model selected than what we click in the ui-sidebar, this check makes sure we clear the selection
      modelId !== sideBarModelId && dispatch("ifcClearSelection");

      // clear selection if we try to hide the same itemId as we have selected
      id === itemId && dispatch("ifcClearSelection");
    }

    const hidden: number[] = getters["getIfcHidden"];
    const isHidden: boolean = hidden.includes(id);
    const isItem: boolean = ids.length === 1;
    // const ids = await dispatch("getExpressIdSubsetIds", id);

    // We can't continue without ids.
    if (!ids) {
      return;
    }

    if (isHidden) {
      commit("removeIfcHidden", ids);
      const subsetIds = modelIds.filter((id) => !getters["getIfcHidden"].includes(id));
      // handle wireframe
      // if isItem is true, we have clicked a single item & this needs different handling of wireframe
      // if isItem, we create a subset of the clicked item AND the other visible items & use this subset to create wireframe.
      // This logic needs to be here because we need to create the subset here.
      if (isItem) {
        const config: SubsetConfig = {
          modelID: modelId,
          ids: subsetIds,
          scene,
          removePrevious: true,
          customID: "full-model",
          applyBVH: true,
        };
        const subset = ifcManager.createSubset(config);
        dispatch("ifcSubsetRotation", { subset, transform });

        await dispatch("wireframeHandler", {
          modelId,
          action: WireframeAction.SHOW_ITEM,
          id,
          ids: subsetIds,
          subset,
        });
      } else {
        // groupItem or storeyItem clicked
        await dispatch("ifcCreateModelSubset", { modelId, ids: subsetIds, transform });

        // wireframe && dispatch("showWireframe", { model, id }); TODO: remove or enable?
        await dispatch("wireframeHandler", { modelId, action: WireframeAction.SHOW, id });
      }
    } else {
      const hiddenIds = [...hidden, ...ids, id];
      commit("addIfcHidden", hiddenIds);
      const subset = ifcManager.removeFromSubset(modelId, hiddenIds, "full-model");

      // Handle wireframe. One way if item was clicked, another if groupItem or storeyItem was clicked
      // Wireframes is created on groupItem-subsets. If we wan't to hide individual items we need to use the subset
      // to create new wireframe. This is done in wireframeHandler/updateWireframe later in
      // process using the provided subset from here.
      if (isItem) {
        dispatch("wireframeHandler", {
          modelId,
          action: WireframeAction.HIDE_ITEM,
          id,
          ids,
          subset,
        });
      } else {
        dispatch("wireframeHandler", { modelId, action: WireframeAction.HIDE, id });
      }
    }
  },

  /**
   * Highligt IFC geometry including children, from sidebar.
   *
   * @param {{ commit: Commit; getters: any; }} Actions { commit, getters }
   * @param {{ id: number }} id IFC model item to highlight. Can be ItemId,groupId or storeyId
   * @param {{newModel: IFCModelItemInterface }}
   * modelId is needed to determine from what model we invoked this method, otherwise
   * we may highlight wrong model & not return the old highlight correctly
   * @returns {void}
   */
  async ifcToggleHighlightFromSidebar(
    {
      state,
      commit,
      getters,
      rootGetters,
    }: { state: ModelsState & SidebarsState; commit: Commit; getters: any; rootGetters: any },
    payload: { id: number; modelId: number }
  ): Promise<void> {
    const { id } = payload;
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const prevToggleModel: IFCModelItemInterface = getters["getIfcHoveredModel"];
    const modelId: number | null = getters["getIfcCurrentModelIdInLeftSideBar"];

    if (modelId === null) {
      return;
    }

    // Is it another ifc model then last ?
    if (
      // first model to get hovered?
      prevToggleModel &&
      // first model to get highligthed?
      prevToggleModel.highlighted !== undefined &&
      // another model hovered than previous model
      modelId !== prevToggleModel.modelId
    ) {
      // remove previuos hover highlight
      try {
        const subset = ifcManager.getSubset(
          prevToggleModel.modelId,
          material,
          `${prevToggleModel.modelId}-${prevToggleModel.highlighted}-highlight`
        );

        subset.visible = false;
      } catch (error) {
        console.error("Subset failed to remove previous highlight: ", error);
        return;
      }

      // remove from state
      commit("removeIfcHighlight", prevToggleModel.modelId);
    }

    // set current hovered to state
    commit("setIfcHoveredId", modelId);

    const model = state.ifcModels.find((m) => m.modelId === modelId);
    if (!model) throw Error(`No model with id: ${modelId}`);
    const { highlighted } = model;

    const isHighlight: boolean = highlighted === id;

    try {
      const subset = ifcManager.getSubset(modelId, material, `${modelId}-${id}-highlight`);
      if (isHighlight) {
        commit("removeIfcHighlight", modelId);
        subset.visible = false;
      } else {
        commit("addIfcHighlight", id);
        subset.visible = true;
      }
    } catch (error) {
      console.error("subset failed in ifcToggleHighlightFromSidebar: ", payload);
    }
  },

  /**
   * Highligt IFC geometry including children.
   *
   * @param {{ commit: Commit; getters: any; }} Actions { commit, getters }
   * @param {{ id: number }} payload IFC model item to highlight.
   * @returns {void}
   */
  async ifcToggleHighlight(
    { commit, getters, rootGetters }: { commit: Commit; getters: any; rootGetters: any },
    id: number
  ): Promise<void> {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const { modelId }: IFCModelItemInterface = getters["getIfcCurrentModel"];

    // We can't continue without a modelId.
    if (typeof modelId === "undefined") {
      return;
    }

    const highlighted: number = getters["getIfcHighlight"];
    const isHighlight: boolean = highlighted === id;
    const subset = ifcManager.getSubset(modelId, material, `${modelId}-${id}-highlight`);

    if (isHighlight) {
      commit("removeIfcHighlight", modelId);
      subset.visible = false;
    } else {
      commit("addIfcHighlight", id);
      subset.visible = true;
    }
  },

  /**
   * Get the subset ids for a given expressId.
   *
   * @param {{ getters: any; rootGetters: any; }} { getters, rootGetters }
   * @param {number} id
   * @returns {(number[] | void)}
   */
  getExpressIdSubsetIds(
    { getters, rootGetters }: { getters: any; rootGetters: any },
    { id, modelContainerId }: { id: number; modelContainerId?: string }
  ): number[] | void {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const { modelId: mID }: IFCModelItemInterface = getters["getIfcCurrentModel"];
    const ifcModels: IFCModelItemInterface[] = getters["getIFCModels"];

    const modelIdFromPayload =
      modelContainerId && ifcModels.find((model) => model.modelContainerId === modelContainerId);
    const modelId = modelIdFromPayload ? modelIdFromPayload.modelId : mID;

    // Get subset manager from IFC manager.
    const { subsets } = ifcManager;

    // Get all subsets.
    const allSubsets = subsets.getAllSubsets();

    // Look for subset that matches the modelId, and highlight id (expressID of top item).
    const matchingEntry = Object.entries(allSubsets).find(([key]) => {
      return key.includes(`${modelId} -`) && key.includes(`-${id}-highlight`);
    });

    // If no matching subset, we can't toggle visibility.
    if (!matchingEntry) {
      return;
    }

    // Extract ids from subset.
    const { ids: matchIds } = matchingEntry[1];

    // Return an array of the subset ids and the expressId.
    const ids = [...matchIds, id];
    return ids;
  },

  /**
   * Revert hidden/isolated IFC model settings to default, i.e. show entire model.
   *
   * @param {{ dispatch: Dispatch; rootGetters: any; state: ModelsState & SidebarsState; }} { dispatch, rootGetters, state }
   * @param {IFCModel} ifcModel
   */
  ifcResetModel(
    {
      state,
      dispatch,
      rootGetters,
      getters,
      commit,
    }: {
      state: ModelsState & SidebarsState;
      dispatch: Dispatch;
      rootGetters: any;
      getters: any;
      commit: Commit;
    },
    ifcModel: IFCModel
  ) {
    const ifcManager: undefined | IFCManager = rootGetters["Ifc/getIFCManager"];
    const { modelID: modelId } = ifcModel;
    const model = state.ifcModels.find((item: IFCModelItemInterface) => item.modelId === modelId);

    if (ifcManager && model) {
      const { ids, transform } = model;

      const updatedModelState: IFCModelItemInterface = {
        ...model,
        hidden: [],
        isolated: [],
        highlighted: undefined,
      };

      ifcManager.removeSubset(modelId, undefined, "full-model");
      dispatch("ifcCreateModelSubset", {
        modelId,
        ids,
        transform,
      });

      ifcModel.visible = true;

      // attach event listeners to new subset mesh
      dispatch("addModelToPotreeJSEventListener", { model: ifcModel });

      // remove any previous highlight
      const prevHoveredModel: IFCModelItemInterface = getters["getIfcHoveredModel"];

      if (prevHoveredModel && typeof prevHoveredModel.highlighted !== "undefined") {
        try {
          const subset = ifcManager.getSubset(
            model.modelId,
            material,
            `${prevHoveredModel.modelId}-${prevHoveredModel.highlighted}-highlight`
          );
          subset.visible = false;

          commit("removeIfcHighlight", prevHoveredModel.modelId);
        } catch (error) {
          console.error("subset failed in ifcResetModel: ", error);
        }
      }

      // reset wireframe
      dispatch("wireframeHandler", { modelId, action: WireframeAction.RESET });

      // update state
      const filteredModelItems = state.ifcModels.filter(
        (item: IFCModelItemInterface) => item.modelId !== modelId
      );
      state.ifcModels = [...filteredModelItems, updatedModelState];
    }
  },

  /**
   * Toggles the highlight for models in the viewer when hover an IFC model auto tag in the tag filter section of the left sidebar (a.k.a. layers).
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @param {TagItemInterface} tag
   */
  viewerIFCModelAutoTagHover(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    tag: TagItemInterface
  ) {
    const { expressId, id, modelId } = tag;
    const modelContainerId = id.split("#")[0];

    if (!expressId || !modelContainerId) {
      return;
    }

    commit("setIfcCurrentModelId", modelId);
    dispatch("ifcToggleHighlightFromSidebar", expressId);
  },
  /**
   * Add a selection on itemChildrenIds using IFCjs-subset-api.
   * Also sync currentModel & currentSelection
   * @param
   * @param payload
   * @returns
   */
  ifcAddSelection(
    { rootGetters, getters, dispatch, commit },
    payload: { model: IFCModelItemInterface; itemChildrenIds: number[]; itemId: number }
  ) {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const {
      model: { modelId, transform },
      itemChildrenIds,
      itemId,
    } = payload;
    if (modelId === undefined || transform === undefined || !itemChildrenIds || !itemId) {
      //console.log("missing input", modelId, transform, itemChildrenIds, itemId);
      return;
    }
    const subset = ifcManager.createSubset({
      modelID: modelId,
      ids: itemChildrenIds,
      material: selectedMaterial,
      scene: getters["getScene"],
      removePrevious: true,
      customID: "ifc-model-selected-items",
    });
    // adjust transform
    dispatch("ifcSubsetRotation", { subset, transform });
    // set setIfcCurrentModelId so we can keep track what model is selected
    // this may be redundant since we implemented selectionState below now.
    // ifcCurrentModel was earlier used to keep track of if we had any selection.
    // This can now be tracked with selectionState
    // However, setIfcCurrentModelId is also used in other places, therefore we are not ready
    // to remove it.
    commit("setIfcCurrentModelId", modelId);
    commit("setCurrentSelection", { modelId, itemChildrenIds, itemId });
  },
  ifcClearSelection({ state, rootGetters, commit }) {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    state.ifcModels.map((m) => {
      ifcManager.removeSubset(m.modelId, selectedMaterial, "ifc-model-selected-items");
    });
    commit("setCurrentSelection", null);
  },
  /**
   * Enables mouse movement-triggered raycaster from Potreejs.
   * On subset hover & click.
   */
  ifcSubsetRaycast({ commit, dispatch, state, getters, rootGetters }) {
    const viewer = getters["getViewer"];

    const ifc: IFCManager = rootGetters["Ifc/getIFCManager"];

    const pick = (event: MouseEvent) => {
      // detect if we are using orbit-controls by checking drag-event & mousemove
      // if we are using orbitcontrols, we abort pick
      if (viewer.inputHandler.drag && event.type === "mousemove") {
        return;
      }

      // Get raycast result from potreejs.
      const hoveredElement = viewer.inputHandler.getHoveredElement();

      if (hoveredElement) {
        // Validate element is ifcMesh.
        if (hoveredElement.object.type !== "Mesh" || hoveredElement.object.modelID === undefined) {
          return;
        }

        // Type is validated.
        const hoveredIfcMesh = hoveredElement.object as IfcMesh;
        // Destructure.
        const { modelID: modelId } = hoveredIfcMesh;

        // get ifcModel using id
        const model: IFCModelItemInterface | undefined = state.ifcModels.find(
          (ifcModel) => ifcModel.modelId === modelId
        );

        if (!model) {
          return;
        }

        // Is it another ifc model then last raycast?
        const prevHoveredModel: IFCModelItemInterface = getters["getIfcHoveredModel"];

        // This check is for hover only, check if there was a previousmodel or this is the first model to get hovered? There can also be a prevModel but there never was a subset created for it, then prevModel.highlighed is undefined.
        if (
          // First model to get hovered?
          prevHoveredModel &&
          // First model to get highligthed?
          prevHoveredModel.highlighted !== undefined &&
          // Another model hovered than previous model
          model.modelId !== prevHoveredModel.modelId
        ) {
          // Remove previuos hover highlight.
          try {
            const subset = ifc.getSubset(
              prevHoveredModel.modelId,
              material,
              `${prevHoveredModel.modelId}-${prevHoveredModel.highlighted}-highlight`
            );
            subset.visible = false;
          } catch (error) {
            console.error("Subset failed to remove previous highlight: ", error);
            return;
          }

          // Remove from state.
          commit("removeIfcHighlight", prevHoveredModel.modelId);
        }

        // set current hovered to state
        commit("setIfcHoveredId", model.modelId);

        // get subset id from IFCjs using raycast data
        const geometry = hoveredElement.object.geometry;
        const index = hoveredElement.faceIndex;

        const id = ifc.getExpressId(geometry, index);

        // compare IFCjs id with our storeys items id
        // if it's not in our hierarchy, we don't wan't to interact with it & ifc.getSubset will fail & complain otherwise.
        // items<IFCStoreyGroupItemsInterface> contains ids for all the elements we have pruned/removed.
        // So if we hover over one of those pruned elements we still get a match on
        // item.id === id because id was fetched from IFCjs that looks on the complete ifc-hierarchy.
        // To get the pruned items closest parent with data we have,we assign item where we found id.
        // TODO: time-test this. If too slow, we need a datastructure?. Also note we only look for items-id, not storeys or groups.
        let itemToHighlight: IFCStoreyGroupItemsInterface | undefined = undefined;

        model?.storeys.map((storey) =>
          storey.groups.map((group) =>
            group.items.map((item: IFCStoreyGroupItemsInterface) => {
              // here we iterate through our hierarchy items
              // if we find anything we return early. Saves having to search through all ids.
              if (item.id === id) {
                itemToHighlight = item;
                //  return; TODO: remove this if we cant return early ?
              }

              // here we search pruned children & set closest item we have in our hierarchy as the element hit
              // if we find any id.
              if (item.ids.includes(id)) {
                itemToHighlight = item;
              }
            })
          )
        );

        // If nothing found, abort.
        if (!itemToHighlight) {
          return;
        }

        // we do this to make typescript happy
        // if there is a better way where we can use itemToHighlight directly, change it.
        const currentItem = itemToHighlight as IFCStoreyGroupItemsInterface;

        if (event.type === "mousemove") {
          // if raycasted model is not highligthed
          if (typeof model.highlighted === "undefined") {
            // first hit
            try {
              const newSubset = ifc.getSubset(
                modelId,
                material,
                `${modelId}-${currentItem.id}-highlight`
              );
              newSubset.visible = true;
              commit("addIfcHighlight", currentItem.id);
            } catch (error) {
              console.error(error);
            }
          } else if (model.highlighted === currentItem.id) {
            // ???
          } else {
            try {
              const subset = ifc.getSubset(
                modelId,
                material,
                `${modelId}-${model.highlighted}-highlight`
              );
              subset.visible = false;
              commit("removeIfcHighlight", modelId);

              const newSubset = ifc.getSubset(
                modelId,
                material,
                `${modelId}-${currentItem.id}-highlight`
              );
              newSubset.visible = true;
              commit("addIfcHighlight", currentItem.id);
            } catch (error) {
              console.error("Subset failed: ", error);
            }
          }
        }
        if (event.type === "mousedown" && event.button !== 2) {
          if (currentItem !== undefined && model.highlighted === currentItem.id) {
            const payload = {
              item: currentItem,
              modelId: model.modelId,
            };
            dispatch("ifcSubsetRaycastClick", payload);
            // dispatch("ifcToggleClick", payload); // OLD WAY
          }
        }
      } else {
        // no raycast-hit, if there is a hovered-model in state, remove it & the highlight on it.
        if (event.type === "mousemove") {
          const model: IFCModelItemInterface = getters["getIfcHoveredModel"];
          if (!model || model.highlighted === undefined) {
            return;
          }

          try {
            const subset = ifc.getSubset(
              model.modelId,
              material,
              `${model.modelId}-${model.highlighted}-highlight`
            );

            commit("removeIfcHighlight", model.modelId);
            subset.visible = false;
          } catch (error) {
            console.error("Subset failed: ", error);
          }
        }
        return;
      }
    };

    viewer.renderer.domElement.onmousemove = pick;
    viewer.renderer.domElement.onmousedown = pick;
  },
  /**
   * When we use raycast click we first need to make sure
   * we have attachments loaded.
   * We do this by using the provided payloads modelId, which is the model we just
   * clicked using the raycaster.
   * We cannot use ifcCurrentModelId as it's updated later in the process.
   * item is the item we clicked on and is used later in the ifcToggleClick , which is
   * the step after this one.
   * @payload { item: IFCStoreyGroupItemsInterface, modelId: number }
   * @returns
   */
  async ifcSubsetRaycastClick({ state, commit, dispatch }, payload) {
    // Get model from state using payload.modelId.
    const model = state.ifcModels.find(
      (ifcModel: IFCModelItemInterface) => ifcModel.modelId === payload.modelId
    );
    if (!model) {
      return;
    }
    // Model container ID (the model containers database entry sort key), not to be confused with `modelId` which is internally created for IFC models when loading them into viewer.
    const modelContainerId = model.modelContainerId;
    // Current model attachments, used to make sure we are only getting attachments if `undefined`, i.e. we have not fetched them yet.
    const modelAttachments = model.attachments;

    if (!modelAttachments) {
      try {
        // Current model `attachments` key is undefined. Get attachments from endpoint.
        const attachments: ModelInpsectorAttachmentInterface[] | undefined = await coreApiPost(
          "/viewer/get-ifc-model-attachments",
          {
            modelContainerId,
          }
        );

        // Set attachments on current model (if empty or undefined, will apply empty array as fallback).
        commit("setIFCModelItemAttachments", {
          modelIndex: payload.modelId,
          attachments,
        });
      } catch (error) {
        console.error(error);
      }
    }

    // now that we have attachments, we can go through ifctoggleclick
    dispatch("ifcToggleClick", payload);
  },
  /**
   * Disables raycaster selection on subsets
   * by disabling the eventlisteners
   */
  disableIfcSubsetRaycast({ getters }) {
    const viewer = getters["getViewer"];
    viewer.renderer.domElement.onmousemove = undefined;
    viewer.renderer.domElement.onmousedown = undefined;
  },
  /**
   * Reads lines from ifc-file of types IFCMATERIAL,IFCCOLOURRGB,IFCSURFACESTYLERENDERING,IFCSURFACESTYLE
   * With these we can associate name & color of materials & match it to model.materials
   * IFCMATERIAL gives number of materials in ifc-file, to confirm if default material is used.
   * IFCSURFACESTYLE gives us name of materials & links to IFCSURFACESTYLERENDERING.
   * IFCSURFACESTYLERENDERING gives links to IFCCOLOURRGB.
   * IFCCOLOURRGB gives us the color value.
   * @param model
   * @returns model with materials that are named
   */
  async findMaterialNames({ rootGetters, dispatch }, { model }) {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const { modelID: modelId } = model;

    // to get all materials, even default or not used materials
    //const IFCMATERIAL = await ifcManager.getAllItemsOfType(modelId, IFCTypes.IFCMATERIAL, true);

    const IFCCOLOURRGB = await ifcManager.getAllItemsOfType(modelId, IFCTypes.IFCCOLOURRGB, true);
    const IFCSURFACESTYLERENDERING = await ifcManager.getAllItemsOfType(
      modelId,
      IFCTypes.IFCSURFACESTYLERENDERING,
      true
    );
    const IFCSURFACESTYLE = await ifcManager.getAllItemsOfType(
      modelId,
      IFCTypes.IFCSURFACESTYLE,
      true
    );

    IFCSURFACESTYLE.map(async (s: any) => {
      IFCSURFACESTYLERENDERING.map(async (issr: any) => {
        IFCCOLOURRGB.map(async (rgb: any) => {
          if (s?.Styles[0]?.value === issr?.expressID) {
            if (rgb?.expressID === issr?.SurfaceColour?.value) {
              model.material.map(async (m: MeshBasicMaterial) => {
                if (
                  m?.color.r === rgb?.Red.value &&
                  m?.color.g === rgb?.Green.value &&
                  m?.color.b === rgb?.Blue.value
                ) {
                  m.name = await dispatch("ifcDisplayname", { modelId, id: s?.expressID });
                }
              });
            }
          }
        });
      });
    });

    // if there is still a unnamed material as default, name it.
    // this is not necessary atm(we set name when we switch materials to MeshBasic..), but may become in the future
    /*  const defaultMat = IFCMATERIAL.find((m: any) => m.Name.value.trim() === "<Unnamed>");
    if (model.material[0].name === "Material 0" && defaultMat) {
      model.material[0].name = "Generic Default Material";
    } */
  },
  // –––––––––––––––––––––––
  // WIREFRAME FUNCTIONS
  // –––––––––––––––––––––––
  /**
   * Handles all wireframe related actions by taking in all the necessary data
   * and then dispatch to the appropriate function.
   * There are many states in which wireframe can be in and
   * this affects what actions should be taken.
   * Possible states:
   * left panel - model is visible/hidden
   * left panel - all models is visible/hidden
   * right panel - wireframe is enabled/disabled
   * wireframe is isolated on storeyId
   * wireframe is isolated on groupId
   * wireframe is hidden on storeyId
   * wireframe is hidden on groupId
   * wireframe is isolated on storeyId & unhidden on some groupId(s)
   * wireframe is isolated on storeyId & unhidden on some storeyId(s)
   *
   * To determine every possible state, we need to know:
   * 1. what model,
   * 2. model visible,
   * 3. model isolated ids,
   * 4. model hidden ids
   * 5. wireframe enabled
   * 6. is id provided if we want to change storeyId or groupId
   * 7. is provided id a storeyId or groupId
   * 8. model isolated & hidden ids
   * 9. model reset
   * @param modelId number
   * @param wireframeAction see wireframeAction-interface: "hide groupId" | "show groupId"
   */
  async wireframeHandler(
    { state, commit, dispatch, getters },
    {
      modelId,
      action,
      id,
      ids,
      subset,
    }: { modelId: number; action?: WireframeAction; id?: number; ids?: number[]; subset: Mesh }
  ) {
    // Get what we need to know:
    const debug = false;

    // model
    const model = state.ifcModels.find((ifcModel) => ifcModel.modelId === modelId);
    if (!model) {
      return;
    }

    // model visibility
    const { modelContainerId } = model;
    const { items } = state.layerData.models;
    const { object } = items[modelContainerId];
    const isModelVisible = object.visible;

    // model isolated ids
    const { isolated } = model;
    const isIsolated = isolated.length > 0;

    // model hidden ids
    const { hidden } = model;
    const isHidden = hidden.length > 0;

    // is id provided
    const isIdProvided = id !== undefined;

    // is provided id a storeyId
    const isStoreyId = isIdProvided && model.storeys.find((storey) => storey.id === id);

    // wireframe on/off
    const { wireframe } = state.graphicsInspector;
    const isWireframeOn = wireframe;

    // Is item clicked inside group
    const isItem =
      id &&
      subset &&
      (action === WireframeAction.HIDE_ITEM || action === WireframeAction.SHOW_ITEM);

    const getGroupItemId = (id: number) => {
      let groupItemId = 0;
      // console.log("gettings groupItemId that id ", id, "belongs to");
      model?.storeys.map((storey) => {
        storey.groups.map((group) => {
          group.items.map((item: IFCStoreyGroupItemsInterface) => {
            //console.log("group:", group.id, "item: ", item.ids);
            if (item.ids.includes(id)) {
              groupItemId = group.id;
            }
          });
        });
      });
      return groupItemId;
    };

    // saved for debugging
    if (debug) {
      console.log("isModelVisible ", isModelVisible);
      console.log("isWireframeOn ", isWireframeOn);
      console.log("isStoreyId ", isStoreyId);
      console.log("isIdProvided ", isIdProvided);
      console.log("isHidden ", isHidden);
      console.log("isIsolated ", isIsolated);
      console.log("model ", model);
      console.log("action ", action);
      console.log("id ", id);
      console.log("isItem ", isItem);
    }

    if (isItem && id && isWireframeOn && isModelVisible) {
      debug && console.log("updating wireframe based on new subset");
      const groupId = getGroupItemId(id);
      commit("setGroupsWithWireMeshItemsHidden", { modelId, groupId });

      await dispatch("updateWireframes", {
        model,
        subset,
        groupIdToUpdate: groupId,
      });
      return;
    }

    // Is tag-ids we wan't to isolate
    if (WireframeAction.ISOLATE_TAGS && ids && isWireframeOn) {
      debug && console.error("isolate tag ids", ids);
      // get all unique group ids
      const groupIds = ids.map((id) => getGroupItemId(id));
      const uniqueGroupIds = [...new Set(groupIds)];

      // create the wireframe using subset
      const wireframe = await dispatch("createSubsetWireframe", { model, subset });

      uniqueGroupIds.map(async (groupId) => {
        await dispatch("removeWireframeSegment", { model, groupIdToUpdate: groupId });
        await dispatch("updateWireMeshAssociation", { model, wireframe, groupIdToUpdate: groupId });
        commit("setGroupsWithWireMeshItemsHidden", { modelId, groupId });
      });
      commit("hideWireframe", { model });
      return;
    }

    // model reset
    if (action === WireframeAction.RESET && isWireframeOn && isModelVisible) {
      // check if we have updated wireframes previously on item level.
      // if we have, we need to reset wireframes completely.
      if (model.groupsWithWireMeshItemsHidden.length > 0) {
        debug && console.log("full reset of wireframes");
        await dispatch("handleWireframeReset", { model });

        // Now that we cleanup wireframes we set groupsWithWireMeshItemsHidden to empty
        // so we don't reset wireframes again.
        commit("clearGroupsWithWireMeshItemsHidden", { modelId: model.modelId });

        // now that we have reset wireframes completely, we can just show wireframes
        commit("showWireframe", { model });
      } else {
        debug && console.log("normal wireframe reset, showing wireframe");
        // No need to reset wireframes completely as we have not updated wireframes on item level.
        commit("showWireframe", { model });
      }
      return;
    }

    // model is hidden in layer & we want to reflect this on wireframe
    if (
      !isHidden &&
      !isIsolated &&
      !isModelVisible &&
      isWireframeOn &&
      action === WireframeAction.UPDATE
    ) {
      debug && console.log("Model is hidden in layer, hideWireframe");
      commit("hideWireframe", { model });
      return;
    }

    // model is made visible in layer & no model parts are hidden or isolated,
    // we want to reflect this on wireframe
    if (
      !isHidden &&
      !isIsolated &&
      isModelVisible &&
      isWireframeOn &&
      action === WireframeAction.UPDATE
    ) {
      debug &&
        console.log(
          "Model is made visible in layer & no parts of model is hidden or isolated, showWireframe"
        );
      commit("showWireframe", { model });
      return;
    }

    // model is made visible in layer & parts are hidden,
    // we want to reflect this on wireframe
    if (
      isHidden &&
      !isIsolated &&
      !isIdProvided &&
      isModelVisible &&
      isWireframeOn &&
      action === WireframeAction.UPDATE
    ) {
      debug &&
        console.log("Model is made visible in layer & parts are hidden, showOrHideWireframe");
      commit("showOrHideWireframe", { model });
      return;
    }

    // models layer is hidden & no model parts is hidden or isolated, we want to reflect this on wireframe
    if (!isHidden && !isIsolated && isWireframeOn && action === WireframeAction.UPDATE) {
      debug &&
        console.log("models layer is hidden & NO parts are hidden or isolated, hideWireframe");
      commit("hideWireframe", { model });
      return;
    }

    // models layer is hidden & parts are hidden, we want to reflect this on wireframe
    if (isHidden && !isIsolated && isWireframeOn && action === WireframeAction.UPDATE) {
      debug && console.log("models layer is hidden & parts are hidden, hideWireframe");
      commit("hideWireframe", { model });
      return;
    }

    // models layer is made visible & we want to reflect this on wireframe
    if (!isHidden && !isIsolated && isWireframeOn && action === WireframeAction.UPDATE) {
      debug &&
        console.log(
          "models layer is made visible & we want to reflect this on wireframe, showWireframe"
        );
      commit("showWireframe", { model });
      return;
    }

    // layer on model is turned on & model has hidden parts, we want to reflect this on wireframe
    if (
      !isIdProvided &&
      action === WireframeAction.UPDATE &&
      isWireframeOn &&
      isHidden &&
      !isIsolated &&
      isModelVisible
    ) {
      debug &&
        console.log("layer on model is turned on & model has hidden parts, showOrHideWireframe");
      commit("showOrHideWireframe", { model });

      return;
    }

    // layer on model is turned OFF & model has hidden parts but no isolated parts
    if (
      !isIdProvided &&
      action === WireframeAction.UPDATE &&
      isWireframeOn &&
      isHidden &&
      !isIsolated &&
      !isModelVisible
    ) {
      debug &&
        console.log(
          "layer on model is turned OFF & model has hidden parts but no isolated parts, hideWireframe"
        );
      commit("hideWireframe", { model });

      return;
    }

    // layer on model is turned OFF & model has hidden parts AND isolated parts
    if (
      !isIdProvided &&
      action === WireframeAction.UPDATE &&
      isWireframeOn &&
      isHidden &&
      isIsolated &&
      !isModelVisible
    ) {
      debug &&
        console.log(
          "layer on model is turned OFF & model has hidden parts AND isolated parts, hideWireframe"
        );
      commit("hideWireframe", { model });

      return;
    }

    // model neither hidden nor isolated & we want to show full model
    if (
      !isHidden &&
      !isIsolated &&
      action === WireframeAction.SHOW &&
      isWireframeOn &&
      isModelVisible
    ) {
      commit("showWireframe", { model });
      return;
    }
    // model neither hidden nor isolated & we want to hide full model
    if (
      !isHidden &&
      !isIsolated &&
      !isWireframeOn &&
      isModelVisible &&
      action === WireframeAction.HIDE
    ) {
      commit("hideWireframe", { model });
      return;
    }
    // model is toggled on in wireframe & no id was provided & we have isolated ids & hidden ids
    if (isHidden && isIsolated && !isIdProvided && isWireframeOn && isModelVisible) {
      // special case handling when ifcModelItems is isolated and we click model layer visible.
      // In that case we need to show wireframe on ifc element and not groupitem.
      // To determine if we have a groupitem or ifc element we compare isolated id with groupitem ids.
      // If we have a match, we have a groupitem, if not we have an ifc element.
      let isGroupItemId = true;
      model.isolated.forEach((id) => {
        if (getGroupItemId(id) !== id) {
          debug &&
            console.warn(
              "ifcModelItems is isolated and we click model layer visible, we have a match, we show wire on ifc element"
            );
          isGroupItemId = false;
          commit("showWireframeByGroupId", { model, id: getGroupItemId(id) });
        }
      });
      debug &&
        console.warn(
          "model is toggled on in wireframe & no id was provided & we have isolated ids & hidden ids, showOrHideWireframe"
        );
      isGroupItemId && commit("showOrHideWireframe", { model });
      return;
    }
    // model is toggled off in wireframe & model has hidden & isolated ids
    if (
      isHidden &&
      isIsolated &&
      !isIdProvided &&
      !isWireframeOn &&
      isModelVisible &&
      WireframeAction.HIDE
    ) {
      commit("hideWireframe", { model });
      return;
    }

    // only act on below cases if model is visible,
    // wireframe enabled &
    // models layer is visible &
    // id is provided
    if (isModelVisible && isWireframeOn && isIdProvided) {
      // Items have been edited & we clicked on a group or storey with items edited in it.
      if (
        id &&
        model.groupsWithWireMeshItemsHidden.length > 0 &&
        model.groupsWithWireMeshItemsHidden.includes(id)
      ) {
        debug &&
          console.log("we clicked hide or show on a groupId or a StoreyId with hidden items in it");
        // rebuild wireframe on just the clicked group or storey from items-wireframes to group-wireframes
        await dispatch("updateWireframe", { model, groupIdToUpdate: id });
      }
      // If items have been edited AND we clicked isolate arent just unhiding another group or storey,
      // we need to reset wireframes on model before actions below.
      if (
        id &&
        model.groupsWithWireMeshItemsHidden.length > 0 &&
        action === WireframeAction.ISOLATE
      ) {
        debug &&
          console.log(
            "item has been edited in this model AND we have clicked isolate,resetting wireframe"
          );
        await dispatch("handleWireframeReset", { model });
        commit("clearGroupsWithWireMeshItemsHidden", { modelId: model.modelId });
      }

      debug && console.log("MODEL", model);

      // model isolated & we want to hide groupId
      if (!isHidden && isIsolated && !isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByGroupId", { model, id });
        return;
      }
      // model isolated & we want to show groupId
      if (!isHidden && isIsolated && !isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByGroupId", { model, id });
        return;
      }
      // model isolated & we want to hide storeyId
      if (!isHidden && isIsolated && isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByStoreyId", { model, id });
        return;
      }
      // model isolated & we want to show storeyId
      if (!isHidden && isIsolated && isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByStoreyId", { model, id });
        return;
      }
      // model hidden & we want to hide groupId
      if (isHidden && !isIsolated && !isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByGroupId", { model, id });
        return;
      }
      // model hidden & we want to show groupId
      if (isHidden && !isIsolated && !isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByGroupId", { model, id });
        return;
      }
      // model hidden & we want to hide storeyId
      if (isHidden && !isIsolated && isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByStoreyId", { model, id });
        return;
      }
      // model hidden & we want to show storeyId
      if (isHidden && !isIsolated && isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByStoreyId", { model, id });
        return;
      }
      // model hidden & isolated & we want to hide groupId
      if (isHidden && isIsolated && !isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByGroupId", { model, id });
        return;
      }
      // model hidden & isolated & we want to show groupId
      if (isHidden && isIsolated && !isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByGroupId", { model, id });
        return;
      }
      // model hidden & isolated & we want to hide storeyId
      if (isHidden && isIsolated && isStoreyId && action === WireframeAction.HIDE) {
        commit("hideWireframeByStoreyId", { model, id });
        return;
      }
      // model hidden & isolated & we want to show storeyId
      if (isHidden && isIsolated && isStoreyId && action === WireframeAction.SHOW) {
        commit("showWireframeByStoreyId", { model, id });
        return;
      }
      // model isolated & we want to update the wireframe to reflect model changes
      if (isIsolated && action === WireframeAction.ISOLATE) {
        commit("isolateWireframe", { model, id });
        return;
      }
    }
    debug && console.warn("NOT YET IMPLEMENTED CASE IN WIREFRAMEHANDLER");
  },

  async handleWireframeReset({ getters, dispatch }, { model }: { model: IFCModelItemInterface }) {
    const scene: Scene = getters["getScene"];

    // iterate all groupIds
    const storeyPromise = model?.storeys.map(async (storey) => {
      const groupPromise = storey.groups.map(async (group) => {
        // fetch wireframe
        const fetchedWireframeObject = await dispatch(
          "Storage/findFetchedWireframe",
          {
            id: group.id,
            modelContainerId: model.modelContainerId,
          },
          { root: true }
        );
        // if we found a fetched wireframe-object we use it

        const wireframe = fetchedWireframeObject.wireframe;

        await dispatch("transformValuesLoop", {
          subset: wireframe,
          transform: model.transform,
        });

        // delete all old wireframes using model.wireMeshAssociations
        await dispatch("updateWireframes", {
          model,
          fetchedWireframe: wireframe,
          groupIdToUpdate: group.id,
        });

        scene.add(wireframe);
      });
      await Promise.all(groupPromise);
    });
    await Promise.all(storeyPromise);
  },
  /**
   * Update just _ONE_ group wireframe. This is used when we convert from wireframe on items to wireframe on group.
   */
  async updateWireframe(
    { getters, dispatch, commit },
    { model, groupIdToUpdate }: { model: IFCModelItemInterface; groupIdToUpdate: number }
  ) {
    const scene = getters["getScene"];
    // fetch wireframe
    const fetchedWireframeObject = await dispatch(
      "Storage/findFetchedWireframe",
      {
        id: groupIdToUpdate,
        modelContainerId: model.modelContainerId,
      },
      { root: true }
    );
    const wireframe = fetchedWireframeObject.wireframe;

    // transform wireframe
    await dispatch("transformValuesLoop", {
      subset: wireframe,
      transform: model.transform,
    });

    // add wireframe to scene
    scene.add(wireframe);

    // update groupsWithWireMeshItemsHidden
    commit("setGroupsWithWireMeshItemsHidden", {
      modelId: model.modelId,
      groupId: groupIdToUpdate,
      inverse: true,
    });

    // delete all old wireframes using model.wireMeshAssociations
    // remove wireframe for this group in threejs/scene
    for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {
        model.wireMeshAssociations[groupId].forEach((e, index: number) => {
          if (index !== 0) {
            scene.remove(e as LineSegments);
            (e as LineSegments).geometry.dispose();
          }
        });
      }
    }
    // clear group in wireMeshAssociations of other LineSegments,but keep storeyId & add the new wireframe.
    for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {
        model.wireMeshAssociations[groupId] = [model.wireMeshAssociations[groupId][0]];
        model.wireMeshAssociations[groupId].push(wireframe);
      }
    }
  },
  /**
   * Creates wireframe using provided subset.
   * @param Model
   * @param subset to create wireframe from
   * @returns wireframe
   */
  async createSubsetWireframe({ dispatch, getters }, { model, subset }) {
    const scene: Scene = getters["getScene"];

    const edgesGeometry = new EdgesGeometry(subset.geometry);
    const wireframe = new LineSegments(edgesGeometry, edgeMat);
    await dispatch("transformValuesLoop", { subset: wireframe, transform: model.transform });
    dispatch("ifcSubsetRotation", { subset: wireframe, transform: model.transform });

    scene.add(wireframe);
    return wireframe;
  },
  /**
   * Removes wireframe segment from threejs-scene.
   * @param model to change
   * @param groupId to remove wireframe segment from
   */
  removeWireframeSegment(
    { getters },
    { model, groupIdToUpdate }: { model: IFCModelItemInterface; groupIdToUpdate: number }
  ) {
    const scene: Scene = getters["getScene"];
    // remove wireframe for this group in threejs/scene
    for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {
        model.wireMeshAssociations[groupId].forEach((e, index: number) => {
          if (index !== 0) {
            scene.remove(e as LineSegments);
            (e as LineSegments).geometry.dispose();
          }
        });
      }
    }
  },
  /**
   * Replaces old wireframe-segment with new wireframe-segment in wireMeshAssociations, the datastructure keeping track
   * of wireframes and mesh.
   * @param model
   * @param wireframe
   * @param groupIdToUpdate
   */
  updateWireMeshAssociation(
    { getters },
    {
      model,
      wireframe,
      groupIdToUpdate,
    }: { model: IFCModelItemInterface; wireframe: Wireframe; groupIdToUpdate: number }
  ) {
    for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {
        model.wireMeshAssociations[groupId] = [model.wireMeshAssociations[groupId][0]];
        model.wireMeshAssociations[groupId].push(wireframe);
      }
    }
  },
  /**
   * TODO: This is to be removed and replaced with the three dispatches
   * -createSubsetWireframe
   * -removeWireframeSegment
   * -updateWireMeshAssociation
   * These 3 above does exactly the same, but in some cases we only wan't to run createSubsetWireframe while removing
   * many wiremeshsegments.
   * Updates wireframe on group-item level.
   * This is used when user click on an item. Since wireframe has been created on group-level, we now need to
   * split up that group-wireframe. We do this by creating the visible items in the group by looking at the subset created for
   * the mesh. Part two is to delete the old line segments. Part three is to update the wireMeshAssociation-structure with new  LineSegments & clearing the old LineSegments.
   *
   *
   */
  async updateWireframes(
    { getters, dispatch },
    {
      model,
      subset,
      fetchedWireframe,
      groupIdToUpdate,
    }: {
      model: IFCModelItemInterface;
      groupIdToUpdate: number;
      subset?: Mesh;
      fetchedWireframe?: Wireframe;
    }
  ) {
    const scene: Scene = getters["getScene"];
    let wireframe: Wireframe;
    // if subset is provided, we have clicked on an item & we need to create a new wireframe
    if (subset) {
      wireframe = await dispatch("createSubsetWireframe", { model, subset });
      /*  const edgesGeometry = new EdgesGeometry(subset.geometry);
      wireframe = new LineSegments(edgesGeometry, edgeMat);
      await dispatch("transformValuesLoop", { subset: wireframe, transform: model.transform });
      dispatch("ifcSubsetRotation", { subset: wireframe, transform: model.transform });
      console.log("adding wireframe created from subset");
      scene.add(wireframe); */
    } else {
      wireframe = fetchedWireframe as Wireframe;
    }

    // remove wireframe for this group in threejs/scene
    dispatch("removeWireframeSegment", { model, groupIdToUpdate });
    /*  for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {
        console.log("removing invalid wireframes for groupId: ", groupIdToUpdate);
        model.wireMeshAssociations[groupId].forEach((e, index: number) => {
          if (index !== 0) {
            scene.remove(e as LineSegments);
            (e as LineSegments).geometry.dispose();
          }
        });
      }
    } */
    // clear group in wireMeshAssociations of other LineSegments,but keep storeyId & add the new wireframe.
    dispatch("updateWireMeshAssociation", { model, wireframe, groupIdToUpdate });
    /*  for (const groupId in model.wireMeshAssociations) {
      if (Number(groupId) === groupIdToUpdate) {

        model.wireMeshAssociations[groupId] = [model.wireMeshAssociations[groupId][0]];
        model.wireMeshAssociations[groupId].push(wireframe);
      }
    } */
  },

  /**
   *  Create subsets based on ifc-types that exist on the model & matched against a ifc-type-list
   *  that has predefined colors for each ifc-type.
   *  This is not used atm as it would require making it work with our current way of highlighting/isolation/hiding & wireframe.
   */
  async ifcCreateTypeMaterialSubsets(
    {
      dispatch,
      rootGetters,
      getters,
    }: { state: State; commit: Commit; dispatch: Dispatch; rootGetters: any; getters: any },
    { modelId, ids, transform }: { modelId: number; ids: number[]; transform: any }
  ) {
    const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
    const scene: Scene = getters["getScene"];

    const onlyAllowedCategoriesEnums = {
      IFCBEAM,
      IFCBUILDINGELEMENTPART,
      IFCBUILDINGELEMENTPROXY,
      IFCCHIMNEY,
      IFCCOLUMN,
      IFCCOVERING,
      IFCCURTAINWALL,
      IFCDOOR,
      IFCFOOTING,
      IFCMEMBER,
      IFCPILE,
      IFCPLATE,
      IFCRAILING,
      IFCRAMP,
      IFCRAMPFLIGHT,
      IFCROOF,
      IFCSHADINGDEVICE,
      IFCSLAB,
      IFCSTAIR,
      IFCSTAIRFLIGHT,
      IFCWALL,
    };

    const onlyAllowedCategories = {
      enum: Object.values(onlyAllowedCategoriesEnums),
      string: Object.keys(onlyAllowedCategoriesEnums),
    };

    const allTypes: any = [];
    ids.forEach((id: number) => {
      const type: string = ifcManager.getIfcType(modelId, id);

      if (typeof allTypes[type] === "number") {
        // console.log("doing noting");
      } else {
        allTypes.push(type);
        //allTypes[type] = id;
      }
    });
    const allUniqueTypesFoundOnModel = [...new Set(allTypes)];

    // prune all types that are not in the allowed categories from allUniqueTypesFoundOnModel
    const onlyOurTypes: any[] = [];
    allUniqueTypesFoundOnModel.filter((type: any) => {
      onlyAllowedCategories.string.map((strCategory: any, index) => {
        if (type === strCategory) {
          const typeAsNumber = onlyAllowedCategories.enum[index];
          const typeAsString = strCategory;
          onlyOurTypes.push({ typeAsNumber, typeAsString });
        }
      });
    });

    // Gets the IDs of all the items of a specific category
    async function getAll(type: number) {
      return ifcManager.getAllItemsOfType(modelId, type, false);
    }

    const findTypeMaterialColorByName = (name: string) => {
      switch (name) {
        case "IFCSLAB":
          return ifcSlabMaterialColor;
        case "IFCBEAM":
          return ifcBeamMaterialColor;
        case "IFCBUILDINGELEMENTPART":
          return ifcBuildingElementPartMaterialColor;
        case "IFCBUILDINGELEMENTPROXY":
          return ifcBuildingElementProxyMaterialColor;
        case "IFCCHIMNEY":
          return ifcChimneyMaterialColor;
        case "IFCCOLUMN":
          return ifcColumnMaterialColor;
        case "IFCCOVERING":
          return ifcCoveringMaterialColor;
        case "IFCCURTAINWALL":
          return ifcCurtainWallMaterialColor;
        case "IFCDOOR":
          return ifcDoorMaterialColor;
        case "IFCFOOTING":
          return ifcFootingMaterialColor;
        case "IFCMEMBER":
          return ifcMemberMaterialColor;
        case "IFCPILE":
          return ifcPileMaterialColor;
        case "IFCPLATE":
          return ifcPlateMaterialColor;
        case "IFCRAILING":
          return ifcRailingMaterialColor;
        case "IFCRAMP":
          return ifcRampMaterialColor;
        case "IFCRAMPFLIGHT":
          return ifcRampFlightMaterialColor;
        case "IFCROOF":
          return ifcRoofMaterialColor;
        case "IFCSHADINGDEVICE":
          return ifcShadingDeviceMaterialColor;
        case "IFCSTAIR":
          return ifcStairMaterialColor;
        case "IFCSTAIRFLIGHT":
          return ifcStairFlightMaterialColor;
        case "IFCWALL":
          return ifcWallMaterialColor;
        default:
          return ifcSlabMaterialColor;
          break;
      }
    };
    const ifcSlabMaterialColor = 0xffff00;
    const ifcBeamMaterialColor = 0xff0000;
    const ifcBuildingElementPartMaterialColor = 0xffffff;
    const ifcBuildingElementProxyMaterialColor = 0x000000;
    const ifcChimneyMaterialColor = 0xffff00;
    const ifcColumnMaterialColor = 0x00ffff;
    const ifcCoveringMaterialColor = 0x444444;
    const ifcCurtainWallMaterialColor = 0x666666;
    const ifcDoorMaterialColor = 0x66ff66;
    const ifcFootingMaterialColor = 0x880088;
    const ifcMemberMaterialColor = 0x888888;
    const ifcPileMaterialColor = 0xff00ff;
    const ifcPlateMaterialColor = 0x00ffff;
    const ifcRailingMaterialColor = 0x00ff11;
    const ifcRampMaterialColor = 0x00ff55;
    const ifcRampFlightMaterialColor = 0x55ff00;
    const ifcRoofMaterialColor = 0x550000;
    const ifcShadingDeviceMaterialColor = 0x99ff00;
    const ifcStairMaterialColor = 0x00ff33;
    const ifcStairFlightMaterialColor = 0x33ff00;
    const ifcWallMaterialColor = 0xff00ff;

    // Creates a new subset containing all elements of a category
    async function newSubsetOfType({ typeAsNumber, typeAsString }: any) {
      const ids = await getAll(typeAsNumber);
      const materialColor = findTypeMaterialColorByName(typeAsString);
      const ifcTypeMaterial = new MeshLambertMaterial({
        color: materialColor,
        transparent: false,
        opacity: 1.0,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
      });

      return ifcManager.createSubset({
        modelID: modelId,
        scene,
        material: ifcTypeMaterial,
        ids,
        removePrevious: true,
        customID: typeAsString,
      });
    }

    // Stores the created subsets
    const subsets: any[] = [];
    onlyOurTypes.map(async ({ typeAsNumber, typeAsString }: any) => {
      const subset = await newSubsetOfType({ typeAsNumber, typeAsString });
      subset.visible = true;
      dispatch("ifcSubsetRotation", { subset, transform });
      subsets[typeAsString] = subset;
    });
  },
};

export default modelsActions;
