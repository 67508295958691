
import Vue from "vue";

export default Vue.extend({
  name: "RoleChip",
  props: {
    role: {
      type: String,
      default: "none",
    },
    label: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    title: "",
    color: "",
  }),
  methods: {
    async getRoleStyle() {
      const style = await this.$store.dispatch("Utilities/roleStyle", this.role);
      const { title, color } = style;
      this.title = title;
      this.color = color;
    },
  },
  watch: {
    role() {
      this.getRoleStyle();
    },
  },
  mounted() {
    this.getRoleStyle();
  },
});
