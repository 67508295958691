/**
 * Sort an array alphabetically.
 *
 * @template T = Record<string, any>
 * @param {T[]} array The array to sort.
 * @param {string} value The value to sort by.
 */
export const sortArray = <T = Record<string, any>>(array: T[], value: string): void => {
  if (!array || !Array.isArray(array) || !value) {
    return;
  }

  array.sort((a: any, b: any): number => {
    if (!a[value] && !b[value]) {
      return 0;
    }

    const aValue = a[value].toLowerCase();
    const bValue = b[value].toLowerCase();

    // Use local compare for return integer.
    // See MDN description for details and warning on not using fixed integers
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#description
    return aValue.localeCompare(bValue);
  });
};
