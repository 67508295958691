
import Vue from "vue";

export default Vue.extend({
  name: "ViewerItemExpansionPanel",
  props: {
    typeDisplay: String,
    type: String,
    items: Array,
    removeFunction: Function,
  },
});
