import { Vector3 } from "three";

import { TransformState } from "./types";

const transformState: TransformState = {
  rightSideBarTransform: false,
  transformSelection: {},
  transformSelectionPivot: new Vector3(),
  transformTool: null,
};
export default transformState;
