const isoDateNow: string = new Date().toISOString();
// Visit for all options on toLocaleString()
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
const timestampToLocaleDate = (timestamp: string | number): string => {
  // make sure timestamp is a number
  const timestampNumber = Number(timestamp);

  return new Date(timestampNumber).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};
const timestampToLocaleDateTime = (timestamp: string | number): string => {
  // make sure timestamp is a number
  const timestampNumber = Number(timestamp);

  return new Date(timestampNumber).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const timestampToLocaleDateTimeSeconds = (timestamp: string | number): string => {
  // make sure timestamp is a number
  const timestampNumber = Number(timestamp);

  return new Date(timestampNumber).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const secondsMinutes = (seconds: number): string => {
  const isNotMinute = seconds < 61;

  let timeLeft: number;

  if (isNotMinute) {
    timeLeft = Math.ceil(seconds);
  } else {
    timeLeft = Math.ceil(seconds / 60);
  }

  return `${timeLeft}${isNotMinute ? "sec" : "min"}`;
};

export {
  isoDateNow,
  secondsMinutes,
  timestampToLocaleDate,
  timestampToLocaleDateTime,
  timestampToLocaleDateTimeSeconds,
};
