
import Vue from "vue";

import { secondsMinutes } from "@/utilities/";

import CancelDialog from "./cancel-dialog.vue";
import LineItem from "./line-item.vue";

export default Vue.extend({
  name: "FileBrowserUploadFileBox",
  components: {
    CancelDialog,
    LineItem,
  },
  //   props: {
  //     pathHistory: {
  //       type: Array,
  //       default: () => [],
  //     },
  //   },
  data: () => ({
    secondsMinutes,
    hide: false,
  }),
  methods: {
    destroyWarning(event: Event) {
      // if processing uploads warn user before
      // destroying component and clearing uploads

      if (this.isUploading) {
        // browser popup
        if (event.type === "beforeunload") {
          event.preventDefault();
        } else {
          // app dialog
          this.$store.commit("Storage/setCancelUploadDialogState", true);
        }
      } else {
        this.dialog = false;
        this.onDestroy();
      }
    },
    onDestroy() {
      // normal close (i.e. cancel dialog) will clear list
      // in case anything else ends upload dialog life cycle
      // make sure it reverts back to default (empty)

      // close upload dialog
      this.dialog = false;
      // reset isUploading state to false
      this.isUploading = false;
      // clear upload file list
      this.uploadFiles = [];
      this.uploadAllFiles = true;
      // TODO: handle multipart upload for cost, so they don't persist
      // remove event listener so user can close window/tab normally
      // closing upload dialog clears all active uploads
      window.removeEventListener("beforeunload", this.destroyWarning);
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["Storage/getUploadDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Storage/setUploadDialogState", state);
      },
    },
    isUploading: {
      get() {
        return this.$store.getters["Storage/getIsUploading"];
      },
      set(state) {
        this.$store.commit("Storage/setIsUploading", state);
      },
    },
    uploadFiles: {
      get() {
        return this.$store.getters["Storage/getUploadFiles"];
      },
      set(uploadFiles) {
        this.$store.commit("Storage/setUploadFiles", uploadFiles);
      },
    },
    uploadAllFiles: {
      get() {
        return this.$store.getters["Storage/getUploadAllFiles"];
      },
      set(reset: boolean) {
        this.$store.commit("Storage/resetUploadAllFiles", reset);
      },
    },
    isImageResizing: {
      get() {
        return this.$store.getters["Storage/getIsImageResize"]?.resize;
      },
    },
    estimatedTime() {
      return this.$store.getters["Storage/getEsitmatedUploadTime"].estimate;
    },
  },
  created() {
    // if uploads are still in progress warn user before closing tab/window
    window.addEventListener("beforeunload", this.destroyWarning);
  },
  destroyed() {
    // run destroy function
    this.onDestroy();
  },
});
