var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{attrs:{"app":"","width":"400"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"accent","dark":""}},[_c('v-toolbar-title',{staticClass:"font-weight-light"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","tile":"","depressed":"","color":"white"},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/switchSidebar')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-icon',[_vm._v("mdi-cube-scan")]),_vm._v(" "+_vm._s(_vm.ifcBuilding.title)+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","tile":"","depressed":"","color":"white"},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/switchSidebar', true)}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),(!_vm.loading)?_c('div',[_vm._l((_vm.ifcBuilding.building.storeys),function(storey,index){return [_c('v-expansion-panels',{key:storey.id,attrs:{"flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":"","color":"grey lighten-3"},on:{"mouseenter":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
                id: storey.id,
                modelId: _vm.getCurrentLeftSideBarModelId(),
              })},"mouseleave":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
                id: storey.id,
                modelId: _vm.getCurrentLeftSideBarModelId(),
              })}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/ifcInteraction', {
                    action: 'isolation',
                    payload: storey,
                  })}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-select-group")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/ifcInteraction', {
                    action: 'visibility',
                    payload: storey,
                  })}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters["PointCloudViewer/getIfcHidden"].includes(storey.id) ? "mdi-eye-off" : "mdi-eye"))])],1),_c('v-btn',{attrs:{"icon":"","small":"","plain":""}},[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-chevron-down")])],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(storey.displayname)+" ")]),(storey.groups.length > 0)?_c('IFCStoreyGroup',{attrs:{"items":storey.groups}}):_vm._e()],1)],1),(index < _vm.ifcBuilding.building.storeys.length - 1)?_c('v-divider',{key:'div-' + index}):_vm._e()]})],2):_c('div',{staticClass:"fill-height d-flex flex-column align-center mt-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Loading IFC attachments")]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"width":3,"color":"primary","indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }