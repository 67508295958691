
import Vue from "vue";

import RoleChip from "@/components/role-chip.vue";
import { User } from "@/store/modules/AccountManagement/types";
import { Roles } from "@/types/enums";
import { SnackbarColors } from "@/types/vuetify";
import { emailRules, fileSizeRules, generatePassword, passwordRules } from "@/utilities";

import ImportDialog from "./import-dialog.vue";

export default Vue.extend({
  name: "ImportUsersFromCSV",
  components: {
    ImportDialog,
    RoleChip,
  },
  data: () => ({
    roles: Roles,
    fileSizeRules,
    fileString: "",
    validatedUsers: [] as Array<User>,
    file: null,
    errors: [] as Array<string>,
    validateLoading: false,
    isButtonActive: false,
    usersCopy: [] as Array<string>,
    showErrorsChip: true,
    shortErrors: [] as Array<string>,
    shortValidatedUsers: [] as Array<User>,
    canShowMoreErrorsBtn: false,
    canShowMoreErrorsState: false,
    showMoreErrorsText: "Show all",
    showMoreValidatedUsersText: "Show all",
    canSkipErrors: false,
    canShowMoreUsersState: false,
    canShowMoreUsersBtn: false,
    numberOfShownErrors: 5,
    numberOfShownValidatedUsers: 10,
  }),
  methods: {
    validate() {
      if (this.validatedUsers.length) {
        this.$store.dispatch("AccountManagement/createUsers", this.validatedUsers);
      } else {
        // This should not be possible since "Create" should be disabled if there are no valid users however, if this happens for some reason user should be informed that an error occurred.
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: "There are no valid users to import",
            color: SnackbarColors.ERROR,
          },
          { root: true }
        );
      }
      // Whatever is currently showing should reset clear all inputs and fields.
      this.clear();
      this.file = null;
    },
    dataValidate() {
      this.clear();
      // no need for extra loading indicator, use AccountManagement/setLoadingState
      this.usersCopy = this.fileString
        // remove empty spaces
        .replace(/[ ]/g, "")
        // replace semicolon with commas
        .replace(/;/g, ",")
        // split by unicode compliant line ending
        .split(/\r\n|(?!\r\n)[\n-\r\x85\u2028\u2029]/)
        // remove empty lines
        .filter((data) => data);
      if (!this.usersCopy.length) {
        this.errors.push("File is empty");
        this.showErrorsChip = false;
        this.displayController();
        return null;
      }
      const emailNotRepeat = new Set();
      let user = {} as User;
      const formattedUsers = this.usersCopy.map((rawUser, index) => {
        let [email, password, role] = rawUser.split(",");

        // make sure email is always lower case
        email = email.toLowerCase();

        // missing commas
        if (!password) {
          password = "";
        }
        // missing commas
        if (!role) {
          role = "";
        }

        const [emailNotEmpty, emailValidation] = emailRules(email);
        const [passNotEmpty, passValidation] = passwordRules(password);
        const roleValidation = Object.values(Roles).includes(role.toLowerCase() as Roles);

        // concatenating errors from one line
        let lineErrors = "";

        if (emailValidation === true && emailNotEmpty === true) {
          if (!emailNotRepeat.has(email)) {
            emailNotRepeat.add(email);
            user = { ...user, email: email };
          } else {
            lineErrors += `"${email}": duplicate entry ${[...emailNotRepeat].indexOf(email) + 1}, `;
          }
        } else {
          if (emailNotEmpty !== true) {
            lineErrors += emailNotEmpty + ", ";
          } else {
            lineErrors += `"${email}": ${emailValidation}, `;
          }
        }
        if (passValidation === true && passNotEmpty === true) {
          user = { ...user, password: password };
        } else {
          if (passNotEmpty !== true) {
            user = { ...user, password: generatePassword() };
          } else {
            lineErrors += `"${password}": ${passValidation}, `;
          }
        }
        if (roleValidation) {
          user = { ...user, role: role.toLowerCase() as Roles };
        } else {
          if (!role.length) {
            user = { ...user, role: Roles.GUEST };
          } else {
            lineErrors += `"${role.toLowerCase()}": incorrect role, `;
          }
        }
        if (lineErrors.length) {
          this.errors.push(`<strong>line ${index + 1}:</strong> ${lineErrors}`);
          this.showErrorsChip = true;
        } else {
          this.validatedUsers.push(user);
        }

        return user;
      });

      if (!this.errors.length && formattedUsers.length) {
        this.isButtonActive = true;
      }

      this.displayController();
    },
    readAndValidateFile() {
      this.$store.commit("AccountManagement/setLoadingState", true);

      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileString = event.target?.result as string;
        this.dataValidate();
      };

      // new blob for handling object null
      reader.readAsText(this.file || new Blob());
      this.clear();

      this.$store.commit("AccountManagement/setLoadingState", false);
    },
    clear() {
      this.validatedUsers = [];
      this.errors = [];
      this.shortErrors = [];
      this.shortValidatedUsers = [];
      this.isButtonActive = false;
      this.canShowMoreErrorsBtn = false;
      this.canShowMoreErrorsState = false;
      this.canShowMoreUsersState = false;
      this.canShowMoreUsersBtn = false;
      this.showMoreErrorsText = "Show all";
      this.showMoreValidatedUsersText = "Show all";
      this.canSkipErrors = false;
    },
    displayController() {
      // control number of shown content in error's and validated user's blocks
      // handling displayed content for errors
      if (this.errors.length) {
        this.isButtonActive = false;
        if (this.errors.length > this.numberOfShownErrors && !this.canShowMoreErrorsState) {
          this.canShowMoreErrorsBtn = true;
          this.shortErrors = this.errors.slice(0, this.numberOfShownErrors);
          this.showMoreErrorsText = `Show All (${this.errors.length - this.numberOfShownErrors})`;
        }
        if (this.canShowMoreErrorsState) {
          this.shortErrors = this.errors;
          this.canShowMoreErrorsBtn = true;
          this.showMoreErrorsText = `Hide`;
        }
        if (this.errors.length <= this.numberOfShownErrors) {
          this.shortErrors = this.errors;
          this.canShowMoreErrorsBtn = false;
        }
      }
      // handling displayed content for valid users
      if (this.validatedUsers.length) {
        this.isButtonActive = true;
        if (
          this.validatedUsers.length > this.numberOfShownValidatedUsers &&
          !this.canShowMoreUsersState
        ) {
          this.shortValidatedUsers = this.validatedUsers.slice(0, this.numberOfShownValidatedUsers);
          this.showMoreValidatedUsersText = `Show All (${
            this.validatedUsers.length - this.numberOfShownValidatedUsers
          })`;
          this.canShowMoreUsersBtn = true;
        }
        if (this.validatedUsers.length <= this.numberOfShownValidatedUsers) {
          this.shortValidatedUsers = this.validatedUsers;
          this.canShowMoreUsersBtn = false;
        }
        if (this.canShowMoreUsersState) {
          this.shortValidatedUsers = this.validatedUsers;
          this.canShowMoreUsersBtn = true;
          this.showMoreValidatedUsersText = `Hide`;
        }
        if (this.errors.length) {
          this.isButtonActive = false;
          if (this.canSkipErrors) {
            this.isButtonActive = true;
          }
        }
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["AccountManagement/getImportDialogState"];
      },
      set(state) {
        this.$store.commit("AccountManagement/setImportDialogState", state);
      },
    },
  },
});
