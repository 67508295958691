
import Vue from "vue";

import { FileBrowser } from "@/components/file-browser";

export default Vue.extend({
  name: "FileBrowserSingleSelect",
  props: {
    dialogState: {
      type: Boolean,
      default: false,
    },
    isViewerDialog: {
      type: Boolean,
      default: false,
    },
    isAttachmentDialog: {
      type: Boolean,
      default: false,
    },
    isProjectDialog: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FileBrowser,
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["Storage/getFileBrowserDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Storage/setFileBrowserDialogState", state);
      },
    },
  },
});
