import { MutationTree } from "vuex";

import { SettingsState } from "./types";

const settingsMutations: MutationTree<SettingsState> = {
  setViewerCanvas(state, canvas: HTMLCanvasElement) {
    state.viewerCanvas = canvas;
  },
};

export default settingsMutations;
