import { Color, Group, Mesh, MeshBasicMaterial, MeshLambertMaterial, Scene } from "three";
import { ActionTree, Commit, Dispatch } from "vuex";

import { WireframeAction } from "@/types/viewer";

import { IfcCurrentMaterial, SidebarsState } from "../sidebars/types";
import { State } from "../types";

const settingsActions: ActionTree<State & SidebarsState, unknown> = {
  /**
   * Update point cloud budget.
   *
   * @param {{ state: State & SidebarsState; }} { state }
   * @returns {void}
   */
  updatePointCloudBudget({ state }: { state: State & SidebarsState }): void {
    const {
      viewer,
      graphicsInspector: { pointBudget },
    } = state;
    viewer?.setPointBudget(pointBudget);
  },

  /**
   * Update FOV.
   *
   * @param {{ state: State & SidebarsState; }} { state }
   * @returns {void}
   */
  updateFov({ state }: { state: State & SidebarsState }): void {
    const {
      viewer,
      graphicsInspector: { fov },
    } = state;
    viewer?.setFOV(fov);
  },

  /**
   * Change background.
   *
   * @param {{ state: State & SidebarsState; }} { state }
   * @returns {void}
   */
  /*  changeBackground({ state }: { state: State & SidebarsState }): void {
    const { viewer, graphicsInspector } = state;
    
    viewer?.setBackground(graphicsInspector.background);
  }, */
  changeBackground({ getters }) {
    const { background, solidColor, gradientColorSky, gradientColorGround } =
      getters["getGraphicsInspector"];

    const viewer = getters["getViewer"];
    if (!viewer) return;

    viewer.setBackground(background);
    if (
      typeof viewer !== "undefined" &&
      typeof solidColor !== "undefined" &&
      typeof gradientColorSky !== "undefined" &&
      typeof gradientColorGround !== "undefined" &&
      background !== "skybox"
    ) {
      const canvas: HTMLCanvasElement = getters["getViewerCanvas"];

      switch (background) {
        case "solid":
          canvas.style.background = `linear-gradient(0deg, ${solidColor}, ${solidColor})`;
          break;
        case "gradient":
          canvas.style.background = `linear-gradient(0deg, ${gradientColorGround}, ${gradientColorSky})`;
          break;
      }
    }
  },
  /**
   * TODO: rename to change wireframe ?
   * Changes wireframe on/off and also switch all materials between basic & lambert
   * @param param0
   */
  changeGraphicsMode({ state, dispatch }: { state: any; dispatch: Dispatch }) {
    const { graphicsInspector, ifcModels } = state;
    const { wireframe, cameraMode, pointQuality } = graphicsInspector;

    // pointquality & splat renderer
    // disabled atm as we are not using it
    /* if (pointQuality === "high") {
      // create hqRenderer/splatRenderer
      state.viewer.useHQ = false;
      // Change point type to paraboloid on all pointclouds
      state.viewer.scene.scenePointCloud.children.forEach((child: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (child.hasOwnProperty("pcoGeometry")) {
          // paraboloid
          child.material.shape = PointShape.PARABOLOID;
          // adaptive size
          child.material.pointSizeType = PointSizeType.ADAPTIVE;
        }
      });
    } else if (cameraMode === "perspective") {
      // remove hqRenderer/splatRenderer
      state.viewer.useHQ = false;
      // Change point type to paraboloid on all pointclouds
      state.viewer.scene.scenePointCloud.children.forEach((child: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (child.hasOwnProperty("pcoGeometry")) {
          // circle, TODO: eval between RECTANGLE(SQUARE) & CIRCLE
          child.material.shape = PointShape.CIRCLE;
          // adaptive size, TODO: eval. FIXED & ATTENUATED
          child.material.pointSizeType = PointSizeType.ADAPTIVE;
        }
      });
    } */
    // change materials to MeshLambertMaterials otherwise MeshBasicMaterials
    ifcModels.forEach((model: any) => {
      if (wireframe) {
        dispatch("changeMaterials", { model, materialType: "basic" });
      } else {
        dispatch("changeMaterials", { model, materialType: "lambert" });
      }
      dispatch("wireframeHandler", {
        modelId: model.modelId,
        action: wireframe ? WireframeAction.SHOW : WireframeAction.HIDE,
      });
    });
  },
  changeMaterials({ getters }, { model, materialType }) {
    const scene: Scene = getters["getScene"];

    const newMaterialArray: any[] = [];
    if (materialType === "lambert") {
      model.materials = model.materials.map((material: MeshBasicMaterial | MeshLambertMaterial) => {
        const newMat = new MeshLambertMaterial({
          color: material.color,
          opacity: material.opacity,
          transparent: material.transparent,
          wireframe: material.wireframe,
          name: material.name,
          /*   polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1, */
        });
        newMaterialArray.push(newMat);
      });
    } else {
      model.materials = model.materials.map((material: any) => {
        const newMat = new MeshBasicMaterial({
          color: material.color,
          opacity: material.opacity,
          transparent: material.transparent,
          wireframe: material.wireframe,
          name: material.name,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
        });
        newMaterialArray.push(newMat);
      });
    }
    model.materials = newMaterialArray;
    scene.children.forEach((sceneObject: any) => {
      if (sceneObject.type === "Group") {
        if ((sceneObject as Group).name === model.modelId) {
          const theModel = sceneObject as Group;
          theModel.children.forEach((child: any) => {
            if (child instanceof Mesh) {
              child.material = newMaterialArray;
            }
          });
        }
      }
    });
  },
  updateGraphicsInspector(
    {
      state,
      commit,
      dispatch,
      getters,
    }: { state: any; commit: Commit; dispatch: Dispatch; getters: any },
    payload
  ) {
    const { colorType, swatchColor } = payload;
    if (swatchColor.length !== 7) {
      return;
    }

    const wireMaterial = getters["getWireframeMaterial"];

    if (colorType && swatchColor !== undefined) {
      switch (colorType) {
        case "modelMaterialColor":
          {
            let currentModelMaterial: IfcCurrentMaterial | undefined;
            if (colorType === "modelMaterialColor") {
              currentModelMaterial = getters["getIfcCurrentMaterial"];
            }

            if (!currentModelMaterial) {
              return;
            }
            const { material: modelMaterial, modelId } = currentModelMaterial;
            const model = state.ifcModels.find((ifcModel: any) => ifcModel.modelId === modelId);
            if (!model) {
              return;
            }
            if (swatchColor.length === 7) {
              modelMaterial.color = new Color(swatchColor);
            }

            commit("updateGraphicsInspector", {
              modelMaterialColor: swatchColor,
              modelMaterial: true,
            });
          }
          break;
        case "wireframeColor":
          wireMaterial.color = new Color(swatchColor);
          commit("updateGraphicsInspector", { wireframeColor: swatchColor });
          break;
        case "solidColor":
          commit("updateGraphicsInspector", { solidColor: swatchColor });
          dispatch("changeBackground");
          break;
        case "gradientColorSky":
          commit("updateGraphicsInspector", { gradientColorSky: swatchColor });
          dispatch("changeBackground");
          break;
        case "gradientColorGround":
          commit("updateGraphicsInspector", { gradientColorGround: swatchColor });
          dispatch("changeBackground");
          break;
        default:
          console.log("this is not yet a implemented path");
          break;
      }
    }
  },
  updateEDLStrength({ state }) {
    const { viewer, graphicsInspector } = state;
    viewer?.setEDLStrength(Number(graphicsInspector.edlStrength));
  },
  updateEDLRadius({ state }) {
    const { viewer, graphicsInspector } = state;
    viewer?.setEDLRadius(graphicsInspector.edlRadius);
  },
  updateEDLOpacity({ state }) {
    const { viewer, graphicsInspector } = state;
    viewer?.setEDLOpacity(graphicsInspector.edlOpacity);
    if (viewer) {
      viewer.edlOpacity = graphicsInspector.edlOpacity;
    }
  },
  setEDLOnOff({ state }) {
    const { viewer, graphicsInspector } = state;
    if (viewer) {
      viewer.useEDL = graphicsInspector.edlEnabled;
      viewer.edlEnabled = graphicsInspector.edlEnabled;
    }
  },
};

export default settingsActions;
