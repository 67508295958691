
import Vue from "vue";

import {
  BottomToolbar,
  DeleteDialog,
  LeftSidebar,
  LoadingDialog,
  ProjectTitlebar,
  RightSidebar,
  ViewerContainer,
} from "@/components/point-cloud-viewer";

export default Vue.extend({
  name: "PointCloudViewerView",
  components: {
    DeleteDialog,
    ProjectTitlebar,
    LeftSidebar,
    LoadingDialog,
    ViewerContainer,
    BottomToolbar,
    RightSidebar,
  },
  mounted() {
    const projectId = this.$route.params.projectId;
    this.$store.commit("Storage/setPath", projectId);
  },
});
