
import Vue from "vue";

import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import {
  AttachmentSectionLineItem,
  AttachmentType,
  InspectorAttachmentTypeInterface,
  VForm,
} from "@/types";
import { SnackbarColors } from "@/types/vuetify";

import AttachmentHeader from "./attachment-header.vue";
import ListItems from "./list-items.vue";

interface Data {
  dialog: boolean;
  path: string;
  title: string;
  description: string;
  link: Partial<InspectorAttachmentTypeInterface>;
  itemTableId?: string;
  modelContainerId?: string;
}

const defaultLink = {
  type: AttachmentType.LINK,
  items: [],
};

export default Vue.extend({
  name: "LinkAttachmentType",
  components: {
    AttachmentHeader,
    ListItems,
  },
  data: (): Data => ({
    dialog: false,
    path: "",
    title: "",
    description: "",
    link: defaultLink,
    itemTableId: undefined,
    modelContainerId: undefined,
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.path = "";
      this.title = "";
      this.description = "";
    },

    async validateForm() {
      const {
        $refs: { formAddLink },
        link: { id },
        path,
        title,
        description,
      } = this;

      const form = formAddLink as VForm;

      if (form.validate() && path !== "") {
        // Make sure we are saving a valid URL.
        const validURL = /^(?:http|https):\/\/.*$/.test(path) ? path : `https://${path}`;

        await this.$store.dispatch("PointCloudViewer/createAttachment", {
          sectionId: id,
          lineItem: {
            path: validURL.toLowerCase(),
            ...(title !== "" && { title }),
            ...(description !== "" && { description }),
          },
          sectionLineItem: AttachmentSectionLineItem.LINE_ITEM,
        });

        // Reset form and data.
        form.reset();
        this.closeDialog();
      } else {
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: "URL can not be empty",
            color: SnackbarColors.ERROR,
            timeout: 2,
          },
          { root: true }
        );
      }
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },
  },
  async mounted() {
    Vue.set(this.link, "items", []);

    const {
      inspector: { attachments },
    } = this;

    const attachment = attachments?.find(
      (item: InspectorAttachmentTypeInterface) => item.type === AttachmentType.LINK
    );

    if (attachment) {
      const { createdAt, createdBy, id, items } = attachment;
      Vue.set(this.link, "createdAt", createdAt);
      Vue.set(this.link, "createdBy", createdBy);
      Vue.set(this.link, "id", id);
      Vue.set(this.link, "items", items ?? []);
    }
  },
  destroyed() {
    this.path = "";
    this.title = "";
    this.description = "";
    this.link = defaultLink;
    this.itemTableId = undefined;
    this.modelContainerId = undefined;
  },
});
