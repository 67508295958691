import { GetterTree } from "vuex";
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel";

import { attachmentsGetters } from "./attachments";
import { modelsGetters } from "./models";
import { pointsGetters } from "./points";
import { settingsGetters } from "./settings";
import { sidebarsGetters } from "./sidebars";
import { transformGetters } from "./transform";
import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  /**
   * Spread getters from other modules.
   */
  ...attachmentsGetters,
  ...modelsGetters,
  ...pointsGetters,
  ...sidebarsGetters,
  ...transformGetters,
  ...settingsGetters,

  getSelectDialogState(state) {
    return state.selectDialog;
  },

  getDeleteDialog: (state) => {
    return state.deleteDialog;
  },

  getScene: (state) => {
    return state.scene;
  },

  getPotreeScene: (state) => {
    return state.potreeScene;
  },

  getLoadingProgress: (state) => {
    return state.loadingProgress;
  },

  getLoadingState: (state) => {
    return state.loading;
  },

  getViewerName: (state) => {
    return state.viewerName;
  },
  getViewer: (state) => {
    return state.viewer;
  },
};

export default getters;
