import { ActionTree, Commit } from "vuex";

import router from "@/router";
import { PointCloudDataFileTypes, SnackbarColors, TagItemInterface } from "@/types";
import { Roles } from "@/types/enums";
import { cleanFileName, formatBytes } from "@/utilities";

import { State } from "./types";

const actions: ActionTree<State, unknown> = {
  /**
   * Reroute to a route with `name`.
   *
   * @returns {void}
   */
  reroute(_, name: string): void {
    router.push({ name });
  },

  copyToClipboard({ commit }, payload: { string: string; reference?: string }): void {
    const { string, reference } = payload;
    navigator.clipboard.writeText(string);
    commit("showSnackbar", {
      message: `${reference} copied to clipboard`,
      color: "success",
      timeout: 2,
    });
  },

  roleStyle(_, role: Roles) {
    const roleStyle = {
      title: role,
      color: "",
    };

    switch (role) {
      case Roles.OWNER:
        roleStyle.color = "black";
        break;
      case Roles.ADMIN:
        roleStyle.color = "green";
        break;
      case Roles.EDITOR:
        roleStyle.color = "orange";
        break;
      case Roles.GUEST:
        roleStyle.color = "blue";
        break;
      case Roles.USER:
        roleStyle.color = "purple";
        break;

      default:
        roleStyle.color = "grey";
        break;
    }
    return roleStyle;
  },

  /**
   * Looks for the array index of a tag with `id` in the store `Tags`.
   *
   * @param {{ getters: any }} { getters }
   * @param {string} id The ID of the tag to find.
   * @returns {(number | undefined)}
   */
  getTagIndex({ rootGetters }: { rootGetters: any }, id: string): number | undefined {
    // Get all tags from the store.
    const tags: TagItemInterface[] = rootGetters["Tags/getTags"];

    // Look for a tag with `id` matching the payload `id`.
    return tags?.findIndex((tag) => tag.id === id);
  },

  /**
   * Tries to find a tag with `index` from the store `Tags`.
   *
   * @param {{ getters: any }} { getters }
   * @param {number} index The index of the tag to get.
   * @returns {(TagItemInterface | undefined)}
   */
  getTagByIndex(
    { rootGetters }: { rootGetters: any },
    index: number
  ): TagItemInterface | undefined {
    // Get all tags from the store.
    const tags: TagItemInterface[] = rootGetters["Tags/getTags"];

    // Look for a tag on `index` in `tags`.
    return tags[index];
  },

  /**
   * Usefull for checking if a list of required variables are set.
   *
   * Takes a list of objects with a `test` and `message` property, where:
   *
   * - `test` is a boolean that is true if the variable is set.
   * - `message` is a string that is used as the message in the snackbar if the test results in false.
   *
   * @param {{ commit: Commit }} { commit }
   * @param {{ test: boolean; message: string; }[]} payload
   * @returns {boolean}
   */
  requiredVariableCheck(
    { commit }: { commit: Commit },
    payload: {
      test: boolean;
      message: string;
    }[]
  ): boolean {
    const missingData: string[] = [];

    payload.forEach((item) => {
      const { message, test } = item;
      if (!test) {
        missingData.push(`<li><strong>${message}</strong></li>`);
      }
    });

    if (missingData.length > 0) {
      commit(
        "Utilities/showSnackbar",
        {
          message: `Missing: <ul>${missingData.join("")}</ul>`,
          color: "error",
          timeout: 2,
        },
        { root: true }
      );

      return false;
    }

    return true;
  },

  /**
   * This utility tests the content of the array of files to see if they are a Potree point cloud data set.
   * Potree (v2 converter) files are:
   * - hierarchy.bin
   * - metadata.json
   * - octree.bin
   *
   * @param {unknown} _
   * @param {File[]} files
   * @returns {Promise<boolean>}
   */
  async isValidPotreeDataSet(_: unknown, files: File[]): Promise<boolean> {
    if (files.length !== 3) {
      return false;
    }
    const potreeDataSet = ["hierarchy.bin", "metadata.json", "octree.bin"];

    return (
      files.some((file) => file.name.includes(potreeDataSet[0])) &&
      files.some((file) => file.name.includes(potreeDataSet[1])) &&
      files.some((file) => file.name.includes(potreeDataSet[2]))
    );
  },

  /**
   * Verifies the file extension of the uploaded file, making sure it is a supported point cloud data file type.
   *
   * @param {unknown} _
   * @param {File[]} files
   * @returns {Promise<boolean>}
   */
  async isValidPointCloudDataFile(_: unknown, files: File[]): Promise<boolean> {
    if (files.length !== 1) {
      return false;
    }

    // Get file extension.
    const fileExtension = files[0].name.split(".").pop();

    return (
      fileExtension !== undefined &&
      (Object.values(PointCloudDataFileTypes) as string[]).includes(fileExtension)
    );
  },

  /**
   * Verify point cloud data before continuing.
   *
   * @param files Array of files to verify.
   * @returns {Promise<boolean>}
   */
  async verifyPointCloudData({ commit, dispatch }, files: File[]): Promise<boolean> {
    // Size limit for point cloud files is currently 25GB.
    const byte = 1024;
    const sizeLimit = 25 * byte ** 3;
    const overLimit = files.some((file) => file.size > sizeLimit);

    if (overLimit) {
      commit("Utilities/showSnackbar", {
        message: `Max file size is currently ${formatBytes(sizeLimit, 0)}`,
        color: SnackbarColors.ERROR,
      });
      return false;
    }

    const isPotree = await dispatch("isValidPotreeDataSet", files);
    const isAllowedFile = await dispatch("isValidPointCloudDataFile", files);

    if (!isPotree && isAllowedFile) {
      const { name: fileName } = files[0];
      const cleanName = cleanFileName(fileName);

      // Set point cloud item data for dialog
      commit(
        "Viewers/setPointCloudUploadDialogItemData",
        {
          name: cleanName,
        },
        { root: true }
      );
    }

    const canContinue = isPotree || isAllowedFile;

    if (!canContinue) {
      commit("Storage/setPath", "", { root: true });
      commit("showSnackbar", {
        message: `Unsupported file type`,
        color: SnackbarColors.ERROR,
      });
    }

    return canContinue;
  },
};

export default actions;
