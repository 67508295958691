import { AuthorInterface } from "./general";

export enum AttachmentSectionLineItem {
  SECTION = "section",
  LINE_ITEM = "lineItem",
}

export enum AttachmentType {
  NOTE = "note",
  LINK = "link",
  FILE = "file",
}

/**
 * These are used to determine the how to process the data in the backend.
 *
 * @export
 * @enum {number}
 */
export enum AttachmentObjectItemType {
  IFC_MODEL_ITEM = "ifcModelItem",
  MEASUREMENTS = "measurements",
  MODEL = "model",
  POINT_OF_INTEREST = "pointOfInterest",
  POINTCLOUD = "pointcloud",
}

/**
 * Collection of all available attachment interfaces.
 *
 * @typedef {AvailableAttachmentInterfaces}
 */
export type AvailableAttachmentInterfaces =
  | InspectorAttachmentNoteInterface
  | InspectorAttachmentLinkInterface
  | InspectorAttachmentFileInterface;

/**
 * The base interface for individual attachment items.
 *
 * See individual attachment interfaces for specifics:
 * - {@link InspectorAttachmentNoteInterface}
 * - {@link InspectorAttachmentLinkInterface}
 * - {@link InspectorAttachmentFileInterface}
 *
 * @export
 * @interface InspectorAttachmentItemBaseInterface
 * @typedef {InspectorAttachmentItemBaseInterface}
 */
export interface InspectorAttachmentItemBaseInterface {
  /**
   * Created at timestamp (UNIX Epoch).
   *
   * @type {number}
   */
  createdAt: number;

  /**
   * Created by author.
   *
   * @type {AuthorInterface}
   */
  createdBy: AuthorInterface;

  /**
   * Optional description.
   *
   * @type {?string}
   */
  description?: string;

  /**
   * Unique identifier of attachment item. This is generated in backend when item is created.
   *
   * @type {string}
   */
  id: string;

  /**
   * When handling IFC model attachments, there must be a `globalId` value present.
   *
   * Used to identify IFC model item in table item `items` array in backend.
   *
   * @type {?string}
   */
  globalId?: string;

  /**
   * When handling model attachments, there must be an `tableItemId` value present.
   *
   * Used to construct sort key on composite key with partition key `MODEL-ATTACHMENTS`.
   *
   * @type {?string}
   */
  tableItemId?: string;

  /**
   * Update at timestamp (UNIX Epoch).
   *
   * @type {?number}
   */
  updatedAt?: number;

  /**
   * Update by author.
   *
   * @type {?AuthorInterface}
   */
  updatedBy?: AuthorInterface;
}

/**
 * AttachmentType.NOTE `type` attachment item.
 *
 * Since description is optional in {@link InspectorAttachmentItemBaseInterface}, we need to overwrite it because it is required in the context of this interface.
 *
 * @export
 * @interface InspectorAttachmentNoteInterface
 * @typedef {InspectorAttachmentNoteInterface}
 */
export interface InspectorAttachmentNoteInterface extends InspectorAttachmentItemBaseInterface {
  /**
   * Content of the note.
   *
   * @type {string}
   */
  description: string;
}

/**
 * AttachmentType.LINK `type` attachment item.
 *
 * @export
 * @interface InspectorAttachmentLinkInterface
 * @typedef {InspectorAttachmentLinkInterface}
 */
export interface InspectorAttachmentLinkInterface extends InspectorAttachmentItemBaseInterface {
  /**
   * The URL for the link.
   *
   * @type {string}
   */
  path: string;

  /**
   * Optional title for the link.
   *
   * @type {?string}
   */
  title?: string;
}

/**
 * AttachmentType.FILE `type` attachment item.
 *
 * @export
 * @interface InspectorAttachmentFileInterface
 * @typedef {InspectorAttachmentFileInterface}
 */
export interface InspectorAttachmentFileInterface extends InspectorAttachmentItemBaseInterface {
  /**
   * File name.
   *
   * @type {string}
   */
  name: string;

  /**
   * Reference to S3 object.
   *
   * @type {string}
   */
  objectName: string;

  /**
   * File type is extracted from `objectName`.
   *
   * @type {string}
   */
  type: string;
}

export interface InspectorAttachmentTypeInterface {
  /**
   * Created at timestamp (UNIX Epoch).
   *
   * @type {number}
   */
  createdAt: number;

  /**
   * Created by author.
   *
   * @type {AuthorInterface}
   */
  createdBy: AuthorInterface;

  /**
   * The ID of the attachment type.
   *
   * @type {string}
   */
  id: string;

  /**
   * Array of items for an attachment type.
   *
   * @type {AvailableAttachmentInterfaces[]}
   */
  items?: AvailableAttachmentInterfaces[];

  /**
   * This helps identify in which table item the model point is located. It is used to construct the sort key on the composite key with partition key `MODEL-ATTACHMENTS`.
   *
   * **NOTE** `tableItemId` is added to the attachment item in the backend response. I.e. it is not part of the data structure in the database.
   *
   * @example `pk: MODEL-ATTACHMENTS, email:[MODEL_CONTAINER_ID]#[TABLE_ITEM_ID]`
   *
   * @type {string}
   */
  tableItemId?: string;

  /**
   * Attachment type, one of {@link AttachmentType}.
   *
   * @type {string}
   */
  type: AttachmentType;
}

/**
 * This is the top level of the attachment data structure for models.
 *
 * @export
 * @interface ModelInpsectorAttachmentInterface
 * @typedef {ModelInpsectorAttachmentInterface}
 */
export interface ModelInpsectorAttachmentInterface {
  /**
   * Array of attachments for a model point.
   *
   * @type {InspectorAttachmentTypeInterface[]}
   */
  attachments: InspectorAttachmentTypeInterface[];

  /**
   * The unique identifier for the model point (a.k.a. IFC component). In IFC models, this is the `GlobalId` attribute. Since "GlobalId" is a very generic term, we use "modelPointGlobalId" to make it more specific.
   *
   * @type {string}
   */
  globalId: string;

  /**
   * This helps identify in which table item the model point is located. It is used to construct the sort key on the composite key with partition key `MODEL-ATTACHMENTS`.
   *
   * **NOTE** `tableItemId` is added to the attachment item in the backend response. I.e. it is not part of the data structure in the database.
   *
   * @example `pk: MODEL-ATTACHMENTS, email:[MODEL_CONTAINER_ID]#[TABLE_ITEM_ID]`
   *
   * @type {string}
   */
  tableItemId?: string;
}
