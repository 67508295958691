
import Vue from "vue";

export default Vue.extend({
  name: "FileBrowserShareFileDialog",
  data: () => ({
    showUrl: false,
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$store.commit("Storage/resetContextItem");
    },
    copyToClipboard() {
      const string = this.shareUrl;
      const reference = this.fileName;
      this.$store.dispatch("Utilities/copyToClipboard", { string, reference });
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Storage/getShareDialogState"];
      },
      set(state) {
        this.$store.commit("Storage/setShareDialogState", state);
      },
    },
    fileName() {
      return this.$store.getters["Storage/getContextItem"].name;
    },
    shareUrl() {
      return this.$store.getters["Storage/getShareUrl"];
    },
  },
});
