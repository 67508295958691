import { BoxHelper, Camera, Group, Scene, Vector2, Vector3, WebGLRenderer } from "three";
import { ActionTree } from "vuex";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { intersectObjectWithRay, TransformControls } from "./transform-controls";
import { TransformState } from "./types";

const transformActions: ActionTree<State & ModelsState & SidebarsState & TransformState, unknown> =
  {
    // ------------------------
    // TRANSFORMCONTROLS
    // ------------------------
    constructPivot({ commit }, payload) {
      const { obj, scene } = payload;
      const objCenter = obj.geometry.boundingBox.getCenter(new Vector3());

      // debug
      const box = new BoxHelper(obj, 0xffff00);
      scene.add(box);

      const pivot = new Vector3(objCenter.x, objCenter.y, objCenter.z);

      /*   const pos = new Vector3(obj.position.x, obj.position.y, obj.position.z);
    pivot.add(pos); */
      commit("setTransformSelectionPivot", pivot);
    },

    /**
     * Inititates transformControls on the provided selection
     * @param payload  camera, renderer, selection : Group(threejs)
     */
    transformControls(
      { getters, state, commit, dispatch },
      payload: { camera: Camera; renderer: WebGLRenderer; selection: Group }
    ) {
      const { camera, renderer, selection } = payload;

      // validation
      if (!camera || !renderer || !selection) {
        throw new Error("Missing element. Can't enable transform-controls!");
      }
      if (selection.type !== "Group") {
        throw new Error("TransformControls is made to work on threejs-Groups only");
      }
      // TODO: fragile way of checking if ifc-model
      const isIfc = typeof selection.name === "number";
      isIfc && dispatch("ifcSubsetRaycast");

      const scene: Scene = getters["getScene"];

      const controls = new TransformControls(payload.camera, payload.renderer.domElement);
      controls.name = "TransformControls";
      commit("setViewerTransformTool", controls);

      controls.attach(selection);
      // commit("showActiveSelectionEffectOnObject", selection); // TODO: unfinished
      //dispatch("constructPivot", { obj: selection, scene }); // TODO: unfinished
      scene.add(controls);

      // remove TransformControls eventlisteners
      commit("removeInternalTransformEvents", { controls });

      // add own eventlisteners to TransformControls-class, so we can add own logic
      // the logic in this case is beeing able to disable OrbitControls
      // when dragevent occurs in transformcontrols
      // otherwise we orbit around at the same time as transforming the object with the gizmo.
      controls.domElement.addEventListener("pointerdown", (event: any) => {
        // if we right-mouse-click we should exit transform
        if (event.button === 2) {
          dispatch("exitTransform", { controls, scene });
        } else {
          commit("handleTransformControlsPointerDown", { controls, event });
        }
      });
      controls.domElement.addEventListener("pointermove", (event: any) => {
        const axis = controls.axis;
        const mode = controls.mode;
        const object = controls.object;
        let space = controls.space;

        if (mode === "scale") {
          space = "local";
        } else if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
          space = "world";
        }

        if (
          object === undefined ||
          axis === null ||
          controls.dragging === false ||
          controls._getPointer(event).button !== -1
        )
          return;

        const { x, y } = controls._getPointer(event);

        controls.getRaycaster().setFromCamera(new Vector2(x, y), controls.camera);

        const planeIntersect: any = intersectObjectWithRay(
          controls._plane,
          controls.getRaycaster(),
          true
        );

        if (!planeIntersect) return;
        if (!controls.enabled) return;

        // PotreeJS-OrbitControls should now be set to disabled on pointerdown/pointerMove/mousedrag
        // as the raycaster has confirmed we are using the transformcontrols
        commit("setOrbitControlDrag", false);

        const payload = {
          controls,
          pointer: controls.pointer,
          planeIntersect,
          mode,
          space,
          axis,
          object,
          event,
        };

        commit("handleTransformControlsPointerMove", payload);
      });

      controls.domElement.addEventListener("pointerup", (event: any) => {
        // OrbitControls should now be set to enabled again on pointerdown/pointerMove/mousedrag
        commit("setOrbitControlDrag", true);
        commit("handleTransformControlsPointerUp", { controls, event });
        if (!controls.object) return;

        // update ifc-subsets
        // ifc-group has a number as name and is here used detect model type
        // TODO: find a better way to detect model type
        if (typeof state.transformSelection?.name !== "number") {
          return;
        }
        // if ifc,get the type out of controls.mode, but rename the strings.
        const type =
          controls.mode === "translate"
            ? "position"
            : controls.mode === "rotate"
            ? "rotation"
            : "scale";
        // update transformvalues for both x,y,z as we don't know what value was updated
        commit("setSubsetTransformValues", { type, axis: "x" });
        commit("setSubsetTransformValues", { type, axis: "y" });
        commit("setSubsetTransformValues", { type, axis: "z" });
      });
      // TODO: we should add an eventlistener that acts on mousehover on transformcontrols so user gets feedback
      // when they are hovering over the control-gizmo
      // TODO: we should have an eventlistener here that gets us OUT of transformcontrols and/or modelselection.
      dispatch("addKeyboardTransformModeKeys", controls);
    },
    async exitTransform({ commit }, payload) {
      const { controls } = payload;
      // TODO: this exit is not complete. If entering & exiting we eventually crash on controls.object not in payload
      // may be fixed. needs testing

      commit("detachObjectFromTransformControls", { controls });
      commit("setRightSideBarTransform", false);
      commit("removeTransformFromScene");
      commit("setTransformSelection", null);
      commit("removeInternalTransformEvents", { controls });
      commit("setViewerTransformTool", null);
      commit("setLeftSidebar", false);
    },
    /**
     * TODO: should these eventlisteners be removed when leaving transformcontrols?
     * Adds keyboard controls for the mode of transformation: scale,rotate,translate
     * @param controls
     */
    addKeyboardTransformModeKeys(_, controls) {
      window.addEventListener("keydown", function (event) {
        switch (event.code) {
          case "KeyW":
            controls.setMode("translate");
            break;
          case "KeyR":
            controls.setMode("scale");
            break;
          case "KeyE":
            controls.setMode("rotate");
            break;
        }
      });
    },
    // update subsets
    updateTransform({ state, commit }, payload) {
      // ifc-group has a number as name and is here used detect model type
      if (typeof state.transformSelection?.name !== "number") {
        return;
      }
      // if ifc-group, go on and update subsets transformvalues
      commit("setSubsetTransformValues", payload);
    },
  };

export default transformActions;
