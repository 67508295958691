
import Vue from "vue";

import { ViewerLayerDataCategoryKey } from "@/types";

export default Vue.extend({
  name: "ViewerLayerItemActionDeleteLayerItem",
  props: {
    itemkey: String,
    item: Object,
    type: String as () => ViewerLayerDataCategoryKey,
  },
  methods: {
    /**
     * Uses the `createdBy` object to determine if the current user can delete the item.
     *
     * Can delete:
     * - `Roles.SU`, `Roles.OWNER`, `Roles.ADMIN`: all, by default.
     * - `Roles.EDITOR`: When user is assigned the editor role they can only delete items they created.
     *
     * @returns {boolean}
     */
    showDeleteButton(): boolean {
      // List of layer item types that can be deleted.
      const availableIn = [
        ViewerLayerDataCategoryKey.MEASUREMENTS,
        ViewerLayerDataCategoryKey.POINT_OF_INTEREST,
      ];

      // Check if the current user has the right role to delete the item.
      if (availableIn.includes(this.type)) {
        const { createdBy } = this.item;

        if (createdBy && createdBy.id) {
          return this.$store.getters["User/getCanEdit"](createdBy.id);
        }
      }

      return false;
    },

    /**
     * Shows the delete dialog and sets the delete dialog object, i.e. the details about the item that is about to be deleted.
     *
     * @returns {void}
     */
    showDeleteDialog(): void {
      const {
        itemkey: id,
        item: { title },
        type,
      } = this;

      const deleteDialogObject = {
        state: true,
        id,
        type,
        message: `Delete ${type} <strong>${title}</strong>?`,
      };

      this.$store.commit("PointCloudViewer/setDeleteDialog", deleteDialogObject);
    },
  },
});
