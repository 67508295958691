const supportedModelTypes = [
  {
    type: "fbx",
    title: "FBX",
  },
  {
    type: "ifc",
    title: "IFC",
  },
];

const availableContainers = [
  {
    type: "pointCloud",
    title: "Point Cloud",
  },
  {
    type: "model",
    title: "3D Model",
  },
];

const getContainerTitle = (type: string): string | undefined => {
  const hasType = availableContainers.find((container) => container.type === type);

  if (hasType) {
    return hasType.title;
  }

  return undefined;
};

export { availableContainers, getContainerTitle, supportedModelTypes };
