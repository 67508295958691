
import Vue from "vue";

import Author from "@/components/author.vue";
import { TagItemInterface, VForm } from "@/types";
import { titleRules } from "@/utilities";

interface Data {
  /**
   * Internal working state of the dialog. Used to disable the dialog and show a loading indicator. It does not use the `Tag` store state `isLoading` since that would result in the page loading indicator to show.
   *
   * @type {boolean}
   * @default false
   */
  working: boolean;

  /**
   * Valid state of the form.
   *
   * @type {boolean}
   * @default true
   */
  valid: boolean;

  /**
   * Form changed state.
   *
   * @type {boolean}
   * @default false
   */
  hasChange: boolean;

  /**
   * Title validation rules. Title is required.
   *
   * @param {string} value The title value.
   * @returns {Array<boolean | string>} The validation rules.
   */
  titleRules: (value: string) => Array<boolean | string>;
}

export default Vue.extend({
  name: "EditTagDialog",
  data: (): Data => ({
    working: false,
    valid: true,
    hasChange: false,
    titleRules,
  }),
  components: {
    Author,
  },
  methods: {
    /**
     * Close the dialog and clear the selected tag from the store. Set a delay to reset the selected tag to handle undefined errors.
     *
     * @returns {void}
     */
    closeDialog(): void {
      if (this.working) {
        return;
      }
      this.editDialogState = false;
      setTimeout(() => {
        this.selectedTag = undefined;
      }, 500);
    },

    /**
     * Validate the form and update the tag.
     *
     * @returns {Promise<void>}
     */
    async updateTag(): Promise<void> {
      // No changes, stop validation.
      if (!this.hasChange) {
        return;
      }
      // Set internal working state to true.
      this.working = true;

      let successfulUpdate = false;
      // Validate the form.
      if ((this.$refs.form as VForm).validate()) {
        successfulUpdate = await this.$store.dispatch("Tags/updateTag", this.selectedTag);
      }

      this.working = false;
      if (successfulUpdate) {
        this.closeDialog();
      }
    },

    /**
     * Validate the form and delete the tag.
     *
     * @returns {Promise<void>}
     */
    async deleteTag(): Promise<void> {
      // Set internal working state to true.
      this.editDialogState = false;
      this.$store.commit("Tags/setDeleteDialogState", true);
      // this.working = true;

      // let successfulDelete = false;
      // const { tableItemId, id, title } = this.selectedTag as TagItemInterface;
      // successfulDelete = await this.$store.dispatch("Tags/deleteTag", {
      //   tableItemId,
      //   id,
      //   title,
      // });

      // this.working = false;
      // if (successfulDelete) {
      // this.closeDialog();
      // }
    },

    /**
     * Copy the stringified tag object to clipboard.
     *
     * @returns {void}
     */
    copyTagInfo(): void {
      this.$store.dispatch("Utilities/copyToClipboard", {
        string: JSON.stringify(this.storeTag),
        reference: this.storeTag.title,
      });
    },
  },
  computed: {
    /**
     * Get the selected tag from the store. This is to avoid the two-way data binding on some values when editing in the form.
     *
     * @returns {TagItemInterface} The selected tag.
     */
    storeTag(): TagItemInterface {
      return this.$store.getters["Tags/getSelectedTag"];
    },

    /**
     * Assign the selected store tag to a new object, to avoid modifying the store directly.
     *
     * @returns {TagItemInterface} A new object from the selected store tag.
     */
    selectedTag: {
      get(): TagItemInterface {
        const storeTag = this.storeTag;

        // Account for undefined description.
        const assignedTag = {
          ...storeTag,
          ...(!storeTag.description && { description: "" }),
        };

        return Object.assign({}, assignedTag);
      },
      set(data?: TagItemInterface) {
        this.$store.commit("Tags/setSelectedTag", data);
      },
    },

    /**
     * Get the edit dialog state from the store.
     *
     * @returns {boolean} The edit dialog state.
     */
    editDialogState: {
      get(): boolean {
        return this.$store.getters["Tags/getEditDialogState"];
      },
      set(value: boolean) {
        this.$store.commit("Tags/setEditDialogState", value);
      },
    },
  },
});
