
import Vue from "vue";

import { IFCModelItemInspectorInterface } from "@/types";

export default Vue.extend({
  name: "ViewerInspectorTabItemsDetailsPoint",
  computed: {
    inspector(): IFCModelItemInspectorInterface {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },
  },
});
