
import Vue from "vue";

export default Vue.extend({
  name: "ViewerDeleteDialog",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleteInputConfirm: "",
  }),
  methods: {
    async confirmDeleteAction() {
      await this.$store.dispatch("Viewers/deleteViewer", this.id);
      this.dialogState = false;
    },
  },
  computed: {
    disableConfirm(): boolean {
      const isInputDelete = this.deleteInputConfirm === "delete";
      return !isInputDelete || this.loading;
    },

    loading(): boolean {
      return this.$store.getters["Viewers/getLoadingState"];
    },

    dialogState: {
      get(): boolean {
        return this.$store.getters["Viewers/getDeleteDialog"];
      },
      set(value: boolean) {
        this.$store.commit("Viewers/setDeleteDialog", value);
      },
    },
  },
});
