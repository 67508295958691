
import Vue from "vue";

export default Vue.extend({
  name: "AppContainerLeftSidebarLogotype",
  props: {
    miniSidebar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    signOut() {
      this.$store.dispatch("User/logOut");
    },
  },
});
