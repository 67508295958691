import { AuthorInterface } from "./general";
import { ViewerInspectorMode, ViewerLayerDataCategoryKey } from "./viewer";

/**
 * Tag group enum for grouping tags in viewer inspector.
 *
 * @export
 * @enum {number}
 */
export enum TagGroup {
  USED = "Viewer tags (used)",
  UNUSED = "Global tags (unused)",
}

/**
 * A single `tag` object.
 *
 * @export
 * @interface TagItemInterface
 * @typedef {TagItemInterface}
 */
export interface TagItemInterface {
  /**
   * Optional tag description.
   *
   * @type {?string}
   */
  description?: string;

  /**
   * Created timestamp (Unix Epoch).
   *
   * @type {number}
   */
  createdAt: number;

  /**
   * Created by with reference to user that created the tag.
   *
   * @type {AuthorInterface}
   */
  createdBy: AuthorInterface;

  /**
   * When dealing with IFC models, `expressId` is used to determine what parts of the IFC model we should be targeting for hover, show, hide, etc.
   *
   * @type {?number}
   */
  expressId?: number;

  /**
   * Optional group for tag. Used when grouping tags in viewer inspector tag selection list.
   *
   * @type {?TagGroup}
   */
  group?: TagGroup;

  /**
   * UUID of the tag.
   *
   * @type {string}
   */
  id: string;

  /**
   * Used to determine if the tag is generated automatically from IFC storey in `destructureIfcModelOnLoad` action durring `storeyPromises` loop.
   *
   * @type {?boolean}
   */
  isAuto?: boolean;

  /**
   * Optional array of items that are tagged with this tag. This is used in the viewer to determine what items are associated with this tag.
   *
   * @type {?TagItemItemsInterface[]}
   */
  items?: TagItemsInterface[];

  modelId?: string;

  /**
   * This is the `sk` of the item that has the tag in its `items` array. It's added in the return when fetching tags and does not exist in the item database object. I.e. the only way to get this is to first fetch all the tags, this is when it's added to the returned tag object.
   *
   * @type {string}
   */
  tableItemId?: string;

  /**
   * Tag title.
   *
   * @type {string}
   */
  title: string;

  /**
   * Optional updated at timestamp (UNIX Epoch).
   *
   * @type {?number}
   */
  updatedAt?: number;

  /**
   * Optional user who updated the entry.
   *
   * @type {?AuthorInterface}
   */
  updatedBy?: AuthorInterface;
}

/**
 * Individual item that is associated with a tag {@link TagItemInterface}.
 *
 * @export
 * @interface TagItemItemsInterface
 * @typedef {TagItemItemsInterface}
 */
export interface TagItemsInterface {
  expressId?: number;

  id: string;

  isAuto?: boolean;

  items?: number[];

  modelContainerId?: string;

  /**
   * This is the `sk` of the item that has the tag in its `items` array. It's added in the return when fetching tags and does not exist in the item database object. I.e. the only way to get this is to first fetch all the tags, this is when it's added to the returned tag object.
   *
   * @type {string}
   */
  tableItemId?: string;

  type: ViewerLayerDataCategoryKey | ViewerInspectorMode;
}

/* export interface IfcModelItem extends TagItemsInterface {
  [key: string]: IfcItem;
  expressId: number;
  modelContainerId: string;

}
export interface IfcModelItemAuto extends IfcModelItem {
  isAuto: boolean;
} */

/**
 * IfcItemInterface
 * @param {string} key : modelContainerId
 * @param {number[]} value : array of expressIds
 */
export interface IfcItemInterface {
  [key: string]: number[];
}
//type TagType = "ifcModelItem" | "pointcloud" | "model" | "measurementDistance" | "pointOfInterest";

export interface TagUndo {
  tagId: string;
  modelContainerId: string;
  // modelId: string;
  visible: boolean | undefined;
}
