
import Vue from "vue";

export default Vue.extend({
  name: "PointCloudViewerLoadingDialog",
  data: () => ({
    dialog: false,
    forceClose: false,
  }),
  computed: {
    showDialog: {
      get(): boolean {
        if (!this.forceClose && this.isLoading) {
          return true;
        }
        return this.dialog;
      },
      set({ dialog, force = false }: { dialog: boolean; force: boolean }): void {
        this.forceClose = force;
        this.dialog = dialog;
      },
    },
    isLoading() {
      const getLoadingProgress = "PointCloudViewer/getLoadingProgress";
      const noActiveLoaders = Object.keys(this.$store.getters[getLoadingProgress]).length === 0;
      const hasLoaderConstructor = this.$store.getters[getLoadingProgress].constructor === Object;
      return !(noActiveLoaders && hasLoaderConstructor);
    },
  },
});
