
import Vue from "vue";

import { availableContainers } from "@/utilities/containers";

export default Vue.extend({
  name: "FileBrowserDeleteFileDialog",
  methods: {
    deleteItem() {
      // if container, delete items (plural) and return
      const path = this.$store.getters["Storage/getPath"];
      const item = this.$store.getters["Storage/getContextItem"];
      const isContainer = availableContainers.find((container) => container.type === path);

      if ((isContainer && isContainer.type === path) || item.type === "folder") {
        this.$store.dispatch("Storage/deleteItems");
        return;
      }

      // Default, delete single item.
      this.$store.dispatch("Storage/deleteItem");
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Storage/getDeleteDialogState"];
      },
      set(state) {
        this.$store.commit("Storage/setDeleteDialogState", state);
      },
    },
    fileName() {
      return this.$store.getters["Storage/getContextItem"].name;
    },
  },
});
