
import Vue from "vue";

import { UserProfileInterface } from "@/types";

interface Data {
  deleteConfirmValue: string;
  deleteFormValidated: boolean;
  user?: UserProfileInterface;
}

export default Vue.extend({
  name: "SingleUserDeleteDialog",
  props: {
    item: {
      type: Object as () => UserProfileInterface,
      required: true,
    },
  },
  data: (): Data => ({
    deleteConfirmValue: "",
    deleteFormValidated: false,
    user: undefined,
  }),
  methods: {
    /**
     * Verify if user can be deleted.
     *
     * @param {string} value - User email.
     * @returns {Array<boolean | string>} - Returns array with boolean or string.
     */
    deleteUserRule(value: string): Array<boolean | string> {
      return [value === this.user?.email || "Incorrect value"];
    },

    /**
     * If user can be deleted, delete user.
     *
     * @param {string} email - User email.
     * @returns {Promise<void>} - Returns void.
     */
    async deleteUser(): Promise<void> {
      if (this.deleteFormValidated && this.user?.email) {
        await this.$store.dispatch("AccountManagement/deleteUser", this.user.email);
        this.dialog = false;
        this.deleteConfirmValue = "";
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["AccountManagement/getDeleteDialogState"];
      },

      set(state: boolean) {
        this.$store.commit("AccountManagement/setDeleteDialogState", state);
      },
    },

    isWorking: {
      get(): boolean {
        return this.$store.getters["AccountManagement/getEditWorkingState"];
      },
      set(state: boolean): void {
        this.$store.commit("AccountManagement/setEditWorkingState", state);
      },
    },
  },
  watch: {
    item: async function () {
      this.user = this.item;
    },
  },
});
