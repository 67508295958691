
import Vue from "vue";

import { FileBrowser } from ".";

export default Vue.extend({
  name: "GeneralFileBrowser",
  components: { FileBrowser },
  methods: {},
  computed: {
    getToolbarTitle() {
      return "File Browser";
    },
    getProjectStorageLocation() {
      return this.$store.getters.getProjectStorageRoot + this.$route.params.id + "/";
    },
  },
});
