
import Vue from "vue";

import Author from "@/components/author.vue";
import ReadMore from "@/components/read-more.vue";
import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import { AttachmentSectionLineItem, AvailableAttachmentInterfaces } from "@/types";

export default Vue.extend({
  name: "ListItems",
  props: {
    type: String,
    openDialog: {
      type: Function,
      required: true,
    },
  },
  components: {
    Author,
    ReadMore,
  },
  methods: {
    async downloadItem(item: Record<string, string | boolean>, action: string) {
      // Add the action to the item object. It's needed when opening PDF files, by adding width and height 100% to the embed element.
      item.action = action;
      this.$store.commit("Storage/setContextItem", item);
      await this.$store.dispatch("Storage/downloadFile");
      // this.$store.commit("Storage/resetContextItem");
    },

    deleteAttachmentConfirmation(itemId: string): void {
      const { type } = this;
      this.$store.commit("PointCloudViewer/setDeleteDialog", {
        state: true,
        type: AttachmentSectionLineItem.LINE_ITEM,
        message: `Delete ${type} line item?`,
        deleteAttachment: {
          lineItemId: itemId,
          sectionId: this.sectionId,
          sectionLineItem: AttachmentSectionLineItem.LINE_ITEM,
        },
      });
    },

    canUserEdit(id: string): boolean {
      return this.$store.getters["User/getCanEdit"](id);
    },

    /**
     * Copy the stringified object to clipboard.
     *
     * @returns {void}
     */
    copyTagInfo(item: AvailableAttachmentInterfaces): void {
      this.$store.dispatch("Utilities/copyToClipboard", {
        string: JSON.stringify(item),
        reference: item.id,
      });
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    sectionId(): string | undefined {
      const inspectorAttachments = this.inspector.attachments;

      if (!inspectorAttachments) {
        return;
      }

      const attachment = inspectorAttachments.find((item) => item.type === this.type);
      return attachment?.id;
    },

    items(): AvailableAttachmentInterfaces[] {
      const inspectorAttachments = this.inspector.attachments;

      if (!inspectorAttachments) {
        return [];
      }

      const attachmentIndex = inspectorAttachments.findIndex(
        (attachment) => attachment.type === this.type
      );

      if (attachmentIndex === -1) {
        return [];
      }

      const attachmentItems = inspectorAttachments[attachmentIndex].items;
      return attachmentItems ?? [];
    },
  },
});
