
import Vue from "vue";

import CoverPlaceholder from "@/components/cover-placeholder.vue";
import RoleChip from "@/components/role-chip.vue";
import { Project } from "@/store/modules/Projects/types";
import { Roles } from "@/types/enums";
import { timestampToLocaleDate } from "@/utilities";

export default Vue.extend({
  name: "ProjectList",
  components: {
    RoleChip,
    CoverPlaceholder,
  },
  data: () => ({
    searchText: "",
    projectListviewSelection: "",
    viewMode: "grid",
    projectHovered: "",
    noSearchResultsFound: false,
  }),
  methods: {
    localDate(timestamp: string): string {
      return timestampToLocaleDate(timestamp);
    },

    switchViewMode() {
      if (this.viewMode === "list") {
        this.viewMode = "grid";
      } else {
        this.viewMode = "list";
      }
    },

    viewProject(projectId: string) {
      this.$router.push({ name: "viewers", params: { projectId } });
    },
    setHoveredProject(projectId: string) {
      this.projectHovered = projectId;
    },
    editProject(projectId: string) {
      this.$router.push({ name: "projectEdit", params: { projectId } });
    },

    projectFiles(projectId: string) {
      this.$router.push({ name: "projectFileBrowser", params: { projectId } });
    },

    getRoleChipColor(role: string) {
      switch (role) {
        case Roles.OWNER:
          return "black";

        case Roles.ADMIN:
          return "green";

        case Roles.EDITOR:
          return "orange";

        case Roles.GUEST:
          return "blue-grey";
      }
    },
    setNoSearchResultsFound(val: boolean) {
      this.noSearchResultsFound = val;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["Projects/getLoadingState"];
    },

    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    hasProjectFileAccess(): boolean {
      return this.$store.getters["User/getHasProjectFileAccess"](this.projectHovered);
    },

    isUser() {
      return this.$store.getters["User/getIsUser"];
    },

    noProjectsAlert() {
      // default to false
      let hasProjects = false;

      // check if loading
      const isLoading = this.$store.getters["Projects/getLoadingState"];
      const isFetched = this.$store.getters["Projects/getFetchedState"];

      // if loading done check if user has viewable projects
      if (!isLoading && isFetched) {
        hasProjects = this.$store.getters["Projects/getProjects"].length < 1;
      }
      return hasProjects;
    },

    projectsList() {
      const projects: Project[] = this.$store.getters["Projects/getProjects"];
      this.setNoSearchResultsFound(false);

      // sort projects alphabetically
      if (projects.length) {
        projects.sort((a, b) => {
          const aTitle = a.title.toLowerCase();
          const bTitle = b.title.toLowerCase();

          // use local compare for return integer
          // see MDN description for details and warning on not using fixed integers
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#description
          return aTitle.localeCompare(bTitle);
        });
      }

      // filter projects based on search text
      const noSearch = this.searchText === "" || this.searchText === null;

      if (noSearch) {
        return projects;
      } else {
        const searchResult = projects.filter((project: Project) => {
          // Convert all strings to lower case.
          const searchText: string = this.searchText.toLowerCase();
          const title: string = project.title.toLowerCase();
          const description: string = project.description.toLowerCase();

          // Only return matches.
          if (title.includes(searchText) || description.includes(searchText)) {
            return project;
          }
        });
        if (searchResult.length === 0) {
          this.setNoSearchResultsFound(true);
          return [];
        } else {
          return searchResult;
        }
      }
    },
  },
  async mounted() {
    // If projects haven't been loaded yet, make initial API call.
    const hasFetched = this.$store.getters["Projects/getFetchedState"];
    if (!hasFetched) {
      await this.$store.dispatch("Projects/fetchProjects");
    }
  },
});
