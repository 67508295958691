
import Vue from "vue";

import { ViewerLayerDataInterface } from "@/store/modules/PointCloudViewer/types";
import { ViewerLayerDataCategoryKey } from "@/types";

import AttachmentDots from "./attachment-dots.vue";
import ListItemActions from "./layer-item-actions/layer-item-actions.vue";

export default Vue.extend({
  name: "PointCloudViewerLeftSidebarLayers",
  components: {
    ListItemActions,
    AttachmentDots,
  },
  methods: {
    async selectItem(itemId: string) {
      if (this.selectedItemId === itemId) {
        this.selectedItemId = undefined;
      } else {
        this.selectedItemId = itemId;
      }
    },

    toggleLayerVisibility(category: ViewerLayerDataCategoryKey) {
      this.$store.dispatch("PointCloudViewer/toggleLayerVisibility", category);
    },
  },
  computed: {
    selectedItemId: {
      get(): string | undefined {
        return this.$store.getters["PointCloudViewer/getSelectedLayerItemId"];
      },
      set(id?: string): void {
        this.$store.commit("PointCloudViewer/setSelectedLayerItemId", id);
      },
    },

    layers(): ViewerLayerDataInterface {
      return this.$store.getters["PointCloudViewer/getLayerData"];
    },
  },
});
