import { Vector3 } from "three";
import Vue from "vue";
import { ActionTree, Commit, Dispatch } from "vuex";

import router from "@/router";
import {
  AttachmentSectionLineItem,
  LayerEditorPointData,
  PotreePoint,
  Roles,
  SnackbarColors,
  ViewerLayerDataCategoryKey,
  ViewerObjectTypeCategory,
  XYZInterface,
} from "@/types";
import { coreApiPost } from "@/utilities";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Measure } from "../../../../public/potree/build/potree/potree.js";
import { SidebarsState } from "../sidebars/types.js";
import { State } from "../types";

const apiRoot = "/viewer";

const pointsActions: ActionTree<State & SidebarsState, unknown> = {
  /**
   * Fetch points from API when point clouds are finished loading. This creates all the points on the viewer and adds the event listener `marker_dropped` to them, which in turn calls the `updatePointOnDatabase` action when the point is moved.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State; }} { commit, dispatch, state }
   * @returns {Promise<void>}
   */
  async fetchPoints({
    commit,
    dispatch,
    state,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    state: State;
  }): Promise<void> {
    const {
      currentRoute: {
        params: { viewerId },
      },
    } = router;
    const { viewer } = state;

    if (!viewer) {
      return;
    }

    try {
      const response: Record<string, any>[] = await coreApiPost(`${apiRoot}/get-points`, {
        viewerId,
      });

      // Make sure response is not undefined and contains at least one item before we continue.
      if (typeof response === "undefined" || response.length < 1) {
        return;
      }

      const restrictedAccess = await dispatch("hasAccess", { is: true, role: Roles.GUEST });

      // Loop point items and place on viewer scene.
      response.forEach(async (point: any) => {
        const { id, title, type, points, cameraPosition } = point;

        // Create new potree point for viewer.
        const potreePoint: Measure = new (window as any).Potree.Measure();

        // Title is universal.
        potreePoint.title = title;

        const data = {
          object: potreePoint,
          ...point,
        };

        // Handle type specific potree point setting
        switch (type) {
          case ViewerObjectTypeCategory.MEASUREMENT_DISTANCE:
            potreePoint.showDistances = true;
            if (!restrictedAccess) {
              potreePoint.addEventListener("marker_dropped", () => {
                dispatch("updatePointOnDatabase", {
                  id,
                  forced: true,
                  category: ViewerObjectTypeCategory.MEASUREMENTS,
                });
              });
            }
            break;
          case ViewerObjectTypeCategory.MEASUREMENT_HEIGHT:
            potreePoint.showDistances = false;
            potreePoint.showHeight = true;
            if (!restrictedAccess) {
              potreePoint.addEventListener("marker_dropped", () => {
                dispatch("updatePointOnDatabase", {
                  id,
                  forced: true,
                  category: ViewerObjectTypeCategory.MEASUREMENTS,
                });
              });
            }
            break;
          case ViewerObjectTypeCategory.POINT_OF_INTEREST:
            potreePoint.showDistances = false;
            potreePoint.showHeight = false;
            potreePoint.showCoordinates = false;
            potreePoint.annotation = await dispatch("potreeAnnotation", {
              id,
              title,
              position: {
                x: points[0].x,
                y: points[0].y,
                z: points[0].z,
              },
              cameraPosition,
            });
            if (!restrictedAccess) {
              potreePoint.addEventListener("marker_dropped", async () => {
                await dispatch("updatePointOfInterestPosition", potreePoint);
                await dispatch("updatePointOnDatabase", {
                  id,
                  forced: true,
                  category: ViewerObjectTypeCategory.POINT_OF_INTEREST,
                });
              });
            }
            break;
        }

        // Must have type to continue.
        if (typeof type !== "undefined") {
          commit("setLayerItemsData", { id, data, type });

          // Set final universal settings for potree point.
          potreePoint.closed = false;
          potreePoint.showArea = false;
          potreePoint.showCoordinates = false;
          potreePoint.cameraPosition = cameraPosition;

          // Create markers for each marker point.
          points.forEach((marker: XYZInterface) => {
            potreePoint.addMarker(new Vector3(marker.x, marker.y, marker.z));
          });

          // Add potree point to scene.
          viewer.scene.addMeasurement(potreePoint);
        }
      });
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
  },

  /**
   * Write point to database.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; }} { dispatch, commit }
   * @param {{ type: string; pointData: PotreePoint }} payload Type of point and point data.
   * @returns {(Promise<string | undefined>)}
   */
  async putPoint(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: { type: string; pointData: any }
  ): Promise<string | undefined> {
    // If user project role is guest throw error.
    const restrictedAccess = await dispatch("hasAccess", { is: true, role: Roles.GUEST });
    if (restrictedAccess) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Unsufficient permission for update action. Your changes will not be saved.",
          color: SnackbarColors.ERROR,
          timeout: 5,
        },
        { root: true }
      );

      return;
    }

    commit("setLoadingState", true);

    // If post request succeeds we will update id, otherwise we will return empty string.
    let id = "";

    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    // Desctructure payload.
    const { type, pointData } = payload;

    // Destructure point data to get title, camera position and points to get position.
    const { points, title, cameraPosition } = pointData;
    const pointsPositions = points.map((point: any) => point.position);

    // Create point object as it is expected by the backend.
    const point = {
      title,
      type,
      // tags: [], // <-- Attachments?
      points: pointsPositions,
      cameraPosition,
    };

    try {
      const response = await coreApiPost(`${apiRoot}/create-point`, {
        projectId,
        viewerId,
        point,
      });

      // Create point post request was successfull, assign `responseId` to id.
      ({ id } = response);

      if (type !== ViewerObjectTypeCategory.POINT_OF_INTEREST) {
        pointData.addEventListener("marker_dropped", () => {
          dispatch("updatePointOnDatabase", {
            id,
            forced: true,
            category: ViewerObjectTypeCategory.MEASUREMENTS,
          });
        });
      }

      if (type === ViewerObjectTypeCategory.POINT_OF_INTEREST) {
        // Get id from response and extract position from point object for potree annotation data.
        const { position } = points[0];

        const potreeAnnotationData = {
          id,
          title,
          cameraPosition,
          position,
        };

        // Add potree annotation to point object
        pointData.annotation = await dispatch("potreeAnnotation", potreeAnnotationData);
        pointData.addEventListener("marker_dropped", async () => {
          await dispatch("updatePointOfInterestPosition", pointData);
          await dispatch("updatePointOnDatabase", {
            id,
            forced: true,
            category: ViewerObjectTypeCategory.POINT_OF_INTEREST,
          });
        });
      }

      // Add created point to layer editor category (a.k.a. left sidbar) this should be done after the point has been successfully created.
      commit("setLayerItemsData", {
        id,
        data: {
          object: pointData,
          ...point,
          ...response,
        },
        type,
      });

      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${title}</strong> created`,
          color: "success",
          timeout: 2,
        },
        { root: true }
      );
    } catch (error: any) {
      commit(
        "Utilities/showSnackbar",
        {
          message: error.toString(),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
    return id;
  },

  /**
   * Update point on database.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State & SidebarsState; }} { commit, dispatch, state }
   * @param {{ id: string; forced: boolean; category: ViewerLayerDataCategoryKey }} payload
   * @returns {Promise<void>}
   */
  async updatePointOnDatabase(
    {
      state,
      dispatch,
      commit,
    }: { commit: Commit; dispatch: Dispatch; state: State & SidebarsState },
    payload: {
      id: string;
      forced: boolean;
      category:
        | ViewerLayerDataCategoryKey.POINT_OF_INTEREST
        | ViewerLayerDataCategoryKey.MEASUREMENTS;
    }
  ): Promise<void> {
    // If user project role is guest throw error.
    const restrictedAccess = await dispatch("hasAccess", { is: true, role: Roles.GUEST });
    if (restrictedAccess) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Unsufficient permission for update action. Your changes will not be saved.",
          color: SnackbarColors.ERROR,
          timeout: 5,
        },
        { root: true }
      );

      return;
    }

    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    const { layerData, measureInspector: inspector } = state;

    const { id, forced = false, category } = payload || {};

    const layerPoint = layerData[category].items[id];

    const { title: inspectorTitle } = inspector || {};

    const hasChange = inspector && inspectorTitle !== layerPoint.title;

    if (hasChange || forced) {
      if (inspector && !forced) {
        layerPoint.title = inspectorTitle;
        if (layerPoint.object?.annotation) {
          layerPoint.object.annotation.title = inspectorTitle;
        }
      }

      layerPoint.points = layerPoint.object?.points.map((point: any) => point.position) ?? [];

      const requiredVariables = [
        {
          test: !!projectId,
          message: "project ID",
        },
        {
          test: !!viewerId,
          message: "viewer ID",
        },
        {
          test: !!layerPoint,
          message: "proper point data",
        },
      ];

      // Can't continue without required data.
      const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
        root: true,
      });

      // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
      if (!hasRequired) {
        return;
      }

      commit("setLoadingState", true);

      try {
        const {
          createdAt,
          createdBy,
          type,
          showAnnotation,
          attachments,
          updatedAt,
          updatedBy,
          object,
          ...pointData
        } = layerPoint;

        await coreApiPost(`${apiRoot}/update-point`, { ...pointData, projectId, viewerId });

        commit(
          "Utilities/showSnackbar",
          {
            message: `<strong>${layerPoint.title}</strong> updated`,
            color: SnackbarColors.SUCCESS,
            timeout: 2,
          },
          { root: true }
        );
      } catch (error: any) {
        console.error(error);

        commit(
          "Utilities/showSnackbar",
          {
            message: error.toString(),
            color: SnackbarColors.ERROR,
          },
          { root: true }
        );
      }
    }

    commit("setLoadingState", false);
  },

  /**
   * Creates a point of interest in viewer and writes to point data to database.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State & SidebarsState; }} { commit, dispatch, state }
   * @returns {Promise<void>}
   */
  async createPointOfInterest({
    dispatch,
    state,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    state: State & SidebarsState;
  }): Promise<void> {
    const {
      viewer,
      layerData: {
        pointOfInterest: { items },
      },
      pointCreationStatus,
    } = state;

    if (!viewer) {
      return;
    }

    const { scene, measuringTool } = viewer;

    const type = ViewerObjectTypeCategory.POINT_OF_INTEREST;

    // Add iterated number to name based on number of existing items of same type.
    const itemName = await dispatch("iterateItemName", {
      items,
      type,
    });

    // Remove any started insertion. This is to prevent the point from creating if the user clicks outside the point cloud.
    if (pointCreationStatus.isCreating) {
      scene.removeMeasurement(pointCreationStatus.pointObject);
    }

    // Initiate Potree insertion tool.
    const point = measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showArea: false,
      closed: true,
      maxMarkers: 1,
    });

    // Add iterated name as title to point object created above.
    point.title = itemName;

    const createEventListener = async () => {
      const cameraPosition = Object.assign({}, scene.getActiveCamera().position);

      // Set point properties before sending to `putPoint` action.
      point.cameraPosition = cameraPosition;
      point.showCoordinates = false;
      point.visible = false;

      await dispatch("putPoint", {
        type,
        pointData: point,
      });

      point.removeEventListener("marker_dropped", createEventListener);
    };

    point.addEventListener("marker_dropped", createEventListener);
  },

  /**
   * Create a potree annotation (a.k.a. "point" or "point of interest").
   *
   * This runs both when creating a new point and when loading points after point cloud(s) have loaded.
   *
   * Adds the event listener `click` to `annotation` object, which in turn triggers `handleEditClick` action when clicking the point of interest lable in the viewer.
   *
   * @param {{ dispatch: Dispatch; state: State; }} { dispatch, state }
   * @param {{ id: string; title: string; position: XYZInterface; cameraPosition: XYZInterface; }} payload
   * @returns {void}
   */
  potreeAnnotation(
    { dispatch, state }: { dispatch: Dispatch; state: State },
    payload: { id: string; title: string; position: XYZInterface; cameraPosition: XYZInterface }
  ): void {
    const { viewer } = state;

    if (!viewer) {
      return;
    }

    const { scene } = viewer;
    const {
      id,
      title,
      position: { x, y, z },
      cameraPosition,
    } = payload;

    const annotation = new (window as any).Potree.Annotation({
      title,
      position: [x, y, z],
      cameraPosition,
      cameraTarget: [x, y, z],
    });
    const domElement = annotation.domElement;
    domElement.off("mouseenter");
    domElement.off("mouseleave");
    domElement.addClass("point-of-interest-viewer-title");
    annotation.addEventListener("click", () => {
      dispatch("handleEditClick", { type: ViewerObjectTypeCategory.POINT_OF_INTEREST, id });
    });
    scene.annotations.add(annotation);
    return annotation;
  },

  /**
   * Edit/update points
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch;}} { commit, dispatch }
   * @param {any} point
   * @returns {Promise<void>}
   */
  async updatePointOfInterestPosition(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    point: any
  ): Promise<void> {
    // If user project role is guest throw error.
    const restrictedAccess = await dispatch("hasAccess", { is: true, role: Roles.GUEST });
    if (restrictedAccess) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Unsufficient permission for update action. Your changes will not be saved.",
          color: SnackbarColors.ERROR,
          timeout: 5,
        },
        { root: true }
      );

      return;
    }

    const pointsPosition = Object.assign({}, point.points[0].position);
    const position = new Vector3(pointsPosition.x, pointsPosition.y, pointsPosition.z);

    if (point.annotation) {
      point.annotation.position = position;
    }
  },

  /**
   * Toggles the right sidebar for point layer items, i.e. `ViewerLayerDataCategoryKey.POINT_OF_INTEREST` and `ViewerLayerDataCategoryKey.MEASUREMENTS`.
   *
   * This action also:
   * - Sets the current editing model to `null`.
   * - Toggles sphere and label position for ViewerLayerDataCategoryKey.POINT_OF_INTEREST items (current and previous).
   *
   * If the right sidebar is closed:
   * - set inspector mode to the `category` parameter.
   * - set current point object to `layerDataItem`.
   * - set measure inpsector to `layerDataItem`.
   * - set any available attachments.
   * - set right sidebar to `true` (open).
   *
   * If it is closed:
   * - set right sidebar to `false` (closed).
   * - set inspector mode to `null`.
   * - set current point object `null`.
   * - set selected attachments to `[]`.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState; }} { commit, dispatch, getters, state }
   * @param {{ id: string; category: ViewerLayerDataCategoryKey }} payload
   * @returns {void}
   */
  editPoint(
    {
      commit,
      dispatch,
      getters,
      state,
    }: { commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState },
    payload: {
      id: string;
      category: ViewerLayerDataCategoryKey;
    }
  ): void {
    const { id, category } = payload;
    const {
      layerData: {
        [category]: {
          items: { [id]: layerDataItem },
        },
      },
      rightSidebar,
      measureInspector,
    } = state;
    const { attachments, object } = layerDataItem;

    commit("setCurrentEditingModel", null);

    // We need to get the current point object before set it, so we can determine if it was previously a point of interest. If it was we need data from it to properly close it.
    const {
      id: previousPointObjectId,
      object: previousObject,
      type: previousPointObjectType,
    } = getters["getCurrentPointObject"] || {};

    // We can now safely set the current point object since we've already stored the previous one.
    commit("setCurrentPointObject", layerDataItem);
    commit("setMeasureInspector", Object.assign({}, layerDataItem));
    commit("setSelectedAttachments", []);

    if (category === ViewerLayerDataCategoryKey.POINT_OF_INTEREST) {
      const {
        annotation: { domElement },
      } = object;

      dispatch("togglePointOfInterestLabel", {
        domElement,
        id,
      });
    }

    const currentInspectorMode = getters["getInspectorMode"];

    const isClosed =
      rightSidebar && currentInspectorMode === category && id === measureInspector.id;

    if (isClosed) {
      commit("setRightSidebar", false);
      commit("setInspectorMode", null);
      commit("setCurrentInspector");
      commit("setCurrentPointObject", null);
      commit("setMeasureInspector", null);
      commit("setSelectedAttachments", []);
    } else {
      if (
        previousPointObjectType &&
        previousPointObjectType === ViewerLayerDataCategoryKey.POINT_OF_INTEREST
      ) {
        const {
          annotation: { domElement },
        } = previousObject;

        dispatch("togglePointOfInterestLabel", {
          domElement,
          id: previousPointObjectId,
        });
      }

      commit("setInspectorMode", category);
      commit("setCurrentInspector");

      attachments?.forEach((attachment) => {
        commit("addAttachment", {
          attachment,
          category,
          id,
          sectionLineItem: AttachmentSectionLineItem.SECTION,
        });
        dispatch("sortAttachmentItems");
      });

      commit("setRightSidebar", true);
    }
  },

  /**
   * Add measurement to database.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State & SidebarsState; }} { commit, dispatch, state }
   * @returns {Promise<void>}
   */
  async createDistansMeasurement({
    commit,
    dispatch,
    state,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    state: State & SidebarsState;
  }): Promise<void> {
    const { viewer, layerData } = state;

    // We must have both viewer and layer data to continue.
    if (viewer && layerData) {
      const { measuringTool, scene } = viewer;
      const {
        measurements: { items },
      } = layerData;

      let isCreating = true;
      const type = "measurementDistance";
      const itemName = await dispatch("iterateItemName", {
        items,
        type,
      });

      // Remove/abort any started insertion.
      if (state.pointCreationStatus.isCreating) {
        scene.removeMeasurement(state.pointCreationStatus.pointObject);
      }

      const point = measuringTool.startInsertion({
        showDistances: true,
        showArea: false,
        closed: false,
      });

      // Add to point creation status.
      commit("setPointCreationStatus", {
        isCreating: true,
        pointObject: point,
      });

      point.title = itemName;

      point.addEventListener("marker_removed", (e: Event & { measurement: PotreePoint }) => {
        // Make sure the distance has atleast 2 points
        if (e.measurement.points.length < 2) {
          scene.removeMeasurement(e.measurement);
          return;
        }

        if (isCreating) {
          point.cameraPosition = Object.assign({}, scene.getActiveCamera().position);

          dispatch("putPoint", { type, pointData: point });

          isCreating = false;

          commit("setPointCreationStatus", {
            isCreating: false,
            pointObject: {},
          });
        }
      });
    }
  },

  /**
   * Create height measurement.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; state: State & SidebarsState; }} { commit, dispatch, state }
   * @returns {Promise<void>}
   */
  async createHeightMeasurement({
    commit,
    dispatch,
    state,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    state: State & SidebarsState;
  }): Promise<void> {
    const { viewer, layerData } = state;

    if (!viewer || !layerData) {
      return;
    }

    const { scene, measuringTool } = viewer;
    const {
      measurements: { items },
    } = layerData;

    let isCreating = true;
    const type = "measurementHeight";
    const itemName = await dispatch("iterateItemName", {
      items,
      type,
    });

    // Remove any started insertion.
    if (state.pointCreationStatus.isCreating) {
      scene.removeMeasurement(state.pointCreationStatus.pointObject);
    }

    const point = measuringTool.startInsertion({
      showDistances: false,
      showHeight: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
    });

    // Add to point creation status.
    commit("setPointCreationStatus", { isCreating: true, pointObject: point });

    point.title = itemName;

    const createEventListener = (e: Event & { measurement: PotreePoint; index: number }) => {
      if (isCreating && e.index === 1) {
        point.cameraPosition = Object.assign({}, scene.getActiveCamera().position);

        dispatch("putPoint", { type, pointData: point });

        isCreating = false;

        // Reset point creation status.
        commit("setPointCreationStatus", {
          isCreating: false,
          pointObject: {},
        });
        point.removeEventListener("marker_dropped", createEventListener);
      }
    };

    point.addEventListener("marker_dropped", createEventListener);
  },

  /**
   * Delete point.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; state: State & SidebarsState; }} { commit, dispatch, getters, state }
   * @returns {Promise<void>}
   */
  async deletePoint({
    commit,
    dispatch,
    getters,
    state,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    getters: any;
    state: State & SidebarsState;
  }): Promise<void> {
    // If user project role is guest throw error.
    const restrictedAccess = await dispatch("hasAccess", { is: true, role: Roles.GUEST });
    if (restrictedAccess) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Unsufficient permission for update action. Your changes will not be saved.",
          color: SnackbarColors.ERROR,
          timeout: 5,
        },
        { root: true }
      );

      return;
    }

    const {
      currentRoute: {
        params: { projectId, viewerId },
      },
    } = router;

    const { deleteDialog, layerData, measureInspector, rightSidebar, viewer } = state;

    const { scene } = viewer || {};

    // Get id and type from delete dialog. These are set in the `showDeleteDialog` method in `src/components/point-cloud-viewer/left-sidebar/list-item-actions.vue`, which in turn uses the current item to get the id and type.
    const { id, type } = deleteDialog;

    if (!type) {
      return;
    }

    const { items } = layerData[type];

    // Typecast to `LayerEditorPointData` because the `items` object in `deletePoint` should only be called from a point.
    const item = <LayerEditorPointData>items[id];
    const { object, title } = item;

    const requiredVariables = [
      {
        test: !!id,
        message: "id",
      },
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!viewerId,
        message: "viewer ID",
      },
      {
        test: !!scene,
        message: "scene",
      },
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    commit("setLoadingState", true);

    try {
      // Start by deleting database entry.
      await coreApiPost(`${apiRoot}/delete-point`, {
        id,
        projectId,
        viewerId,
      });

      // Remove point from viewer and layer editor (a.k.a. left sidebar).
      if (type === ViewerLayerDataCategoryKey.POINT_OF_INTEREST) {
        scene.removeAnnotation(object?.annotation);
      }
      scene.removeMeasurement(object);
      commit("removeLayerItemData", { id, type });

      // Close inspector (a.k.a. right sidebar) if it's open with the current object.
      if (
        rightSidebar &&
        getters["getInspectorMode"] === ViewerLayerDataCategoryKey.POINT_OF_INTEREST &&
        item.id === measureInspector.id
      ) {
        commit("setInspectorMode", null);
        commit("setRightSidebar", false);
        commit("setCurrentEditingModel", null);
      }

      // Reset and close delete dialog.
      deleteDialog.state = false;
      deleteDialog.id = "";

      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${title}</strong> deleted`,
          color: SnackbarColors.SUCCESS,
          timeout: 2,
        },
        { root: true }
      );
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Toggles the visibility of a point of interest label. Used with {@link togglePointOfInterestSphere} and {@link toggleLayerItemVisibility}.
   *
   * @param {{ dispatch: Dispatch; state: SidebarsState; }} { dispatch, state }
   * @param {{ domElement: HTMLElement & { addClass: (className: string) => void; removeClass: (className: string) => void; hasClass: (className: string) => boolean; }; id: string; }} payload
   * @returns {void}
   */
  togglePointOfInterestLabel(
    { dispatch, state }: { dispatch: Dispatch; state: SidebarsState },
    payload: {
      domElement: HTMLElement & {
        addClass: (className: string) => void;
        removeClass: (className: string) => void;
        hasClass: (className: string) => boolean;
      };
      id: string;
    }
  ): void {
    const { domElement, id } = payload;

    const {
      layerData: {
        [ViewerLayerDataCategoryKey.POINT_OF_INTEREST]: {
          items: {
            [id]: {
              object: {
                annotation: { _visible: visible },
              },
            },
          },
        },
      },
    } = state;

    if (!domElement) {
      return;
    }

    const isOpen = domElement.hasClass("poi-open");
    if (isOpen) {
      domElement.removeClass("poi-open");
    } else {
      domElement.addClass("poi-open");
    }

    dispatch("togglePointOfInterestSphere", { id, visible });
  },

  /**
   * Toggles the visibility of a point of interest sphere, which is a part of the chain of events that are trigger when a point is being selected in the viewer.
   *
   * Optionally, the visibility of the point of interest can be set by passing `visible` as a boolean.
   *
   * {@link togglePointOfInterestLabel}
   * {@link toggleLayerItemVisibility}.
   *
   * @param {{ commit: Commit; state: State & SidebarsState;  }} { commit, state }
   * @param {{ id: string; visible?: boolean; }} payload
   * @returns {void}
   */
  togglePointOfInterestSphere(
    { commit, state }: { commit: Commit; state: State & SidebarsState },
    payload: {
      id: string;
      visible?: boolean;
    }
  ): void {
    const { id, visible } = payload;

    const {
      layerData: {
        [ViewerLayerDataCategoryKey.POINT_OF_INTEREST]: {
          items: {
            [id]: { object },
          },
        },
      },
    } = state;

    // Toggle the visibility of the point of interest. If `visible` is not passed, then toggle the current visibility.
    Vue.set(object, "visible", visible ?? !object.visible);

    if (object.visible) {
      commit("setVisibleSpheres", [id]);
    } else {
      commit("setVisibleSpheres", []);
    }
  },

  /**
   * Iterates items and adds number to created item.
   *
   * @param {*} _ State is not used.
   * @param {{ items: any; type: ViewerObjectTypeCategory; }} payload
   * @returns {string}
   */
  iterateItemName(_: any, payload: { items: any; type: ViewerObjectTypeCategory }): string {
    const { items, type } = payload;

    // Set item title based on type
    let title;
    switch (type) {
      case ViewerObjectTypeCategory.POINT_OF_INTEREST:
        title = "POI";
        break;
      case ViewerObjectTypeCategory.MEASUREMENT_DISTANCE:
        title = "Distance";
        break;
      case ViewerObjectTypeCategory.MEASUREMENT_HEIGHT:
        title = "Height";
        break;
    }

    const nameTest = new RegExp(title + "\\s\\(\\d\\)", "i");
    const numberedNames = Object.values(items).filter((item: any) => item.title.match(nameTest));
    const number = numberedNames.length + 1;
    return `${title} (${number})`;
  },

  /**
   * Looks at the current camera position (`getActiveCamera`) and compares it to saved camera position. If the camera has changed we will enable the `saveCameraPosition` button. When user clicks the button we will update the camera position for `currentPointObject` on the database.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   */
  saveCameraPosition({
    commit,
    dispatch,
    getters,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    getters: any;
  }) {
    const potreeScene = getters["getPotreeScene"];
    const currentPointObject = getters["getCurrentPointObject"];

    const { id, type } = currentPointObject;

    const cameraPosition = Object.assign({}, potreeScene.getActiveCamera().position);

    let category;

    switch (type) {
      case ViewerObjectTypeCategory.MEASUREMENT_DISTANCE:
      case ViewerObjectTypeCategory.MEASUREMENT_HEIGHT:
        category = ViewerLayerDataCategoryKey.MEASUREMENTS;
        break;

      default:
        category = type;
    }

    commit("setLayerItemData", {
      category,
      data: {
        cameraPosition: cameraPosition,
      },
      id,
    });

    dispatch("updatePointOnDatabase", {
      category,
      forced: true,
      id,
    });
  },
};

export default pointsActions;
