import { GetterTree } from "vuex";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getLoadingState: (state) => {
    return state.loading;
  },

  getFetchedState: (state) => {
    return state.fetched;
  },

  getUserList: (state) => {
    return state.userList;
  },

  getUserGroupList: (state) => {
    return state.userGroupList;
  },

  getImportDialogState: (state) => {
    return state.importDialog;
  },

  getEditDialogState: (state) => {
    return state.editDialog;
  },

  getEditWorkingState: (state) => {
    return state.editWorking;
  },

  getDeleteDialogState: (state) => {
    return state.deleteDialog;
  },

  getDeleteWorkingState: (state) => {
    return state.deleteWorking;
  },
};

export default getters;
