import { GetterTree } from "vuex";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getProjects: (state) => {
    return state.list;
  },

  getFetchedState: (state) => {
    return state.fetched;
  },

  getLoadingState: (state) => {
    return state.loading;
  },

  getProject: (state) => (projectId: string) => {
    return state.list.find((project) => project.id === projectId);
  },

  getSelectDialogState(state) {
    return state.selectDialog;
  },

  getUploadingState(state) {
    return state.uploading;
  },

  getDeleteDialog(state) {
    return state.deleteDialog;
  },

  getIsDeleted(state) {
    return state.isDeleted;
  },
};

export default getters;
