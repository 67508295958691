import Vue from "vue";
import { MutationTree } from "vuex";

import {
  AttachmentSectionLineItem,
  AttachmentType,
  AvailableAttachmentInterfaces,
  IFCModelItemAttachmentMutationAction,
  InspectorAttachmentTypeInterface,
  ModelInpsectorAttachmentInterface,
  ViewerLayerDataCategoryKey,
} from "@/types";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";
import { AttachmentsState } from "./types";

const attachmentMutations: MutationTree<State & AttachmentsState & ModelsState & SidebarsState> = {
  resetSelectedAttachments: (state) => {
    state.selectedAttachmentTypes = [];
  },

  updateIFCModelItemAttachments(
    state,
    payload: {
      modelId: number;
      attachment: ModelInpsectorAttachmentInterface;
      action: IFCModelItemAttachmentMutationAction;
    }
  ) {
    const { modelId, attachment, action } = payload;
    const modelIndex = state.ifcModels.findIndex((model) => model.modelId === modelId);

    if (attachment && typeof modelIndex === "number") {
      const modelAttachments = state.ifcModels[modelIndex].attachments;

      if (action === IFCModelItemAttachmentMutationAction.ADD) {
        if (modelAttachments) {
          state.ifcModels[modelIndex].attachments = [...modelAttachments, attachment];
        } else {
          state.ifcModels[modelIndex].attachments = [attachment];
        }
      }

      // if (
      //   action === IFCModelItemAttachmentMutationAction.UPDATE ||
      //   action === IFCModelItemAttachmentMutationAction.DELETE
      // ) {
      //   const { itemTableId: itemAttachmentTableId, type: itemAttachment } =
      //     modelAttachmentIdObject(attachment);

      //   const attachmentIndex = state.ifcModels[modelIndex].attachments?.findIndex(
      //     (modelAttachment) =>
      //       modelAttachmentIdObject(modelAttachment).type === itemAttachment &&
      //       modelAttachmentIdObject(modelAttachment).itemTableId === itemAttachmentTableId
      //   );

      //   if (modelAttachments && typeof attachmentIndex === "number") {
      //     if (action === IFCModelItemAttachmentMutationAction.UPDATE) {
      //       modelAttachments[attachmentIndex] = attachment;
      //     }

      //     if (action === IFCModelItemAttachmentMutationAction.DELETE) {
      //       modelAttachments.splice(attachmentIndex, 1);
      //     }
      //   }
      // }
    }
  },

  addAttachment: (
    state,
    payload: {
      attachment?: InspectorAttachmentTypeInterface;
      sectionId?: string;
      category: ViewerLayerDataCategoryKey;
      id: string;
      isIFCModelitem?: boolean;
      lineItem?: AvailableAttachmentInterfaces;
      modelContainerId?: string;
      sectionLineItem: AttachmentSectionLineItem;
      tableItemId?: string;
    }
  ) => {
    const { selectedAttachmentTypes } = state;
    const {
      attachment,
      sectionId,
      category,
      id,
      isIFCModelitem,
      lineItem,
      modelContainerId,
      sectionLineItem,
      tableItemId,
    } = payload;

    const isSection = sectionLineItem === AttachmentSectionLineItem.SECTION;
    const isLineItem = sectionLineItem === AttachmentSectionLineItem.LINE_ITEM;

    const { type: attachmentType } = attachment || {};

    if (isSection && attachmentType) {
      // Only add attachment if not already in array. There can only be one of each attachment type from `Attachments` enum in the `selectedAttachmentTypes` array.
      const hasAttachmentType = selectedAttachmentTypes.includes(attachmentType);

      if (hasAttachmentType) {
        return;
      }

      selectedAttachmentTypes.push(attachmentType);
      Vue.set(state, "selectedAttachmentTypes", selectedAttachmentTypes);
    }

    if (!category && !isIFCModelitem) {
      // We won't be able to identify the layer item without a category. This is only needed when dealing with layer items (i.e. non-IFC model items).
      return;
    }

    const inspector = state.currentInspector;

    if (!inspector) {
      return;
    }

    if (!isIFCModelitem) {
      // Default to dealing with layer items.
      const layerItem = state.layerData[category].items[id];
      const attachments = layerItem.attachments ?? [];

      if (isSection && attachment) {
        // Handle attachment sections.
        const hasAttachment = attachments.find((attachment) => attachment.type === attachmentType);

        // Only add attachment if not already in array. There can only be one of each attachment type from `Attachments` enum in the `selectedAttachmentTypes` array.
        if (hasAttachment) {
          return;
        }

        attachments.push(attachment);
        // We have to update both the layer item and the inspector. The inspector is a representation of the layer item and is used to display the attachments in the right sidebar (i.e. inspector) and the layer item displays the attachment dots in the left sidebar (i.e. layers).
        Vue.set(layerItem, "attachments", attachments);
        Vue.set(inspector, "attachments", attachments);
      }

      if (isLineItem && lineItem && layerItem.attachments && inspector.attachments) {
        // Handle attachment line items. This requires the inspector to have the attachments array.
        const lineItemAttachmentIndex = attachments.findIndex((item) => item.id === sectionId);

        if (!attachments || lineItemAttachmentIndex === -1) {
          // Attahcment index not found.
          return;
        }

        const attachmentSection = attachments[lineItemAttachmentIndex];
        const attachmentItems = attachmentSection.items;

        // If there are no items yet, we will set the first one, otherwise we will push to the existing array.
        if (!attachmentItems) {
          layerItem.attachments[lineItemAttachmentIndex].items = [lineItem];
        } else {
          layerItem.attachments[lineItemAttachmentIndex].items?.push(lineItem);
        }

        const updatedAttachmentItems = layerItem.attachments[lineItemAttachmentIndex].items;
        Vue.set(layerItem.attachments, "items", updatedAttachmentItems);
      }
    } else {
      // Handle IFC model items.
      const currentModel = state.ifcModels.find(
        (model) => model.modelContainerId === modelContainerId
      );

      if (!currentModel) {
        // We can't continue without a model to add the attachment to.
        return;
      }

      // We will start by checking if there are any attachments for the current model. If there are none, we will create a new array and add the attachment to it. If there are attachments, we will check if the attachment already exists. If it does, we will update it, otherwise we will add it to the array.
      const modelAttachments = currentModel.attachments ?? [];
      const ifcModelItem = modelAttachments.find((item) => item.globalId === id);

      if (isSection && attachment) {
        // Handle attachment sections for IFC model items. Since we always want to update the inspector with the latest attachment, we can set attachments to an empty array first.
        const attachments: InspectorAttachmentTypeInterface[] = [];
        attachments.push(attachment);

        if (ifcModelItem) {
          // If the model item already exists, we will update the attachments array.
          const { attachments: ifcModelItemAttachments } = ifcModelItem;

          // Add any existing attachments to the new array.
          attachments.push(...ifcModelItemAttachments);

          // Set the new attachments array on the model item.
          Vue.set(ifcModelItem, "attachments", attachments);
        } else {
          // If the model item does not exist, we will create a new one and add it to the model attachments array.
          const newModelItem = {
            globalId: id,
            attachments: [attachment],
            tableItemId,
          };

          // Add the new model item to the model attachments array.
          modelAttachments.push(newModelItem);

          // Set the new model item on the model attachments array.
          Vue.set(currentModel, "attachments", modelAttachments);
        }

        // Finally, we will update the inspector with the attachments array and the new attachment.
        Vue.set(inspector, "attachments", attachments);
      }

      if (isLineItem && lineItem && ifcModelItem) {
        // Handle attachment line items for IFC model items. We need to find the attachment section and add the line item to it.
        const attachmentSectionIndex = ifcModelItem.attachments.findIndex(
          (item) => item.id === sectionId
        );
        const attachmentSection = ifcModelItem.attachments[attachmentSectionIndex];
        const lineItems = attachmentSection.items ?? [];
        lineItems.push(lineItem);

        // Use Vue.set to update the array in both the model item and the inspector.
        Vue.set(attachmentSection, "items", lineItems);
        Vue.set(inspector, "attachments.items", lineItems);
      }
    }
  },

  deleteAttachment: (
    state,
    payload: {
      category: ViewerLayerDataCategoryKey;
      id: string;
      isIFCModelitem?: boolean;
      lineItemId?: string;
      modelContainerId?: string;
      sectionId: string;
      sectionLineItem: string;
    }
  ) => {
    const {
      category,
      id,
      isIFCModelitem,
      lineItemId,
      modelContainerId,
      sectionId,
      sectionLineItem,
    } = payload;

    const { selectedAttachmentTypes } = state;

    const isSection = sectionLineItem === AttachmentSectionLineItem.SECTION;
    const isLineItem = sectionLineItem === AttachmentSectionLineItem.LINE_ITEM;

    const inspector = state.currentInspector;

    if (!category || !inspector) {
      return;
    }

    if (!isIFCModelitem) {
      // Default to dealing with layer items.
      const layerItem = state.layerData[category].items[id];
      const attachments = layerItem.attachments;

      if (!attachments) {
        // We can't continue without attachments.
        return;
      }

      const sectionIndex = attachments.findIndex((section) => section.id === sectionId);

      if (sectionIndex === -1 || !layerItem.attachments) {
        return;
      }

      if (isSection) {
        const section = attachments[sectionIndex];
        const selectedSectionIndex = selectedAttachmentTypes.findIndex(
          (type) => type === section.type
        );

        selectedAttachmentTypes.splice(selectedSectionIndex, 1);
        layerItem.attachments.splice(sectionIndex, 1);

        Vue.set(layerItem, "attachments", layerItem.attachments);
      }

      if (isLineItem) {
        const attachmentItems = attachments[sectionIndex].items;

        if (!attachmentItems) {
          return;
        }

        const lineItemIndex = attachmentItems.findIndex((item) => item.id === lineItemId);

        if (lineItemIndex === -1) {
          return;
        }

        layerItem.attachments[sectionIndex].items?.splice(lineItemIndex, 1);
      }
    } else {
      // Handle IFC model items.
      const currentModel = state.ifcModels.find(
        (model) => model.modelContainerId === modelContainerId
      );

      if (!currentModel) {
        // We can't continue without a model to add the attachment to.
        return;
      }

      // We will start by checking if there are any attachments for the current model.
      const modelAttachments = currentModel.attachments ?? [];
      const ifcModelItem = modelAttachments.find((item) => item.globalId === id);

      if (!ifcModelItem) {
        // Since we are deleting an attachment, we must assume that the model item exists. If we can't find it we can't continue.
        return;
      }

      const { attachments } = ifcModelItem;

      const sectionIndex = attachments.findIndex((section) => section.id === sectionId);
      const section = attachments[sectionIndex];

      if (isSection) {
        // Handle attachment sections for IFC model items.
        const selectedSectionIndex = selectedAttachmentTypes.findIndex(
          (type) => type === section.type
        );

        selectedAttachmentTypes.splice(selectedSectionIndex, 1);
        attachments.splice(sectionIndex, 1);

        Vue.set(ifcModelItem, "attachments", attachments);
      }

      if (isLineItem) {
        // Handle attachment line items for IFC model items.
        const lineItems = section.items;

        if (!lineItems) {
          // Section items must exist to continue.
          return;
        }

        const lineItemIndex = lineItems.findIndex((item) => item.id === lineItemId);
        lineItems.splice(lineItemIndex, 1);

        // Use Vue.set to update the array in both the model item and the inspector.
        Vue.set(section, "items", lineItems);
        Vue.set(inspector, "attachments.items", lineItems);
      }
    }
  },

  /**
   * This mutation happens when users "enter" an IFC model. Not to be confused with {@link addAttachment}, which is used durring {@link createAttachment}, and sets the attachment to the current model.
   *
   * @param {State & AttachmentsState & ModelsState & SidebarsState} state
   * @param {{ modelIndex: number; attachments: ModelInpsectorAttachmentInterface[]; }} payload
   */
  setIFCModelItemAttachments(
    state: State & AttachmentsState & ModelsState & SidebarsState,
    payload: {
      modelIndex: number;
      attachments: ModelInpsectorAttachmentInterface[];
    }
  ) {
    const { modelIndex, attachments } = payload;
    state.ifcModels[modelIndex].attachments = attachments ?? [];
  },

  setSelectedAttachments: (state, selectedAttachmentTypes: AttachmentType[]) => {
    state.selectedAttachmentTypes = selectedAttachmentTypes;
  },

  // removeSelectedAttachment: (state, attachemntType: AttachmentType) => {
  //   const { selectedAttachmentTypes, measureInspector } = state;

  //   const inspectorTypeIndex = measureInspector.attachments?.findIndex(
  //     (attachment) => attachment.type === attachemntType
  //   );

  //   const selectedTypeIndex = selectedAttachmentTypes.findIndex(
  //     (attachment) => attachment === attachemntType
  //   );

  //   if (
  //     selectedTypeIndex !== -1 &&
  //     typeof inspectorTypeIndex !== "undefined" &&
  //     inspectorTypeIndex !== -1
  //   ) {
  //     state.measureInspector.attachments?.splice(inspectorTypeIndex, 1);
  //     state.selectedAttachmentTypes.splice(selectedTypeIndex, 1);
  //   }
  // },

  // setSelectedAttachmentFile: (state, file: File) => {
  //   state.selectedAttachmentFile = file;
  // },

  // resetSelectedAttachmentFile: (state) => {
  //   state.selectedAttachmentFile = undefined;
  // },

  // setMeasureInpectorAttachments: (
  //   state,
  //   payload: {
  //     id: number;
  //     attachmentData: InspectorAttachmentTypeInterface;
  //   }
  // ) => {
  //   const { id, attachmentData } = payload;
  //   // We can only set attachments to a specific index (`id`) if there are any attachments.
  //   if (state.measureInspector.attachments) {
  //     state.measureInspector.attachments[id] = attachmentData;
  //   }
  // },
};

export default attachmentMutations;
