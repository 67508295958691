/**
 * @file This files all general purpose enums. For type specific enums, see the types file.
 */

/**
 * Password character types. Used to construct rules, generate and validate passwords.
 *
 * @export
 * @enum {number}
 */
export enum passwordCharacters {
  UPPER = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  LOWER = "abcdefghijklmnopqrstuvwxyz",
  NUMBER = "0123456789",
  SYMBOL = "$*.!?:|_-",
}

/**
 * Ways to interact with a model attachment.
 *
 * @export
 * @enum {number}
 */
export enum IFCModelItemAttachmentMutationAction {
  ADD = "add",
  UPDATE = "update",
  DELETE = "delete",
}

/**
 * Allowed roles for a user.
 *
 * @export
 * @enum {number}
 */
export enum Roles {
  ADMIN = "admin",
  EDITOR = "editor",
  GUEST = "guest",
  OWNER = "owner",
  USER = "user",
}

export enum FileBrowserAccessRole {
  ADMIN = "admin",
  EDITOR = "editor",
  GUEST = "guest",
}

/**
 * Available user statuses.
 *
 * @export
 * @enum {number}
 */
export enum UserStatus {
  ACTIVE = "active",
  DISABLED = "disabled",
  PENDING = "pending",
}
