import Vue from "vue";
import { MutationTree } from "vuex";

import { LayerEditorPointData, ViewerObjectTypeCategory } from "@/types/viewer";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";

const pointsMutations: MutationTree<State & ModelsState & SidebarsState> = {
  removeLayerItemData(
    state,
    payload: {
      id: string;
      type:
      | ViewerObjectTypeCategory.POINTCLOUDS
      | ViewerObjectTypeCategory.MODELS
      | ViewerObjectTypeCategory.POINT_OF_INTEREST
      | ViewerObjectTypeCategory.MEASUREMENTS;
    }
  ) {
    const { id, type } = payload;
    Vue.delete(state.layerData[type].items, id);
  },

  setSelectDialogState(state, dialogState: boolean) {
    state.selectDialog = dialogState;
  },

  setCurrentPointObject(state, currentPointObject: LayerEditorPointData) {
    state.currentPointObject = currentPointObject;
  },

  setInspectorCopy(state, payload) {
    const { id, inspectorCopy } = payload;
    Vue.set(state.layerData.pointOfInterest.items, id, inspectorCopy);
  },

  setObjectTitle(state, payload) {
    const { id, title } = payload;
    Vue.set(state.layerData.pointOfInterest.items[id], "name", title);
  },

  setMeasureCommentInput(state, measureCommentInputValue) {
    state.measureCommentInput = measureCommentInputValue;
  },

  setPointCreationStatus(state, payload) {
    state.pointCreationStatus = payload;
  },

  setPointOfIntereset(state, payload) {
    const { id, measureInspector } = payload;
    //state.layerData.pointOfInterest[id] = measureInspector;
    Vue.set(state.layerData.pointOfInterest, id, measureInspector);
  },

  setVisibleSpheres(state, visibleSpheres) {
    state.visibleSpheres = visibleSpheres;
  },
};

export default pointsMutations;
