
import Vue from "vue";

import { VForm } from "@/types";
import { folderNameRules } from "@/utilities";

export default Vue.extend({
  name: "FileBrowserCreateFolderDialog",
  data: () => ({
    folderNameRules,
    folderName: "",
    folderNameValid: true,
  }),
  watch: {
    dialog() {
      if (!this.dialog) {
        this.folderName = "";
      }
    },
  },
  methods: {
    async validateFolderName() {
      // validate form text field for folder name
      if ((this.$refs.createFolderForm as VForm).validate()) {
        // send put request
        await this.$store.dispatch("Storage/putFolder", { name: this.folderName, type: "folder" });

        // reset folder name and close dialog
        this.folderName = "";
        this.dialog = false;
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Storage/getCreateFolderDialogState"];
      },
      set(state) {
        this.$store.commit("Storage/setCreateFolderDialogState", state);
      },
    },
  },
});
