import { Roles, UserStatus } from "@/types";

import { State } from "./types";

const getDefaultState = (): State => {
  return {
    authenticatedUser: undefined,
    authorProfiles: [],
    clients: [],
    errorMsg: {},
    instructionsSent: false,
    isConfirmedUser: true,
    loading: false,
    loginErrorMessage: {},
    selectedClient: undefined,
    userActionPayload: {
      action: "",
      userCredentials: {
        email: "",
        password: "",
      },
    },
    userProfile: {
      createdAt: -1,
      createdBy: {
        id: "",
        name: "",
        reference: "",
      },
      email: "",
      name: {
        first: "",
        last: "",
      },
      profileImage: "",
      role: Roles.USER,
      uuid: "",
      status: UserStatus.PENDING,
    },
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
