
import Vue from "vue";

import { FileUploadStatus } from "@/store/modules/Storage/types";

export default Vue.extend({
  name: "UploadDialogLineItem",
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    FileUploadStatus,
  }),
  methods: {
    async deleteUploadFile(file: File) {
      await this.$store.dispatch("Storage/abortUploadFile", file);
    },
    async deleteBeforeUpload(file: File) {
      await this.$store.dispatch("Storage/abortUploadFile", file);
      this.$store.commit("Storage/removeFromUploadFiles", file);
      this.$store.commit("Storage/removeFromUploadRequests", file);
    },
  },
  computed: {
    uploadProgress(): number {
      const { progress } =
        this.$store.getters["Storage/getFileUploadProgress"][this.item.file.name] || 0;
      return progress;
    },
  },
});
