import {
  Delete,
  DeleteObjectCommand,
  DeleteObjectCommandInput,
  DeleteObjectsCommand,
  DeleteObjectsCommandInput,
  GetObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { getSignedUrl as getSignedS3Url } from "@aws-sdk/s3-request-presigner";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { ActionTree } from "vuex";

import { ContextItem, FetchedWireFrame, FileBrowserFile, SnackbarColors } from "@/types";
import {
  cleanFileName,
  coreApiPost,
  getClientData,
  getNameType,
  getS3Object,
  imageResize,
  removeDiacritics,
} from "@/utilities";

import { FileUploadStatus, State } from "./types";

function getS3ClientConfig() {
  const { region, bucket } = getClientData();
  const credentials = Auth.currentUserCredentials;
  const clientS3 = new S3Client({ region, credentials });
  return { bucket, clientS3 };
}

const apiRoot = "/file";
const actions: ActionTree<State, unknown> = {
  // get database file browser records
  async fetchFiles({ commit }, path) {
    commit("setLoadingState", true);

    try {
      // get user client data from API
      const responseData = await coreApiPost(`${apiRoot}/get-files`, { path });

      commit("setFileBrowserItems", responseData);
      commit("setFilesInFolder", responseData.files);
      // commit("setFetchedState", true);
    } catch (error: unknown) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  async getContainer({ commit, dispatch, getters }, containerId) {
    const idComponents = containerId.split("#");
    const type = idComponents[0];
    const path = idComponents[1];

    try {
      // get user client data from API
      const responseData = await coreApiPost(`${apiRoot}/get-files`, { path });
      const contextItem: ContextItem = getters["getContextItem"];

      const { action, id, type: contextType, pointCloudType } = contextItem;

      // If the current context is a download action and the user is trying to download a point cloud file we will check if we have all the required data to determine the orginal point cloud file.
      if (
        id &&
        action === "download" &&
        contextType === "pointCloud" &&
        pointCloudType !== "potree"
      ) {
        const { files } = responseData;

        // Look for the original point cloud file in the files array, which in this context are the files in the point cloud container.
        const originalPointcloudFile = files.find(
          (fileObject: File) => fileObject.type === pointCloudType
        );

        if (originalPointcloudFile) {
          const { objectName, name, type } = originalPointcloudFile;

          const downloadName = type ? `${name}.${type}` : name;

          const source = await getS3Object(
            objectName,
            "",
            undefined,
            undefined,
            true,
            downloadName
          );

          dispatch("processDownloadItem", { action, item: source, name: downloadName, type });
        }

        return;
      }

      if (type === "pointCloud") {
        const filterPointCloudFile = (name: string) =>
          responseData.files.filter((fileObject: { name: string }) => fileObject.name === name)[0]
            .objectName;

        const octree = filterPointCloudFile("octree");
        const hierarchy = filterPointCloudFile("hierarchy");
        const metadata = filterPointCloudFile("metadata");
        return { octree, hierarchy, metadata };
      }

      if (type === "model") {
        const modelDataObject = {} as Record<string, string>;
        const allowedTypes = ["ifc", "fbx"];
        responseData.files.forEach((fileObject: { type: string; objectName: string }) => {
          const { type, objectName } = fileObject;
          if (allowedTypes.includes(type)) {
            modelDataObject.type = type;
            // Main model inside the container will have different names,
            // to unify modeldataobject has model property.
            modelDataObject.model = objectName;
          }
        });

        return modelDataObject;
      }
    } catch (error: unknown) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
  },

  // get true if fileName exist in current folder or in upload activity bar.
  // dont count items with status canceled or none .
  async isFileNameExist({ state, commit, getters, dispatch }, name: string): Promise<boolean> {
    if (!state.filesInFolder.length) {
      await dispatch("fetchFiles", state.path);
      commit("setFilesInFolder", getters["getFileBrowserItems"](state.path));
    }

    if (state.filesInFolder.length) {
      for (const file of state.filesInFolder) {
        if (file?.name === cleanFileName(name)) {
          // checking filename in curent folder
          return true;
        }
      }
    }

    if (state.uploadAllFiles.length) {
      for (const fileUpload of state.uploadAllFiles) {
        if (
          fileUpload.file.name === name &&
          fileUpload.path === state.path &&
          (fileUpload.status === FileUploadStatus.UPLOADING ||
            fileUpload.status === FileUploadStatus.UPLOADED)
        ) {
          // checking filename in upload Activity bar items
          return true;
        }
      }
    }

    return false;
  },

  // get unique valid name for file.
  // based on current folder and upload activity bar files.
  async uploadFileNameValidation({ dispatch }, fileName: string) {
    if (await dispatch("isFileNameExist", fileName)) {
      let newFileName = cleanFileName(fileName) + " (1)" + `.${getNameType(fileName)}`;
      let index = 1;
      while (await dispatch("isFileNameExist", newFileName)) {
        newFileName = cleanFileName(fileName) + ` (${index})` + `.${getNameType(fileName)}`;
        index++;
      }
      return newFileName;
    } else return fileName;
  },

  // used for adding/refreshing upload activity bar with new files from uploadFiles.
  async uploadActivityFiles({ state, commit, dispatch }) {
    let validFileName: string;
    let newFile: File;
    if (state.uploadFiles) {
      state.uploadFiles.forEach(async (file: File) => {
        validFileName = await dispatch("uploadFileNameValidation", file.name);
        newFile = new File([file], validFileName, {
          type: file.type === "" ? file.name.split(".").pop() : file.type,
        });
        commit("addUploadAllFiles", newFile);
      });
    }
  },

  // put actions to create folder and file
  async putFile({ commit, getters, rootGetters, state }, file: File) {
    const { bucket, clientS3 } = getS3ClientConfig();

    // if putting profile image use user sub (cognito id) for filename
    // this will overwrite if user changes image
    const isProfileImage = getters["getIsProfileImage"];
    const authUser = await Auth.currentAuthenticatedUser();
    const userId = authUser.attributes.sub;

    // unique values
    const timestamp = Date.now();
    const id = isProfileImage ? userId : uuidv4();
    let path = "";

    // Taking the path for every file from Upload Activity Bar
    state.uploadAllFiles.map((fileUpload) => {
      if (fileUpload.file.name === file.name && fileUpload.file.type === file.type) {
        path = fileUpload.path;
      }
    });

    if (!path.length) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "Path is missing, aborting upload",
          color: "error",
        },
        { root: true }
      );
      return;
    }

    // destructure upload request data
    const { name, size } = file;
    // type

    // different files have very varying grouping and meta definitions
    // better to use the filename extension for "type"
    // user is in control of what the file is named which is more predictable
    // this should solve all download issues with incorrect filename extensions
    const fileType = getNameType(name);

    // OLD - DELETE ONCE NEW IS CONFIRMED
    // // handle object file ending
    // let fileType = "unknown";
    // if (type !== "") {
    //   fileType = getGroupType(type);
    // } else {
    //   // if type is empty extract from file name
    //   fileType = getNameType(name);
    // }

    // create S3 file object name
    const objectName = isProfileImage ? `${userId}.${fileType}` : `${id}_${timestamp}.${fileType}`;

    try {
      // max allowed queue size in S3 10000

      // convert megabyte (MB) value to bytes
      const megabyteTobyte = (mb: number) => mb * 1024 * 1024;

      // allowed allocated memory in bytes (queueSize * partSize)
      const maxQueueSize = 50;

      // // with the exception of the first and last parts (which can be any size)
      // // the smallest allowed part size is 5MB (5242880)
      // const minPartSize = megabyteTobyte(5);

      // progress will not start untill first part is processed
      // part sizes should not be bigger than 25MB (26214400)
      const maxPartSize = megabyteTobyte(25);

      // determine if multipart should be configured or handled by upload
      const shouldSplit = size > maxPartSize;
      const queueSize = shouldSplit ? maxQueueSize : undefined;
      // const partSize = shouldSplit ? minPartSize : undefined;
      const leavePartsOnError = shouldSplit ? false : undefined;

      // upload file to S3
      const uploadRequest = new Upload({
        client: clientS3,
        queueSize,
        // partSize,
        leavePartsOnError,
        params: {
          Bucket: bucket,
          Body: file,
          Key: `files/${objectName}`,
        },
      });

      // set the upload object to the state

      commit("setUploadObject", uploadRequest);

      commit("addUploadProgress", { fileName: file.name, progress: 0 });

      uploadRequest.on("httpUploadProgress", (progress) => {
        const { loaded, total } = progress;

        if (loaded && total) {
          // total upload size in bytes
          const totalUploadSize = getters["getTotalUploadSize"];
          // upload start epoch time
          const started = getters["getEsitmatedUploadTime"].start;
          // epoch time passed since upload start
          const elapsed = Date.now() - started;
          // upload speed in Bps
          const uploadSpeed = loaded / (elapsed / 1000);
          // upload time estimation
          const estimate = (totalUploadSize - loaded) / uploadSpeed;
          // item percentage progress
          const loadedPercent = (loaded / total) * 100;

          // total time progress based on total upload size
          commit("setEsitmatedUploadTime", { estimate });

          // individual item percentage progress
          commit("updateUploadProgress", { id: file.name, progress: loadedPercent });
        }
      });

      // set state isUploading to true
      commit("setIsUploading", true);
      // wait for file upload to finish
      await uploadRequest.done();

      if (state.isUploadAborted) {
        return;
      }

      // This value is used in the backend to determine if the uploaded file is apart of a point cloud container. If not the uploaded file will not trigger the potree convertion flow.
      const isPointcloudContainer = rootGetters["Viewers/getIsPointcloudContainer"];

      // // TODO: check existing equal name and iterate, e.g. file, file (1), file (2) etc
      // put ddb entry for uploaded file
      const ddbPutResponse = await coreApiPost(`${apiRoot}/create-file`, {
        // bucket,
        id,
        isPointcloudContainer,
        name: cleanFileName(name),
        objectName: objectName,
        path,
        size,
        timestamp,
        type: fileType,
      });

      commit("setFile", { path, file: ddbPutResponse });
      commit("setContextItem", { id, name, objectName });
      commit("deleteUploadProgress", file.name);

      // Reset the `isPointcloudContainer` state to `undefined` so that the next time a file is uploaded, the state is reset since this should only be `true` when we are in the process of creating a point cloud container and the associated files.
      commit("Viewers/setIsPointcloudContainer", undefined, { root: true });

      // if dealing with profile finalize by updating user profile on ddb
      if (isProfileImage) {
        const userProfile = rootGetters["User/getUserProfile"];
        commit(
          "User/setUserProfile",
          {
            ...userProfile,
            imageSource: `${userId}.${fileType}`,
          },
          { root: true }
        );
        await this.dispatch("User/updateUserProfile");
      }

      // // don't show the success message if upload item (and type) exists
      // // upload item is used on drag-n-drop and select upload
      // const viewerUploading = rootGetters["Viewers/getUploadingState"];
      // if (!viewerUploading) {
      //   commit(
      //     "Utilities/showSnackbar",
      //     {
      //       message: `${name} uploaded`,
      //       color: "success",
      //     },
      //     { root: true }
      //   );
      // }
    } catch (error: any) {
      // const {
      //   response: { data: message },
      // } = error;
      const { message } = error;
      if (!(error.message === "Upload aborted."))
        commit(
          "Utilities/showSnackbar",
          {
            message,
            color: "error",
          },
          { root: true }
        );
    }
  },

  async abortUploadFile({ state, commit }, file: File) {
    try {
      state.uploadRequests.forEach(async (uploadRequest) => {
        if (uploadRequest.params.Body.name === file.name) {
          await uploadRequest.abort();
          commit("updateUploadAllFiles", { file: file, status: FileUploadStatus.CANCELED });
          commit("deleteUploadProgress", file.name);
        }
      });
    } catch (error) {
      // TODO: Handle error properly
      console.log(error);
    }
  },

  async abortUpload({ state, commit }) {
    try {
      state.uploadRequests.forEach(async (uploadRequest) => {
        await uploadRequest.abort();
      });

      commit("setIsUploadAbortedState", true);
    } catch (error) {
      // TODO: Handle error properly
      console.log(error);
    }
  },

  async putFolder({ commit, getters }, payload) {
    const {
      name,
      type,
      modelType,
      pointCloudType,
    }: { name: string; type: string; modelType?: string; pointCloudType?: string } = payload;
    // unique values
    const timestamp = Date.now();
    const id = uuidv4();
    const path = getters["getPath"];

    try {
      // put ddb entry for uploaded file
      const ddbPutResponse = await coreApiPost(`${apiRoot}/create-folder`, {
        id,
        timestamp,
        path,
        name,
        type,
        ...(modelType && { modelType }),
        ...(pointCloudType && { pointCloudType }),
      });

      commit("setFile", { path, file: ddbPutResponse });

      // don't show the success message if type is in ignore list
      const ignoreList = ["pointCloud", "model"];
      if (!ignoreList.includes(type)) {
        commit(
          "Utilities/showSnackbar",
          {
            message: `${name} created`,
            color: SnackbarColors.SUCCESS,
          },
          { root: true }
        );
      }
      // return id used for the folder
      // usefull for chained events that
      // create a folder for items
      return ddbPutResponse.id;
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
  },

  async uploadFiles({ state, dispatch, commit, getters, rootGetters }) {
    // no files, abort
    if (!state.uploadFiles.length) {
      commit(
        "Utilities/showSnackbar",
        {
          message: "No file(s) selected",
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
      return;
    }

    const path = getters["getPath"];
    const { type: uploadItemType } = rootGetters["Viewers/getUploadItem"];

    //await dispatch("uploadActivityFiles");

    // close select file dialog when switching to upload
    commit("setSelectFileDialogState", false);

    // close file browser dialog when switching to upload
    commit("setFileBrowserDialogState", false);

    // show upload dialog
    commit("setUploadDialogState", true);

    // set state isUploading to true
    commit("setIsUploading", true);

    // set abort state to false
    commit("setIsUploadAbortedState", false);

    // set upload start timestamp
    commit("setEsitmatedUploadTime", {
      start: Date.now(),
    });

    if (uploadItemType === "pointCloud") {
      // Verify point cloud data
      const pointCloudDataSuccess = dispatch("Utilities/verifyPointCloudData", state.uploadFiles, {
        root: true,
      });

      if (!pointCloudDataSuccess) {
        commit("setIsUploading", false);
        commit("setIsProfileImage", false);
        commit("resetTotalUploadSize");
        commit("resetEsitmatedUploadTime");
        commit("setUploadFiles", []);
        return;
      }
    }

    const uploads = state.uploadFiles.map(async (file: File) => {
      const validFileName = await dispatch("uploadFileNameValidation", file.name);

      let uploadFile = new File([file], validFileName, {
        type: file.type === "" ? file.name.split(".").pop() : file.type,
      });

      // check if image should be resized
      const { resize, width, height } = getters["getIsImageResize"];

      if (resize) {
        // resize image and return blob
        let blob = (await imageResize(file)) as Blob;

        // resize image, using provided width an height, return blob
        if (width && height) {
          blob = (await imageResize(file, width, height)) as Blob;
        }

        // create new file object from resized bolb
        uploadFile = new File([blob], validFileName, {
          type: "image/jpeg",
        });

        // reset isImageResize state
        commit("resetIsImageResize");
      }

      // adding files to upload activity bar after name validation
      commit("addUploadAllFiles", uploadFile);

      // increment total upload size per file
      commit("setTotalUploadSize", uploadFile.size);

      commit("updateUploadAllFiles", {
        file: uploadFile,
        status: FileUploadStatus.UPLOADING,
        path,
      });

      await dispatch("putFile", uploadFile);
    });

    await Promise.all(uploads);

    commit("setIsUploading", false);
    commit("setIsProfileImage", false);
    commit("resetTotalUploadSize");
    commit("resetEsitmatedUploadTime");
    // commit("setUploadFiles", []);
  },

  async updateItem({ commit }, item) {
    commit("setLoadingState", true);

    try {
      // put ddb entry for uploaded file
      const ddbPutResponse = await coreApiPost(`${apiRoot}/update-item`, { item });

      const { name } = ddbPutResponse;

      commit(
        "Utilities/showSnackbar",
        {
          message: `${name} updated`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Deletes a single item (file or folder) using the current path from `getPath` and the current context item from `getContextItem`.
   *
   * @async
   * @param {{ commit: any; dispatch: any; getters: any; }} { commit, dispatch, getters }
   * @returns {Promise<void>}
   */
  async deleteItem({
    commit,
    dispatch,
    getters,
  }: {
    commit: any;
    dispatch: any;
    getters: any;
  }): Promise<void> {
    // The path is used to determine
    const path = getters["getPath"];
    const deleteObject = getters["getContextItem"];
    const { id, objectName }: FileBrowserFile = deleteObject;

    const requiredVariables = [
      {
        test: !!path,
        message: "path",
      },
      {
        test: !!id,
        message: "ID",
      },
      {
        test: !!objectName,
        message: "name",
      },
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    // At this point we can start showing the loading state.
    commit("setLoadingState", true);

    // Setup S3.
    const { bucket, clientS3 } = getS3ClientConfig();
    const deleteObjectInput: DeleteObjectCommandInput = {
      Bucket: bucket,
      Key: `files/${objectName}`,
    };
    const deleteObjectCommand = new DeleteObjectCommand(deleteObjectInput);

    try {
      // Delete database item entry
      const responseData = await coreApiPost(`${apiRoot}/delete-item`, { id });
      const { name } = responseData;

      // Delete object from S3 bucket.
      await clientS3.send(deleteObjectCommand);

      // Remove item from `Storage` store state now that the database entries and S3 object have been deleted.
      commit("removeItem", { path, id });

      // Close the delete dialog since the item has been deleted.
      commit("setDeleteDialogState", false);

      // Reset the context item object so that any other dialogs or references to the item is cleared.
      commit("resetContextItem");

      // Notify user of success.
      commit(
        "Utilities/showSnackbar",
        {
          message: `${name} deleted`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: unknown) {
      // Display caught errors here.
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    // Finally, turn off loading state.
    commit("setLoadingState", false);
  },

  async deleteItems({ commit, getters }) {
    const path = getters["getPath"];
    const deleteObject = getters["getContextItem"];
    const rootId = deleteObject.id;

    // setup S3
    const { bucket, clientS3 } = getS3ClientConfig();

    try {
      // batch delete database entry items
      const responseData = await coreApiPost(`${apiRoot}/delete-items`, { rootId });

      // destructure response data
      const { itemCount, deleteReferences }: { itemCount: number; deleteReferences: string[] } =
        responseData;

      // if object references in response
      if (deleteReferences.length) {
        // build correct delete object for command input
        const references = deleteReferences.map((item: string) => ({ Key: item }));
        const deleteObjects: Delete = {
          Objects: references,
        };
        // delete objects from bucket
        const deleteObjectInput: DeleteObjectsCommandInput = {
          Bucket: bucket,
          Delete: deleteObjects,
        };
        const deleteObjectCommand = new DeleteObjectsCommand(deleteObjectInput);
        await clientS3.send(deleteObjectCommand);
      }

      // remove item from storage store
      commit("removeItem", { path, id: rootId });

      // close dialog and reset the context item object
      commit("setDeleteDialogState", false);
      commit("resetContextItem");

      // display status
      commit(
        "Utilities/showSnackbar",
        {
          message: `${itemCount} items deleted`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: unknown) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message: String(error).replace(/NotAuthorizedException:\s(.*?)/, "$1"),
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  // signed url used for sharing a public file
  // @ttl: expiration time (in seconds), defaults to 1 hour
  // 1 hour = 3600
  // 24 day = 86400
  // 7 days = 604800 (max)
  async signedUrl({ commit, getters }, payload: { ttl?: number; object?: Record<string, any> }) {
    const { ttl, object } = payload || {};

    let name, objectName, type;
    if (!object) {
      const contextItem: FileBrowserFile = getters["getContextItem"];
      name = contextItem.name;
      objectName = contextItem.objectName;
      type = contextItem.type;
    } else {
      name = object.name;
      objectName = object.objectName;
      type = object.type;
    }

    const { bucket, clientS3 } = getS3ClientConfig();
    const getObjectInput = {
      Bucket: bucket,
      Key: `files/${objectName}`,
      ResponseContentDisposition: `attachment; filename="${removeDiacritics(name)}.${type}"`,
      ResponseContentType: type,
    };
    const command = new GetObjectCommand(getObjectInput);

    try {
      return await getSignedS3Url(clientS3, command, { expiresIn: ttl ? ttl : 3600 });
    } catch (error) {
      // display error
      commit(
        "Utilities/showSnackbar",
        {
          message:
            "There was a problem getting a url for the file. Try again, if the problem persists, contact support",
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
  },

  // async signedUrlforFile({ commit }, fileObject) {
  //   const { name, objectName, type } = fileObject.object;

  //   const { bucket, clientS3 } = getS3ClientConfig();
  //   const getObjectInput = {
  //     Bucket: bucket,
  //     Key: `files/${objectName}`,
  //     ResponseContentDisposition: `attachment; filename="${name}"`,
  //     ResponseContentType: type,
  //   };

  //   const command = new GetObjectCommand(getObjectInput);

  //   try {
  //     return await getSignedS3Url(clientS3, command, { expiresIn: 28800 });
  //   } catch (error) {
  //     // display error
  //     commit(
  //       "Utilities/showSnackbar",
  //       {
  //         message:
  //           "There was a problem getting a url for the file. Try again, if the problem persists, contact support",
  //         color: SnackbarColors.ERROR,
  //       },
  //       { root: true }
  //     );
  //   }
  // },

  // Download file
  async downloadFile({ getters, dispatch }) {
    // Get context item for deconstruct.
    const contextItem: ContextItem = getters["getContextItem"];

    const { name, type: payloadType, objectName, action, id, pointCloudType } = contextItem;

    // Make sure that type is never `undefined`. If for whatever reason the type has not been determined at this point we will try to extract it from the `name` variable. As a last resort we will use `unknown` as the type.
    let type = payloadType;
    if (!type) {
      type = name.split(".").pop() || "unknown";
    }

    // If type is not undefined combine name and type for correct file name with extesion, otherwise use name.
    const downloadName = name.split(".").pop() !== type ? `${name}.${type}` : name;

    if (
      id &&
      action === "download" &&
      payloadType === "pointCloud" &&
      pointCloudType !== "potree"
    ) {
      // Get original point cloud data file
      dispatch("getContainer", id);
      return;
    }

    const source = await getS3Object(
      objectName,
      "",
      undefined,
      undefined,
      true,
      downloadName,
      action === "open" ? true : false
    );

    // const anchor = document.createElement("a");
    // anchor.download = downloadName;
    // anchor.href = source;
    // anchor.click();
    // anchor.remove();

    // Get blob url.
    // const downloadObject = await getS3Object(objectName);
    dispatch("processDownloadItem", { item: source, name: downloadName, type, action });
  },

  processDownloadItem(
    { commit },
    payload: { item?: string; data?: string; name: string; type: string; action: string }
  ) {
    const { data, item, name, type, action } = payload;

    let downloadItem = "";

    if (!item && data) {
      const dataChunks = Array.isArray(data) ? data : [data];
      const blob = new Blob(dataChunks, { type });

      // Assign blob to create object URL.
      downloadItem = URL.createObjectURL(blob);
    }

    if (item) {
      downloadItem = item;
    }

    if (!downloadItem) {
      return;
    }

    if (action === "download") {
      // Create anchor element for blob url.
      const anchor = document.createElement("a");
      anchor.href = downloadItem;
      anchor.type = type;
      anchor.download = name;

      document.body.appendChild(anchor);
      // Invoke mouse click event on anchor element.
      anchor.dispatchEvent(new MouseEvent("click"));

      // Firefox requires delay before revoking object URL.
      setTimeout(() => {
        anchor.remove();
        URL.revokeObjectURL(downloadItem as string);
      }, 60);
    }

    if (action === "open") {
      const insetType = ["pdf"];
      const embed = document.createElement("embed");
      embed.src = downloadItem;
      if (insetType.includes(type)) {
        embed.style.width = "100%";
        embed.style.height = "100%";
      }

      const tab = window.open("", "_blank");

      tab!.document.body.appendChild(embed);
      tab!.document.title = name;

      tab!.document.body.style.margin = "0";
    }

    commit("resetContextItem");

    /* SAVE FOR LATER
    open if possible otherwise download
    const imageElement = `<img src="${signedURL}" />`;
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(imageElement);
    }else{
      window.open(signedURL, "_blank");
    }
    */
  },

  /**
   * Share file with signed url
   *
   * See signedUrl for TTL
   *
   * @async
   * @param {{ commit: any; dispatch: any; }} { commit, dispatch }
   * @returns {*}
   */
  async shareFile({ commit, dispatch }) {
    commit("setShareDialogState", true);
    const signedURL = await dispatch("signedUrl");
    commit("setShareUrl", signedURL);
  },

  async findFetchedWireframe(
    { getters },
    { id, modelContainerId }: { id: number; modelContainerId: string }
  ) {
    const fetchedWireframes: FetchedWireFrame[] = getters["getFetchedWireframes"];
    return fetchedWireframes.find(
      (wireframe) => wireframe.id === id && wireframe.modelContainerId === modelContainerId
    );
  },
};

export default actions;
