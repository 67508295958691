
import Vue from "vue";

import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import {
  ModelInspectorInterface,
  PointcloudInspectorInterface,
  PointInspectorInterface,
} from "@/store/modules/PointCloudViewer/types";
import { IFCModelItemInspectorInterface, ViewerInspectorMode, XYZInterface } from "@/types";

import { AttachmentsSection } from "./attachments-section";
import TagsSection from "./tags-section.vue";

interface Data {
  defaultInspectorTab: string;
}

export default Vue.extend({
  name: "ViewerInspectorTabItemsInfo",
  components: {
    AttachmentsSection,
    TagsSection,
  },
  data: (): Data => ({
    defaultInspectorTab: "info",
  }),
  methods: {
    async saveCameraPosition() {
      this.$store.dispatch("PointCloudViewer/saveCameraPosition");
    },

    updatePointOnDatabase() {
      this.$store.dispatch("PointCloudViewer/updatePointOnDatabase", {
        id: (this.inspector as PointInspectorInterface).id,
        forced: false,
        inspector: this.inspector,
        category: this.inspectorMode,
      });
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    inspectorMode(): string {
      return this.$store.getters["PointCloudViewer/getInspectorMode"];
    },

    isPointInspector(): boolean {
      return (
        this.inspectorMode === ViewerInspectorMode.POINT_OF_INTEREST ||
        this.inspectorMode === ViewerInspectorMode.MEASUREMENTS
      );
    },

    disableSaveCameraPosition(): boolean {
      const potreeScene = this.$store.getters["PointCloudViewer/getPotreeScene"];
      const itemCameraPosition = (this.inspector as PointInspectorInterface)
        .cameraPosition as XYZInterface & {
        [key: string]: number;
      };

      return Object.entries(potreeScene.getActiveCamera().position).some(
        ([key, value]) => itemCameraPosition[key] === value
      );
    },

    labelVModel: {
      get(): string {
        let title = "";

        switch (this.inspectorMode) {
          case ViewerInspectorMode.POINTCLOUD:
            title = (this.inspector as PointcloudInspectorInterface).title;
            break;

          case ViewerInspectorMode.MODEL:
            title = (this.inspector as ModelInspectorInterface).title;
            break;

          case ViewerInspectorMode.IFC_MODEL_ITEM:
            title = (this.inspector as IFCModelItemInspectorInterface).name;
            break;

          case ViewerInspectorMode.POINT_OF_INTEREST:
          case ViewerInspectorMode.MEASUREMENTS:
            title = this.getInspectorName;
            break;
        }

        return title;
      },
      set(value: string) {
        if (!this.isPointInspector) {
          return;
        }

        this.getInspectorName = value;
      },
    },

    getInspectorName: {
      get(): string {
        return this.inspectorMode !== "ifcModelItem"
          ? (this.inspector as PointInspectorInterface).title
          : "";
      },
      set(value: string) {
        if (this.inspectorMode === "ifcModelItem") {
          return;
        }
        const measureInspector = this.inspector as PointInspectorInterface;
        measureInspector.title = value;

        this.$store.commit("PointCloudViewer/setMeasureInspector", measureInspector);
      },
    },

    loading(): boolean {
      return this.$store.getters["PointCloudViewers/getSyncingState"];
    },
  },
});
