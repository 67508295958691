import { AwsConfig } from "./types/aws";
import { getClientData } from "./utilities/local-storage";

// set
let endpoint: string;
switch (process.env.NODE_ENV) {
  case "staging":
    endpoint = "https://api.staging.openpoint.se";
    break;
  case "sandbox":
    endpoint = "https://api.sandbox.openpoint.se";
    break;

  default:
    endpoint = "https://api.openpoint.se";
    break;
}
// set base static (known) AWS config data
let awsConfig: AwsConfig = {
  Auth: {
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "core",
        endpoint,
      },
    ],
  },
};

// get "openpointClientAuthData" from local storage
const clientData = getClientData();

// if client data is not undefined spread
// client specific data to AWS config object
if (clientData) {
  const { identityPoolId, userPoolId, userPoolWebClientId, region, bucket } = clientData;

  awsConfig = {
    ...awsConfig,
    Auth: {
      region,
      identityPoolId,
      userPoolId,
      userPoolWebClientId,
      ...awsConfig.Auth,
    },
    Storage: {
      AWSS3: {
        region,
        bucket: bucket,
      },
    },
  };
}

export default awsConfig;
