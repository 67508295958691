var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel-content',[_c('v-list',{staticClass:"mt-n2",attrs:{"dense":""}},[_c('v-list-item-group',[_vm._l((_vm.groupItems),function(item,index){return [_c('v-divider',{key:'div-' + index}),_c('v-list-item',{key:item.id,attrs:{"ripple":false},on:{"mouseenter":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
              id: item.id,
              modelId: _vm.getCurrentLeftSideBarModelId(),
            })},"mouseleave":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleHighlightFromSidebar', {
              id: item.id,
              modelId: _vm.getCurrentLeftSideBarModelId(),
            })},"click":function($event){_vm.$store.dispatch('PointCloudViewer/ifcToggleClick', {
              item,
              modelId: _vm.getCurrentLeftSideBarModelId(),
            })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium overflow-visible"},[_c('span',{staticClass:"ml-12 mr-1"},[_vm._v(_vm._s(item.displayname))]),(item.attachments && item.attachments.length > 0)?_c('AttachmentDots',{attrs:{"attachments":item.attachments}}):_vm._e()],1)],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){return _vm.$store.dispatch('PointCloudViewer/ifcToggleVisible', {
                  id: item.id,
                  ids: item.ids,
                })}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters["PointCloudViewer/getIfcHidden"].includes(item.id) ? "mdi-eye-off" : "mdi-eye"))])],1)],1)],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }