
import Vue from "vue";

export default Vue.extend({
  name: "ViewerLayerItemActionToggleVisibility",
  props: {
    itemkey: String,
    item: Object,
    type: String,
  },
  methods: {
    toggleLayerItemVisibility(item: Record<string, unknown>, type: string) {
      const payload = { item, type };
      this.$store.dispatch("PointCloudViewer/toggleLayerItemVisibility", payload);
    },
    getObjectVisibilityState(item: Record<string, unknown>, type: string) {
      // const payload = { item, type };
      // this.$store.dispatch("PointCloudViewer/getObjectVisibilityState", payload);

      return this.$store.getters["PointCloudViewer/getObjectVisibilityState"](item)(type);
    },
  },
});
