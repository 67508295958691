import { State } from "./types";

const getDefaultState = (): State => {
  return {
    deleteDialog: false,
    fetched: false,
    isDeleted: [],
    list: [],
    loading: false,
    selectDialog: false,
    uploading: false,
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
