/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, Commit, Dispatch } from "vuex";

import { FileBrowserAccessRole, UpdatedProjectUsersInterface } from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { coreApiPost } from "@/utilities/core-api";
import { getClientData } from "@/utilities/local-storage";

import { State, User } from "./types";

const apiRoot = "/account";
const actions: ActionTree<State, unknown> = {
  /**
   * Set user role.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; }} { commit, dispatch } - Vuex commit and dispatch.
   * @param {{ email: string; fromRole: string; toRole: string }} payload - User email, from role and to role.
   * @returns {Promise<void>}
   */
  async setUserRole(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: { email: string; fromRole: string; toRole: string }
  ): Promise<void> {
    commit("setEditWorkingState", true);

    const { email, fromRole, toRole } = payload;
    const { clientName } = getClientData();

    try {
      await coreApiPost(`${apiRoot}/set-user-role`, {
        clientName,
        adminUser: email,
        fromRole,
        toRole,
      });
      await dispatch("fetchUsers");
      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${email}</strong> successfully changed role from <strong>${fromRole}</strong> to <strong>${toRole}</strong>`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setEditWorkingState", false);
  },

  /**
   * Set user state.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; }} { commit, dispatch } - Vuex commit and dispatch.
   * @param {{ email: string; userState: boolean }} payload - User email and state.
   * @returns {Promise<void>}
   */
  async setUserState(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: { email: string; userState: boolean }
  ): Promise<void> {
    commit("setEditWorkingState", true);

    const { email, userState } = payload;

    try {
      await coreApiPost(`${apiRoot}/set-user-state`, {
        adminUser: email,
        adminState: userState,
      });
      await dispatch("fetchUsers");
      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${email}</strong> was successfully ${userState ? "enabled" : "disabled"
            }`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setEditWorkingState", false);
  },

  async setUserProjectAccess(
    { commit }: { commit: Commit },
    payload: {
      userEmail: string;
      projects: UpdatedProjectUsersInterface[];
    }
  ): Promise<void> {
    commit("setEditWorkingState", true);

    const { userEmail, projects } = payload;

    try {
      await coreApiPost(`${apiRoot}/set-user-project-access`, {
        userEmail,
        projects,
      });

      commit("projectAccessupdateUserList", {
        userEmail,
        projects,
      });

      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${userEmail}</strong> was successfully updated in ${projects.length} projects.`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setEditWorkingState", false);
  },

  async setUserFileBrowserAccess(
    { commit }: { commit: Commit },
    payload: {
      editUser: string;
      rootFileAccess?: FileBrowserAccessRole;
    }
  ): Promise<void> {
    commit("setEditWorkingState", true);

    const { editUser, rootFileAccess } = payload;

    try {
      await coreApiPost(`${apiRoot}/set-user-file-browser-access`, payload);

      commit(
        "Utilities/showSnackbar",
        {
          message: `<strong>${editUser}</strong> has <strong>${rootFileAccess ? "" : "no"
            } access</strong> to organization file browser.`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setEditWorkingState", false);
  },

  /**
   * Fetch users.
   *
   * @async
   * @param {{ commit: Commit; }} { commit } - Vuex commit.
   * @returns {Promise<void>}
   */
  async fetchUsers({ commit }: { commit: Commit }, nonAdmin = false): Promise<void> {
    // TODO: 60 is the max in a single call, so proper pagination call needed for future
    commit("setLoadingState", true);

    try {
      const fetchUsersResponse = await coreApiPost(`${apiRoot}/list-users`, {
        Limit: 60,
        nonAdmin,
        PaginationToken: "",
      });

      commit("setUserList", fetchUsersResponse);

      if (!nonAdmin) {
        commit("setFetchedState", true);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;
      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },

  /**
   * Create users from array in batches of 20.
   *
   * **NOTE!** More than 20 users causes lambda function `createUsers` to timeout. Timeout for the lambda function is set to 5 seconds. Increasing the timeout is not recommended.
   *
   * @async
   * @param {{ commit: Commit; }} { commit } - Vuex commit.
   * @param {User[]} payload - Array of users to create.
   * @returns {Promise<void>}
   */
  async createUsers({ commit }: { commit: Commit }, payload: User[]): Promise<void> {
    commit("setLoadingState", true);

    try {
      const userCount = 20;
      let users: User[] = [];
      let totalUserCount = 0;

      const successArray: any[] = [];
      const errorArray: any[] = [];

      // Iterate through the payload array in groups of the specified size.
      for (const user of payload) {
        users.push(user);
        totalUserCount++;

        if (users.length === userCount || totalUserCount === payload.length) {
          // Make a request to the API with the current batch of users.
          const createResponse = await coreApiPost(`${apiRoot}/create-users`, { users });
          const { successArray: responseSuccessArray, errorArray: responseErrorArray } =
            createResponse;
          successArray.push(...responseSuccessArray);
          errorArray.push(...responseErrorArray);

          // Reset the batch of users
          users = [];
        }
      }

      // Handle any remaining users in the payload array.
      if (users.length > 0) {
        // Make a request to the API with the remaining batch of users.
        const createResponse = await coreApiPost(`${apiRoot}/create-users`, { users });
        const { successArray: responseSuccessArray, errorArray: responseErrorArray } =
          createResponse;
        successArray.push(...responseSuccessArray);
        errorArray.push(...responseErrorArray);
      }

      if (successArray.length) {
        for (const user of successArray) {
          commit("createUpdateUserList", user);
        }
      }

      const successMessage = `Successfully created ${successArray.length} ${successArray.length > 1 ? "users" : "user"
        }`;
      const errorMessage = errorArray
        .map((item: { email: string; message: string }) => {
          const { email, message } = item;
          return `<strong>${email}</strong>: ${message}`;
        })
        .join("<br />");
      commit(
        "Utilities/showSnackbar",
        {
          message: errorArray.length
            ? `${errorMessage}
              ${successArray.length
              ? `<div class="mt-2">
              ${successMessage}
              </div>`
              : ""
            }`
            : successMessage,
          color: errorArray.length ? SnackbarColors.ERROR : SnackbarColors.SUCCESS,
          timeout: errorArray.length ? 10 : undefined,
        },
        { root: true }
      );
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;

      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    commit("setLoadingState", false);
  },

  /**
   * Delete a single user.
   *
   * @async
   * @param {{ commit: Commit; }} { commit } - Vuex commit.
   * @param {string} email - User email.
   * @returns {Promise<void>}
   */
  async deleteUser({ commit }: { commit: Commit }, email: string): Promise<void> {
    commit("setLoadingState", true);

    try {
      await coreApiPost(`${apiRoot}/delete-user`, {
        adminDeleteUser: email,
      });

      commit("deleteUpdateUserList", email);

      commit(
        "Utilities/showSnackbar",
        {
          message: `${email} was succefully deleted`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        response: { data: message },
      } = error;

      commit(
        "Utilities/showSnackbar",
        {
          message,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }
    commit("setLoadingState", false);
  },
};

export default actions;
