
import Vue from "vue";

import { FileBrowserDialog } from "@/components/file-browser";
import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import {
  AttachmentSectionLineItem,
  AttachmentType,
  ContextItem,
  InspectorAttachmentFileInterface,
  InspectorAttachmentTypeInterface,
  VForm,
} from "@/types";

import AttachmentHeader from "./attachment-header.vue";
import ListItems from "./list-items.vue";

interface Data {
  dialog: boolean;
  description: string;
  file: Partial<InspectorAttachmentTypeInterface>;
  itemTableId?: string;
  modelContainerId?: string;
  // `selectedFile` in `validateForm` requires an `id` property.
  emptyFileItem: Omit<InspectorAttachmentFileInterface, "createdAt" | "createdBy"> & { id: string };
}

const defaultFile = {
  type: AttachmentType.FILE,
  items: [],
};

const emptyFileItem = {
  id: "",
  name: "",
  description: "",
  objectName: "",
  type: "",
};

export default Vue.extend({
  name: "FileAttachmentType",
  components: {
    AttachmentHeader,
    ListItems,
    FileBrowserDialog,
  },
  data: (): Data => ({
    dialog: false,
    file: defaultFile,
    description: "",
    itemTableId: undefined,
    modelContainerId: undefined,
    emptyFileItem,
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.description = "";
      this.selectedFile = this.emptyFileItem;
    },

    handleSelect() {
      this.$store.commit("Storage/setPath", this.$route.params.projectId);
      this.$store.commit("Storage/setFileBrowserDialogState", true);
    },

    async validateForm() {
      const {
        $refs: { formAddFile },
        file: { id },
        selectedFile,
        description,
      } = this;

      const form = formAddFile as VForm;

      if (form.validate()) {
        await this.$store.dispatch("PointCloudViewer/createAttachment", {
          sectionId: id,
          lineItem: {
            ...selectedFile,
            ...(description && { description }),
          },
          sectionLineItem: AttachmentSectionLineItem.LINE_ITEM,
        });

        // Reset form and data.
        form.reset();
        this.closeDialog();
      }
    },
  },
  computed: {
    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    hasSelectedFile(): boolean {
      const contextItem = this.selectedFile;
      const emptyValues = Object.values(contextItem).every((value) => value === "");

      return !emptyValues;
    },

    selectedFile: {
      get(): ContextItem {
        return this.$store.getters["Storage/getContextItem"];
      },
      set(value: ContextItem) {
        this.$store.commit("Storage/setContextItem", value);
      },
    },

    selectedFileName: {
      get(): string {
        const file = this.selectedFile;
        return file?.name ?? "";
      },
      set() {
        this.$store.commit("PointCloudViewer/resetSelectedAttachmentFile");
      },
    },
  },
  async mounted() {
    Vue.set(this.file, "items", []);

    // Clear any existing context item data on mount. Closing the POI inspector should always clear selected input.
    this.$store.commit("Storage/resetContextItem");

    const {
      inspector: { attachments },
    } = this;

    const attachment = attachments?.find(
      (item: InspectorAttachmentTypeInterface) => item.type === AttachmentType.FILE
    );

    if (attachment) {
      const { createdAt, createdBy, id, items } = attachment;
      Vue.set(this.file, "createdAt", createdAt);
      Vue.set(this.file, "createdBy", createdBy);
      Vue.set(this.file, "id", id);
      Vue.set(this.file, "items", items ?? []);
    }
  },
  destroyed() {
    this.file = defaultFile;
    this.description = "";
  },
});
