
import Vue from "vue";

import {
  ModelInspectorInterface,
  PointcloudInspectorInterface,
  PointInspectorInterface,
} from "@/store/modules/PointCloudViewer/types";
import { IFCModelItemInspectorInterface, ViewerInspectorMode } from "@/types";

import { TabDetails, TabInfo } from "./tab-items";

interface Data {
  inspectorTab: string;
}

export default Vue.extend({
  name: "ViewerInspector",
  components: {
    TabDetails,
    TabInfo,
  },
  data: (): Data => ({
    inspectorTab: "info",
  }),
  methods: {
    /**
     * Routes the user to the edit viewer page.
     *
     * @returns void
     */
    editViewer() {
      const { projectId, viewerId } = this.$route.params;

      this.$router.push({
        name: "viewerEdit",
        params: { projectId, viewerId },
      });
    },
  },
  computed: {
    inspectorMode(): string {
      return this.$store.getters["PointCloudViewer/getInspectorMode"];
    },

    /**
     * Returns the current point cloud inspector.
     *
     * @returns PointcloudInspectorInterface
     */
    pointcloudInspector(): PointcloudInspectorInterface {
      return this.$store.getters["PointCloudViewer/getPointcloudInspector"];
    },

    /**
     * Returns the current point cloud inspector.
     *
     * @returns PointcloudInspectorInterface
     */
    modelInspector(): ModelInspectorInterface {
      return this.$store.getters["PointCloudViewer/getModelInspector"];
    },

    /**
     * Returns the current IFC model item inspector.
     *
     * @returns IFCModelItemInspectorInterface
     */
    ifcModelItemInspector(): IFCModelItemInspectorInterface {
      return this.$store.getters["PointCloudViewer/getIFCModelItemInspector"];
    },

    /**
     * Returns the current point inspector.
     *
     * @returns PointInspectorInterface
     */
    pointInspector(): PointInspectorInterface {
      return this.$store.getters["PointCloudViewer/getMeasureInspector"];
    },

    loading(): boolean {
      return this.$store.getters["PointCloudViewers/getSyncingState"];
    },

    /**
     * Returns true if the inspector is one of `ViewerInspectorMode.POINT_OF_INTEREST` or `ViewerInspectorMode.MEASUREMENTS`.
     *
     * @returns boolean
     */
    isPointInspector(): boolean {
      return (
        this.inspectorMode === ViewerInspectorMode.POINT_OF_INTEREST ||
        this.inspectorMode === ViewerInspectorMode.MEASUREMENTS
      );
    },

    /**
     * Returns true if the inspector is `ViewerInspectorMode.POINTCLOUD`.
     *
     * @returns boolean
     */
    isPointcloudInspector(): boolean {
      return this.inspectorMode === ViewerInspectorMode.POINTCLOUD;
    },

    /**
     * Returns true if the inspector is `ViewerInspectorMode.MODEL`.
     *
     * @returns boolean
     */
    isModelInspector(): boolean {
      return this.inspectorMode === ViewerInspectorMode.MODEL;
    },

    /**
     * Returns true if the inspector is `ViewerInspectorMode.IFC_MODEL_ITEM`.
     *
     * @returns boolean
     */
    isIFCModelItem(): boolean {
      return this.inspectorMode === ViewerInspectorMode.IFC_MODEL_ITEM;
    },
  },
});
