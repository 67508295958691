
import Vue from "vue";

import { DeleteTagDialog, EditTagDialog, Tag } from "@/components/tags";
import { TagItemInterface, VForm } from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { sortArray, titleRules } from "@/utilities";

interface Data {
  /**
   * Whether the user has made any changes to the form.
   *
   * @type {boolean}
   * @default false
   */
  hasChange: boolean;
  /**
   * String used to filter tags in search field.
   *
   * @type {string}
   * @default ""
   */
  searchText: string;
  /**
   * The title of the tag.
   *
   * @type {string}
   * @default ""
   */
  title: string;
  /**
   * The description of the tag. Optional.
   *
   * @type {string}
   * @default undefined
   */
  description?: string;
  /**
   * The rules for the title field when creating a new tag. Must not be empty.
   *
   * @type {Array<boolean | string>}
   * @returns {Array<boolean | string>} The validation rules.
   */
  titleRules: (value: string) => Array<boolean | string>;
}

export default Vue.extend({
  name: "SettingsTags",
  components: {
    DeleteTagDialog,
    EditTagDialog,
    Tag,
  },
  data: (): Data => ({
    hasChange: false,
    searchText: "",
    title: "",
    description: undefined,
    titleRules,
  }),
  methods: {
    /**
     * Fetches tags from the server and updates the `Tags` store with the new data.
     *
     * This is to avoid unnecessarily fetching all the tags, e.g. if the user is only creating tags and has no interest in managing them.
     *
     * @returns {Promise<void>}
     */
    async fetchTags(): Promise<void> {
      if (!this.hasFetched) {
        await this.$store.dispatch("Tags/fetchTags");
        this.$store.commit("Tags/setTagListCount", this.tagList.length);
        this.$store.commit("Tags/setTagListFilterCount", this.tagList.length);
      }
    },

    /**
     * Validates the form and creates a new tag if it's valid. It also make sure that the tag is unique (if it has fetched tags, to match agains) and updates the `Tags` store with the new data.
     *
     * @returns {Promise<void>}
     */
    async createTag(): Promise<void> {
      // Make sure the tag is unique. This is verified in the backend when creating the tag as well. In case the tags are fetched, we can check against the list of tags.
      if (
        this.tagList.some(
          (tag) => tag.title.toLowerCase().trim() === this.title.toLowerCase().trim()
        ) ||
        !this.form.validate()
      ) {
        // Show "already exists" message.
        this.$store.commit(
          "Utilities/showSnackbar",
          {
            message: `Tag <strong>${this.title}</strong> already exists.`,
            color: SnackbarColors.ERROR,
          },
          { root: true }
        );
      } else {
        // Create the tag.
        const tagCreated = await this.$store.dispatch("Tags/createTag", {
          title: this.title.trim(),
          description: this.description,
        });

        // Continue after successful API post.
        if (tagCreated) {
          this.resetForm();
        }
      }
    },

    /**
     * Reset the form.
     *
     * @returns {void}
     */
    resetForm(): void {
      this.form.reset();
      this.title = "";
      this.description = "";
      this.hasChange = false;
    },

    /**
     * Sort the tag list alphabetically.
     *
     * @param {TagItemInterface[]} tags The tag list to sort.
     * @returns {void}
     */
    sorter(tags: TagItemInterface[]): void {
      sortArray(tags, "title");
    },
  },
  computed: {
    /**
     * Checks `Tags` store `getFetchState` to determine if tags have been fetched.
     *
     * @returns {boolean}
     */
    hasFetched(): boolean {
      return this.$store.getters["Tags/getFetchState"];
    },

    /**
     * Gets the store `tagListCount` value.
     *
     * @returns {number}
     */
    tagListCount(): number {
      return this.$store.getters["Tags/getTagListCount"];
    },

    /**
     * Gets the store `tagListFilterCount` value.
     *
     * @returns {number}
     */
    tagListFilterCount(): number {
      return this.$store.getters["Tags/getTagListFilterCount"];
    },

    editDialogState: {
      /**
       * Get the `Tags` store `getEditDialogState` state.
       *
       * @returns {boolean}
       */
      get(): boolean {
        return this.$store.getters["Tags/getEditDialogState"];
      },
      /**
       * Set the `Tags` store `getEditDialogState` state.
       *
       * @param {boolean} value - Show/hide dialog.
       */
      set(value: boolean) {
        return this.$store.commit("Tags/setEditDialogState", value);
      },
    },

    /**
     * Get the `Tags` store `selectedTag`. This is the tag that is currently being edited.
     *
     * @returns {TagItemInterface}
     */
    selectedTag(): TagItemInterface {
      return this.$store.getters["Tags/getSelectedTag"];
    },

    /**
     * Get the current `VForm` instance.
     *
     * @returns {VForm}
     */
    form(): VForm {
      return this.$refs.form as VForm;
    },

    /**
     * Get the `Tags` store `tags` and filter them based on the search text.
     *
     * @returns {TagItemInterface[]}
     */
    tagList(): TagItemInterface[] {
      const tags: TagItemInterface[] = this.$store.getters["Tags/getTags"];

      // Sort tags alphabetically.
      if (tags.length) {
        this.sorter(tags);
      }

      // Filter tags based on search text.
      const noSearch = this.searchText === "" || this.searchText === null;

      if (noSearch) {
        return tags;
      } else {
        const filteredTags = tags.filter((tag) => {
          // Convert all strings to lower case
          const searchText: string = this.searchText.toLowerCase();
          const title: string = tag.title.toLowerCase();
          // const description: string = tag.description.toLowerCase();

          // Only return matches
          // if (title.includes(searchText) || description.includes(searchText)) {
          if (title.includes(searchText)) {
            return tag;
          }
        });
        this.$store.commit("Tags/setTagListFilterCount", filteredTags.length);
        return filteredTags;
      }
    },

    working: {
      /**
       * Get the `Tags` store `getLoadingState`.
       *
       * @returns {boolean}
       */
      get(): boolean {
        return this.$store.getters["Tags/getLoadingState"];
      },
      /**
       * Set the `Tags` store `setLoadingState`.
       *
       * @param {boolean} value - Turn `working` on or off.
       */
      set(value: boolean) {
        return this.$store.commit("Tags/setLoadingState", value);
      },
    },
  },

  beforeMount() {
    // Set the initial tag count if the tags have already been fetched.
    if (this.hasFetched) {
      this.$store.commit("Tags/setTagListCount", this.tagList.length);
      this.$store.commit("Tags/setTagListFilterCount", this.tagList.length);
    }
  },
});
