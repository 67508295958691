
import Vue from "vue";

import { CurrentInspectorTuple } from "@/store/modules/PointCloudViewer/sidebars/types";
import { TagGroup, TagItemInterface } from "@/types";
import { Roles } from "@/types/enums";
import { sortArray } from "@/utilities";

interface Data {
  userRole?: Roles;
  fetchingTags: boolean;
  tagFilter: string;
}

export default Vue.extend({
  name: "PointCloudViewerInspectorTags",
  components: {
    // Tag,
  },
  data: (): Data => ({
    userRole: undefined,
    fetchingTags: false,
    tagFilter: "",
  }),
  methods: {
    async createTag(title: string) {
      if (!title || title === "") {
        return;
      }

      const responseData = await this.$store.dispatch("Tags/createTag", {
        title,
        returnResponse: true,
      });

      this.addViewerLayerItemTag({
        ...responseData,
        tagId: responseData.id,
        group: TagGroup.USED,
      });
    },

    selectItem(attrs: Record<string, unknown>, tag: TagItemInterface) {
      const isSelected = attrs["aria-selected"] === "true";

      if (isSelected) {
        this.removeViewerLayerItemTag(tag);
      } else {
        this.addViewerLayerItemTag(tag);
      }
    },

    async addViewerLayerItemTag(tag: Partial<TagItemInterface>) {
      if (!this.isAdmin || !tag) {
        return;
      }

      await this.$store.dispatch("Tags/addViewerLayerItemTag", {
        inspector: this.inspector,
        tag,
      });
      this.itemsListRef.blur();
    },

    async removeViewerLayerItemTag(tag: Partial<TagItemInterface>) {
      if (!this.isAdmin || !tag) {
        return;
      }

      const tagData = this.viewerTags.find((item) => item.id === tag.id);

      const { tableItemId } =
        tagData?.items?.find((item) => item.id === this.inspectorIds.id) || {};

      await this.$store.dispatch("Tags/removeViewerLayerItemTag", {
        globalTags: this.globalTags,
        inspector: this.inspector,
        inspectorTags: this.inspectorTags,
        tableItemId,
        tag,
      });
      this.itemsListRef.blur();
    },

    /**
     * Sort the tag list alphabetically.
     *
     * @param {TagItemInterface[]} tags The tag list to sort.
     * @returns {void}
     */
    sorter(tags: Partial<TagItemInterface>[]): void {
      sortArray(tags, "title");
    },
  },
  computed: {
    itemsListRef(): HTMLInputElement {
      return this.$refs.itemsList as HTMLInputElement;
    },

    isWorking(): boolean {
      return (
        this.$store.getters["PointCloudViewer/getLoadingState"] ||
        this.$store.getters["Tags/getLoadingState"]
      );
    },

    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    inspector(): CurrentInspectorTuple {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    inspectorIds() {
      return this.$store.getters["PointCloudViewer/getCurrentInspectorIds"];
    },

    /**
     * Get the `Tags` store.
     *
     * @returns {TagItemInterface[]}
     */
    globalTags: {
      get(): TagItemInterface[] {
        const tags: TagItemInterface[] = this.$store.getters["Tags/getTags"];
        const updatedTags = tags.map((item) => ({
          ...item,
          group: TagGroup.UNUSED,
        }));
        return updatedTags;
      },

      set(tags: TagItemInterface[]) {
        this.$store.commit("Tags/setTags", tags);
      },
    },

    /**
     * Get the `viewerTags` store.
     *
     * @returns {TagItemInterface[]}
     */
    viewerTags: {
      get(): TagItemInterface[] {
        const tags: TagItemInterface[] = this.$store.getters["Tags/getViewerTags"] ?? [];
        this.sorter(tags);

        const updatedTags =
          tags?.reduce((itemsArray: TagItemInterface[], item) => {
            const { items, isAuto } = item;

            if (!isAuto) {
              itemsArray.push({
                ...item,
                group: TagGroup.USED,
                items,
              });
            }

            return itemsArray;
          }, []) ?? [];

        return updatedTags;
      },
      set(tags: TagItemInterface[]) {
        this.$store.commit("Tags/setViewerTags", tags);
      },
    },

    inspectorTags: {
      get(): Partial<TagItemInterface>[] {
        const { id: inspectorId } = this.inspectorIds;

        const filteredTags = this.viewerTags.filter((item) =>
          item.items?.some((item) => item.id === inspectorId)
        );

        return filteredTags.map((item) => {
          const { id, title } = item;

          return {
            id,
            title,
            group: TagGroup.USED,
            // ! Table item ID is not "tag-based". It "belongs" to the item.
            tableItemId: item.tableItemId,
          };
        });
      },

      set(tags: Partial<TagItemInterface>[]): Partial<TagItemInterface>[] {
        const { id: inspectorId } = this.inspectorIds;

        const updatedTags = tags.map((item) => {
          const { id: tagId } = item;
          const tag = this.viewerTags.find((tag) => tag.id === tagId);
          if (tag) {
            const { title, items } = tag;
            return {
              title,
              items: items?.filter((item) => item.id !== inspectorId) ?? [],
            };
          }
          return {
            tag: item,
            items: [],
          };
        });

        return updatedTags;
      },
    },

    /**
     * Gets available tags based on the `viewerTags` and `globalTags` store and group them by `TagGroup`.
     *
     * @returns {(Partial<TagItemInterface> & { header?: TagGroup; divider?: boolean })[]}
     */
    availableTags(): Partial<
      TagItemInterface & {
        header?: TagGroup;
        divider?: boolean;
      }
    >[] {
      const hasViewerTags = this.viewerTags.length > 0;
      const hasGlobalTags = this.globalTags.length > 0;

      // Sort arrays alphabetically.
      this.sorter(this.viewerTags);
      this.sorter(this.globalTags);

      return [
        // If `viewerTags` is not empty, add header and spread tags.
        ...(hasViewerTags
          ? [
              {
                header: TagGroup.USED,
              },
              ...this.viewerTags.map((item) => ({
                ...item,
                id: item.id,
              })),
            ]
          : []),
        // If `globalTags` is not empty, add a divider, header and spread tags.
        ...(hasGlobalTags
          ? [
              ...(hasViewerTags ? [{ divider: true }] : []),
              {
                header: TagGroup.UNUSED,
              },
              ...this.globalTags.map((item) => ({
                ...item,
                id: item.id,
              })),
            ]
          : []),
      ];
    },
  },
  async beforeMount() {
    this.userRole = this.$store.getters["User/getAuthUserRole"].role;
    // Check if the global tags have been fetched.
    const hasStoreTags: TagItemInterface[] = this.$store.getters["Tags/getFetchState"];

    // If not, fetch them.
    if (!hasStoreTags) {
      // Set the fetching state to true.
      this.fetchingTags = true;
      // Fetch the tags.
      await this.$store.dispatch("Tags/fetchTags");
      // Set the fetching state to false.
      this.fetchingTags = false;
    }

    // Map the used tags, remove used tags from the global tag list with matching `title` and add `group` key with value `TagGroup.USED` the the return item. There should always only be one tag with the same `title` available.
    const viewerTags = this.viewerTags.map((item) => {
      const globalItemIndex = this.globalTags.findIndex((tag) => tag.title === item.title);

      if (globalItemIndex !== -1) {
        this.globalTags.splice(globalItemIndex, 1);
      }

      return {
        ...item,
        group: TagGroup.USED,
      };
    });

    this.viewerTags = viewerTags;

    // Remove current tags from the global tag list with matching `title`.
    this.inspectorTags.forEach((item) => {
      const globalItemIndex = this.globalTags.findIndex((tag) => tag.title === item.title);

      if (globalItemIndex !== -1) {
        this.globalTags.splice(globalItemIndex, 1);
      }
    });
  },
});
