
import Vue from "vue";

import Author from "@/components/author.vue";
import { PointInspectorInterface } from "@/store/modules/PointCloudViewer/types";
import { AuthorsObjectInterface, XYZInterface } from "@/types";

export default Vue.extend({
  name: "ViewerInspectorTabItemsDetailsPoint",
  components: {
    Author,
  },
  methods: {
    stringifyInspector(coordinates?: boolean): string {
      const {
        params: { projectId, viewerId },
      } = this.$route;

      // Setup initial inspector object and include project ID since this is not part of the inspector object but might be useful for debugging purposes.
      let inspector: Partial<PointInspectorInterface> & {
        projectId: string;
      } = {
        projectId,
        viewerId,
        ...(coordinates
          ? {
              id: this.inspector.id,
              position: this.inspector.points,
            }
          : {
              ...this.inspector,
            }),
      };

      // Object in points are of `Measure` type and cause issues when stringifying. Set object to optional and delete it if it exists.
      if ("object" in inspector) {
        delete (inspector as { object?: unknown }).object;
      }

      return JSON.stringify(inspector);
    },
    /**
     * Copy the stringified object to clipboard.
     *
     * @returns {void}
     */
    copyInfo(): void {
      // Send the stringified inspector object to the clipboard.
      this.$store.dispatch("Utilities/copyToClipboard", {
        string: this.stringifyInspector(),
        reference: "Inspector item",
      });
    },

    async downloadData(coordinates?: boolean) {
      const data = this.stringifyInspector(coordinates);
      const fileType = "text/x-json";

      const {
        inspector: { id, title, type },
      } = this;

      this.$store.dispatch("Storage/processDownloadItem", {
        data,
        name: coordinates
          ? `coordinates-${title}-${type}-${id}.json`
          : `${title}-${type}-${id}.json`,
        type: fileType,
        action: "download",
      });
    },

    downloadCoordinates() {
      this.downloadData(true);
    },
  },
  computed: {
    inspector(): PointInspectorInterface {
      return this.$store.getters["PointCloudViewer/getCurrentInspector"];
    },

    getInspectorName: {
      get(): string {
        return (this.inspector as PointInspectorInterface).title;
      },
      set(value: string) {
        const measureInspector = this.inspector as PointInspectorInterface;
        measureInspector.title = value;

        this.$store.commit("PointCloudViewer/setMeasureInspector", measureInspector);
      },
    },

    pointInspectorTabDetailsPoint(): Record<string, XYZInterface>[] {
      return (
        (this.inspector as PointInspectorInterface).object.points?.filter(
          (point: any) => point.position
        ) ?? []
      );
    },
    pointInspectorTabDetailsPointsVModel: {
      get(): number[] {
        return this.pointInspectorTabDetailsPoint?.map((_, index) => index) ?? [];
      },
      set(value: number[]) {
        return value;
      },
    },

    /**
     * Create the authors object which holds both auther and optional update author data.
     *
     * @returns {Promise<AuthorsObjectInterface>}
     */
    authorsObject(): Promise<AuthorsObjectInterface> {
      return this.$store.dispatch("User/getAuthorsObject", this.inspector);
    },
  },
});
