import { Object3D, Vector3 } from "three";
import { MutationTree } from "vuex";
import { IfcMesh } from "web-ifc-three/IFC/BaseDefinitions";

import { ModelTransformKeys, ModelTransformXYZKeys } from "@/types/model";

import { ModelsState } from "../models/types";
import { SidebarsState } from "../sidebars/types";
import { State } from "../types";
import { TransformState } from "./types";

const transformMutations: MutationTree<SidebarsState & TransformState & ModelsState & State> = {
  setRightSideBarTransform(state, sidebarState) {
    state.rightSidebar = sidebarState;
    state.rightSideBarTransform = sidebarState;
  },
  setTransformSelection(state, payload) {
    const { building } = payload;
    state.transformSelection = building;
  },
  setTransformSelectionPivot(state, payload) {
    if (state.transformSelection) {
      state.transformSelectionPivot = payload;
      state.transformTool.position = (state.transformTool.position as Vector3).add(payload);
    }
  },
  setOrbitControlDrag(state, payload) {
    if (state && state.viewer && state.viewer.controls) {
      state.viewer.controls.dragEnabled = payload;
    }
  },
  handleTransformControlsPointerDown(state, payload) {
    payload.controls._onPointerDown(payload.event);
  },
  handleTransformControlsPointerUp(state, payload) {
    // state.viewer.controls.dragEnabled = true
    payload.controls._onPointerUp(payload.event);
  },
  handleTransformControlsPointerMove(state, payload) {
    const { controls, planeIntersect, mode, space, axis, object, event } = payload;
    controls.pointerMove({
      pointer: controls._getPointer(event),
      planeIntersect,
      mode,
      space,
      axis,
      object,
    });
  },
  removeInternalTransformEvents(state, payload) {
    payload.controls.dispose();
  },
  setViewerTransformTool(state, payload) {
    state.transformTool = payload;
  },
  detachObjectFromTransformControls(state, payload) {
    const { controls } = payload;
    controls.detach();
  },
  /**
   * Sets orbitcontrols to pan only
   * Modified/added to OrbitControls in potree-build around line:81725
   * Search for setPanOnly-method & state this.panOnly in potree.js to find all changes.
   * @param state
   * @param payload boolean
   */
  setOrbitControlPan(state, payload) {
    state?.viewer?.controls.setPanOnly(payload);
  },
  removeTransformFromScene(state) {
    if (state && state.scene) {
      state.scene.children = state.scene.children.filter(
        (child: Object3D) => child.name != "TransformControls"
      );
    }
  },
  /**
   *
   * @param state
   * @param payload type & axis
   *  type refers to position,scale or rotation.
   *  axis refers to x,y,z
   */
  setSubsetTransformValues(
    state,
    payload: { axis: ModelTransformXYZKeys; type: ModelTransformKeys }
  ) {
    const { axis, type } = payload;

    if (state.transformSelection) {
      const modelIdFromSelection = Number(state.transformSelection?.name);
      // find scene children matching ifcmodelId,
      if (state.scene && state.scene.children.length > 0) {
        const childMeshList = state.scene.children.filter((child) => {
          child === undefined &&
            (child as Object3D).type !== "Mesh" &&
            (child as IfcMesh).modelID === undefined &&
            (child as IfcMesh).modelID === modelIdFromSelection;
        });

        const ifcMeshList = childMeshList as IfcMesh[];
        ifcMeshList?.map((child: IfcMesh) => {
          if (state.transformSelection) {
            child[type][axis] = state.transformSelection[type][axis];
          }
        });
      }
    }
  },
};

export default transformMutations;
