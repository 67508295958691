
import Vue from "vue";

import RoleChip from "@/components/role-chip.vue";

export default Vue.extend({
  name: "PointCloudTitlebar",
  components: {
    RoleChip,
  },
  methods: {
    reroute() {
      // Reset viewer container state.
      this.$store.commit("PointCloudViewer/resetState");

      // Push router back to viewers.
      this.$store.dispatch("Utilities/reroute", "viewers");
    },
  },
  computed: {
    userRole() {
      const projectId = this.$route.params.projectId;
      const project = this.$store.getters["Projects/getProject"](projectId);
      let projectUserRole = undefined;

      if (!project) {
        this.$store.dispatch("Projects/fetchProject", projectId);
      } else {
        projectUserRole = project.role;
      }

      return projectUserRole;
    },
  },
});
