import { State } from "./types";

const getDefaultState = (): State => {
  return {
    ifcManager: undefined,
    ifcLoader: undefined,
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
