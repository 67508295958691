import { GetterTree } from "vuex";

import { ViewerInterface } from "@/types";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getProjectId(state) {
    return state.projectId;
  },

  getViewerId(state) {
    return state.viewerId;
  },

  getUploadNameDialogState(state) {
    return state.uploadNameDialog;
  },

  getSelectDialogState(state) {
    return state.selectDialog;
  },

  getUploadSelectDone(state) {
    return state.uploadSelectDone;
  },

  getUploadItem(state) {
    return state.uploadItem;
  },

  getPotreeConverterDialogState(state) {
    return state.potreeConverterDialog;
  },

  getPointCloudUploadDialogItemData(state) {
    return state.pointCloudUploadDialogItemData;
  },

  getPushItem(state) {
    return state.pushItem;
  },

  getFetchedState: (state) => (projectId: string) => {
    return state.fetched.includes(projectId);
  },

  getIsPointcloudContainer: (state) => {
    return state.isPointcloudContainer;
  },

  getLoadingState: (state) => {
    return state.loading;
  },

  getUploadingState: (state) => {
    return state.uploading;
  },

  getViewers: (state) => (projectId: string) => {
    let viewers: ViewerInterface[] = [];

    const projectViewers = state.projectViewers.find((project) => project.id === projectId);
    if (projectViewers) {
      viewers = projectViewers.viewers;
    }

    return viewers;
  },

  getViewer: (state) => (payload: Record<string, string>) => {
    const { projectId, viewerId } = payload;
    const projectViewers = state.projectViewers.find((project) => project.id === projectId);

    if (typeof projectViewers === "undefined" || projectViewers.viewers.length < 1) {
      return;
    }

    const viewers = projectViewers.viewers;
    state.viewer = viewers.find((viewer) => viewer.id === viewerId);

    return state.viewer;
  },

  getDeleteDialog(state) {
    return state.deleteDialog;
  },
};

export default getters;
