
import Vue from "vue";

import { getS3Object } from "@/utilities/files";

export default Vue.extend({
  name: "ProjectCoverImagePlaceholder",
  props: {
    objectReference: {
      type: String,
      required: true,
    },
    defaultSource: {
      type: String,
    },
    height: {
      type: [Number, String],
    },
    maxHeight: {
      type: [Number, String],
    },
    width: {
      type: [Number, String],
    },
    maxWidth: {
      type: [Number, String],
    },
    contain: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
    },
  },
  data: () => ({
    source: "",
  }),
  async created() {
    this.source = await this.getSource();
  },
  methods: {
    async getSource() {
      // find default image
      const defaultImage = require.context("@/assets/images/", false, /\.png$/);
      let image = defaultImage(`./${this.defaultSource}`);

      if (this.objectReference && this.objectReference !== "") {
        const imageResponse = await getS3Object(this.objectReference);

        if (imageResponse) {
          image = imageResponse;
        }
      }

      return image;
    },
  },
});
