import { ActionTree, Commit, Dispatch } from "vuex";
import { IFCManager } from "web-ifc-three/IFC/components/IFCManager";

import router from "@/router";
import {
  HTTPResponseCodes,
  IfcItemInterface,
  IFCModelItemInspectorInterface,
  IFCModelItemInterface,
  TagItemInterface,
  TagItemsInterface,
  TagUndo,
  ViewerInspectorMode,
  ViewerLayerDataCategoryKey,
  ViewerObjectTypeCategory,
} from "@/types";
import { SnackbarColors } from "@/types/vuetify";
import { sortArray } from "@/utilities";
import { coreApiPost } from "@/utilities/core-api";

import {
  ModelInspectorInterface,
  PointcloudInspectorInterface,
  PointInspectorInterface,
  ViewerLayerDataInterface,
  ViewerLayerDataMeasurementsInterface,
  ViewerLayerDataModelsInterface,
  ViewerLayerDataPointcloudsInterface,
  ViewerLayerDataPointOfInterestInterface,
} from "../PointCloudViewer/types";
import { State } from "./types";

// The root of the API endpoint for `Tags`.
const apiRoot = "/tag";

const actions: ActionTree<State, unknown> = {
  /**
   * Reusable snackbar dispatcher.
   *
   * @param {{ commit: Commit; }} { commit }
   * @param {{ message: string; color: SnackbarColors; timeout?: number }} payload The snackbar message, color and optional timeout.
   * @returns {void}
   */
  showSnackBar(
    { commit }: { commit: Commit },
    payload: { message: string; color: SnackbarColors; timeout?: number }
  ): void {
    const { color, message, timeout } = payload;

    commit(
      "Utilities/showSnackbar",
      {
        message,
        color,
        ...(timeout && { timeout }),
      },
      { root: true }
    );
  },

  /**
   * Reusable store tags updater and sorter. Used after creating or deleting a tag.
   *
   * @param {{ commit: Commit; state: State }} { commit, state }
   * @returns {void}
   */
  updateSortTags({ commit, state }: { commit: Commit; state: State }): void {
    // Update the tags list count.
    commit("setTagListCount", state.tags.length);
    // Update the tag list filter count.
    commit("setTagListFilterCount", state.tags.length);
    // Sort the updated tag list.
    sortArray(state.tags, "title");
  },

  /**
   * Reusable error handler for tag actions. Displays a snackbar with the error message.
   *
   * @param {{ dispatch: Dispatch }} { dispatch }
   * @param {{ error: any; action: string; title: string }} payload The error object, action and title.
   * @returns {void}
   */
  errorHandler(
    { dispatch }: { dispatch: Dispatch },
    payload: { error: any; action: string; title?: string }
  ): void {
    const { error, action, title } = payload;
    let errorMessage = `Could not ${action} ${title ? `tag <strong>${title}</strong>` : "tags"}:`;

    // Handle errors.
    if (typeof error === "object") {
      // Error is an object.
      const errorObject: Record<string, any> = error.response;

      // Switch cases for `HTTPResponseCodes` with default.
      switch (errorObject.status) {
        case HTTPResponseCodes.CONFLICT:
        case HTTPResponseCodes.NOT_FOUND:
        case HTTPResponseCodes.BAD_REQUEST:
          errorMessage = errorObject.data?.toString() ?? `${errorMessage} unknown error.`;
          break;

        default:
          // Error `status` is unknown, return `data`.
          errorMessage = `${errorMessage} ${error.data}`;
          break;
      }
    } else {
      // Error is unknown, convert it to a string.
      errorMessage = `${errorMessage} ${error.toString()}`;
    }

    dispatch("showSnackBar", {
      message: errorMessage,
      color: SnackbarColors.ERROR,
    });
  },

  /**
   * Fetches all tags from the server.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch }} { commit, dispatch }
   * @returns {Promise<void>}
   */
  async fetchTags({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): Promise<void> {
    commit("setLoadingState", true);

    try {
      // Try to fetch tags from the server.
      const responseData: TagItemInterface[] = await coreApiPost(`${apiRoot}/get-tags`);
      const tagListCount = responseData.length;
      commit("setTags", responseData);
      commit("setFetchedState", true);
      commit("setTagListCount", tagListCount);
      commit("setTagListFilterCount", tagListCount);
    } catch (error: any) {
      // Show error message.
      dispatch("errorHandler", {
        error,
        action: "fetch",
      });
    }

    commit("setLoadingState", false);
  },

  /**
   * Fetches a single tag from the server if it can't be found in store.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any }} { commit, dispatch, getters }
   * @param {string} id - The ID of the tag to fetch.
   * @returns {Promise<TagItemInterface>}
   */
  async fetchTag(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: {
      tableItemId: string;
      id: string;
      title: string;
    }
  ): Promise<TagItemInterface> {
    const { tableItemId, id, title } = payload;
    commit("setLoadingState", true);

    /**
     * Tests if a tag exists in the store. If it does there is no need to fetch it from the server.
     *
     * @returns {(number | TagItemInterface)}
     */
    const testTag = async (): Promise<number | TagItemInterface> => {
      const tagIndex: number = await dispatch("Utilities/getTagIndex", id, { root: true });
      const tag: TagItemInterface | undefined = await dispatch(
        "Utilities/getTagByIndex",
        tagIndex,
        {
          root: true,
        }
      );

      // Get the `createdAt` property of the tag, which will be undefined if the tag is not found, hence we continue to fetch the tag from the server after this function finishes. If the tag is not found, return the `tagIndex` of the tag, we will send it in the payload to get it.
      return typeof tag?.createdAt === "undefined" ? tagIndex : tag;
    };

    // Set initial return data to the result of `testTag`.
    let returnData = await testTag();

    // If the tag is not found in the store, try to fetch it from the server. Otherwise return the tag from the store in `returnData`.
    if (typeof returnData === "number") {
      try {
        // Try to fetch a single tag from the server.
        const responseData: TagItemInterface = await coreApiPost(`${apiRoot}/get-tag`, {
          tableItemId,
          id,
        });

        // Update the tag in the store.
        commit("updateTags", responseData);

        // Set the return data to the tag that was fetched from the server.
        returnData = responseData;
      } catch (error: any) {
        // Show error message.
        dispatch("errorHandler", {
          error,
          action: "get",
          title,
        });
      }
    }

    commit("setLoadingState", false);
    // Return the tag.
    return <TagItemInterface>returnData;
  },

  /**
   * Creates a new tag on the server.
   *
   * @async
   * @param {{ commit: any; dispatch: Dispatch; }} { commit, dispatch }
   * @param {{ title: string; description?: string; }} payload The optional title and description of the tag.
   * @returns {Promise<boolean>}
   */
  async createTag(
    { commit, dispatch }: { dispatch: Dispatch; commit: any },
    payload: {
      title: string;
      description?: string;
      returnResponse?: boolean;
    }
  ): Promise<boolean> {
    const { title, returnResponse = false } = payload;

    commit("setLoadingState", true);
    // Set initial success state to undefined. This will be updated to true or false depending on the outcome of the request.
    let success = undefined;
    let responseData = undefined;

    try {
      // Try to create the tag on the server.
      responseData = await coreApiPost(`${apiRoot}/create-tag`, payload);

      if (!returnResponse) {
        // Add the new tag to the store.
        commit("setNewTag", responseData);
        dispatch("updateSortTags");
      }

      // Show success message.
      dispatch("showSnackBar", {
        message: `Tag <strong>${payload.title}</strong> created.`,
        color: SnackbarColors.SUCCESS,
      });

      // All good, update success state to true.
      success = true;
    } catch (error: any) {
      // Show error message.
      dispatch("errorHandler", {
        error,
        action: "create",
        title,
      });
      success = false;
    }

    commit("setLoadingState", false);
    // Return success state.
    return returnResponse ? responseData : success;
  },

  /**
   * Updates a tag on the server.
   *
   * @async
   * @param {{ commit: any; dispatch: any }} { commit, dispatch }
   * @param {{ id: string; title?: string; description?: string; }} payload The ID of the tag to update, and optional new title and description.
   * @returns {Promise<boolean>}
   */
  async updateTag(
    { commit, dispatch, state }: { commit: any; dispatch: any; state: State },
    tag: TagItemInterface
  ): Promise<boolean> {
    const { tableItemId, id, title, description } = tag;
    let success = undefined;

    // Get tag from store for comparison.
    const storeTag = state.tags.find((tag) => tag.id === id);
    const { title: storeTitle, description: storeDescription } = storeTag || {};

    // Check if the title or description has changed.
    const titleChange = title !== storeTitle;
    const descriptionChange = description !== storeDescription;

    try {
      // Try to update the tag on the server with update payload and make sure that undefined values are excluded. No need to save undefined values in database.
      const responseData = await coreApiPost(`${apiRoot}/update-tag`, {
        tableItemId,
        id,
        ...(titleChange && { title: title.toLocaleLowerCase().trim() }),
        ...((description ?? descriptionChange) && { description: description?.trim() }),
      });

      // If description is undefined or empty, delete it from `storeTag` since we will use that to update the tag in the store.
      if (typeof description === "undefined" || description === "") {
        delete storeTag?.description;
      }

      // Build the updated tag object. Spread the existing `storeTag` overwriting or appending any new values from `responseData`.
      const updateadTag = {
        ...storeTag,
        ...responseData,
      };

      // Update the tag in the store using the `updatedTag`.
      commit("updateTags", updateadTag);

      dispatch("showSnackBar", {
        message: `Tag <strong>${title ?? storeTitle}</strong> updated.`,
        color: SnackbarColors.SUCCESS,
      });

      success = true;
    } catch (error: any) {
      // Show error message.
      dispatch("errorHandler", {
        error,
        action: "update",
        title,
      });
      success = false;
    }

    // Return success state.
    return success;
  },

  /**
   * Deletes a tag from the server.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch }} { commit, dispatch }
   * @param {{ id: string; }} payload The ID of the tag to delete.
   * @returns {Promise<boolean>}
   */
  async deleteTag(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: {
      tableItemId: string;
      id: string;
      title: string;
    }
  ): Promise<boolean> {
    commit("setLoadingState", true);
    const { tableItemId, id, title } = payload;

    let success = undefined;

    try {
      await coreApiPost(`${apiRoot}/delete-tag`, {
        tableItemId,
        id,
      });

      // Remove the tag from the store.
      commit("deleteTag", id);
      dispatch("updateSortTags");

      dispatch("showSnackBar", {
        message: `Tag <strong>${title}</strong> deleted.`,
        color: SnackbarColors.SUCCESS,
      });

      success = true;
    } catch (error: any) {
      dispatch("errorHandler", {
        error,
        action: "delete",
        title,
      });
      success = false;
    }

    commit("setLoadingState", false);
    // Return success state.
    return success;
  },

  /**
   * Associates an inspector (e.g. layer- or IFC-item) with a selected tag in a viewer.
   *
   * Takes in the current inspector and a list of tags where the last item is the tag that was just added.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any; }} { commit, dispatch, getters, rootGetters}
   * @param {{ inspector: PointcloudInspectorInterface & ModelInspectorInterface & PointInspectorInterface & IFCModelItemInspectorInterface; tags: Partial<TagItemInterface>[] }} payload
   * @returns {Promise<void>}
   */
  async addViewerLayerItemTag(
    {
      commit,
      dispatch,
      rootGetters,
    }: {
      commit: Commit;
      dispatch: Dispatch;
      getters: any;
      rootGetters: any;
    },
    payload: {
      inspector: PointcloudInspectorInterface &
        ModelInspectorInterface &
        PointInspectorInterface &
        IFCModelItemInspectorInterface;
      tag: Partial<TagItemInterface>;
    }
  ): Promise<void> {
    const {
      currentRoute: {
        params: { projectId, viewerId: currentViewerId },
      },
    } = router;

    const { inspector, tag } = payload;

    const { id: inspectorId, type, globalId, expressId } = inspector;

    let id = inspectorId;

    // Tag title is used to find index of tag in viewer tags.
    const { title, id: tagId } = tag;

    // Add tag to viewer tags. Mutation makes sure tag is only added once.
    commit("addViewerLayerItemTag", tag);

    let item: Partial<TagItemsInterface> = {};
    let viewerId;
    let modelContainerId;
    let pointcloudId;

    switch (type) {
      case ViewerInspectorMode.POINT_OF_INTEREST:
      case ViewerInspectorMode.MEASUREMENT_DISTANCE:
      case ViewerInspectorMode.MEASUREMENT_HEIGHT:
        viewerId = currentViewerId;
        item = {
          id,
          type,
        };
        break;

      case ViewerInspectorMode.POINTCLOUD:
        pointcloudId = id;
        item = {
          id,
          type,
        };
        break;

      case ViewerInspectorMode.MODEL:
        modelContainerId = id;
        item = {
          id,
          type,
        };
        break;

      case ViewerInspectorMode.IFC_MODEL_ITEM:
        ({ modelContainerId } = rootGetters["PointCloudViewer/getIfcCurrentModel"]);
        id = modelContainerId;
        item = {
          id: globalId,
          type,
        };
        break;
    }

    const requiredVariables = [
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!tagId,
        message: "tag ID",
      },
      {
        test: !!id,
        message: "inspector ID",
      },
      {
        test: !!item,
        message: "item",
      },
      ...(type === ViewerInspectorMode.POINT_OF_INTEREST ||
      type === ViewerInspectorMode.MEASUREMENT_DISTANCE ||
      type === ViewerInspectorMode.MEASUREMENT_HEIGHT
        ? [
            {
              test: !!viewerId,
              message: "viewer ID",
            },
          ]
        : []),
      ...(type === ViewerInspectorMode.POINTCLOUD
        ? [
            {
              test: !!pointcloudId,
              message: "pointcloud ID",
            },
          ]
        : []),
      ...(type === ViewerInspectorMode.MODEL || type === ViewerInspectorMode.IFC_MODEL_ITEM
        ? [
            {
              test: !!modelContainerId,
              message: "model container ID",
            },
          ]
        : []),
      ...(type === ViewerInspectorMode.IFC_MODEL_ITEM
        ? [
            {
              test: !!globalId,
              message: "IFC model item global ID",
            },
          ]
        : []),
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    // Set loading state so user gets some feedback.
    commit("PointCloudViewer/setLoadingState", true, { root: true });

    try {
      // Update the tag on the server.
      const { tableItemId } = await coreApiPost(`/viewer/add-tag`, {
        // tableItemId,
        item,
        modelContainerId,
        pointcloudId,
        projectId,
        tagId,
        viewerId,
      });

      commit("addViewerTagItem", {
        title,
        item: {
          ...item,
          ...(type === ViewerInspectorMode.IFC_MODEL_ITEM && {
            modelContainerId,
            expressId,
          }),
          tableItemId,
        },
      });

      dispatch("toggleTagLayerItemVisibility", {
        id,
        type,
        visible: true,
      });

      commit(
        "Utilities/showSnackbar",
        {
          message: `${title} added to ${type}.`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error) {
      // Show error message.
      commit(
        "Utilities/showSnackbar",
        {
          message: `There was an error adding ${title} to ${type}.`,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    // We are done here.
    commit("PointCloudViewer/setLoadingState", false, { root: true });
  },

  /**
   * Removes an inspector (e.g. layer- or IFC-item) from a selected tag in a viewer.
   *
   * @param {{ commit: Commit; rootGetters: any; }} { commit, rootGetters }
   * @param {{ item: Partial<TagItemInterface>; inspector: PointcloudInspectorInterface & ModelInspectorInterface & PointInspectorInterface & IFCModelItemInspectorInterface; inspectorTags: Partial<TagItemInterface>[]; globalTags: TagItemInterface[]; }} payload
   * @returns {void}
   */
  async removeViewerLayerItemTag(
    {
      commit,
      dispatch,
      rootGetters,
    }: {
      commit: Commit;
      dispatch: Dispatch;
      state: State;
      rootGetters: any;
    },
    payload: {
      globalTags: TagItemInterface[];
      inspector: PointcloudInspectorInterface &
        ModelInspectorInterface &
        PointInspectorInterface &
        IFCModelItemInspectorInterface;
      inspectorTags: Partial<TagItemInterface>[];
      tableItemId: string;
      tag: Partial<TagItemInterface>;
    }
  ): Promise<void> {
    const {
      currentRoute: {
        params: { projectId, viewerId: currentViewerId },
      },
    } = router;

    const {
      globalTags,
      inspector: { id: inspectorId, globalId, type },
      inspectorTags,
      tableItemId,
      tag,
    } = payload;

    let id = inspectorId;

    const { id: tagId, title } = tag;

    const index = inspectorTags.findIndex((tag) => tag.id === id);

    if (index !== -1) {
      inspectorTags.splice(index, 1);
    }

    let viewerId;
    let modelContainerId;
    let pointcloudId;

    switch (type) {
      case ViewerInspectorMode.POINT_OF_INTEREST:
      case ViewerInspectorMode.MEASUREMENT_DISTANCE:
      case ViewerInspectorMode.MEASUREMENT_HEIGHT:
        viewerId = currentViewerId;
        break;

      case ViewerInspectorMode.POINTCLOUD:
        pointcloudId = id;
        break;

      case ViewerInspectorMode.MODEL:
        modelContainerId = id;
        break;

      case ViewerInspectorMode.IFC_MODEL_ITEM:
        ({ modelContainerId } = rootGetters["PointCloudViewer/getIfcCurrentModel"]);
        id = globalId;
        break;
    }

    const requiredVariables = [
      {
        test: !!projectId,
        message: "project ID",
      },
      {
        test: !!tagId,
        message: "tag ID",
      },
      {
        test: !!id,
        message: "inspector ID",
      },
      {
        test: !!tableItemId,
        message: "table item ID",
      },
      ...(type === ViewerInspectorMode.POINT_OF_INTEREST ||
      type === ViewerInspectorMode.MEASUREMENT_DISTANCE ||
      type === ViewerInspectorMode.MEASUREMENT_HEIGHT
        ? [
            {
              test: !!viewerId,
              message: "viewer ID",
            },
          ]
        : []),
      ...(type === ViewerInspectorMode.POINTCLOUD
        ? [
            {
              test: !!pointcloudId,
              message: "pointcloud ID",
            },
          ]
        : []),
      ...(type === ViewerInspectorMode.MODEL || type === ViewerInspectorMode.IFC_MODEL_ITEM
        ? [
            {
              test: !!modelContainerId,
              message: "model container ID",
            },
          ]
        : []),
    ];

    // Can't continue without required data.
    const hasRequired = await dispatch("Utilities/requiredVariableCheck", requiredVariables, {
      root: true,
    });

    // If there are missing variables, stop here. The snackbar will be shown in the requiredVariableCheck action, with details on what variables are missing.
    if (!hasRequired) {
      return;
    }

    // Set loading state so user gets some feedback.
    commit("PointCloudViewer/setLoadingState", true, { root: true });

    try {
      commit("removeViewerTagItem", {
        globalTags,
        itemId: id,
        tagId,
      });

      if (type !== ViewerInspectorMode.IFC_MODEL_ITEM) {
        dispatch("toggleTagLayerItemVisibility", {
          id,
          type,
          visible: false,
        });
      }

      // Update tag on the server.
      await coreApiPost(`/viewer/remove-tag`, {
        itemId: id,
        modelContainerId,
        pointcloudId,
        projectId,
        tableItemId,
        tagId,
        type,
        viewerId,
      });

      commit(
        "Utilities/showSnackbar",
        {
          message: `${title} removed from ${type}.`,
          color: SnackbarColors.SUCCESS,
        },
        { root: true }
      );
    } catch (error: any) {
      // ! Look into maybe reverting the changes if the request fails?
      // // Add tag to viewer tags. Mutation makes sure tag is only added once.
      // commit("addViewerLayerItemTag", item);

      // // Since the previous step adds the tag to the viewer tags, we can now get the index of the tag in the viewer tags because it is guaranteed to exist.
      // const viewerTags: TagItemInterface[] = getters["getViewerTags"] ?? [];
      // const viewerTagIndex = viewerTags.findIndex((item) => item.title === title);
      // commit("addViewerTagItem", {
      //   tagIndex: viewerTagIndex,
      //   item,
      // });

      // Show error message.
      commit(
        "Utilities/showSnackbar",
        {
          message: `There was an error removing ${title} from ${type}.`,
          color: SnackbarColors.ERROR,
        },
        { root: true }
      );
    }

    // We are done here.
    commit("PointCloudViewer/setLoadingState", false, { root: true });
  },

  /**
   * Toggle the layer item visibility when a tag is added or removed from an item.
   *
   * First checks if the item is assigned to other tags and if the item is being set to `visible = true | false`. Depending on the result, the item is either hidden or stays visible.
   *
   * @async
   * @param {{ dispatch: any; getters: any; }} { dispatch, getters }
   * @param {{ type: ViewerInspectorMode; id: string; visible: boolean }} payload
   * @returns {Promise<void>}
   */
  async toggleTagLayerItemVisibility(
    { dispatch, getters }: { dispatch: any; getters: any },
    payload: { type: ViewerInspectorMode; id: string; visible: boolean }
  ): Promise<void> {
    const { id, type, visible } = payload;

    const viewerTags: TagItemInterface[] = getters["getViewerTags"];
    const selectedViewerTags: TagItemInterface[] = getters["getSelectedViewerTags"];
    const hiddenTagItems: TagItemInterface[] = getters["getHiddenTagItems"];

    const isTagAssigned = viewerTags.some((tag) => tag.items?.some((item) => item.id === id));

    const isTagSelected = selectedViewerTags.some((tag) =>
      tag.items?.some((item) => item.id === id)
    );

    const isTagHidden = hiddenTagItems.some((tag) => tag.items?.some((item) => item.id === id));

    // If the item is assigned to other tags we need to determine if the item should be hidden or not.
    if ((isTagAssigned && isTagSelected && !visible) || (isTagHidden && visible)) {
      return;
    }

    let category;

    switch (type) {
      case ViewerInspectorMode.POINTCLOUD:
        category = ViewerLayerDataCategoryKey.POINTCLOUDS;
        break;

      case ViewerInspectorMode.MODEL:
        category = ViewerLayerDataCategoryKey.MODELS;
        break;

      case ViewerInspectorMode.POINT_OF_INTEREST:
        category = ViewerLayerDataCategoryKey.POINT_OF_INTEREST;
        break;

      case ViewerInspectorMode.MEASUREMENT_DISTANCE:
      case ViewerInspectorMode.MEASUREMENT_HEIGHT:
        category = ViewerLayerDataCategoryKey.MEASUREMENTS;
        break;
    }

    if (!category) {
      dispatch("showSnackBar", {
        message: "Category to reset layer item is undefined.",
        color: SnackbarColors.ERROR,
      });
      return;
    }

    const layerItem = await dispatch(
      "PointCloudViewer/getLayerItemData",
      {
        id,
        category,
      },
      {
        root: true,
      }
    );

    if (!layerItem) {
      dispatch("showSnackBar", {
        message: "No layer item found.",
        color: SnackbarColors.ERROR,
      });
      return;
    }

    dispatch(
      "PointCloudViewer/toggleLayerItemVisibility",
      {
        item: layerItem,
        type: category,
        visible,
      },
      {
        root: true,
      }
    );
  },
  // This function is used to dispatch the toggleLayerItemVisibility action on the provided item.
  async tagHandleLayerItem(
    { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any },
    {
      item,
      type,
      visible,
      isSelected,
    }: {
      item: TagItemsInterface;
      type: string;
      visible: boolean;
      isSelected?: boolean;
    }
  ): Promise<void> {
    const tagsUndo = getters["getTagsUndo"];

    let newVisible = visible;
    // check for undo-tag matching item
    tagsUndo.forEach((tagUndo: TagUndo) => {
      // in model
      if (tagUndo.modelContainerId === item.id && tagUndo.visible !== undefined && isSelected) {
        newVisible = tagUndo.visible;
        // remove this undo-tag as it does not apply anymore
        commit("removeTagUndo", { tagId: tagUndo.tagId });
      }
    });

    await dispatch(
      "PointCloudViewer/toggleLayerItemVisibility",
      {
        item,
        type,
        visible: newVisible,
      },
      { root: true }
    );
  },

  /**
   * Filters the viewer data by a tag and updates the visibility of the viewer items.
   *
   * @async
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; }} { commit, dispatch, getters }
   * @param {{ tagData: TagItemInterface, isSelected: boolean }} payload
   * @returns {Promise<void>}
   */
  async tagFilter(
    {
      commit,
      dispatch,
      getters,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any },
    payload: { tagData: TagItemInterface; isSelected: boolean }
  ): Promise<void> {
    const { tagData, isSelected } = payload;

    const tagsUndo = getters["getTagsUndo"];

    const removed = tagData;

    // Update the selected tags.
    if (isSelected) {
      commit("removeSelectedViewerTag", tagData);
    } else {
      commit("pushSelectedViewerTag", tagData);
    }

    // Collect the selected layer-items to a single array of unique layer-items. (tagReduceSelectedItems)
    // Collect ifcModelItems in a separate object, where key is modelContainerId and value is array of ifcModelItem express-ids. (tagIfcModelItems)
    const {
      tagReduceSelectedItems,
      tagIfcModelItems,
    }: { tagReduceSelectedItems: TagItemsInterface[]; tagIfcModelItems: IfcItemInterface } =
      await dispatch("tagReduceSelectedItems", { tagData });

    // Get the layers that should be visible.
    const tagReduceLayers: TagItemsInterface[] = await dispatch("tagReduceLayers", {
      selectedItems: tagReduceSelectedItems,
      tagData,
    });

    // Get the hidden tags and check if any items are selected.
    const hidden: string[] = getters["getHiddenTagItems"];
    const selectedItems = tagReduceSelectedItems.length > 0;
    const selectedIfcItems = Object.keys(tagIfcModelItems).length > 0;

    // Update the visibility of the viewer items that has a place in layers.
    // Note that ifcModelItems are not included in the layers, so they are handled separately below.
    tagReduceLayers.forEach((item) => {
      const { type, id } = item;

      // check with viewerHiddenTagItems-state. If item is in it, mark it as to be hidden.
      const shouldBeMadeHidden = hidden.includes(id);

      const isNoTagsActive = !selectedItems && !selectedIfcItems;
      const isAnyTagsActive = selectedIfcItems || selectedItems;

      const isModel =
        type === ViewerInspectorMode.MODEL ||
        (type as ViewerLayerDataCategoryKey & "ifc") === "ifc";

      const removedContainerId = removed.id.split("#")[0];
      // Have we deselected an autoTag
      const isDeselectedAutoTag = isSelected && removed.isAuto;
      // Is nothing on the model of the autoTag now selected, but something is selected in other tags
      const isOtherTagsActive = !tagIfcModelItems[removedContainerId] && isAnyTagsActive;
      // Is the item we iter. now, the model of the deselected autoTag
      const isModelOfDeselectedAutoTag =
        isDeselectedAutoTag && isModel && removedContainerId === id;

      // If item is not in viewerHiddenTagItems-list or nothing is selected, make item visible.
      let visible = !shouldBeMadeHidden || isNoTagsActive;

      // If we have deselected an autoTag completely, and other tags are active,
      // we need to hide the model.
      if (isOtherTagsActive && isModelOfDeselectedAutoTag) {
        visible = false;
      }

      if (isNoTagsActive && isModel) {
        //console.log("deisolating model ", item.id);
        // console.log("visible? ", visible);
        dispatch(
          "PointCloudViewer/ifcToggleIsolateTags",
          {
            modelContainerId: item.id,
          },
          { root: true }
        );
      }

      // model,removedModelContainerId _not_ in this item
      const isNotModelOfRemovedTag = isModel && removedContainerId !== id;

      // We wan't to prevent update on autoTags that where not in the now deselected tag.
      // Otherwise we would update other models & their wireframe will get removed.
      if (
        // model,removedModelContainerId not in this item or deselect,autoTag,no tags active
        isNotModelOfRemovedTag &&
        isDeselectedAutoTag &&
        isAnyTagsActive // if model is auto and is not the same as the one that was clicked on
        // if no tags now are active and the tag that was deselected is auto
      ) {
        return;
      }

      switch (type as ViewerLayerDataCategoryKey & "ifc" & ViewerInspectorMode) {
        case ViewerLayerDataCategoryKey.POINTCLOUDS:
        case ViewerInspectorMode.MODEL:
        case ViewerLayerDataCategoryKey.POINT_OF_INTEREST:
        case ViewerLayerDataCategoryKey.MEASUREMENTS:
          dispatch("tagHandleLayerItem", { item, type, visible });
          break;
        case "ifc":
          dispatch("tagHandleLayerItem", {
            item,
            type: ViewerInspectorMode.MODEL,
            visible,
            isSelected,
            tagData,
          });
          break;

        case ViewerObjectTypeCategory.MEASUREMENT_DISTANCE:
        case ViewerObjectTypeCategory.MEASUREMENT_HEIGHT:
          // console.log("measurementDistance", item, visible);
          dispatch("tagHandleLayerItem", {
            item,
            type: ViewerLayerDataCategoryKey.MEASUREMENTS,
            visible,
          });
          break;

        default:
          console.error("not yet implemented", type, item);
          break;
      }
    });

    if (!isSelected && selectedIfcItems) {
      // handle ifc model items when selected
      Object.keys(tagIfcModelItems).forEach((modelContainerId) => {
        // new isolation
        dispatch(
          "PointCloudViewer/ifcToggleIsolateTags",
          {
            modelContainerId,
            ids: tagIfcModelItems[modelContainerId],
          },
          { root: true }
        );
      });
      return;
    }

    // handle ifc model items when deselected
    // go through all models & if they have isolated items , deisolate them
    if (isSelected) {
      const { isAuto } = removed;
      isAuto &&
        dispatch("tagHandleRemovedAutoTag", {
          tagIfcModelItems,
          removed,
          selectedIfcItems,
          selectedItems,
        });

      // tempCounter keeps track of which removed models we already have handled. This prevents us from de-isolating the same models multiple times.
      // otherwise we would de-isolate on every ifcModelItems we find on that model when going through removed.items.
      const tempCounter: string[] = [];
      removed.items?.forEach((removedItem) => {
        // if removed was an ifcModelItem & the model is visible & has isolated parts & no parts is still selected in other tags, we need to de-isolate the model.
        // the model is visible if it's in tagReduceSelectedItems or if nothing is in tagReduceSelectedItems.
        if (removedItem.type === ViewerInspectorMode.IFC_MODEL_ITEM) {
          const { modelContainerId } = removedItem;
          if (!modelContainerId) {
            return;
          }

          const isVisible =
            tagReduceSelectedItems.length === 0 ||
            tagReduceSelectedItems.some(
              (layerItem) =>
                layerItem.type === ViewerInspectorMode.MODEL && layerItem.id === modelContainerId
            );

          // If there is still ifcItems selected in other tags, so we need to do a new isolation.
          if (tagIfcModelItems[modelContainerId]) {
            // This is so we only isolate once per model.
            const alreadyIsolated = tempCounter.includes(modelContainerId);

            isVisible &&
              !alreadyIsolated &&
              dispatch(
                "PointCloudViewer/ifcToggleIsolateTags",
                {
                  modelContainerId,
                  ids: tagIfcModelItems[modelContainerId],
                },
                { root: true }
              );

            !alreadyIsolated && tempCounter.push(modelContainerId);
          } else {
            // find out if the model is isolated or not
            const ifcModels: IFCModelItemInterface[] = rootGetters["PointCloudViewer/getIFCModels"];
            const model = ifcModels.find(
              (ifcModel) => ifcModel.modelContainerId === modelContainerId
            );
            const isIsolated = model && model.isolated.length > 0;

            // There is no ifcItems active on this model, so we need to de-isolate item
            isIsolated &&
              isVisible &&
              dispatch(
                "PointCloudViewer/ifcToggleIsolateTags",
                {
                  modelContainerId: modelContainerId,
                },
                { root: true }
              );
          }
        }

        // if removed has ifcModelItems in it, check if any of that model ifcItems are still selected.
        // if they are, we need to isolate model with those ifcItems.
        if (
          removedItem.type === ViewerInspectorMode.MODEL &&
          tagIfcModelItems[removedItem.id] &&
          !tempCounter.includes(removedItem.id)
        ) {
          // new isolation on ifcModelItems
          dispatch(
            "PointCloudViewer/ifcToggleIsolateTags",
            {
              modelContainerId: removedItem.id,
              ids: tagIfcModelItems[removedItem.id],
            },
            { root: true }
          );
        }
      });

      // TODO: possible inconcurrency as we remove tagsUndo up in tagHandleLayerItem. That may execute
      // before this and then our model wont be de-isolated
      // If user deselected a tag containing ifcItem & it's model were added to tagsUndo in the process,we now need to desiolate
      // that model and show it with all ifcItems.
      tagsUndo.forEach((tagUndo: TagUndo) => {
        if (tagUndo.modelContainerId && !selectedIfcItems) {
          dispatch(
            "PointCloudViewer/ifcToggleIsolateTags",
            {
              modelContainerId: tagUndo.modelContainerId,
            },
            { root: true }
          );
        }
      });

      return;
    }

    // if no tags are active, we need to show all models & remove any isolation on ifcItems on models.
    // FIXME: Why do we use tagUndo?, Shouldn't we check removed.items and de-isolate any models that are in there?
    // And isn't that done in the if (isSelected) block above?. So this should then be reduntant and can be removed
    if (!selectedIfcItems && !isSelected) {
      // console.log("here we check if we need to de-isolate model as no ifcItems are selected");
      // if tagsUndo contains a model that is in tagReduceSelectedItems,
      // that model was earlier isolated,but now we need to show full model again as it's visible
      tagsUndo.forEach((tagUndo: TagUndo) => {
        if (tagReduceSelectedItems.some((item) => item.id === tagUndo.modelContainerId)) {
          // Model was isolated, but now we need to show full model again as it's visible

          dispatch(
            "PointCloudViewer/ifcToggleIsolateTags",
            {
              modelContainerId: tagUndo.modelContainerId,
            },
            { root: true }
          );
        }
      });

      return;
    }
    if (!selectedIfcItems && isSelected) {
      //console.log("NO IFCITEMS");
      return;
    }
    // console.error("END OF TAGFILTER");
  },
  /**
   * Autotag had it's ids in last tagIfcModelItems. So now when we have deselected it, we need to isolate
   * this model with the tagIfcModelItems that are still selected.
   * Or if no tagIfcModelItems are selected on this model now, we need to de-isolate this model.
   * @param param0
   * @param param1
   */
  tagHandleRemovedAutoTag(
    { dispatch },
    { tagIfcModelItems, removed, selectedIfcItems, selectedItems }
  ) {
    const debug = false;
    // get modelContainerId from removed autoTag
    const modelContainerId = removed.id.split("#")[0];

    if (tagIfcModelItems[modelContainerId]) {
      debug && console.log("new isolation on model: ", modelContainerId);
      // new isolation

      dispatch(
        "PointCloudViewer/ifcToggleIsolateTags",
        {
          modelContainerId,
          ids: tagIfcModelItems[modelContainerId],
        },
        { root: true }
      );
    } else if (!selectedIfcItems || !selectedItems) {
      debug && console.log("deisolating model: ", modelContainerId);
      // no autoTags active on this model, deisolate this modelId
      dispatch(
        "PointCloudViewer/ifcToggleIsolateTags",
        {
          modelContainerId,
        },
        { root: true }
      );
    }
  },

  /**
   * We go through every selected tag and keep unique items in uniqueItemList and we sort out ifcModelItems and put them in ifcModelItems.
   * @param
   * @returns { uniqueItemList:TagItemsInterface[], ifcModelItems:IfcItemInterface }
   */
  tagCollectAndSortUniqueItems(
    { getters },
    { autoTagsIfcModelItems }: { autoTagsIfcModelItems: IfcItemInterface }
  ) {
    // Get the array of selected tags from the store.
    const selectedTags: TagItemInterface[] = getters["getSelectedViewerTags"];
    const ifcItems: IfcItemInterface = autoTagsIfcModelItems;

    // Reduce the selected tags to a single array of unique items.
    const uniqueItemList = selectedTags.reduce((itemsArray: TagItemsInterface[], selectedTag) => {
      selectedTag.items?.forEach((item) => {
        const { type, id, modelContainerId, expressId } = item;
        const existingItem = itemsArray.some((arrayItem) => arrayItem.id === id);

        // If the item(modelContainerId) does not already exist in the array, add it to the key-val-list.
        if (!existingItem) {
          if (type !== ViewerInspectorMode.IFC_MODEL_ITEM) {
            itemsArray.push(item);
          } else {
            if (modelContainerId && expressId && ifcItems[modelContainerId]) {
              // modelContainerId already exists in ifcItems
              if (ifcItems[modelContainerId].includes(expressId) === false) {
                // expressId does not exist in ifcItems[modelContainerId],adding it
                ifcItems[modelContainerId].push(expressId);
              }
            } else {
              // first expressId added to this modelContainerId
              if (modelContainerId && expressId) {
                ifcItems[modelContainerId] = [expressId];
              }
            }
          }
        }
      });

      return itemsArray;
    }, []);

    // Return the array of unique items for the selected tags.
    return { uniqueItemList, ifcModelItems: ifcItems };
  },

  /**
   * Handles logic for ifcModelItems.
   *
   * @param
   * @returns
   */
  async tagHandleIfcModelItems(
    { rootGetters, dispatch, commit },
    {
      tagData,
      uniqueItemList,
      ifcModelItems,
      autoTagsIfcModelItems,
    }: {
      tagData: TagItemInterface;
      uniqueItemList: TagItemsInterface[];
      ifcModelItems: IfcItemInterface;
      autoTagsIfcModelItems: IfcItemInterface;
    }
  ) {
    // temporarily create ifc model items in this set
    // We need this to add the model to uniqueItemList after we have matched ifcItems against
    // uniqueItemList. We need to add the model to tagReduceSelectedItems or the model-layer will not be made visible.
    const temporaryModelArray: TagItemsInterface[] = [];

    Object.keys(ifcModelItems).forEach((modelContainerId) => {
      const modelAlreadySelected = uniqueItemList.find((item) => item.id === modelContainerId);
      const isAutoTag = autoTagsIfcModelItems[modelContainerId] !== undefined;

      if (modelAlreadySelected) {
        // if the model was selected in the current tag, ifcItems on this model are isolated and we therefore need to de-isolate this model.
        const deIsolate =
          tagData &&
          tagData.items?.some(
            (item) => item.type === ViewerInspectorMode.MODEL && item.id === modelContainerId
          );
        if (deIsolate) {
          // model was selected in the current tag.
          // IfcItems on this model are isolated and we therefore need to de-isolate full model
          dispatch(
            "PointCloudViewer/ifcToggleIsolateTags",
            {
              modelContainerId,
            },
            { root: true }
          );
        }
        // remove item from ifcModelItems using modelContainerId as key
        // we always do this if the model is already selected since if full model is to be visible
        // we don't need to both with ifcModelItems.
        delete ifcModelItems[modelContainerId];
      } else {
        temporaryModelArray.push({
          id: modelContainerId,
          type: ViewerInspectorMode.MODEL,
        });

        // find out if the model is visible or not
        const ifcModels: IFCModelItemInterface[] = rootGetters["PointCloudViewer/getIFCModels"];
        const modelId = ifcModels.find(
          (ifcModel) => ifcModel.modelContainerId === modelContainerId
        )?.modelId;
        if (modelId !== undefined && !isAutoTag) {
          const ifcManager: IFCManager = rootGetters["Ifc/getIFCManager"];
          const modelSubset = ifcManager.getSubset(modelId, undefined, "full-model");

          // create undo so we can return the model to it's original state when we remove the tag
          commit("addTagUndo", {
            tagId: tagData.id,
            modelContainerId,
            visible: modelSubset.visible,
          });
        }
      }
    });

    return { temporaryModelArray, filteredIfcModelitems: ifcModelItems };
  },

  /**
   * Adds models we need to be visible for ifcItems to display to uniqueItemList, so they are made visible when we reduce the layers in tagReduceLayers.
   * @param
   * @param
   * @returns
   */
  async tagAddModelsToUniqueItemList(
    { getters },
    {
      temporaryModelArray,
      uniqueItemList,
    }: { temporaryModelArray: TagItemsInterface[]; uniqueItemList: TagItemsInterface[] }
  ) {
    // Add unique models to uniqueItemList. Part 1.
    // extract only uniqueModels from temporaryModelArray
    const uniqueModels = Array.from(
      temporaryModelArray.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
    );

    // Adding found model that had ifcItems but was not in tags selection uniqueModels.
    // Add unique models to uniqueItemList. Part 2.
    // we can push these onto the uniqueItemList because we know no model from uniqueModels can exist on uniqueItemList.
    uniqueModels.forEach((model) => uniqueItemList.push(model));
    return uniqueItemList;
  },
  async tagCollectAutoTagsIfcItems({ getters, dispatch }) {
    const ifcModelItems: IfcItemInterface = {};
    // Get the array of selected tags from the store.
    const selectedTags: TagItemInterface[] = getters["getSelectedViewerTags"];

    // If the tag is an auto-tag, we get ifcModelItems for that storey and add to ifcItems.
    const promises = selectedTags.map(async (selectedTag) => {
      if (selectedTag.isAuto) {
        const modelContainerId = selectedTag.id.split("#")[0];
        const ids = await dispatch(
          "PointCloudViewer/getExpressIdSubsetIds",
          { id: selectedTag.expressId, modelContainerId: modelContainerId },
          { root: true }
        );

        if (ifcModelItems[modelContainerId]) {
          ifcModelItems[modelContainerId] = ifcModelItems[modelContainerId].concat(ids);
        } else {
          ifcModelItems[modelContainerId] = [...ids];
        }
      }
    });
    await Promise.all(promises);

    return ifcModelItems;
  },

  /**
   * Reduces the list of selected tags to a single array of unique items by removing duplicates.
   * NOTE: It has a special handling for IFC model items.
   * If we would add IFC model items to the itemsArray, no models with the same id would be added after the first item was added, as they share model-id.
   *
   * @param {{ getters: any }} { getters }
   * @returns {TagItemsInterface[]} The array of unique items for the selected tags.
   */
  async tagReduceSelectedItems(
    {
      dispatch,
    }: { state: State; commit: Commit; rootGetters: any; getters: any; dispatch: Dispatch },
    { tagData }
  ): Promise<{ tagReduceSelectedItems: TagItemsInterface[]; tagIfcModelItems: IfcItemInterface }> {
    // Go through tags-selection and handle ifcAutoTags
    const autoTagsIfcModelItems: IfcItemInterface = await dispatch("tagCollectAutoTagsIfcItems");

    // See tagCollectAndSortUniqueItems for more info.
    const {
      uniqueItemList,
      ifcModelItems,
    }: { uniqueItemList: TagItemsInterface[]; ifcModelItems: IfcItemInterface } = await dispatch(
      "tagCollectAndSortUniqueItems",
      { autoTagsIfcModelItems }
    );

    // See tagHandleIfcModelItems for more info.
    const {
      temporaryModelArray,
      filteredIfcModelitems,
    }: { temporaryModelArray: TagItemsInterface[]; filteredIfcModelitems: IfcItemInterface } =
      await dispatch("tagHandleIfcModelItems", {
        tagData,
        uniqueItemList,
        ifcModelItems,
        autoTagsIfcModelItems,
      });

    // See tagAddModelsToUniqueItemList for more info.
    const handledUniqueItemList = await dispatch("tagAddModelsToUniqueItemList", {
      temporaryModelArray,
      uniqueItemList,
    });

    // Return the array of unique items for the selected tags.
    return {
      tagReduceSelectedItems: handledUniqueItemList,
      tagIfcModelItems: filteredIfcModelitems,
    };
  },

  /**
   * Reduces a list of viewer layers by hiding any items that are not selected in the given payload for the specified tag.
   *
   * @param {{ commit: Commit; rootGetters: any }} { commit, rootGetters }
   * @param {{ selectedItems: TagItemsInterface[]; tag: TagItemInterface }} payload
   * @returns {TagItemsInterface[]} The array of items that have been hidden.
   */
  tagReduceLayers(
    { commit, rootGetters }: { commit: Commit; rootGetters: any; getters: any },
    payload: { selectedItems: TagItemsInterface[]; tagData: TagItemInterface }
  ): TagItemsInterface[] {
    const { selectedItems } = payload;

    const layers: ViewerLayerDataInterface = rootGetters["PointCloudViewer/getLayerData"];
    const layersArray: (ViewerLayerDataPointcloudsInterface &
      ViewerLayerDataModelsInterface &
      ViewerLayerDataPointOfInterestInterface &
      ViewerLayerDataMeasurementsInterface)[] = Object.values(layers);

    const layerItems = layersArray.reduce((itemsArray: TagItemsInterface[], layer) => {
      const { items } = layer;

      // Iterate over each item in the layer.
      Object.values(items).forEach((item) => {
        const { id } = item;

        // Check if item is in the selected items array.
        const keepItem = selectedItems.find((selectedItem) => selectedItem.id === id);

        if (keepItem) {
          // If the item is in the selected items array, remove it from the hidden tag items array.
          commit("removeHiddenTagItem", id);
        } else {
          // Otherwise, add it to the hidden tag items array.
          commit("pushHiddenTagItem", id);
        }

        // Add the item to the array of items that need to be hidden.
        itemsArray.push(item);
      });

      // Return the array of items that need to be hidden.
      return itemsArray;
    }, []);

    return layerItems;
  },

  /**
   * Resets any filters that have been applied to the tag items, i.e. sets all layers to visible and deselects all tags.
   *
   * @param {{ commit: Commit; dispatch: Dispatch; getters: any; rootGetters: any; }} { commit, dispatch, getters, rootGetters }
   */
  tagFilterReset({
    commit,
    dispatch,
    getters,
    rootGetters,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    getters: any;
    rootGetters: any;
  }) {
    const layers: ViewerLayerDataInterface = rootGetters["PointCloudViewer/getLayerData"];
    const selectedTags: TagItemInterface[] = getters["getSelectedViewerTags"];
    const hidden: string[] = getters["getHiddenTagItems"];

    // Used to keep track of what models we have already de-isolated
    // This is to prevent us from de-isolating the same model multiple times.
    const modelsAlreadyDeisolated: any = {}; // TODO: fix type, {key:string , value:boolean}?
    selectedTags.forEach((tag) => {
      // autoTags
      if (tag.isAuto) {
        const modelContainerId = tag.id.split("#")[0];
        if (!modelsAlreadyDeisolated[modelContainerId]) {
          dispatch(
            "PointCloudViewer/ifcToggleIsolateTags",
            {
              modelContainerId,
            },
            { root: true }
          );
        }
        modelsAlreadyDeisolated[modelContainerId] = true;
      }
      // ifcModelItems
      if (tag.items) {
        tag.items.forEach((item) => {
          if (
            item.type === ViewerInspectorMode.IFC_MODEL_ITEM &&
            item.modelContainerId &&
            !modelsAlreadyDeisolated[item.modelContainerId]
          ) {
            dispatch(
              "PointCloudViewer/ifcToggleIsolateTags",
              {
                modelContainerId: item.modelContainerId,
              },
              { root: true }
            );
            modelsAlreadyDeisolated[item.modelContainerId] = true;
          }
        });
      }
      commit("removeSelectedViewerTag", tag);
    });

    hidden.forEach((id) => {
      const layer = Object.values(layers).find(
        (layer) => layer.items[id] && layer.items[id].id === id
      );
      const item = layer.items[id];
      if (item) {
        dispatch(
          "PointCloudViewer/toggleLayerItemVisibility",
          {
            item,
            type: item.type,
            visible: true,
          },
          { root: true }
        );
        commit("removeHiddenTagItem", id);
      }
    });
  },
};

export default actions;
