
import Vue from "vue";

import router from "@/router";
import { Project } from "@/store/modules/Projects/types";

export default Vue.extend({
  name: "Projects",
  methods: {
    reroute(to?: string): void {
      const {
        name: routeName,
        params: { projectId },
      } = this.$route;
      let newRoute: string;
      let params: Record<string, string> | undefined;

      switch (routeName) {
        case "project":
        case "projectCreate":
        case "viewers":
          newRoute = "projects";
          break;

        case "projectEdit":
        case "projectFileBrowser":
          newRoute = "project";
          params = { projectId };
          break;

        case "viewerEdit":
        case "viewerCreate":
          newRoute = "viewers";
          params = { projectId };
          break;

        default:
          newRoute = "projects";
          break;
      }

      if (to) {
        router.push({ name: to, params: { projectId } });
      } else {
        router.push({ name: newRoute, ...(params && { params }) });
      }
      // this.$store.dispatch("Utilities/reroute", newRoute, params);
    },
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    hasProjectFileAccess(): boolean {
      const {
        params: { projectId },
      } = this.$route;
      return this.$store.getters["User/getHasProjectFileAccess"](projectId);
    },

    showCreateNewProject(): boolean {
      const { name: routeName } = this.$route;
      return this.isAdmin && (routeName === "projects" || routeName === "project");
    },

    getToolbarLabel(): { show: boolean; label: string } {
      const { name: routeName } = this.$route;
      let toolbarLabel = { show: false, label: "" };

      switch (routeName) {
        case "project":
          toolbarLabel = { show: true, label: "Details" };
          break;
        case "projectEdit":
          toolbarLabel = { show: true, label: "Edit project" };
          break;
        case "projectFileBrowser":
          toolbarLabel = { show: true, label: "File browser" };
          break;
        case "viewers":
          toolbarLabel = { show: true, label: "Point Clouds" };
          break;
        case "viewerEdit":
          toolbarLabel = { show: true, label: "Edit point cloud viewer" };
          break;
        case "viewerCreate":
          toolbarLabel = { show: true, label: "Create point cloud viewer" };
          break;
      }

      return toolbarLabel;
    },
    getToolbarTitle() {
      const {
        name,
        params: { projectId },
      } = this.$route;

      let isDeletedList;
      let isDeleted;
      let project: Project | undefined;
      let title = "";

      switch (name) {
        case "projects":
          title = "Projects";
          break;
        case "projectCreate":
          title = "Create Project";
          break;
        case "project":
        case "projectEdit":
        case "projectFileBrowser":
        case "viewers":
        case "viewerCreate":
        case "viewerEdit":
          isDeletedList = this.$store.getters["Projects/getIsDeleted"];
          isDeleted = isDeletedList.includes(projectId);
          project = this.$store.getters["Projects/getProject"](projectId);
          if (!isDeleted && !project && projectId) {
            this.$store.dispatch("Projects/fetchProject", projectId);
            project = this.$store.getters["Projects/getProject"](projectId);
          }

          if (project) {
            title = project.title;
          } else {
            title = "Getting project title";
          }
          break;
      }

      return title;
    },
  },
});
