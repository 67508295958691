
import Vue from "vue";

import { FileBrowser } from "@/components/file-browser";

export default Vue.extend({
  name: "ProjectFileStorage",
  components: {
    FileBrowser,
  },
});
