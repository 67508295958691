import Vue from "vue";
import { MutationTree } from "vuex";

import {
  LayerEditorModelData,
  LayerEditorPointcloudData,
  LayerEditorPointData,
  ViewerInspectorMode,
  ViewerLayerDataCategoryKey,
  ViewerObjectTypeCategory,
} from "@/types";

import { ModelsState } from "../models/types";
import { GraphicsInspectorInterface, State } from "../types";
import { SidebarsState } from "./types";

const sidebarsMutations: MutationTree<SidebarsState & ModelsState & State> = {
  clearLayerEditor(state) {
    state.layerData.pointclouds.items = {};
    state.layerData.models.items = {};
  },

  setInspectorMode(state, inspectorMode: ViewerInspectorMode) {
    state.inspectorMode = inspectorMode;
  },

  setCurrentInspector(state) {
    const inspectorMode = state.inspectorMode;

    switch (inspectorMode) {
      case ViewerInspectorMode.POINTCLOUD:
        state.currentInspector = state.pointcloudInspector;
        return;

      case ViewerInspectorMode.MODEL:
        state.currentInspector = state.modelInspector;
        return;

      case ViewerInspectorMode.IFC_MODEL_ITEM:
        state.currentInspector = state.ifcModelItemInspector;
        return;

      case ViewerInspectorMode.MEASURE:
      case ViewerInspectorMode.MEASUREMENTS:
      case ViewerInspectorMode.MEASUREMENT_HEIGHT:
      case ViewerInspectorMode.MEASUREMENT_DISTANCE:
      case ViewerInspectorMode.POINT_OF_INTEREST:
        state.currentInspector = state.measureInspector;
        return;

      default:
        state.currentInspector = undefined;
        return;
    }
  },

  setLayerDataMeasurementsItems(state, payload) {
    const { id, data } = payload;
    Vue.set(state.layerData.measurements.items, id, data);
  },

  setLayerDataModels(state, filteredItems) {
    state.layerData.models["items"] = filteredItems;
  },

  setLayerDataModelsItems(state, payload) {
    const { id, data } = payload;
    Vue.set(state.layerData.models.items, id, data);
  },

  setLayerItemsData(
    state,
    payload: {
      id: string;
      data: any;
      type:
        | ViewerObjectTypeCategory.POINTCLOUDS
        | ViewerObjectTypeCategory.MEASUREMENTS
        | ViewerObjectTypeCategory.MODELS
        | ViewerObjectTypeCategory.POINT_OF_INTEREST;
    }
  ) {
    const { id, data, type } = payload;

    // Check if type contains the word "measurement" since that is a collection for multiple types it should be assigned to one category.
    const category = type.includes("measurement") ? ViewerObjectTypeCategory.MEASUREMENTS : type;

    // Update the layer data category items.
    Vue.set(state.layerData[category].items, id, data);
  },

  setLayerItemData(
    state,
    payload: {
      category: ViewerLayerDataCategoryKey;
      data: LayerEditorPointData | LayerEditorModelData | LayerEditorPointcloudData;
      id: string;
    }
  ) {
    const { category, data, id } = payload;

    Vue.set(state.layerData[category].items, id, data);
  },

  setLeftSidebarIfc(state, payload) {
    state.leftSidebarIfc = payload;
  },

  setMeasureInspector(state, measureInspector) {
    state.measureInspector = measureInspector;
  },

  setPointcloudInspector(state, pointcloudInspector) {
    state.pointcloudInspector = pointcloudInspector;
  },

  setModelInspector(state, modelInspector) {
    state.modelInspector = modelInspector;
  },

  setGraphicsInspector(state, graphicsInspector) {
    state.graphicsInspector = {
      ...state.graphicsInspector,
      ...graphicsInspector,
    };
    // state.graphicsInspector = graphicsInspector;
  },

  setRightSidebar(state, sidebarState) {
    state.rightSidebar = sidebarState;
  },

  setLeftSidebar(state, leftSidebarState) {
    state.leftSidebar = leftSidebarState;
  },

  toggleLeftSideBar(state) {
    state.leftSidebar = !state.leftSidebar;
  },

  toggleRightSideBar(state) {
    state.rightSidebar = !state.rightSidebar;
  },

  /**
   * Set what model is active on
   * left sidebar.
   * @param payload:ifc-modelId | null
   */
  setIfcCurrentModelIdInLeftSideBar(state, payload) {
    state.ifcCurrentModelIdInLeftSideBar = payload;
  },
  updateGraphicsInspector(state, payload: Partial<GraphicsInspectorInterface>) {
    state.graphicsInspector = {
      ...state.graphicsInspector,
      ...payload,
    };
  },
  /**
   * Keeps track of what material is current in model materials ui
   * @param state
   * @param material
   */
  setIfcCurrentMaterial(state, payload) {
    const model = state.ifcModels.find(
      (ifcModel) => ifcModel.modelContainerId === payload.modelContainerId
    );
    if (!model) {
      return;
    }

    state.ifcCurrentMaterial = { modelId: model.modelId, material: payload.material };
  },

  setSelectedLayerItemId(state, value) {
    state.selectedLayerItemId = value;
  },
};

export default sidebarsMutations;
