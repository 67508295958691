<template>
  <v-list nav dense>
    <v-list-item
      v-for="menuItem in generalMenuItems"
      :to="menuItem.to"
      :key="menuItem.title"
      :ripple="false"
      active-class="white--text"
      color="accent lighten-5"
      dark
    >
      <v-list-item-icon class="accent--text text--lighten-5">
        <v-icon>{{ menuItem.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ menuItem.title }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AppContainerLeftSidebarMenu",
  computed: {
    generalMenuItems() {
      const isAdmin = this.$store.getters["User/getIsAdmin"];
      const rootFileAccess = this.$store.getters["User/getHasRootFileAccess"];
      const items = [
        {
          title: "Projects",
          icon: "mdi-file-tree",
          to: "/projects",
        },
      ];

      if (isAdmin) {
        items.unshift({
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/",
        });
      }

      if (isAdmin || rootFileAccess) {
        items.push({
          title: "Files",
          icon: "mdi-folder",
          to: "/file-browser",
        });
      }

      return items;
    },
  },
});
</script>
