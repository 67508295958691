
import Vue from "vue";

import IFCStoreys from "./ifc/storeys.vue";
import Layers from "./layers/layers.vue";
import TagFilter from "./tags-filter.vue";

export default Vue.extend({
  name: "PointCloudViewerLeftSidebar",
  components: {
    IFCStoreys,
    Layers,
    TagFilter,
  },
  computed: {
    state: {
      get(): boolean {
        return this.$store.getters["PointCloudViewer/getLeftSidebar"];
      },
      set(value: boolean) {
        this.$store.commit("PointCloudViewer/setLeftSidebar", value);
      },
    },
    isIFCModel() {
      return this.$store.getters["PointCloudViewer/getLeftSidebarIfc"];
    },
  },
});
