<template>
  <v-list v-if="hasAdminAccess" nav dense>
    <v-list-item
      v-for="menuItem in adminMenuItems"
      :to="menuItem.to"
      :key="menuItem.title"
      :ripple="false"
      active-class="white--text"
      color="accent lighten-5"
      dark
    >
      <v-list-item-icon class="accent--text text--lighten-5">
        <v-icon>{{ menuItem.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ menuItem.title }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from "vue";

import { Roles } from "@/types/enums";

export default Vue.extend({
  name: "AppContainerLeftSidebarAdminMenu",
  data: () => ({
    adminMenuItems: [
      {
        title: "Users",
        icon: "mdi-account-multiple",
        to: "/user-management",
      },
      {
        title: "Add Users",
        icon: "mdi-plus",
        to: "/add-users",
      },
      {
        title: "Settings",
        icon: "mdi-cog",
        to: "/settings",
      },
    ],
  }),
  computed: {
    hasAdminAccess() {
      const userRole = this.$store.getters["User/getAuthUserRole"].role;
      return userRole === Roles.ADMIN || userRole === Roles.OWNER;
    },
  },
});
</script>
