var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',[(!_vm.wrapper || _vm.wrapper === 'list')?_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item',{class:[
        _vm.listColumn ? 'flex-column' : '',
        _vm.small ? 'caption' : '',
        'd-flex align-start px-0',
        _vm.color,
      ],staticStyle:{"min-height":"auto"},attrs:{"dense":"","inactive":""}},[_c('div',{class:[_vm.listColumn ? '' : 'mr-2']},[(_vm.strongTitle)?_c('strong',[_vm._v("Created:")]):_c('span',[_vm._v("Created:")])]),_c('div',[_vm._v(" "+_vm._s(_vm.timestampToLocaleDateTime(_vm.item.createdAt))+" ")]),(!_vm.listColumn)?_c('span',{staticClass:"mx-2"},[_c('strong',[_vm._v("by")])]):_vm._e(),(_vm.author.name || _vm.author.reference)?_c('div',[_vm._v(" "+_vm._s(!_vm.author.name ? _vm.author.reference : `${_vm.author.name} (${_vm.author.reference})`)+" ")]):_c('v-progress-circular',{attrs:{"color":"primary","width":2,"size":14,"indeterminate":""}})],1),(_vm.item.updatedAt)?_c('v-list-item',{class:[
        _vm.listColumn ? 'flex-column' : '',
        _vm.small ? 'caption' : '',
        'd-flex align-start px-0',
        _vm.color,
      ],staticStyle:{"min-height":"auto"},attrs:{"inactive":""}},[_c('div',{class:[_vm.listColumn ? '' : 'mr-2']},[(_vm.strongTitle)?_c('strong',[_vm._v("Updated:")]):_c('span',[_vm._v("Updated:")])]),_c('div',[_vm._v(" "+_vm._s(_vm.timestampToLocaleDateTime(_vm.item.updatedAt))+" ")]),(!_vm.listColumn)?_c('span',{staticClass:"mx-2"},[_c('strong',[_vm._v("by")])]):_vm._e(),(_vm.updateAuthor.name || _vm.updateAuthor.reference)?_c('div',[_vm._v(" "+_vm._s(!_vm.updateAuthor.name ? _vm.updateAuthor.reference : `${_vm.updateAuthor.name} (${_vm.updateAuthor.reference})`)+" ")]):_c('v-progress-circular',{attrs:{"color":"primary","width":2,"size":14,"indeterminate":""}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }