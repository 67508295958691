
import { Auth } from "aws-amplify";
import Vue from "vue";

import { HeaderLogotype, LoginBackground, MultiClientList } from "@/components/login";
import { SnackbarColors, VForm } from "@/types";
import { codeRules, emailRules, passwordRules } from "@/utilities";

export default Vue.extend({
  name: "ResetPassword",
  components: {
    LoginBackground,
    HeaderLogotype,
    MultiClientList,
  },
  data: () => ({
    email: "",
    code: "",
    password: "",
    hasVerification: false,
    emailRules,
    passwordRules,
    codeRules,
  }),
  methods: {
    backToLogin(): void {
      if (this.instructionsSent) {
        this.formReset.resetValidation();
      } else {
        this.formSend.resetValidation();
      }
      this.email = "";
      this.$store.commit("User/setClients", []);
      this.instructionsSent = false;
      this.$router.push("/login");
    },
    onClientSelect(): void {
      this.sendInstructions();
    },
    async sendInstructions(): Promise<void> {
      // make sure the email field is not empty
      if (this.formSend.validate()) {
        this.$store.commit("User/setUserActionPayload", {
          action: "passwordReset",
          userCredentials: {
            email: this.email.toLowerCase(),
            password: "",
          },
        });
        await this.$store.dispatch("User/initiate");

        if (this.instructionsSent) {
          this.$nextTick(() => {
            this.formReset.resetValidation();
            this.codeInputRef.focus();
          });
        }
      }
    },
    async resetPassword(): Promise<void> {
      const { email, code, password } = this;
      if (this.formReset.validate()) {
        try {
          await Auth.forgotPasswordSubmit(email, code, password);
          // login user with new password
          this.$store.commit("User/setUserActionPayload", {
            action: "login",
            userCredentials: {
              email: email.toLowerCase(),
              password: password,
            },
          });
          await this.$store.dispatch("User/initiate");
        } catch (error) {
          this.$store.commit("User/setUserActionPayload", false);
          this.$store.commit(
            "Utilities/showSnackbar",
            {
              message:
                "There was an error reseting your password. Make sure all fields are correct.",
              color: SnackbarColors.ERROR,
            },
            { root: true }
          );
        }
      }
    },
  },
  computed: {
    instructionsSent: {
      get(): boolean {
        return this.$store.getters["User/getInstructionsSent"];
      },
      set(state: boolean) {
        this.$store.commit("User/setInstructionsSent", state);
      },
    },
    multiClient: {
      get(): boolean {
        return this.$store.getters["User/getClients"].length > 1;
      },
      set(reset): void {
        return this.$store.commit("User/setClients", reset);
      },
    },
    emailInput(): HTMLInputElement {
      return this.$refs.emailInput as HTMLInputElement;
    },
    codeInputRef(): HTMLInputElement {
      return this.$refs.codeInput as HTMLInputElement;
    },
    newPasswordInputRef(): HTMLInputElement {
      return this.$refs.newPasswordInput as HTMLInputElement;
    },
    formSend(): VForm {
      return this.$refs.formSend as VForm;
    },
    formReset(): VForm {
      return this.$refs.formReset as VForm;
    },
  },
  mounted() {
    this.formSend.resetValidation();
    this.emailInput.focus();
  },
  destroyed() {
    this.$store.commit("User/setInstructionsSent", false);
  },
});
