import { Group, LineSegments } from "three";
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel";

import { InspectorAttachmentTypeInterface, ModelInpsectorAttachmentInterface } from "./attachment";
import { XYZInterface } from "./general";
import { ViewerInspectorMode } from "./viewer";

/**
 * Available model types.
 *
 * @export
 * @enum {number}
 */
export enum ModelTypes {
  IFC = "ifc",
  FBX = "fbx",
}

/**
 * Allowed model types.
 *
 * @export
 * @typedef {AllowedModelTypes}
 */
export type AllowedModelTypes = ModelTypes.IFC | ModelTypes.FBX;

/**
 * FBX model type.
 *
 * @export
 * @typedef {FBXModel}
 */
export type FBXModel = Group;

export enum ModelTransformXYZKeys {
  x = "x",
  y = "y",
  z = "z",
}

/**
 * Model transform keys.
 *
 * @export
 * @enum {number}
 */
export enum ModelTransformKeys {
  SCALE = "scale",
  POSITION = "position",
  ROTATION = "rotation",
}

/**
 * Model transform interface.
 *
 * @export
 * @interface ModelTransformInterface
 * @typedef {ModelTransformInterface}
 */
export interface ModelTransformInterface {
  /**
   * TODO: Write description.
   *
   * @type {XYZInterface}
   */
  [ModelTransformKeys.SCALE]: XYZInterface;

  /**
   * TODO: Write description.
   *
   * @type {XYZInterface}
   */
  [ModelTransformKeys.POSITION]: XYZInterface;

  /**
   * TODO: Write description.
   *
   * @type {XYZInterface}
   */
  [ModelTransformKeys.ROTATION]: XYZInterface;
}
export interface IFCModelItemInspectorInterface {
  /**
   * Optional attachments on item. These are not part of the IFC file. They are stored in the database and are linked using the `globalId` property (refered to as `modelPointGlobalId` internally).
   *
   * @type {?InspectorAttachmentTypeInterface[]}
   */
  attachments?: InspectorAttachmentTypeInterface[];

  /**
   * Optional description of model component item.
   *
   * @type {?string}
   */

  description?: string;

  /**
   * Express ID of IFC component from IFCjs.
   *
   * @example 14342
   * @type {number}
   */
  expressId: number;

  /**
   * Global ID from IFC file.
   *
   * @exampl "0xvbY3xV52hBV8Vlda7BU$"
   * @type {string}
   */
  globalId: string;

  /**
   * IFC component item tag.
   *
   * @example "3706422"
   * @type {string}
   */
  itemTag: string;

  /**
   * ID of current model.
   *
   * @type {number}
   */
  modelId: number;

  /**
   * Name of IFC component.
   *
   * @example "Basic Roof"
   * @type {string}
   */
  name: string;

  /**
   * IFC component object type.
   *
   * @example "Basic Roof:Tak 250 mm"
   * @type {string}
   */
  objectType: string;

  /**
   * IFC component predefined type.
   *
   * @example "NOTDEFINED"
   * @type {string}
   */
  predefinedType: string;

  /**
   * IFC component property sets. These are optional properties set in the IFC file.
   *
   * @type {PropertySetsInterface[]}
   */
  propertySets: PropertySetsInterface[];

  /**
   * This is the `sk` (sortkey) of the model attachments database entry. It is added in the backend to each item in the array of items in the response.
   *
   * @type {?string}
   */
  tableItemId?: string;

  /**
   * The inspector type.
   *
   * @example "InspectorMode.IFC_MODEL_ITEM"
   * @type {string}
   */
  type: ViewerInspectorMode;
}

/**
 * Property set item interface for `properties` array in {@link PropertySetsInterface}.
 *
 * @export
 * @interface PropertySetsItemInterface
 * @typedef {PropertySetsItemInterface}
 */
export interface PropertySetsItemInterface {
  /**
   * Property name comes from application in which IFC file was created.
   *
   * @type {string}
   */
  name: string;

  /**
   * Property value comes from application in which IFC file was created.
   *
   * @type {(string | number)}
   */
  value: string | number;
}

/**
 * Property set interface for `propertySets` array.
 *
 * @export
 * @interface PropertySetsInterface
 * @typedef {PropertySetsInterface}
 */
export interface PropertySetsInterface {
  /**
   * Optional item description.
   *
   * @type {?string}
   */
  description?: string;

  /**
   * `globalId` is a unique identifier for an item in an IFC model.
   *
   * @type {string}
   */
  globalId: string;

  /**
   * `id` is item express ID.
   *
   * @type {number}
   */
  id: number;

  /**
   * Property set name comes from application in which IFC file was created.
   */
  name: string;

  /**
   * Array of property objects with `name` and `value`.
   *
   * @type {PropertySetsItemInterface[]}
   */
  properties: PropertySetsItemInterface[];
}

/**
 * Group item from IFC model used in `storey-group-recursive` component. These are psuedo groups and do not exist in the IFC file. They are created durring IFC loading custom parsing process.
 *
 * @export
 * @interface IFCModelGroupItemsInterface
 * @typedef {IFCModelGroupItemsInterface}
 */
export interface IFCModelGroupItemInterface {
  /**
   * Optional attachments in the group.
   *
   * @type {?ModelInpsectorAttachmentInterface[]}
   */
  attachments?: ModelInpsectorAttachmentInterface[];

  /**
   * Display name of group item. Uses `longName` property if it exists, otherwise uses `name`.
   *
   * @type {string}
   */
  displayname: string;

  /**
   * Global ID of group item.
   *
   * @type {string}
   */
  globalId: string;

  /**
   * Express ID of the group item.
   *
   * @type {number}
   */
  id: number;

  /**
   * Array of express IDs of items in the group item.
   *
   * @type {number[]}
   */
  ids: number[];
}

/**
 * TODO: Write description.
 *
 * @export
 * @interface FetchedModelDataInterface
 * @typedef {FetchedModelDataInterface}
 */
export interface FetchedModelDataInterface {
  /**
   * TODO: Write description.
   *
   * @type {(FBXModel | IFCModel)}
   */
  id: string;

  /**
   * TODO: Write description.
   *
   * @type {(FBXModel | IFCModel)}
   */
  object: FBXModel | IFCModel;

  /**
   * TODO: Write description.
   *
   * @type {string}
   */
  title: string;

  /**
   * TODO: Write description.
   *
   * @type {AllowedModelTypes}
   */
  type: AllowedModelTypes;
}

/**
 * TODO: Write description.
 *
 * @export
 * @interface FetchedModelInterface
 * @typedef {FetchedModelInterface}
 */
export interface FetchedModelInterface {
  /**
   * TODO: Write description.
   *
   * @type {FetchedModelDataInterface}
   */
  data: FetchedModelDataInterface;

  /**
   * TODO: Write description.
   *
   * @type {string}
   */
  id: string;
}

export type Wireframe = LineSegments;
/**
 * Used to handle wireframe-linesegments for fetched ifc-models.
 * In conjuction with groupId(id) & modelId(modelContainerId) we can match
 * this line-segment with the right model-mesh in the viewer.
 */
export interface FetchedWireFrame {
  /**
   * id represents the groupId in the model
   */
  id: number;
  /**
   * modelContainerId of the fetched model this line-segment belongs to
   */
  modelContainerId: string;
  /**
   * The line-segments (geometry+material)
   */
  wireframe: Wireframe;
}
