import { Material } from "three";
import { GetterTree } from "vuex";

import { SidebarsState } from "../sidebars/types";
import { ModelsState } from "./types";

const modelsGetters: GetterTree<ModelsState & SidebarsState, unknown> = {
  getIFCModelItemInspector: (state) => {
    return state.ifcModelItemInspector;
  },

  getIfcBuilding: (state) => {
    return state.ifcBuilding;
  },

  getIfcCurrentModel: (state) => {
    return state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
  },

  // ! Discontinued? Look at `Ifc/getIFCManager` and usage. Do repository search for `getIFCManager`.
  getIFCManager: (state) => {
    return state.ifcManager;
  },

  getIfcHidden: (state) => {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      return model.hidden;
    }
    return [];
  },

  getIfcIsolated: (state) => {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      return model.isolated;
    }
    return [];
  },

  getIfcHighlight: (state) => {
    const model = state.ifcModels.find((item) => item.modelId === state.ifcCurrentModelId);
    if (model) {
      return model.highlighted;
    }
    return [];
  },

  getIFCModels: (state) => {
    return state.ifcModels;
  },

  getIfcHoveredModel: (state) => {
    return state.ifcModels.find((item) => item.modelId === state.ifcHoveredId);
  },

  getCurrentSelection: (state) => {
    return state.currentSelection;
  },

  getWireframeMaterial(state) {
    return state.wireframeMaterial;
  },

  getModelLoadingState: (state) => {
    return state.modelLoading;
  },

  // getIfcModels(state) {
  //   if (!state.layerData) {
  //     return;
  //   }

  //   const {
  //     ifcModels,
  //     layerData: {
  //       models: { items },
  //     },
  //   } = state;

  //   return ifcModels.map((ifcModel) => {
  //     if (!items || !ifcModel) {
  //       return;
  //     }
  //     // get items from one model,identified using modelContainerId
  //     const item: { object: any; title: string; type: string } = items[ifcModel.modelContainerId];
  //     if (!item) {
  //       return;
  //     }
  //     if (!item.title) {
  //       return;
  //     }
  //     return {
  //       title: item.title,
  //       modelId: ifcModel.modelContainerId,
  //       material: ifcModel.materials,
  //     };
  //   });
  // },

  /**
   * Suggested refactoring of above getter, by @axelra82
   *
   * If accepted, remove old getter.
   */
  getIfcModels(state) {
    if (!state.layerData) {
      return;
    }

    const {
      ifcModels,
      layerData: {
        models: { items },
      },
    } = state;

    if (!items) {
      return;
    }

    const modelsList = ifcModels.reduce(
      (
        newList: {
          title: string;
          modelId: string;
          material: Material[];
        }[],
        ifcModel
      ) => {
        // ! Can `ifcModel` be undefined? These are iterations over an array. In what scenario would `ifcModel` be undefined?
        if (!ifcModel) {
          return newList;
        }

        const { modelContainerId, materials } = ifcModel;
        // Get items from one model, identified using modelContainerId.
        const item: {
          object: any;
          title: string;
          type: string;
        } = items[modelContainerId];

        // ! Can `item.title` exist without `item`? If not, wouldn't `!item.title` suffice?
        if (!item || !item.title) {
          return newList;
        }

        newList.push({
          title: item.title,
          modelId: modelContainerId,
          material: materials,
        });

        return newList;
      },
      []
    );

    return modelsList;
  },
};

export default modelsGetters;
