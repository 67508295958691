import { MutationTree } from "vuex";
import { IFCLoader } from "web-ifc-three";
import { IFCManager } from "web-ifc-three/IFC/components/IFCManager";

import { getDefaultState } from "./state";
import { State } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIFCManager(state, ifcManager: IFCManager) {
    state.ifcManager = ifcManager;
  },
  setIFCLoader(state, ifcLoader: IFCLoader) {
    state.ifcLoader = ifcLoader;
  },
};

export default mutations;
