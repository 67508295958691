
import { Material, MeshBasicMaterial, MeshLambertMaterial } from "three";
import Vue from "vue";

import { GraphicsInspectorInterface } from "@/store/modules/PointCloudViewer/types";

import { SettingsColorPicker } from "./";

interface Data {
  isSandbox: boolean;
  // inspectorTabs: string;
  selectedModelId: string | null;
  selectMaterialDisabled: boolean;
  materialColorPickerDisabled: boolean;
}

export default Vue.extend({
  name: "ViewerGraphicsSettings",
  components: {
    SettingsColorPicker,
  },
  // props: {},
  data: (): Data => ({
    isSandbox: process.env.NODE_ENV === "sandbox",
    // inspectorTabs: "info",
    selectedModelId: null,
    selectMaterialDisabled: true,
    materialColorPickerDisabled: true,
  }),
  methods: {
    changeInWireframe() {
      this.selectMaterialDisabled = true;
      this.materialColorPickerDisabled = true;
      this.selectedModelId = null;
      this.$store.dispatch("PointCloudViewer/changeGraphicsMode");
    },
    updatePointCloudBudget() {
      this.$store.dispatch("PointCloudViewer/updatePointCloudBudget");
    },

    updateFov() {
      this.$store.dispatch("PointCloudViewer/updateFov");
    },

    changeBackground() {
      this.$store.dispatch("PointCloudViewer/changeBackground");
    },

    editViewer() {
      const {
        params: { projectId, viewerId },
      } = this.$route;

      this.$router.push({
        name: "viewerEdit",
        params: { projectId, viewerId },
      });
    },
  },
  computed: {
    /**
     * Get graphics inspector.
     *
     * @returns GraphicsInspectorInterface
     */
    graphicsInspector(): GraphicsInspectorInterface {
      return this.$store.getters["PointCloudViewer/getGraphicsInspector"];
    },
    modelsLoading(): boolean {
      const loading = this.$store.getters["PointCloudViewer/getModelLoadingState"];

      return loading;
    },

    models: {
      get() {
        const ifcModels: { material: Material[]; modelId: string; title: string }[] =
          this.$store.getters["PointCloudViewer/getIfcModels"];

        return ifcModels;
      },
      set(value: string) {
        this.$store.commit("PointCloudViewer/updateGraphicsInspector", {
          modelMaterialColor: "",
          modelMaterial: true,
        });

        this.selectMaterialDisabled = false;
        this.materialColorPickerDisabled = true;
        this.selectedModelId = value;
      },
    },
    materials: {
      get() {
        const ifcModels: { material: Material[]; modelId: string; title: string }[] =
          this.$store.getters["PointCloudViewer/getIfcModels"];

        if (!ifcModels.length) {
          //   console.log("no models", ifcModels);
          return;
        }
        if (typeof ifcModels[0] === "undefined") {
          return;
        }
        if (!this.selectedModelId) {
          // console.log("no selectedModelId!");
          return;
        }

        const ifcModel = ifcModels.find((m) => m.modelId === this.selectedModelId);

        if (!ifcModel) {
          // console.log("no ifc model found");
          return;
        }

        const { material } = ifcModel;

        if (!material) {
          //   console.log("no material found");
          return;
        }

        return material;
      },
      set(value: string) {
        this.materialColorPickerDisabled = false;
        const ifcModels: { material: Material[]; modelId: string; title: string }[] =
          this.$store.getters["PointCloudViewer/getIfcModels"];
        const ifcModel = ifcModels.find((m) => m.modelId === this.selectedModelId);

        if (!ifcModel) {
          //   console.log("no ifc model found");
          return;
        }

        const { material } = ifcModel;

        if (!material) {
          //   console.log("no material found");
          return;
        }
        const oneMat = material.find((mat) => mat.uuid === value);

        const hexString = "#".concat(
          (oneMat as MeshLambertMaterial | MeshBasicMaterial).color.getHexString()
        );
        this.$store.commit("PointCloudViewer/setIfcCurrentMaterial", {
          modelContainerId: ifcModel.modelId,
          material: oneMat,
        });

        this.$store.dispatch("PointCloudViewer/updateGraphicsInspector", {
          colorType: "modelMaterialColor",
          swatchColor: hexString,
        });
      },
    },
  },
});
