
import Vue from "vue";

import Snackbar from "@/components/snackbar.vue";

export default Vue.extend({
  components: {
    Snackbar,
  },
});
