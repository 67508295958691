import { GetterTree } from "vuex";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getCurrentPath: (state) => {
    return state.currentPath;
  },

  getCurrentPathName: (state) => {
    return state.currentPathName;
  },

  getSnackbarColor: (state) => {
    return state.snackbar.color;
  },

  getSnackbarIcon: (state) => {
    return state.snackbar.icon;
  },

  getSnackbarMessage: (state) => {
    return state.snackbar.message;
  },

  getSnackbarTimeout: (state) => {
    return state.snackbar.timeout;
  },

  getSnackbarTitle: (state) => {
    return state.snackbar.title;
  },

  getSnackbarVisible: (state) => {
    return state.snackbar.visible;
  },

  getUserRoles: (state) => {
    return state.userRoles;
  },
};

export default getters;
