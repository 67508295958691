
import Vue from "vue";

import { TagItemInterface } from "@/types";

export default Vue.extend({
  name: "DeleteTagDialog",
  data: () => ({
    working: false,
    deleteKeyword: "delete",
    deleteConfirmValue: "",
    deleteFormValidated: false,
  }),
  methods: {
    /**
     * Close the dialog and clear the selected tag from the store. Set a delay to reset the selected tag to handle undefined errors.
     *
     * @returns {void}
     */
    closeDialog(): void {
      if (this.working) {
        return;
      }
      this.dialog = false;
      this.deleteConfirmValue = "";
    },

    /**
     * Verify if deleted can continue.
     *
     * @param {string} value
     * @returns {Array<boolean | string>}
     */
    deleteRule(value: string): Array<boolean | string> {
      return [value === this.deleteKeyword || "Incorrect value"];
    },

    /**
     * If tag can be deleted, delete tag.
     *
     * @returns {Promise<void>}
     */
    async deleteTag(): Promise<void> {
      if (this.deleteFormValidated) {
        const { tableItemId, id, title } = this.storeTag as TagItemInterface;
        await this.$store.dispatch("Tags/deleteTag", {
          tableItemId,
          id,
          title,
        });

        this.dialog = false;
        this.deleteConfirmValue = "";
      }
    },
  },
  computed: {
    /**
     * Get the delete dialog state from the store.
     *
     * @returns {boolean}
     */
    dialog: {
      get(): boolean {
        return this.$store.getters["Tags/getDeleteDialogState"];
      },
      set(value: boolean) {
        this.$store.commit("Tags/setDeleteDialogState", value);
      },
    },

    /**
     * Get the selected tag from the store. This is to avoid the two-way data binding on some values when editing in the form.
     *
     * @returns {TagItemInterface} The selected tag.
     */
    storeTag(): TagItemInterface {
      return this.$store.getters["Tags/getSelectedTag"];
    },
  },
});
