import { AuthorInterface } from "./general";

export interface FileBrowserFile {
  createdAt: number;
  createdBy: string;
  id: string;
  name: string;
  objectName: string;
  path: string;
  processing?: boolean;
  rename: boolean;
  size: number;
  type: string;
  updatedAt: number;
  updatedBy: string;
}

export type FileType = {
  type?: string;
  icon: string;
  color: string;
};

export interface FileBrowserFolder {
  id: string;
  name: string;
  type: string;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
}

export interface FileBrowserList {
  id: string;
  files: (FileBrowserFile | FileBrowserFolder | null)[];
}

export interface FileBrowserNavigationHistoryObject {
  id: string;
  name: string;
}

export interface ContextItem {
  action?: string;
  /**
   * Created at timestamp (UNIX Epoch).
   *
   * @type {number}
   */
  createdAt?: number;
  /**
   * User who created the entry.
   *
   * @type {AuthorInterface}
   */
  createdBy?: AuthorInterface;
  id: string;
  name: string;
  objectName: string;
  pointCloudType?: string;
  rename?: boolean;
  type: string;
}

export enum PointCloudDataFileTypes {
  LAS = "las",
  LAZ = "laz",
  // E57 = "e57",
}

export enum BrowserOpenFileTypes {
  JPG = "jpg",
  JPEG = "jpeg",
  PNG = "png",
  GIF = "gif",
  WEBP = "webp",
  TIFF = "tiff",
  PSD = "psd",
  RAW = "raw",
  BMP = "bmp",
  HEIF = "heif",
  SVG = "svg",
  EPS = "eps",
  PDF = "pdf",
}
