
import Vue from "vue";

import AppContainer from "@/components/app-container/app-container.vue";
import { UploadDialog } from "@/components/file-browser";

export default Vue.extend({
  name: "Login",
  components: {
    AppContainer,
    UploadDialog,
  },
});

// There is currently no good way of enforcing a user signout when user closes browser window or tab

// Our application is mostly used on a desktop/laptop environment, so the standard `beforeunload` would be good enough for our current needs.

// **NOTE!** For alternative devices (e.g. mobile, tablet etc) `pagehide` or `visibilitychange` are the recommended standards.

// With all that being said. The `beforeunload` is somewhat outdated and requires:
// 1. e.preventDefault() for Firefox
// 3. e.returnValue = "string" for Chrome

// While both of these activate the event (https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event), only the first one is non-deprecated.

// Another issue is that this enforces a popup asking the user if they are sure they want to continue. This is undesired and can even be counter-intuitive for the purpose of forcefully signing out users when they close the window or tab.

// Furthermore the `beforeunload` triggers on page refresh, which is also not a desired behavior for user sign out (i.e. we don't want to sign out a user just because they refresh the page).

// The following window and document APIs have been tested without any cross-browser compatible solution for a desired result:

// **Window API**
// `beforeunload`, `unload`, `pagehide`

// **Document API**
// `visibilitychange`

// Also tested with event options `capture: true` and `passive: true`, without any success.

// Lastly, as per MDN documentation,

// ```
// // the absence of a returnValue property on the event will guarantee the browser unload happens
// delete e["returnValue"];
// ```
// also has no effect, as the problem is not the `unload` not happening, but rather what invokes the calls inside the `beforeunload` event.

// Below are some examples of tests that have been unsuccessful

// ```
// const clientData = getClientData();
// const storageKeyPrefix = clientData.storageKeyPrefix;
// const userEmail = clientData.email;

// const awsStorage = [
//   "accessToken",
//   "idToken",
//   "refreshToken",
//   "LastAuthUser",
//   "clockDrift",
//   "userData",
//   ];
//   window.addEventListener("beforeunload", () => {
//     awsStorage.forEach((key: string) => {
//       if (key !== "LastAuthUser") {
//         localStorage.removeItem(`${storageKeyPrefix}.${userEmail}.${key}`);
//       } else {
//         localStorage.removeItem(`${storageKeyPrefix}.${key}`);
//       }
//     });
//   });

//   document.addEventListener("visibilitychange", () => {
//     console.log(document.visibilityState);
//      if (document.visibilityState === "hidden") {
//       this.$store.dispatch("User/logOut");
//     }
//     if (document.visibilityState === "visible") {
//       // do something
//     }
//   });

//   window.addEventListener("beforeunload", (e) => {
//     e.preventDefault(); // Firefox / WebKit
//     e.returnValue = ""; // Chrome - deprecated
//     this.$store.dispatch("User/logOut");
//     // absence of returnValue on event guarantees browser unload
//    delete e.returnValue;
//   },
//   {
//     capture: true,
//     passive: true,
//   }
// );
// ```
