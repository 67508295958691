import { GetterTree } from "vuex";

import { ViewerObjectTypeCategory } from "@/types/viewer";

import { SidebarsState } from "../sidebars/types";
import { State } from "../types";

const pointsGetters: GetterTree<State & SidebarsState, unknown> = {
  getCurrentPointObject: (state) => {
    return state.currentPointObject;
  },

  getMeasureCommentInput: (state) => {
    return state.measureCommentInput;
  },

  getObjectVisibilityState: () => (item: Record<string, any>) => (category: string) => {
    switch (category) {
      case ViewerObjectTypeCategory.MEASUREMENTS:
        return item.object.visible;

      case ViewerObjectTypeCategory.POINT_OF_INTEREST:
        if (item.object["annotation"]) {
          return item.object["annotation"].visible;
        }
        break;

      case ViewerObjectTypeCategory.MODELS:
        return item.object.visible;

      case ViewerObjectTypeCategory.POINTCLOUDS:
        return item.object.visible;
    }
  },
};

export default pointsGetters;
