import { passwordCharacters } from "@/types/enums";

const generatePassword = (length = 8): string => {
  const characterSet = {
    upper: passwordCharacters.UPPER,
    lower: passwordCharacters.LOWER,
    number: passwordCharacters.NUMBER,
    symbol: passwordCharacters.SYMBOL,
  };
  const random = (string: string) => string.charAt(Math.floor(Math.random() * string.length));

  let password = "";
  password += random(characterSet.upper);
  password += random(characterSet.lower);
  password += random(characterSet.number);
  password += random(characterSet.symbol);

  for (let i = password.length; i < length; i++) {
    password += random(Object.values(characterSet).join(""));
  }

  return password;
};

export { generatePassword };
