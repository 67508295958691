
import Vue from "vue";

import { GraphicsInspectorInterface } from "@/store/modules/PointCloudViewer/types";

export default Vue.extend({
  name: "SettingsColorPicker",
  props: {
    color: {
      type: String,
      required: true,
    },
    isWireframe: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    colorType: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    colorPicker: false,
    swatchColor: "",
  }),
  watch: {
    // listens to color updates
    color: function (newColor: string) {
      Vue.set(this, "swatchColor", newColor);
    },
  },
  methods: {
    updateSwatch() {
      // this check is needed to avoid color picker
      // showing wrongly formatted hex
      if (this.swatchColor.length > 7 && !this.colorPicker) {
        this.swatchColor = this.color;
      }
      this.$store.dispatch("PointCloudViewer/updateGraphicsInspector", {
        colorType: this.colorType,
        swatchColor: this.swatchColor,
      });
    },
  },
  computed: {
    getGraphicsInspector(): GraphicsInspectorInterface {
      return this.$store.getters["PointCloudViewer/getGraphicsInspector"];
    },

    /**
     * Determines if the color swatch is white.
     *
     * @returns {boolean}
     */
    whiteSwatch(): boolean {
      return this.swatchColor.toLocaleLowerCase() === "#ffffff";
    },

    /**
     * Returns the style for the color swatch.
     *
     * @returns {Record<string, string>}
     */
    swatchStyle(): Record<string, string> {
      // If the swatch is white, add a border to it since it won't be visible on the white background.
      const whiteSwatchBorder = this.whiteSwatch ? "1px solid #e5e5e5" : "none";

      return {
        backgroundColor: this.swatchColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: "4px",
        transition: "border-radius 200ms ease-in-out",
        border: whiteSwatchBorder,
      };
    },
  },
  beforeMount() {
    this.swatchColor = this.color;
  },
});
