
import Vue from "vue";

import RoleChip from "@/components/role-chip.vue";
import { Roles } from "@/types/enums";

export default Vue.extend({
  name: "ImportUsersDialog",
  components: {
    RoleChip,
  },
  data: () => ({
    roles: Roles,
    InformationRules: [
      "Max file size is 2MB",
      "One user per line/row",
      "Leave password or role blank for default values",
      "Duplicate email entries and white space will be purged",
      'Use "," (comma) or ";" (semicolon) to separate data',
      "Password must be at least 8 and max 99 characters long, a special character from the set <code>^$*.[]{}()\"!/\\,><':;|_~`</code> and containe at least one (1) number, one (1) uppercase and one (1) lower case letter",
    ],
    InformationExamples: [
      "user1@email.com,OKpassword123!, owner",
      "user2@email.com,,guest",
      "user3@email.com",
      "user4@email.com, AlsoOKpass'123, admin",
      "user5@email.com; ;",
      "user6@email.com; ,",
    ],
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.getters["AccountManagement/getImportDialogState"];
      },
      set(state) {
        this.$store.commit("AccountManagement/setImportDialogState", state);
      },
    },
  },
});
