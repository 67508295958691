
import Vue from "vue";

import { Roles, UserProfileInterface } from "@/types";

export default Vue.extend({
  name: "ProjectInfo",
  computed: {
    isLoading() {
      return this.$store.getters["Projects/getLoadingState"];
    },

    isAdmin(): boolean {
      return this.$store.getters["User/getIsAdmin"];
    },

    projectFileAccess(): boolean {
      const { role, projectAccess } = this.$store.getters[
        "User/getUserProfile"
      ] as UserProfileInterface;
      const adminRoles = [Roles.OWNER, Roles.ADMIN];

      if (adminRoles.includes(role)) {
        return true;
      }

      if (projectAccess) {
        const {
          params: { projectId },
        } = this.$route;
        const userProjectAccess = projectAccess.find((project) => project.id === projectId);
        if (userProjectAccess) {
          if (
            userProjectAccess.role === Roles.EDITOR ||
            userProjectAccess.role === Roles.ADMIN ||
            userProjectAccess.role === Roles.GUEST
          ) {
            return true;
          }
        }
        // return userProjectAccess?.role === Roles.EDITOR;
      }

      return false;
    },

    getProjectDescription(): string {
      const projectId = this.$route.params.projectId;
      const project = this.$store.getters["Projects/getProject"](projectId);
      let projectDescription = "";

      if (project) {
        projectDescription = project.description;
      }
      return projectDescription;
    },
  },
});
