import { GetterTree } from "vuex";

import { FileBrowserAccessRole, Roles } from "@/types/enums";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getAuthenticatedUser: (state) => {
    return state.authenticatedUser;
  },

  getUserProfile: (state) => {
    return state.userProfile;
  },

  getAuthorProfiles: (state) => {
    return state.authorProfiles;
  },

  getLoadingState: (state) => {
    return state.loading;
  },

  getLoginErrorMessage: (state) => {
    return state.loginErrorMessage;
  },

  getConfirmedUserState: (state) => {
    return state.isConfirmedUser;
  },

  getClients: (state) => {
    return state.clients;
  },

  getSelectedClient: (state) => {
    return state.selectedClient;
  },

  getInstructionsSent: (state) => {
    return state.instructionsSent;
  },

  getUserName: (state) => {
    const {
      userProfile: { name },
    } = state;

    const { first, last } = name || {};

    if (!!first || !!last) {
      if (!!first && !last) {
        return `${first} ${last}`;
      }

      return first || last;
    }

    return undefined;
  },

  getUserSub: (state) => {
    // destructure state
    const { authenticatedUser } = state;
    // ternary return
    return authenticatedUser ? authenticatedUser["attributes"].sub : "No User";
  },

  getUserGroups: (state) => {
    // destructure state
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      // destructure authenticated user
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = authenticatedUser;

      return payload["cognito:groups"];
    }

    return "User has no group";
  },

  getIsAdmin: (state) => {
    // destructure state
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      // destructure authenticated user
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = authenticatedUser;

      const groups = payload["cognito:groups"];
      return groups.includes(Roles.OWNER) || groups.includes(Roles.ADMIN);
    }

    return false;
  },

  getIsOwner: (state) => {
    // destructure state
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      // destructure authenticated user
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = authenticatedUser;

      const groups = payload["cognito:groups"];
      return groups.includes(Roles.OWNER);
    }

    return false;
  },

  getIsUser: (state) => {
    // destructure state
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      // destructure authenticated user
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = authenticatedUser;

      const groups = payload["cognito:groups"];
      return groups.includes(Roles.USER);
    }

    return false;
  },

  getHasRootFileAccess: (state) => {
    const {
      userProfile: { rootFileAccess },
    } = state;

    if (!rootFileAccess) {
      return false;
    }

    return Object.values(FileBrowserAccessRole).includes(rootFileAccess);
  },

  getHasProjectFileAccess: (state) => (projectId: string) => {
    const {
      userProfile: { projectAccess, role },
    } = state;
    const adminRoles = [Roles.OWNER, Roles.ADMIN];
    if (adminRoles.includes(role)) {
      return true;
    }

    const project = projectAccess?.find((item) => {
      return item.id === projectId;
    });

    if (!project) {
      return false;
    }
    const { role: projectRole } = project;
    if (projectRole === Roles.EDITOR || projectRole === Roles.ADMIN) {
      return true;
    }

    //return projectRole === Roles.EDITOR;
  },

  getAuthUserRole: (state) => {
    const roles = {
      owner: {
        role: Roles.OWNER,
        color: "black",
      },
      admin: {
        role: Roles.ADMIN,
        color: "green",
      },
      user: {
        role: Roles.USER,
        color: "purple",
      },
    };

    // Destructure state.
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      // Destructure authenticated user.
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = authenticatedUser;

      const groups = payload["cognito:groups"];

      if (groups.includes(Roles.OWNER)) {
        return roles.owner;
      }

      if (groups.includes(Roles.ADMIN)) {
        return roles.admin;
      }

      if (groups.includes(Roles.USER)) {
        return roles.user;
      }
    }

    // TODO: Handle error.
    return undefined;
  },

  getUserEmail: (state) => {
    // destructure state
    const { authenticatedUser } = state;

    if (authenticatedUser) {
      const { attributes } = authenticatedUser;
      return attributes ? attributes.email : "";
    }

    return "No User";
  },

  getCanEdit: (state) => (id: string) => {
    const {
      userProfile: { uuid, role },
    } = state;

    // Allow admins and owners to edit others content.
    const adminRoles = [Roles.OWNER, Roles.ADMIN];

    return adminRoles.includes(role) || id === uuid;
  },
};

export default getters;
