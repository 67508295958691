import { API } from "aws-amplify";

import { getClientData } from "./local-storage";
import { getJwt } from "./token";

const coreApiPost = async (
  path: string,
  body?: Record<string, unknown> | undefined
): Promise<any> => {
  const clientData = getClientData();

  if (clientData) {
    const apiName = "core";
    const jwt = await getJwt();

    const postData = {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
      },
      body,
    };

    const postResponse = await API.post(apiName, path, postData);

    return postResponse;
  } else {
    throw {
      response: { data: "Missing client data" },
    };
  }
};

export { coreApiPost };
