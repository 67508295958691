import { AttachmentType } from "@/types";

import { AttachmentsState } from "./types";

const attachmentsState: AttachmentsState = {
  attachmentTypes: [AttachmentType.NOTE, AttachmentType.LINK, AttachmentType.FILE],
  selectedAttachmentFile: undefined,
  selectedAttachmentTypes: [],
};

export default attachmentsState;
