import "./registerServiceWorker";

import { Amplify, Auth } from "aws-amplify";
import Vue from "vue";

import App from "@/app.vue";
import awsConfig from "@/aws-config";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import store from "@/store";

Amplify.configure(awsConfig);
Auth.configure();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
