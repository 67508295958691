
import Vue from "vue";

export default Vue.extend({
  name: "DragNDropArea",
  data: () => ({
    canDrop: false,
  }),
  methods: {
    dragOver() {
      const routeName = this.$store.getters["Utilities/getCurrentPathName"];
      const allowedViewes = ["fileBrowser", "projectFileBrowser"];

      if (allowedViewes.includes(routeName)) {
        this.canDrop = true;
      }
    },
    dropFile(event: { dataTransfer: DataTransfer }) {
      this.canDrop = false;
      const uploadFiles = Object.values(event.dataTransfer.files);
      this.$store.commit("Storage/setUploadFiles", uploadFiles);
      // this.$store.commit("Storage/setShowFileInput", false);
      // this.$store.commit("Storage/setUploadDialogState", true);
      //this.$store.dispatch("Storage/uploadActivityFiles");
      this.$store.dispatch("Storage/uploadFiles");
    },
  },
  created() {
    document.addEventListener("dragenter", this.dragOver, false);
  },
});
