import { passwordCharacters } from "@/types/enums";

import { formatBytes } from "./files";
import { passwordHint } from "./hints";

const emailRules = (value: string): Array<boolean | string> => [
  !!value || "Email is required",
  /.+@.+\..+/.test(value) || "Invalid email",
];

const usernameRules = (value: string): Array<boolean | string> => [
  !!value || "Username is required",
];

const nameRules = (value: string): Array<boolean | string> => [
  !!value || "Name may only contain letters, spaces and dashes",
  /^[\p{L}a-zA-Z\s.-]+$/u.test(value),
];

const titleRules = (value: string): Array<boolean | string> => [!!value || "Title is required"];

const descriptionRules = (value: string): Array<boolean | string> => [
  !!value || "Description is required",
];

// Construct password from enum with allowed/required password characters
const passwordRegex = new RegExp(
  `^(?=.*[${passwordCharacters.UPPER}])(?=.*[${passwordCharacters.LOWER}])(?=.*[${passwordCharacters.NUMBER}])(?=.*[${passwordCharacters.SYMBOL}])\\S{8,99}$`
);
const passwordRules = (value: string): Array<boolean | string> => [
  !!value || "Password is required",
  passwordRegex.test(value) || passwordHint,
];

const usernameEmailRules = (value: string): Array<boolean | string> => [
  !!value || "Username or email is required",
];

const codeRules = (value: string): Array<boolean | string> => [
  /^\d{6}$/.test(value) || "Code must be exactly 6 numbers",
];

const folderNameRules = (value: string): Array<boolean | string> => [
  !!value || "Folder name is required",
  /^[\p{L}0-9a-zA-Z\s_\-*.–,—:;~^¨'"(){}[\]`´|/\\=&%#@+]*$/u.test(value) ||
    "Folder Name may only contain alpha numeric characters and some special characters",
];

const viewerModelUploadNameRules = (value: string): Array<boolean | string> => [
  !!value || "Item name is required",
  /^[a-zA-Z0-9\s]*$/.test(value) || "Item name may only contain alpha numeric characters",
];

const fileSizeRules = (file: File, maxFileSize: number): Array<boolean | string> => [
  !file || file.size < maxFileSize || `Max file size is ${formatBytes(maxFileSize)}`,
];

export {
  codeRules,
  descriptionRules,
  emailRules,
  fileSizeRules,
  folderNameRules,
  nameRules,
  passwordRules,
  titleRules,
  usernameEmailRules,
  usernameRules,
  viewerModelUploadNameRules,
};
