
import Vue from "vue";

import { timestampToLocaleDateTimeSeconds } from "@/utilities";

interface Data {
  appVersion: string;
  appBuild: string;
  appBuildTime: string;
  showBuild: boolean;
  timestampToLocaleDateTimeSeconds: (timestamp: string | number) => string;
}

export default Vue.extend({
  name: "Login",
  data: (): Data => ({
    appVersion: process.env.VUE_APP_VERSION ?? "unknown",
    appBuild: process.env.VUE_APP_BUILD ?? "unknown",
    appBuildTime: process.env.VUE_APP_BUILD_TIME ?? "unknown",
    showBuild: false,
    timestampToLocaleDateTimeSeconds,
  }),
  methods: {
    copyBuild() {
      const string = this.appBuild;
      this.showBuild = false;
      this.$store.dispatch("Utilities/copyToClipboard", {
        string,
        reference: `Build number (${string})`,
      });
    },
  },
});
