import { State } from "./types";

const getDefaultState = (): State => {
  return {
    addUserResponseMessage: { message: "", color: "grey" },
    deleteDialog: false,
    editDialog: false,
    editWorking: false,
    fetched: false,
    importDialog: false,
    loading: false,
    showResposeMessage: false,
    userGroupList: {},
    userList: [],
    deleteWorking: false,
  };
};
const state: State = getDefaultState();

export default state;
export { getDefaultState };
