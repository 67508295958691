import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-initials-sprites";
import { MutationTree } from "vuex";

import { AuthorInterface, UserActionPayload, UserProfileInterface } from "@/types";
import { getS3Object } from "@/utilities/files";

import { getDefaultState } from "./state";
import { SelectedClient, State } from "./types";

const mutations: MutationTree<State> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setUserActionPayload(state, payload: UserActionPayload) {
    state.userActionPayload = payload;
  },

  setLoadingState(state, loadingState: boolean) {
    state.loading = loadingState;
  },

  setLoginErrorMessage(state, loginErrorMessage: Record<string, unknown>) {
    state.loginErrorMessage = loginErrorMessage;
  },

  async setUserProfileImage(state) {
    const {
      authenticatedUser,
      userProfile: { uuid, imageSource },
    } = state;
    let image = "";

    // if image source is user uuid (cognito sub)
    // create blob otherwise use avatar
    if (imageSource && imageSource.includes(uuid)) {
      image = await getS3Object(imageSource);
    } else {
      const svg = createAvatar(style, {
        seed: authenticatedUser?.username,
      });
      const blob = new Blob([svg], { type: "image/svg+xml" });
      image = URL.createObjectURL(blob);
    }

    state.userProfile.profileImage = image;
  },

  setAuthenticatedUser(state, authUser: CognitoUserInterface) {
    state.authenticatedUser = authUser;
  },

  setUserProfile(state, userProfile: UserProfileInterface) {
    state.userProfile = userProfile;
  },

  setAuthorProfiles(state, authorProfile: AuthorInterface) {
    state.authorProfiles.push(authorProfile);
  },

  setUserConfirmationState(state, confirmedUserState: boolean) {
    state.isConfirmedUser = confirmedUserState;
  },

  setClients(state, clients: SelectedClient[]) {
    state.clients = clients;
  },

  setSelectedClient(state, client: SelectedClient) {
    state.selectedClient = client;
  },

  setInstructionsSent(state, isSent: boolean) {
    state.instructionsSent = isSent;
  },
};

export default mutations;
