
import Vue from "vue";

import {
  BrowserOpenFileTypes,
  FileBrowserFile,
  FileBrowserNavigationHistoryObject,
  PointCloudDataFileTypes,
} from "@/types";
import { formatBytes, getTypeIcon, timestampToLocaleDateTime } from "@/utilities";

import CreateFolderDialog from "./create-folder-dialog.vue";
import DeleteDialog from "./delete-dialog.vue";
import FileSelectDialog from "./file-select-dialog.vue";
import InfoDialog from "./info-dialog.vue";
import ShareDialog from "./share-dialog.vue";

interface ItemAction {
  text: string;
  icon: string;
  action: string;
  containerCompatible: boolean;
}

export default Vue.extend({
  name: "FileBrowser",
  components: {
    CreateFolderDialog,
    DeleteDialog,
    FileSelectDialog,
    InfoDialog,
    ShareDialog,
  },
  props: {
    initialPath: {
      type: String,
      default: "root",
    },
    dialogState: {
      type: Boolean,
      default: false,
    },
    isViewerDialog: {
      type: Boolean,
      default: false,
    },
    isAttachmentDialog: {
      type: Boolean,
      default: false,
    },
    isProjectDialog: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedFile: [] as FileBrowserFile[],
    formatBytes,
    getTypeIcon,
    timestampToLocaleDateTime,
    pathName: "",
    pathHistory: [] as FileBrowserNavigationHistoryObject[],
    headers: [
      { text: "File name", value: "name" },
      { text: "Size", value: "size" },
      { text: "Created", value: "createdAt" },
      { text: "", value: "actions" },
    ],
    itemActionSelection: null,
    itemActions: [
      {
        text: "File info",
        icon: "mdi-information",
        action: "info",
        containerCompatible: true,
      },
      {
        text: "Rename",
        icon: "mdi-form-textbox",
        action: "rename",
        containerCompatible: true,
      },
      // {
      //   text: "Download",
      //   icon: "mdi-cloud-download",
      //   action: "download",
      //   containerCompatible: false,
      // },
      {
        text: "Share",
        icon: "mdi-link-variant",
        action: "share",
        containerCompatible: false,
      },
      {
        text: "Delete",
        icon: "mdi-delete",
        action: "delete",
        containerCompatible: true,
      },
    ],
  }),
  methods: {
    isItemProcessingBoolean(processing?: string | boolean) {
      return typeof processing !== "boolean";
    },

    isItemProcessingString(processing?: string | boolean) {
      return typeof processing !== "string";
    },

    isItemProcessing(processing?: string | boolean) {
      return this.isItemProcessingString(processing) && this.isItemProcessingBoolean(processing);
    },

    handleUploadAction() {
      this.$store.commit("Storage/setSelectFileDialogState", true);
    },

    // Handle select file button click.
    async handleSelectedFile() {
      // Make sure file(s) is selected.
      if (this.selectedFile.length) {
        // Project edit file browser dialog (cover image).
        // Point cloud viewer file attachment file browser dialog.
        if (this.isProjectDialog || this.isAttachmentDialog) {
          const { name, objectName, type } = this.selectedFile[0];
          this.$store.commit("Storage/setContextItem", { name, objectName, type });
          // Close file browser on single item select click.
          this.$store.commit("Storage/setFileBrowserDialogState", false);
        }
        // Viewer edit file browser dialog.
        if (this.isViewerDialog) {
          const pushItem = await this.$store.dispatch(
            "Viewers/setSelectedItem",
            this.selectedFile[0]
          );

          // Finalize item selection.
          // set push item
          this.$store.commit("Viewers/setPushItem", pushItem);
        }

        // Point cloud viewer file attachment file browser dialog.
        // if (this.isAttachmentDialog) {
        //   this.$store.commit("PointCloudViewer/setSelectedAttachmentFile", this.selectedFile[0]);
        // }

        // close select file dialog
        // this.$store.commit("Storage/setSelectFileDialogState", false);
        this.$store.commit("Storage/setFileBrowserDialogState", false);

        // Reset file selection.
        this.selectedFile = [];
      }
    },

    // get files for file browser
    fetchFiles(refresh = false) {
      // check if current path is in store
      const hasFiles = this.$store.getters["Storage/getFileBrowserItems"](this.path);
      this.$store.commit("Storage/setFilesInFolder", hasFiles);
      // if path has not been fetched
      // or if user requested a refresh
      if (refresh || !hasFiles.length) {
        this.$store.dispatch("Storage/fetchFiles", this.path);
      }
    },

    // async pointcloudContainerOrigin() {
    // //   const contextItem: FileBrowserFile = getters["getContextItem"];

    // // const { name, objectName, type, id } = contextItem;

    // // await dispatch("Storage/getContainer", id);
    // },

    // handle item menu actions
    itemMenuAction(item: FileBrowserFile, action: string) {
      this.$store.commit("Storage/setContextItem", {
        ...item,
        action: action,
      });

      switch (action) {
        case "info":
          this.$store.commit("Storage/setItemInfoDialogState", true);
          break;
        case "rename":
          item.rename = true;
          break;
        case "download":
          this.$store.dispatch("Storage/downloadFile");
          break;
        case "open":
          this.$store.dispatch("Storage/downloadFile");
          break;
        case "share":
          this.$store.dispatch("Storage/shareFile");
          break;
        case "delete":
          this.$store.commit("Storage/setDeleteDialogState", true);
          break;
      }
    },

    // display mini-dialog for renaming item
    renameItem(item: FileBrowserFile) {
      this.$store.dispatch("Storage/updateItem", item);
      item.rename = false;
    },

    // make sure action can be performed on item
    showItemAction(item: Record<string, any>, context: ItemAction) {
      const { type } = item;
      const { containerCompatible, action } = context;

      // folders and containers (e.g. pointCloud, model etc)
      if (!containerCompatible && type === "folder") {
        return false;
      }

      switch (action) {
        case "download":
          return this.canDownloadItem(item);
        case "share":
          return this.canShareItem(type);
        case "delete":
          return this.canDeleteItem(type);

        default:
          return true;
      }
    },

    /**
     * Determine if item select is available and if the select button should show used in combination with single or multi select.
     *
     * @returns {boolean}
     */
    showSingleSelect(): boolean {
      const dialogWithSelect =
        this.isSelect && (this.isProjectDialog || this.isViewerDialog || this.isAttachmentDialog);
      if (dialogWithSelect) {
        // should select show after root
        if (!this.isAttachmentDialog && this.pathHistory.length < 2) {
          return true;
        }
        return true;
      }
      return false;
    },

    /**
     * If single select is enabled and item is clicked this will handle the selection, i.e. select file and close dialog.
     *
     * Verifies that the item is not undefined and that single select is enabled.
     *
     * @param {FileBrowserFile} item
     * @returns {void}
     */
    singleSelectClick(item?: FileBrowserFile): void {
      if (typeof item !== "undefined" && this.showSingleSelect()) {
        this.selectedFile = [item];
        this.handleSelectedFile();
      }
    },

    /**
     * Determine if clicking item enters it:
     * e.g. folders, containers (point clouds, models) etc.
     *
     * @param {string} type
     * @returns {boolean}
     */
    allowEnterItem(item: Record<string, string>): boolean {
      const { type, id } = item;
      const isSupport = id.split("#")[1] === "support";
      const allowedTypes = ["folder", "model"];
      return allowedTypes.includes(type) || isSupport;
    },

    canOpenItem(type: BrowserOpenFileTypes): boolean {
      const browserOpenFileTypes = Object.values(BrowserOpenFileTypes);
      return browserOpenFileTypes.includes(type);
    },

    /**
     * Items that can be downloaded. Currently restricted to simple items:
     * i.e. not folders/containers
     *
     * @param {string} type
     * @returns {boolean}
     */
    canDownloadItem(item: Record<string, any>): boolean {
      const { type, pointCloudType } = item;
      const pointCloudDownload =
        pointCloudType &&
        pointCloudType !== "potree" &&
        type === "pointCloud" &&
        (Object.values(PointCloudDataFileTypes) as string[]).includes(pointCloudType);

      const ignoredTypes = ["folder", "pointCloud", "model"];
      return pointCloudDownload || !ignoredTypes.includes(type);
    },

    // items that can be shared with a signed URL
    canShareItem(type: string) {
      const ignoredTypes = ["folder", "pointCloud", "model"];
      return !ignoredTypes.includes(type);
    },

    // items that should not have deleted option
    // currently all items can be deleted
    canDeleteItem(type: string) {
      const ignoredTypes: string[] = [];
      return !ignoredTypes.includes(type);
    },

    async enterPath(item: FileBrowserNavigationHistoryObject) {
      const { id, name } = item;

      // exclude the parent id from the path
      // and check if path has passed root.
      // paths are used as id for creating
      // new entries

      const path = id.split("#");
      this.path = path[1] ? path[1] : path[0];

      // get path content
      this.fetchFiles();

      // make sure to not create duplicate entries
      const hasPathInHistory = this.pathHistory.find((item) => item.id === id);
      if (!hasPathInHistory) {
        // push new path to path history
        this.pathHistory.push({ id, name });
      }
      // log to see how it works @dredd746
      // set current path name
      this.currentPathName = name;
    },
    // navigate to path takes one (1) index value (number) and uses
    // index to identify requested path and remove items after index
    navigateToPath(index: number) {
      // assign path history and new object for enter path
      const history = this.pathHistory;
      const path = Object.assign({}, history[index]);

      // delete all items in path history starting after index
      const deleteFrom = history.length - (index - 1);
      history.splice(index, deleteFrom);

      // get new current path content
      this.enterPath(path);
    },

    navigateFromPath() {
      // remove last item in history
      this.pathHistory.pop();

      // enter patch with last item in history
      const previous = this.pathHistory.length - 1;
      const path = this.pathHistory[previous];
      this.enterPath(path);
    },
    // TODO: change sort logic
    sortFileBrowser(items: Record<string, string>[]) {
      items.sort((a, b) => {
        const aType = a.type.toLowerCase();
        const bType = b.type.toLowerCase();
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        // use local compare for return integer
        // see MDN description for details and warning on not using fixed integers
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#description
        return aType.localeCompare(bType) || aName.localeCompare(bName);
      });

      return items;
    },
  },
  watch: {
    // if file browser is served in a dialog
    // look for changes in dialog state
    // populate/clear on dialog open/close
    dialogState() {
      // reset file selection on open/close
      if (this.isSelect) {
        this.selectedFile = [];
      }

      // when dialog opens
      if (this.dialogState) {
        // set root path for file browser content
        this.$store.commit("Storage/setPath", this.initialPath);

        // do initial fetch of root folder
        this.fetchFiles();

        if (this.isViewerDialog) {
          this.pathHistory.push({
            id: this.initialPath,
            name: this.currentPathName,
          });
        }
      } else {
        // when dialog closes
        if (this.isProjectDialog) {
          this.$store.commit("Storage/setPath", "");
          this.$store.commit("Storage/setIsSingleUploadSelect", false);
          this.$store.commit("Storage/resetIsImageResize");
        }
        if (this.isViewerDialog) {
          this.$store.commit("Storage/setPath", "");
          this.$store.commit("Viewers/setUploadingState", false);
          this.pathHistory = [];
        }
        if (this.isAttachmentDialog) {
          this.$store.commit("Storage/setPath", this.$route.params.projectId);
          this.currentPathName = "";
        }
      }
    },
  },
  computed: {
    path: {
      get() {
        return this.$store.getters["Storage/getPath"];
      },
      set(itemPath) {
        this.$store.commit("Storage/setPath", itemPath);
      },
    },
    pathItems() {
      const items = this.$store.getters["Storage/getFileBrowserItems"](
        this.$store.getters["Storage/getPath"]
      );
      // Append the "rename" key to all items. Used to toggle rename dialog in items, this is a ui specific need, and should not be in the database entry object.
      const appendedKeys = items.map((item: FileBrowserFile) => {
        return { ...item, rename: false };
      });

      return appendedKeys;
    },
    isLoading: {
      get() {
        return this.$store.getters["Storage/getLoadingState"];
      },
      set(state) {
        this.$store.commit("Storage/getLoadingState", state);
      },
    },
    currentPathName: {
      get() {
        if (this.isProjectDialog || this.isViewerDialog) {
          // use path to alter home
          // folder name in dialog mode
          const type = this.$store.getters["Storage/getPath"];
          if (type) {
            switch (type) {
              case "cover":
                return "Cover images";
              case "pointCloud":
                return "Point Clouds";
              case "model":
                return "3D Models";
            }
          }
        }

        return this.pathName === "" ? "Home" : this.pathName;
      },
      set(name: string) {
        this.pathName = name;
      },
    },
    loadingMessage() {
      // default message
      let message = "Loading files";

      // if current path name is not "Home" and in a dialog
      if (this.currentPathName !== "Home" && this.dialogState) {
        message = `Loading ${this.currentPathName}`;
      }

      return message;
    },
    emptyMessage() {
      // default message
      let message = "This folder has no files";

      // if current path name is not "Home" and in a dialog
      if (this.currentPathName !== "Home" && this.dialogState) {
        message = `No ${this.currentPathName} uploaded`;
      }

      return message;
    },
  },
  async mounted() {
    const {
      params: { projectId },
    } = this.$route;
    const hasAccess = this.$store.getters["User/getHasProjectFileAccess"](projectId);
    if (projectId && !hasAccess) {
      this.$store.dispatch(
        "User/errorSnackbar",
        "Guest does not have access to project files,redirecting to project list"
      );
      // redirect to project list
      this.$router.push({ name: "projects" });
    }

    // set root path for file browser content
    this.$store.commit("Storage/setPath", this.initialPath);

    // do initial fetch of root folder
    this.fetchFiles();

    this.pathHistory.push({
      id: this.initialPath,
      name: this.currentPathName,
    });
  },
});
