
import Vue from "vue";
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel";

export default Vue.extend({
  name: "ViewerLayerItemActionResetIFCModel",
  props: {
    itemkey: String,
    item: Object,
    type: String,
  },
  methods: {
    ifcResetModel(item: { object: IFCModel; title: string; type: string }) {
      this.$store.dispatch("PointCloudViewer/ifcResetModel", item.object);
    },
  },
});
