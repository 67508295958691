
import Vue from "vue";

export default Vue.extend({
  name: "FileBrowserSelectFileDialog",
  props: {
    pathHistory: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeFileSelect() {
      this.dialog = false;
      this.selectedFiles = [];
    },
    async handleSelectedFiles() {
      // return if in viewer item upload
      if (this.isViewerUploading) {
        return this.viewerUploading();
      }

      // default behaviour
      //await this.$store.dispatch("Storage/uploadActivityFiles");
      await this.$store.dispatch("Storage/uploadFiles");
    },
    async viewerUploading() {
      // spread existing values to the upload item
      // add selected files to upload items array
      this.$store.commit("Viewers/setUploadItem", {
        ...this.$store.getters["Viewers/getUploadItem"],
        items: this.selectedFiles,
      });

      // this.$store.commit("Storage/setUploadDialogState", false);
      this.$store.commit("Viewers/setUploadNameDialogState", true);
    },
  },
  watch: {
    // look for changes in dialog state
    // used to reset values on dialog close
    dialog() {
      this.selectedFiles = [];
      // this.isMultiple = true;
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.getters["Storage/getSelectFileDialogState"];
      },
      set(state: boolean) {
        this.$store.commit("Storage/setSelectFileDialogState", state);
      },
    },
    // showFileInput(): boolean {
    //   return this.$store.getters["Storage/getShowFileInput"];
    // },
    // isUploading(): boolean {
    //   return this.$store.getters["Storage/getIsUploading"];
    // },
    isMultiple: {
      get(): boolean {
        return !this.$store.getters["Storage/getIsSingleUploadSelect"];
      },
      set(state: boolean) {
        this.$store.commit("Storage/setIsSingleUploadSelect", state);
      },
    },
    selectedFiles: {
      get(): File[] {
        return this.$store.getters["Storage/getUploadFiles"];
      },
      set(files: File[]) {
        // const arrayCheck = this.initialSelectedFiles;
        // determine if file is single select object
        const isSingleSelectionFile = !this.isMultiple && !Array.isArray(files);

        // make sure files is not null/empty
        // upload list must always be array
        this.$store.commit(
          "Storage/setUploadFiles",
          !files ? [] : isSingleSelectionFile ? [files] : files
        );
      },
    },
    // uploadFiles: {
    //   get(): Record<string, unknown>[] {
    //     return this.$store.getters["Storage/getUploadFiles"];
    //   },
    //   set(files: Record<string, unknown>[]) {
    //     // determine if file is single select object
    //     const isSingleSelectionFile = !this.isMultiple && !Array.isArray(files);

    //     // make sure files is not null/empty
    //     // upload list must always be array
    //     this.$store.commit(
    //       "Storage/setUploadFiles",
    //       !files ? [] : isSingleSelectionFile ? [files] : files
    //     );
    //   },
    // },
    // uploadProgress(): Record<string, unknown> {
    //   return this.$store.getters["Storage/getFileUploadProgress"];
    // },
    isViewerUploading(): boolean {
      // check if in viewer item in upload process
      // and in root of item category (a.k.a container)
      // if inside a container, revert to default behaviour
      const uploadingState = this.$store.getters["Viewers/getUploadingState"];
      const pathHistory = this.pathHistory.length;
      return uploadingState && pathHistory < 2;
    },
    actionTitle() {
      let title = "upload";

      if (this.isViewerUploading) {
        title = "continue";
      }
      return title;
    },
  },
});
