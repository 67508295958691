import { GetterTree } from "vuex";

import {
  IFCModelItemInspectorInterface,
  ViewerInspectorMode,
  ViewerLayerDataCategoryKey,
} from "@/types";

import { CurrentInspectorTuple, SidebarsState } from "./types";

const sidebarsGetters: GetterTree<SidebarsState, unknown> = {
  getGraphicsInspector: (state) => {
    return state.graphicsInspector;
  },

  getInspectorMode: (state) => {
    return state.inspectorMode;
  },

  getCurrentInspector: (state) => {
    return state.currentInspector;
  },

  getLayerData: (state) => {
    return state.layerData;
  },

  getLeftSidebarIfc: (state) => {
    return state.leftSidebarIfc;
  },

  getLeftSidebar: (state) => {
    return state.leftSidebar;
  },

  getMeasureInspector: (state) => {
    return state.measureInspector;
  },

  getPointcloudInspector: (state) => {
    return state.pointcloudInspector;
  },

  getModelInspector: (state) => {
    return state.modelInspector;
  },

  getRightSidebar: (state) => {
    return state.rightSidebar;
  },

  /**
   * Get the current inspector id.
   *
   * If the current inspector is of type `ViewerInspectorMode.IFC_MODEL_ITEM` the `globalId` will be `id`, and `tableItemId` if applicable, will be returned.
   */
  getCurrentInspectorIds: (state) => {
    const inspector = state.currentInspector;

    const returnObject: {
      id?: string;
      tableItemId?: string;
      modelContainerId?: string;
    } = {};

    const { type } = inspector || {};

    // Set variables based on inspector type.
    switch (type) {
      case ViewerInspectorMode.POINT_OF_INTEREST:
      case ViewerInspectorMode.MEASUREMENT_DISTANCE:
      case ViewerInspectorMode.MEASUREMENT_HEIGHT:
      case ViewerInspectorMode.POINTCLOUD:
        returnObject.id = (
          inspector as Exclude<CurrentInspectorTuple, IFCModelItemInspectorInterface> | undefined
        )?.id;
        break;

      case ViewerInspectorMode.MODEL:
        returnObject.id = (
          inspector as Exclude<CurrentInspectorTuple, IFCModelItemInspectorInterface> | undefined
        )?.id;
        // returnObject.modelContainerId =
        break;

      case ViewerInspectorMode.IFC_MODEL_ITEM:
        returnObject.id = (inspector as IFCModelItemInspectorInterface | undefined)?.globalId;
        returnObject.tableItemId = (
          inspector as IFCModelItemInspectorInterface | undefined
        )?.tableItemId;
        break;
    }

    return returnObject;
  },

  /**
   * Get a converted inspector mode `ViewerInspectorMode` to a layer data category key `ViewerLayerDataCategoryKey`.
   */
  getInspectorModeAsLayerCategory: (state) => {
    let category;
    switch (state.inspectorMode) {
      case ViewerInspectorMode.POINTCLOUD:
        category = ViewerLayerDataCategoryKey.POINTCLOUDS;
        break;

      case ViewerInspectorMode.MODEL:
        category = ViewerLayerDataCategoryKey.MODELS;
        break;

      case ViewerInspectorMode.IFC_MODEL_ITEM:
        category = ViewerInspectorMode.IFC_MODEL_ITEM;
        break;

      case ViewerInspectorMode.POINT_OF_INTEREST:
        category = ViewerLayerDataCategoryKey.POINT_OF_INTEREST;
        break;

      case ViewerInspectorMode.MEASUREMENTS:
        category = ViewerLayerDataCategoryKey.MEASUREMENTS;
        break;
    }

    return category;
  },
  getIfcCurrentModelIdInLeftSideBar(state) {
    return state.ifcCurrentModelIdInLeftSideBar;
  },
  getIfcCurrentMaterial: (state) => {
    return state.ifcCurrentMaterial;
  },

  getSelectedLayerItemId: (state) => {
    return state.selectedLayerItemId;
  },
};

export default sidebarsGetters;
