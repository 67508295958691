// Viewer types
import { Color, LineSegments, Material } from "three";
import { FlatMesh, Vector } from "web-ifc/web-ifc-api";

import { InspectorAttachmentTypeInterface, ModelInpsectorAttachmentInterface } from "./attachment";
import { AuthorInterface, XYZInterface } from "./general";
import { ModelTransformInterface, ModelTypes } from "./model";
import { TagItemInterface } from "./tag";

export type AcceptedUploadTypesSingular = "pointCloud" | "model";
export type AcceptedUploadTypesPlural = "pointClouds" | "models";

export type SupportedModelTypes = ModelTypes.IFC | ModelTypes.FBX;

export interface ModelData {
  attachments?: InspectorAttachmentTypeInterface[];
  enabled: boolean;
  id: string;
  path: string;
  title: string;
  transform: {
    position: XYZInterface;
    rotation: XYZInterface;
    scale: XYZInterface;
  };
  type?: string;
}

export interface PotreePointsInterface {
  classification: [];
  intensity: [];
  position: XYZInterface;
  rgba: [];
}

export interface PotreeAnnotationInterface {
  cameraPosition: string[];
  domElement: HTMLElement;
  position: string[];
  title: string;
  _visible: boolean;
}

export interface PotreePoint {
  annotation: PotreeAnnotationInterface;
  cameraPosition: XYZInterface;
  closed: boolean;
  points: PotreePointsInterface[];
  showArea: boolean;
  showCoordinates: boolean;
  showDistances: boolean;
  showHeight: boolean;
  title: string;
  uuid: string;
  visible: boolean;
}

export interface MeasureData {
  attachments: InspectorAttachmentTypeInterface[];
  cameraPosition: XYZInterface[];
  id: string;
  points: XYZInterface[];
  title: string;
  type: string;
  viewerId: string;
}

export interface LayerEditorPointData {
  attachments?: InspectorAttachmentTypeInterface[];
  cameraPosition: XYZInterface[];
  createdAt: number;
  createdBy: AuthorInterface;
  id: string;
  object: PotreePoint;
  points: XYZInterface[];
  showAnnotation: boolean;
  title: string;
  type: ViewerLayerDataCategoryKey;
  updatedAt?: number;
  updatedBy?: AuthorInterface;
  viewerId: string;
}

export interface LayerEditorModelData {
  attachments?: InspectorAttachmentTypeInterface[];
  id: string;
  object: Record<string, any>;
  title: string;
  type: string;
}

export interface LayerEditorPointcloudData {
  attachments?: InspectorAttachmentTypeInterface[];
  id: string;
  object: Record<string, any>;
  title: string;
  type: ViewerLayerDataCategoryKey;
}

export interface PointCloudData {
  attachments?: InspectorAttachmentTypeInterface[];
  camera: {
    lookat: XYZInterface;
    position: XYZInterface;
  };
  enabled: boolean;
  id: string;
  path: string;
  title: string;
  transform: {
    position: XYZInterface;
    rotation: XYZInterface;
    scale: XYZInterface;
  };
}

export enum ViewerLayerDataCategoryKey {
  MEASUREMENTS = "measurements",
  MODELS = "models",
  POINT_OF_INTEREST = "pointOfInterest",
  POINTCLOUDS = "pointclouds",
}

export enum ViewerBackground {
  GRADIENT = "gradient",
  SOLID = "solid",
  SKYBOX = "skybox",
}
export interface ViewerInterface {
  // Potree related data.
  background: ViewerBackground;
  controls?: any;
  createdAt: number;
  createdBy: AuthorInterface;
  description: string;
  edlOpacity: number;
  edlRadius: number;
  edlStrength: number;
  fitToScreen?: any;
  fov: number;
  id: string;
  image: string;
  measuringTool?: any;
  models: ModelData[];
  pointBudget: number;
  pointClouds: PointCloudData[];
  pointColorType: string;
  pointShape: string;
  pointSize: number;
  pointSizeType: string;
  scene?: any;
  setBackground?: any;
  setEDLOpacity: (num: number) => void;
  setEDLRadius: (num: number) => void;
  setEDLStrength: (num: number) => void;
  useEDL: boolean;
  edlEnabled: boolean;
  setFOV?: any;
  setPointBudget?: any;
  setTopView: () => void;
  tags?: TagItemInterface[];
  title: string;
  updatedAt?: number;
  updatedBy?: AuthorInterface;
}

// IFCStoreyItem
export interface IFCStoreyGroupItemsInterface {
  id: number;
  ids: number[];
  displayname: string;
  globalId: string;
  attachments?: InspectorAttachmentTypeInterface[];
}

// IFCStoreyGroup that holds IFCStoreyItems
export interface IFCStoreyGroupInterface {
  id: number;
  ids: number[];
  displayname: string;
  items: IFCStoreyGroupItemsInterface[];
}

// IFCStorey that holds IFCStoreyGroups
export interface IFCStoreyInterface {
  id: number;
  ids: number[];
  displayname: string;
  groups: IFCStoreyGroupInterface[];
}
/**
 * Keeps track of current selection using:
 * modelId for current ifcModel
 * itemId is the parentobject of all itemChildrenIds & is the object we select
 * itemChildrenIds holds item-ids we use/used to create a selection-subset
 * and is only a part of all expressIds for the ifc-model, the ones we
 * found when picking
 *
 */
export interface currentSelection {
  modelId: number;
  itemId: number;
  itemChildrenIds: number[];
}

export interface IFCModelItemInterface {
  /**
   * Any attachments associated with model.
   */
  attachments?: ModelInpsectorAttachmentInterface[];

  /**
   * Durring `destructureIfcModelOnLoad` we will add `storeys` as auto generated IFC tags to the model.
   *
   * @type {?TagItemInterface[]}
   */
  autoTags?: TagItemInterface[];

  /**
   * All ids in parsed IFC model. I.e. result of `ifcManager.ifcAPI.LoadAllGeometry(modelId)`.
   */
  ids: number[];

  /**
   * Array of isolated (i.e. all others are hidden) IFC items.
   */
  isolated: number[];

  /**
   * Array of hidden IFC items.
   */
  hidden: number[];

  /**
   * Currently highlighted IFC item.
   */
  highlighted?: number;

  /**
   * Model sort key on partion key `FILE`. Container starts with `model`.
   * Using `modelContainerId` you can extract model file or associate with model container.
   *
   * e.g. query `pk: FILE and begins with sk: [modelContainerId]` and filter type `ifc` (in case model container has multiple files). `objectName` in response is reference to bucket item.
   */
  modelContainerId: string;

  /**
   * Internally parsed (by web-ifc) model id (iterated numbers, i.e. 0, 1, etc).
   */
  modelId: number;

  /**
   * Destructured storeys (a.k.a floors) from parsed IFC.
   */
  storeys: IFCStoreyInterface[];

  /**
   * Model transform key values, e.g. `position`, `scale` and `rotation.
   */
  transform: ModelTransformInterface;
  /**
   * This object upholds references between mesh <-> mesh edge
   */
  wireMeshAssociations: WireMeshAssociations;
  /**
   * This object holds keep track of any wiremesh-items that are hidden
   * When we want to show full model on reset we use this list to know which specific groups have been
   * edited & we need to rebuild the wireframe for.
   * This is an optimation so we don't have to rebuild all
   * wireframes on reset or update. Just use this list of groupIds to rebuild wireframes using the fetched models.
   */
  groupsWithWireMeshItemsHidden: number[];
  /**
   * List of materials on model
   */
  materials: Material[];
}

export type wireMeshAssociations = Array<Array<number | LineSegments>>;

export interface IFCItemDisplaynameInterface {
  displayname: string;
  id: number;
}

export interface ModelGeometry {
  _data: Vector<FlatMesh>;
  _size: number;
}

/**
 * Object type category. Used in logic to determine object type.
 *
 * @export
 * @enum {number}
 */
export enum ViewerObjectTypeCategory {
  ATTACHMENT = "attachment",
  MEASUREMENT = "measurement",
  MEASUREMENT_DISTANCE = "measurementDistance",
  MEASUREMENT_HEIGHT = "measurementHeight",
  MEASUREMENTS = "measurements",
  MODELS = "models",
  POINT_OF_INTEREST = "pointOfInterest",
  POINTCLOUDS = "pointclouds",
  TAG = "tag",
}

/**
 * Possible types when create point in action dispacth `createPoint`.
 *
 * @export
 * @enum {number}
 */
export enum CreatePointType {
  MEASUREMENT_DISTANCE = "measurementDistance",
  MEASUREMENT_HEIGHT = "measurementHeight",
  POINT_OF_INTEREST = "pointOfInterest",
}

export enum ViewerInspectorMode {
  IFC_MODEL_ITEM = "ifcModelItem",
  MEASURE = "measure",
  MEASUREMENT_DISTANCE = "measurementDistance",
  MEASUREMENT_HEIGHT = "measurementHeight",
  MEASUREMENTS = "measurements",
  MODEL = "model",
  POINT_OBJECT = "pointObject",
  POINT_OF_INTEREST = "pointOfInterest",
  POINTCLOUD = "pointcloud",
  VIEWER_SETTINGS = "viewerSettings",
}

/**
 * Object type display title. Used when displaying object type category in UI.
 *
 * @export
 * @enum {number}
 */
export enum ViewerObjectTypeDisplayTitle {
  MEASUREMENT = "Measurement",
  MEASUREMENT_DISTANCE = "Distance measurement",
  MEASUREMENT_HEIGHT = "Height measurement",
  MEASUREMENTS = "Measurements",
  MODELS = "Models",
  POINT_OF_INTEREST = "Point of Interest",
  POINTCLOUDS = "Pointclouds",
  TAG = "Tag",
}

/**
 * Point size type. Used in logic to determine point size type.
 *
 * @export
 * @enum {number}
 */
export enum ViewerPointSizeType {
  ADAPTIVE = "ADAPTIVE",
  ATTENUATED = "ATTENUATED",
  FIXED = "FIXED",
}

/**
 * Point shape. Used in logic to determine point shape.
 *
 * @export
 * @enum {number}
 */
export enum ViewerPointShape {
  CIRCLE = "CIRCLE",
  PARABOLOID = "PARABOLOID",
  SQUARE = "SQUARE",
}

/**
 * Point color type. Used in logic to determine point color type.
 *
 * @export
 * @enum {number}
 */
export enum ViewerPointColorType {
  COLOR = "COLOR",
  DEPTH = "DEPTH",
  ELEVATION = "ELEVATION",
  HEIGHT = "HEIGHT",
  RGB = "RGB",
}

export type WireMeshAssociations = Array<Array<number | LineSegments>>;

/**
 * Used in wireframeHandler to dispatch logic flow
 * SHOW = "Show wireframe",
 * HIDE = "Hide wireframe",
 * ISOLATE = "Isolate by group or storey id",
 * UPDATE = "Update wireframe", : used when layer is hidden or other context changes occur
 * ISOLATE_TAGS can be anything,storey,group,items
 * @export
 * @enum {number}
 */
export enum WireframeAction {
  SHOW = "Show wireframe",
  HIDE = "Hide wireframe",
  ISOLATE = "Isolate by group or storey id",
  UPDATE = "Update wireframe",
  RESET = "Reset wireframe",
  HIDE_ITEM = "Hide wireframe item",
  SHOW_ITEM = "Show wireframe item",
  ISOLATE_TAGS = "Isolate ids we got from tags",
}

export enum CameraMode {
  ORTHOGRAPHIC = "orthographic",
  PERSPECTIVE = "perspective",
  VR = "vr",
}

export enum PointSizeType {
  FIXED = 0,
  ATTENUATED = 1,
  ADAPTIVE = 2,
}

export enum PointShape {
  SQUARE = 0,
  CIRCLE = 1,
  PARABOLOID = 2,
}

export interface PointCloudDialogItemData {
  name: string;
}
