import { GetterTree } from "vuex";

import { State } from "../types";
import { SettingsState } from "./types";

const settingsGetters: GetterTree<State & SettingsState, unknown> = {
  getViewerCanvas(state) {
    return state.viewerCanvas;
  },
};
export default settingsGetters;
