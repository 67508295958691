import { Roles } from "@/types/enums";
import { SnackbarColors } from "@/types/vuetify";

import { State } from "./types";

const state: State = {
  currentPath: undefined,
  currentPathName: undefined,
  userRoles: [Roles.OWNER, Roles.ADMIN, Roles.USER],
  snackbar: {
    visible: false,
    title: "",
    message: "",
    icon: "",
    color: SnackbarColors.BLUE,
    timeout: 5,
  },
};

export default state;
