
import Vue from "vue";

import IFCStoreyGroupRecursive from "./storey-group-recursive.vue";

export default Vue.extend({
  name: "IFCStoreyGroup",
  components: {
    IFCStoreyGroupRecursive,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCurrentLeftSideBarModelId() {
      return this.$store.getters["PointCloudViewer/getIfcCurrentModelIdInLeftSideBar"];
    },
  },
});
