
import Vue from "vue";

export default Vue.extend({
  name: "AttchmentChipType",
  props: {
    type: {
      type: String,
      default: "",
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pointer() {
      return this.isSelect ? { cursor: "pointer" } : {};
    },
    color(): string {
      switch (this.type) {
        case "note":
          return "primary";
        case "link":
          return "purple";
        case "file":
          return "warning";
        default:
          return "";
      }
    },
  },
});
