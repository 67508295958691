import { GetterTree } from "vuex";

import { FileBrowserFile, FileBrowserFolder } from "@/types/file";

import { State } from "./types";

const getters: GetterTree<State, unknown> = {
  getLoadingState: (state) => {
    return state.loading;
  },

  getPath: (state) => {
    return state.path;
  },

  getFetchedModels: (state) => {
    return state.fetchedModels;
  },

  getFetchedWireframes: (state) => {
    return state.fetchedWireframes;
  },

  getFileBrowserDialogState: (state) => {
    return state.fileBrowserDialog;
  },

  getDeleteDialogState: (state) => {
    return state.deleteDialog;
  },

  getShareDialogState: (state) => {
    return state.shareDialog;
  },

  getCreateFolderDialogState: (state) => {
    return state.createFolderDialog;
  },

  getUploadDialogState: (state) => {
    return state.uploadDialog;
  },

  getCancelUploadDialogState: (state) => {
    return state.cancelUploadDialog;
  },

  getSelectFileDialogState: (state) => {
    return state.selectFileDialog;
  },

  getShareUrl: (state) => {
    return state.shareUrl;
  },

  getContextItem: (state) => {
    return state.contextItem;
  },

  getIsImageResize: (state) => {
    return state.isImageResize;
  },

  getIsSingleUploadSelect: (state) => {
    return state.isSingleUploadSelect;
  },

  getIsProfileImage: (state) => {
    return state.isProfileImage;
  },

  getItemInfoDialogState(state) {
    return state.itemInfoDialog;
  },

  getRenameItemDialogState(state) {
    return state.renameItemDialog;
  },

  getShowFileInput: (state) => {
    return state.showFileInput;
  },

  getUploadFiles: (state) => {
    return state.uploadFiles;
  },

  getUploadAllFiles: (state) => {
    return state.uploadAllFiles;
  },

  getFilesInFolder: (state) => {
    return state.filesInFolder;
  },

  getTotalUploadSize: (state) => {
    return state.totalUploadSize;
  },

  getEsitmatedUploadTime: (state) => {
    return state.esitmatedUploadTime;
  },

  getIsUploading: (state) => {
    return state.isUploading;
  },

  getProjectStorageRoot: (state) => {
    return state.projectStorageRoot;
  },

  getFileBrowserItems: (state) => (folderId: string) => {
    const findFiles = state.files.find((folder) => folder.id === folderId);
    let files: (FileBrowserFile | FileBrowserFolder | null)[] = [];

    if (findFiles) {
      files = findFiles.files;
    }

    return files;
  },

  getFileUploadProgress: (state) => {
    return state.fileUploadProgress;
  },
};

export default getters;
